@use 'defaults';
@use 'util';

$splash-action-max-width: 180px;

$splash-body-width: 15em;

$splash-title-font: 1.2em;
$splash-subtitle-font: 1em;
$splash-servers-margin-value: 1em;

$splash-spinner-spacing: 2em;

// These variables are set to match with native splashscreen logo
$splash-logo-width: 158px;
$splash-logo-height: 36px;

.main-splash {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: defaults.$color-background;
  z-index: 2;
  transition: opacity defaults.$standard-animation-time;
  opacity: 1;

  &.ng-hide {
    opacity: 0;
  }
}

.bas-splash {

  .loading-info-container,
  .home-button-container {
    flex: 1;
  }

  .bas-splash-content-max-width {
    max-width: $splash-body-width;
  }

  .bas-splash-title,
  .bas-splash-title-spinner,
  .bas-splash-subtitle,
  .bas-splash-action,
  .bas-splash-home-button {
    display: none;
  }

  &.bas-splash-show-title {
    .bas-splash-title {
      display: block;
      font-size: $splash-title-font;
      padding-top: $splash-servers-margin-value;
      color: defaults.$color-text-light-secondary;
    }
  }

  &.bas-splash-show-title-spinner {
    .bas-splash-title-spinner {
      display: block;
    }
  }

  &.bas-splash-show-subtitle {
    .bas-splash-subtitle {
      display: block;
      font-size: $splash-subtitle-font;
      color: defaults.$color-text-light-secondary;
    }
  }

  &.bas-splash-show-action {
    .bas-splash-action {
      display: inline-block;
      max-width: $splash-action-max-width;
    }
  }

  &.bas-splash-show-home-button {
    .bas-splash-home-button {
      display: block;
      max-width: $splash-action-max-width;
    }
  }
}

// On Lisa device some contents are hidden in splash screen
// because of the smaller screen size.
.bas-device-lisa .bas-splash {
  &.bas-splash-show-action {
    .bas-splash-action {
      display: none;
    }
  }

  &.bas-splash-show-title-spinner {
    .bas-splash-title-spinner {
      display: none;
    }
  }

  // Showing and hiding between three dots and two dots (ellipsis)
  // depends on which splash title is shown.
  &.bas-show-ellipsis-three-dots {
    .bas-splash-title {
      @include util.bas-append-ellipsis-three-dots;
    }
  }

  &.bas-show-ellipsis-two-dots {
    .bas-splash-title {
      @include util.bas-append-ellipsis-two-dots;
    }
  }
}

.bas-splash-title-spinner {
  margin-bottom: $splash-spinner-spacing;
  margin-top: $splash-spinner-spacing;
  width: 36px;

  @media (max-width: #{defaults.$media-width-xxsmall}) {
    width: 24px;
  }
}

.bas-splash-logo {
  width: $splash-logo-width;
  height: $splash-logo-height;
  fill: defaults.$color-foreground;
}
