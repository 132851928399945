@use 'defaults';
@use 'util';

$bd-core-width-big: 500px;
$bd-core-width: 300px;
$bd-core-side-spacing: 16px;
$bd-core-info-icon-size: 24px;
$bd-core-cloud-icon-size: 20px;
$bd-core-thrash-icon-size: 32px;

$bas-core-top-background-image: linear-gradient(
  to bottom,
  rgba(0, 0, 0, .7) 0%,
  rgba(0, 0, 0, .45) 50%,
  rgba(0, 0, 0, .35) 70%,
  transparent 100%
);
$bas-core-bottom-background-image: linear-gradient(
  to top,
  rgba(0, 0, 0, .7) 0%,
  rgba(0, 0, 0, .35) 45%,
  rgba(0, 0, 0, .25) 80%,
  transparent 100%
);

// bd = Bas Discovery

.bd-image-container {
  @include util.button-cursor();

  position: relative;
  padding-bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  fill: defaults.$color-highlight-muted;
  background-color: defaults.$color-background-dark-on-dark;
}

.bd-core-info-icon {
  svg {
    fill: defaults.$color-grey;
  }
}

.bd-core-info-icon-width {
  width: $bd-core-info-icon-size;
}

.bd-core-info-icon-height {
  height: $bd-core-info-icon-size;
}

.bd-cloud-icon {
  position: relative;
  min-width: $bd-core-cloud-icon-size;
  height: $bd-core-cloud-icon-size;

  svg {
    fill: defaults.$color-grey;
    height: 100%;
  }

  &.bd-dark-cloud {

    svg {
      fill: defaults.$color-background-shadow;
    }
  }
}

.bd-trash-icon {
  width: $bd-core-thrash-icon-size;
  height: $bd-core-thrash-icon-size;

  svg {
    width: 100%;
  }
}

.bd-core-slide-content {
  width: calc(
      100% - #{2 * (defaults.$bs-swiper-nav-size + $bd-core-side-spacing)}
    );
  max-width: 80vh;

  // Experimentally determined

  @media (min-height: #{$bd-core-width + 56px}) {
    max-width: $bd-core-width;
  }

  @media (min-width: defaults.$media-query-pc-landscape) {
    max-width: $bd-core-width-big;
  }
}

.bd-change-account-btn-container {

  &::after {
    content: '';
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 8px;

    // Experimentally determined

    @media (min-height: #{$bd-core-width + 100px}) {
      flex-basis: 24px;
    }

    @media (min-height: #{$bd-core-width + 200px}) {
      flex-basis: 36px;
    }

    @media (min-height: #{$bd-core-width + 300px}) {
      flex-basis: 64px;
    }

    @media (min-height: #{$bd-core-width + 400px}) {
      flex-basis: 92px;
    }
  }
}

.bd-core {
  // Experimentally determined
  font-size: 0.85rem;

  @media (min-width: #{$bd-core-width + 100px}) {
    font-size: 1rem;
  }
}

// Bas Discovery logic

.bas-discovery {
  .bd-title,
  .bd-placeholder {
    display: none;
  }

  &.bas-discovery--show-title {
    .bd-title {
      display: block;
    }
  }

  &.bas-discovery--show-placeholder {
    .bd-placeholder {
      display: block;
    }
  }
}

// Bas Discovered core

.bd-cloud-icon-integrator-access {
  display: none;
}

.bas-device--pro-live {

  .bd-cloud-icon-integrator-access {
    display: block;
  }
}

.bd-core {

  .bas-image-parent {

    &.bas-image--valid:not(.bas-image--default) {

      .bd-core-info-top {
        background-image: $bas-core-top-background-image;
      }

      .bd-core-info {
        background-image: $bas-core-bottom-background-image;
      }
    }
  }

  .bd-trash-icon,
  .bd-core-info-icon,
  .bd-cloud-icon-online,
  .bd-cloud-icon-integrator-access,
  .bas-project-online-cloud-icon,
  .bas-project-offline-cloud-icon,
  .bas-project-integrator-access-granted-icon,
  .bas-project-integrator-access-disabled-icon,
  .bd-core-location {
    display: none;
  }

  .bd-core-info-top {
    opacity: 0;
  }

  &.discovered-core-show-extra-info {
    .bd-core-info-top {
      opacity: 1;
    }
  }

  &.discovered-core-has-location {
    .bd-core-location {
      display: block;
    }
  }

  &.discovered-core-is-remote {

    .bd-core-info-icon,
    .bd-cloud-icon-online,
    .bas-project-offline-cloud-icon,
    .bas-project-integrator-access-disabled-icon {
      display: block;
    }

    &.discovered-core-is-online {

      .bas-project-online-cloud-icon {
        display: block;
      }

      .bas-project-offline-cloud-icon {
        display: none;
      }
    }

    &.discovered-core--integrator-access-granted {

      .bas-project-integrator-access-granted-icon {
        display: block;
      }

      .bas-project-integrator-access-disabled-icon {
        display: none;
      }
    }
  }

  &.discovered-core-is-deletable {
    .bd-trash-icon {
      display: flex;
    }
  }

  &.discovered-core-is-selected {
    .bd-image-container {
      opacity: 0.75;
    }
  }
}

// "Change account" button
// Only available on environments that support Basalte account

.bd-change-account-btn-container,
.bd-top-filler,
.bd-change-account-btn {
  display: none;
}

.bas-device--supports-live {
  .bd-change-account-btn-container {
    display: flex;
  }

  .bd-top-filler,
  .bd-change-account-btn {
    display: block;
  }
}
