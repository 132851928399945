@use 'sass:math';
@use 'defaults';

// TODO Use defaults value for color
$bas-spinner-color: #aaa;
$bas-spinner-number-blades: 12;
$bas-spinner-animation-time: 1s;
$bas-spinner-blade-width: 5%;
$bas-spinner-blade-height: 25%;
$bas-spinner-blade-border-radius: 1em;
$bas-spinner-blade-transform-origin-y: math.div(50%, $bas-spinner-blade-height) * 100%;

.bas-spinner-blade {
  display: block;
  position: absolute;
  top: 0;
  left: 50% - $bas-spinner-blade-width * 0.5;
  width: $bas-spinner-blade-width;
  height: $bas-spinner-blade-height;
  border-radius: $bas-spinner-blade-border-radius;
  background-color: $bas-spinner-color;
  transform-origin: 50% $bas-spinner-blade-transform-origin-y;
  transform: rotate(0deg);
  opacity: 0;
  animation: bas-spinner-blade-fade $bas-spinner-animation-time infinite linear;
  will-change: opacity;
}

$bas-spinner-blade-opacity-step: math.div(1, $bas-spinner-number-blades);
$bas-spinner-blade-angle: math.div(360deg, $bas-spinner-number-blades);
$bas-spinner-delay-step: math.div($bas-spinner-animation-time, $bas-spinner-number-blades);

@for $i from 1 through $bas-spinner-number-blades {
  .bas-spinner-blade-#{$i} {
    transform: rotate(($i - 1) * $bas-spinner-blade-angle);
    animation-delay: -$bas-spinner-animation-time + ($i - 1) * $bas-spinner-delay-step;
  }
}

@keyframes bas-spinner-blade-fade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// Swooping spinner

$cycle-duration: 1.05s;
$rotate-duration: #{0.8 * $cycle-duration};
$border-width: 4px;

.bas-spinner-swoop {
  display: inline-block;
  animation-name: bas-spinner-swoop-anim-spinner;
  animation-duration: $rotate-duration;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-play-state: running;
  animation-fill-mode: both;
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  .bas-spinner-swoop-circle {
    width: 100%;
    height: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    .bas-spinner-swoop-circle-inner {
      box-sizing: border-box;
      display: block;
      content: '';
      transform: rotate(45deg);
      border-radius: 50%;
      border-top: $border-width solid defaults.$color-highlight-on-dark;
      border-left: $border-width solid defaults.$color-highlight-on-dark;
      border-right: $border-width solid transparent;
      border-bottom: $border-width solid transparent;
      width: 100%;
      height: 200%;
      animation-name: bas-spinner-swoop-anim-circle-1;
      animation-delay: 0s;
      animation-duration: $cycle-duration;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: cubic-bezier(.5, 0, .5, 1);
      animation-fill-mode: both;
      animation-play-state: running;
    }
  }

  .bas-spinner-swoop-circle-2 {
    transform: rotate(180deg);
    top: 50%;

    .bas-spinner-swoop-circle-inner {
      animation-name: bas-spinner-swoop-anim-circle-2;
    }
  }

  @keyframes bas-spinner-swoop-anim-circle-1 {
    from {
      transform: rotate(60deg);
    }

    to {
      transform: rotate(205deg);
    }
  }

  @keyframes bas-spinner-swoop-anim-circle-2 {
    from {
      transform: rotate(-40deg);
    }

    to {
      transform: rotate(-115deg);
    }
  }

  @keyframes bas-spinner-swoop-anim-spinner {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
