@use 'defaults';
@use 'util';

$marquee-font-size: 1em;
$marquee-item-margin: auto .5em;
$marquee-item-big-font: 1.4em;
$marquee-item-small-font: 1em;

.bas-marquee-container {
  display: block;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
}

.bas-marquee-mask {
  mask: linear-gradient(
      to right,
      transparent 0%,
      black 5%,
      black 95%,
      transparent 100%
    );
}

.bas-marquee-wrapper {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  margin-left: 1em;
  margin-right: 1em;
  height: 1.9em;
}

.bas-marquee-item {
  @include defaults.font-roboto-light;

  display: inline-block;
  position: relative;
  margin: $marquee-item-margin;
  text-align: right;
  font-size: $marquee-item-small-font;
  color: defaults.$color-foreground;

  &.big {
    @include defaults.font-roboto-regular;

    font-size: $marquee-item-big-font;
  }
}

.bas-marquee-item-wrap {
  display: inline;
  margin-right: 5em;
}

.bas-marquee-divider {
  display: inline-block;
  position: relative;
  width: 1px;
  margin: $marquee-item-margin;
  background: defaults.$color-highlight-on-dark;
}

.bas-marquee-hidden {
  display: none;
}

.bas-marquee-divider-placeholder {
  @include defaults.font-roboto-regular;

  opacity: 0;
  font-size: $marquee-item-big-font;
}

// bmq = bas-marquee

.bmq-wrapper {
  white-space: nowrap;

  .bmq-1 {
    max-width: 100%;
  }

  .bmq-2 {
    display: none;
  }

  .bmq-title {
    @include util.ellipsis-overflow;

    flex: 1 1 auto;
    display: none;
  }

  .bmq-separator {
    flex: 0 0 auto;
    display: none;
  }

  .bmq-subtitle {
    @include util.ellipsis-overflow;

    flex: 1 1 auto;
    display: none;
  }

  &.bmq-has-title {

    .bmq-title {
      display: inline-block;
    }
  }

  &.bmq-has-subtitle {

    .bmq-subtitle {
      display: inline-block;
    }
  }

  &.bmq-has-title.bmq-has-subtitle {

    .bmq-separator {
      display: inline-block;
    }
  }

  &.bmq-marquee {

    .bmq-1 {
      max-width: unset;
    }

    .bmq-title {
      flex: 0 0 auto;
      text-overflow: clip;
    }

    .bmq-subtitle {
      flex: 0 0 auto;
      text-overflow: clip;
    }

    &.bmq-too-small {

      .bmq-1,
      .bmqc-invisible {
        padding-right: 2em;
      }

      .bmq-2 {
        display: inline-flex;
      }
    }
  }
}

.bmq-moving-box {
  white-space: nowrap;
}

.bmqc {
  display: inline-flex;
  flex-flow: row nowrap;
  white-space: nowrap;
}

.bmqc-invisible {
  position: absolute;
  display: block;
  white-space: nowrap;
  opacity: 0;
}

.bmq-el {
  display: inline-block;
  color: defaults.$color-foreground;
  white-space: nowrap;
  overflow: hidden;
}

.bmq-separator {
  position: relative;
  display: inline-block;
  width: 1px;
  height: 1.8em;
  margin: 0 .6em;
  background-color: defaults.$color-highlight-on-dark;
  white-space: nowrap;
}

.bmq-title {
  @include defaults.font-roboto-regular;

  margin-top: .05em;
  font-size: 1.4em;
}

.bmq-subtitle {
  @include defaults.font-roboto-light;

  margin-top: .4em;
}
