@use 'sass:math';
@use 'defaults';
@use 'util';

// Alarms new
/////////////

$alarm-font-size: 16px;
$alarm-font-size-phone: 12px;

$alarm-main-header-height: 3em;
$alarm-main-header-height-phone: 4em;
$alarm-main-header-background: #ebebeb;

$alarm-main-header-back-width: 24px;
$alarm-main-header-back-margin: 0 .6em 0 0;

$alarm-main-header-title-font: 1em;
$alarm-main-header-title-font-phone: 1.2em;

$alarm-main-section-padding: 0 0 .6em 0;

$alarm-main-input-time-font: 2.6em;
$alarm-main-input-time-font-phone: 1.8em;
$alarm-main-input-time-height: math.div(4em, $alarm-main-input-time-font) * 1em;
$alarm-main-input-time-height-phone: math.div(4em, $alarm-main-input-time-font-phone) * 1em;
$alarm-main-input-time-margin: 0 0 .4em 0;

$alarm-main-element-width: 95%;
$alarm-main-element-margin: 0 ((100% - $alarm-main-element-width) * 0.5);
$alarm-main-element-margin-padding: .4em 0;
$alarm-main-element-padding: .4em .6em;
$alarm-main-element-title-font: 1em;
$alarm-main-element-title-font-phone: 1.2em;
$alarm-main-element-content-offset: 1em;
$alarm-main-element-content-padding: 0 0 0 $alarm-main-element-content-offset;
$alarm-main-element-content-margin: 0 0 0 $alarm-main-element-content-offset;

$alarm-main-header-padding: 0 1em;

$alarm-main-options-translate-show: -(100% + (100% - $alarm-main-element-width));

$alarm-main-type-header-toggle-margin: 0 0 0 .4em;

$alarm-main-type-input-width: 90%;
$alarm-main-type-input-max-width: 90%;
$alarm-main-type-input-height: 1.8em;
$alarm-main-type-input-margin: .8em auto;
$alarm-main-type-input-padding: .2em .4em;
$alarm-main-type-input-font: 1em;
$alarm-main-type-input-line-height: 1.8em;
$alarm-main-type-input-border-radius: 50px;

$alarm-main-element-volume-content-width: calc(100% - #{$alarm-main-element-content-offset} - 1em);
$alarm-main-element-volume-label-width: 2.7em;
$alarm-main-element-volume-label-font: 1em;
$alarm-main-element-volume-label-font-phone: 1.4em;

$alarm-main-remove-font: 1em;
$alarm-main-remove-font-phone: 1.2em;
$alarm-main-remove-margin: .5em auto 0 auto;
$alarm-main-remove-padding: .4em 1em .5em 1em;
$alarm-main-remove-border-radius: 50px;
$alarm-main-remove-border: 1px solid defaults.$color-highlight-on-dark;

$alarm-main-attribute-active-background: rgba(defaults.$color-background, .2);
$alarm-main-attribute-padding-phone: .6em 0;
$alarm-main-attribute-current-font: .8em;
$alarm-main-attribute-current-font-phone: 1em;
$alarm-main-attribute-icon-margin: 0 .4em;
$alarm-main-attribute-icon-size: 24px;
$alarm-main-attribute-warning-size: 24px;

$alarm-options-section-header-padding: .4em 0;

$alarm-options-section-header-title-margin: 0 .4em 0 0;

$alarm-options-section-name-margin: .4em;
$alarm-options-section-name-font: 1.2em;
$alarm-options-section-name-color: #ababab;
$alarm-options-section-name-line-color: #ababab;
$alarm-options-section-name-offset: .5em;

$alarm-options-section-item-padding: .4em 0;
$alarm-options-section-item-color: defaults.$color-text-dark;
$alarm-options-section-item-border: 1px solid #f0f0f0;
$alarm-options-section-item-background-active: rgba(#898989, .3);

$alarm-options-selector-size: 26px;
$alarm-options-selector-inner-size: 20px;
$alarm-options-selector-margin: 0 .4em 0 0;
$alarm-options-selector-border: 1px solid defaults.$color-highlight-on-dark;

$alarm-main-message-width: 90%;
$alarm-main-message-line-font-phone: 1.2em;

$alarm-main-message-new-margin: 1em auto .4em auto;

// Alarm Main
/////////////

// Alarm Main Header

.alarm-main-header {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: $alarm-main-header-height;
  padding: $alarm-main-header-padding;
  font-size: $alarm-font-size;
  background-color: $alarm-main-header-background;
  box-sizing: border-box;

  @media (max-width: #{defaults.$media-phone-width}) {
    height: defaults.$main-header-height-phone;
    font-size: $alarm-font-size-phone;
  }
}

.alarm-main-header-back {
  position: relative;
  flex: 0 0 auto;
  width: $alarm-main-header-back-width;
  margin: $alarm-main-header-back-margin;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-dark-control-buttons;
    transform: translate(-250%, -50%);
    transition: transform defaults.$standard-animation-time ease-in-out;
  }

  &:active {
    > svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }

  &.alarm-main-options-show {
    > svg {
      transform: translate(-50%, -50%);
    }
  }
}

.alarm-main-header-title {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  min-width: 0;
  font-size: $alarm-main-header-title-font;
  transform: translateX(-25px);
  transition: transform defaults.$standard-animation-time ease-in-out;

  &.alarm-main-options-show {
    transform: translateX(0);
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-main-header-title-font-phone;
  }
}

// Alarm Main Section

.alarm-main-section {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  padding: $alarm-main-section-padding;
  font-size: $alarm-font-size;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-font-size-phone;
  }
}

// Alarm Main Settings

.alarm-main-settings {
  position: relative;
  display: block;
  width: 100%;
  transform: translateX(0);
  transition: transform defaults.$standard-animation-time ease-in-out;

  .alarm-main-attribute-warning {
    display: none;
  }

  &.alarm-main-options-show {
    transform: translateX(-100%);
  }

  &.alarm-main-wrong-time {
    .attribute-time {
      .alarm-main-attribute-warning {
        display: block;
      }
    }
  }

  &.alarm-main-wrong-zone {
    .attribute-zone {
      .alarm-main-attribute-warning {
        display: block;
      }
    }
  }

  &.alarm-main-wrong-source {
    .attribute-source {
      .alarm-main-attribute-warning {
        display: block;
      }
    }
  }

  &.alarm-main-wrong-music {
    .attribute-music {
      .alarm-main-attribute-warning {
        display: block;
      }
    }
  }
}

.alarm-main-input-time {
  @include defaults.font-roboto-light;

  display: block;
  width: 100%;
  height: $alarm-main-input-time-height;
  margin: $alarm-main-input-time-margin;
  text-align: center;
  color: defaults.$color-foreground;
  background-color: defaults.$color-highlight-on-light;
  font-size: $alarm-main-input-time-font;
  line-height: $alarm-main-input-time-height;

  .grsw {
    line-height: initial;
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    height: $alarm-main-input-time-height-phone;
    font-size: $alarm-main-input-time-font-phone;
    line-height: $alarm-main-input-time-height-phone;
  }
}

@mixin alarm-main-element-title() {
  @include defaults.font-roboto-regular;

  display: inline-block;
  color: defaults.$color-background;
  font-size: $alarm-main-element-title-font;
  vertical-align: middle;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-main-element-title-font-phone;
  }
}

.alarm-main-type {
  position: relative;
  display: block;
  width: $alarm-main-element-width;
  margin: $alarm-main-element-margin;
  padding: $alarm-main-element-margin-padding;
}

.alarm-main-type-header {
  display: block;
  color: defaults.$color-background;
}

.alarm-main-type-header-title {
  @include alarm-main-element-title();
}

.alarm-main-type-options {
  display: block;
  list-style-type: none;
  width: $alarm-main-element-width;
  margin: $alarm-main-element-margin;
  padding: $alarm-main-element-margin-padding;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.alarm-main-type-option {
  @include defaults.font-roboto-light;

  display: inline-block;
  margin: .2em .4em;
  padding: .2em .4em;
  font-size: .8em;
  color: defaults.$color-highlight-on-dark;
  border: 1px solid defaults.$color-highlight-on-dark;
  border-radius: 50px;

  &.active,
  &:active {
    color: defaults.$color-foreground;
    background-color: defaults.$color-highlight-on-dark;
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: 1em;
  }
}

.alarm-main-volume {
  position: relative;
  display: block;
  width: $alarm-main-element-width;
  margin: $alarm-main-element-margin;
  padding: $alarm-main-element-margin-padding;
}

.alarm-main-volume-title {
  @include alarm-main-element-title();
}

.alarm-main-volume-content {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: $alarm-main-element-volume-content-width;
  margin: $alarm-main-element-content-margin;
}

.alarm-main-volume-slider {
  flex: 1 1 auto;
}

.alarm-main-volume-label {
  @include defaults.font-roboto-light;

  flex: 0 0 auto;
  width: $alarm-main-element-volume-label-width;
  font-size: $alarm-main-element-volume-label-font;
  text-align: right;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-main-element-volume-label-font-phone;
  }
}

.alarm-main-attribute {
  @include util.button-cursor();

  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: $alarm-main-element-width;
  margin: $alarm-main-element-margin;
  padding: $alarm-main-element-margin-padding;
  border-bottom: 1px solid defaults.$color-dark-separator;

  &:last-child {
    border-bottom: none;
  }

  &:active {
    background-color: $alarm-main-attribute-active-background;
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    padding: $alarm-main-attribute-padding-phone;
  }

  &.disabled {

    &:active {
      background-color: transparent;
    }

    .alarm-main-attribute-title {
      color: defaults.$color-background-disabled;
    }

    .alarm-main-attribute-current {
      color: defaults.$color-text-dark-transparent-disabled;
    }

    .alarm-main-attribute-icon {
      > svg {
        fill: defaults.$color-dark-control-buttons-disabled;
      }
    }
  }
}

.alarm-main-attribute-title {
  @include alarm-main-element-title();

  flex: 1 1 auto;
  min-width: 0;
}

.alarm-main-attribute-current {
  @include defaults.font-roboto-light;

  flex: 1 1 auto;
  min-width: 0;
  font-size: $alarm-main-attribute-current-font;
  text-align: right;
  color: defaults.$color-text-dark-transparent;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-main-attribute-current-font-phone;
  }
}

.alarm-main-attribute-warning {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  width: $alarm-main-attribute-warning-size;
  height: $alarm-main-attribute-warning-size;
  margin-left: .4em;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-highlight-on-light;
  }
}

.alarm-main-attribute-icon {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  width: $alarm-main-attribute-icon-size;
  height: $alarm-main-attribute-icon-size;
  margin: $alarm-main-attribute-icon-margin;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-dark-control-buttons;
  }
}

.alarm-main-remove {
  position: relative;
  display: none;
  text-align: center;

  @media (max-width: #{defaults.$media-phone-width}) {
    display: block;
    font-size: $alarm-main-remove-font-phone;
  }
}

.alarm-main-remove-content {
  @include defaults.font-roboto-regular;

  display: inline-block;
  margin: $alarm-main-remove-margin;
  padding: $alarm-main-remove-padding;
  text-align: center;
  font-size: 1em;
  color: defaults.$color-background;
  border-radius: $alarm-main-remove-border-radius;
  border: $alarm-main-remove-border;

  &:active {
    color: defaults.$color-highlight-on-dark;
  }
}

// Alarm Main Options

.alarm-main-options {
  position: absolute;
  display: block;
  top: 0;
  left: 100%;
  width: $alarm-main-element-width;
  height: calc(100% - .8em);
  margin: $alarm-main-element-margin;
  padding: $alarm-main-element-margin-padding;
  overflow-x: visible;
  overflow-y: hidden;
  font-size: $alarm-font-size;
  list-style-type: none;
  transform: translateX(0);
  transition: transform defaults.$standard-animation-time ease-in-out;
  z-index: 1;

  &.alarm-main-options-show {
    transform: translateX($alarm-main-options-translate-show);
    overflow-x: visible;
    overflow-y: auto;
  }
}

.alarm-main-options-section-header {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: $alarm-options-section-header-padding;
}

.alarm-main-options-section-header-title {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  min-width: 0;
  margin: $alarm-options-section-header-title-margin;
  font-size: $alarm-main-element-title-font;
  color: defaults.$color-background;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-main-element-title-font-phone;
  }
}

.alarm-main-type-header-toggle {
  flex: 0 0 auto;
}

input[type='date'].alarm-main-type-input-date {
  border-radius: $alarm-main-type-input-border-radius;
}

.alarm-main-type-input-date {
  @include defaults.font-roboto-light;

  display: block;
  width: $alarm-main-type-input-width;
  max-width: $alarm-main-type-input-max-width;
  height: $alarm-main-type-input-height;
  margin: $alarm-main-type-input-margin;
  padding: $alarm-main-type-input-padding;
  text-align: center;
  font-size: $alarm-main-type-input-font;
  line-height: $alarm-main-type-input-line-height;
  color: defaults.$color-foreground;
  background-color: defaults.$color-highlight-on-light;
  box-sizing: border-box;
}

@mixin alarm-options-sections() {
  position: relative;
  display: block;
  width: $alarm-main-element-width;
  flex: 0 1 auto;
  margin: $alarm-main-element-margin;
  font-size: $alarm-font-size;
  list-style-type: none;
}

@mixin alarm-options-section() {
  position: relative;
  display: block;
}

@mixin alarm-options-section-items() {
  position: relative;
  display: block;
  width: $alarm-main-element-width;
  margin: $alarm-main-element-margin;
  list-style-type: none;
}

@mixin alarm-options-section-item() {
  @include defaults.font-roboto-regular;

  display: block;
  text-align: left;
}

.alarm-main-options-sections {
  @include alarm-options-sections();
}

.alarm-main-options-section {
  @include alarm-options-section();
}

.alarm-main-options-warning {
  @include defaults.font-roboto-light;

  display: none;
  text-align: center;
}

.alarm-main-warning-show {
  .alarm-main-options-warning {
    display: block;
  }
}

.alarm-main-options-section-name {
  margin: $alarm-options-section-name-margin;
}

.alarm-main-options-section-items {
  @include alarm-options-section-items();
}

.alarm-main-options-section-item {
  @include alarm-options-section-item();
  @include util.button-cursor();

  padding: $alarm-options-section-item-padding;
  color: $alarm-options-section-item-color;
  border-bottom: $alarm-options-section-item-border;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  &:active {
    background-color: $alarm-options-section-item-background-active;
  }
}

.alarm-main-options-section-item-selector {
  position: relative;
  display: inline-block;
  width: $alarm-options-selector-size;
  height: $alarm-options-selector-size;
  margin: $alarm-options-selector-margin;
  border-radius: 100%;
  border: $alarm-options-selector-border;
  vertical-align: middle;
  flex: 0 0 auto;

  &:active {
    &::after {
      @include util.absolute-center();

      content: '';
      width: $alarm-options-selector-inner-size;
      height: $alarm-options-selector-inner-size;
      border-radius: 100%;
      background-color: defaults.$color-highlight-on-dark;
    }
  }

  &.selected {
    &::after {
      @include util.absolute-center();

      content: '';
      width: $alarm-options-selector-inner-size;
      height: $alarm-options-selector-inner-size;
      border-radius: 100%;
      background-color: defaults.$color-highlight-on-dark;
    }
  }
}

.alarm-main-options-section-item-name {
  @include defaults.font-roboto-regular;

  display: inline-block;
  text-align: left;
  vertical-align: middle;
  flex: 1 1 auto;
  min-width: 0;
}

// Alarm Main Message

.alarm-main-message {
  @include util.absolute-center ();

  width: $alarm-main-message-width;
  font-size: $alarm-font-size;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-font-size-phone;
  }
}

.alarm-main-message-line {
  @include defaults.font-roboto-regular;

  display: block;
  text-align: center;
  color: defaults.$color-text-dark-transparent;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-main-message-line-font-phone;
  }
}

.alarm-main-message-new {
  position: relative;
  display: block;
  margin: $alarm-main-message-new-margin;
  text-align: center;
}

.alarm-main-message-new-content {
  @include defaults.font-roboto-regular;

  display: inline-block;
  padding: $alarm-main-remove-padding;
  text-align: center;
  font-size: $alarm-main-remove-font;
  color: defaults.$color-background;
  border-radius: $alarm-main-remove-border-radius;
  border: $alarm-main-remove-border;

  &:active {
    color: defaults.$color-highlight-on-dark;
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $alarm-main-remove-font-phone;
  }
}
