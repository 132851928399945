@use 'bas_notch_const';

@mixin disable-transitions() {
  &.bas-transition-can-be-disabled,
  .bas-transition-can-be-disabled {
    transition: none !important;

    .bas-image {
      transition: none !important;
    }
  }
}

@mixin disable-state-animations() {
  &.bas-animation-can-be-disabled,
  .bas-animation-can-be-disabled {
    animation: none !important;
  }
}

@mixin disable-animations() {
  &.bas-animation-disabled,
  .bas-animation-disabled {
    animation: none !important;
  }
}

@mixin absolute-0-0() {
  position: absolute;
  top: 0;
  left: 0;
}

@mixin absolute-0-0-bottom() {
  position: absolute;
  left: 0;
  bottom: 0;
}

@mixin absolute-center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-match-parent() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin vertical-center() {
  top: 50%;
  transform: translateY(-50%);
}

@mixin ellipsis-overflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin button-cursor() {
  cursor: pointer;
}

@mixin default-cursor() {
  cursor: default;
}

@mixin bas-fake-hide() {
  visibility: hidden;
  z-index: -10;
  pointer-events: none;
}

@mixin bas-fake-show() {
  visibility: visible;
  z-index: auto;
  pointer-events: auto;
}

@mixin bas-fake-hide-opacity() {
  opacity: 0;
  z-index: -10;
  pointer-events: none;
}

@mixin bas-fake-show-opacity() {
  opacity: 1;
  z-index: auto;
  pointer-events: auto;
}

@mixin empty-no-collapse() {
  &:empty::after {
    content: '\a0';
  }
}

@mixin bas-notch-padding--all() {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: bas_notch_const.$notch-safe-top;
  padding-left: bas_notch_const.$notch-safe-left;
  padding-right: bas_notch_const.$notch-safe-right;
  padding-bottom: bas_notch_const.$notch-safe-bottom;
}

@mixin bas-notch-padding--top() {
  padding-top: 0;
  padding-top: bas_notch_const.$notch-safe-top;
}

@mixin bas-notch-padding--bottom() {
  padding-bottom: 0;
  padding-bottom: bas_notch_const.$notch-safe-bottom;
}

@mixin bas-notch-padding--left-bottom() {
  padding-left: 0;
  padding-bottom: 0;
  padding-left: bas_notch_const.$notch-safe-left;
  padding-bottom: bas_notch_const.$notch-safe-bottom;
}

@mixin bas-notch-padding--right-bottom() {
  padding-right: 0;
  padding-bottom: 0;
  padding-right: bas_notch_const.$notch-safe-right;
  padding-bottom: bas_notch_const.$notch-safe-bottom;
}

@mixin bas-notch-padding--top-left-right() {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: bas_notch_const.$notch-safe-top;
  padding-left: bas_notch_const.$notch-safe-left;
  padding-right: bas_notch_const.$notch-safe-right;
}

@mixin bas-notch-padding--left-right-bottom() {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: bas_notch_const.$notch-safe-left;
  padding-right: bas_notch_const.$notch-safe-right;
  padding-bottom: bas_notch_const.$notch-safe-bottom;
}

@mixin bas-notch-position--all() {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  top: bas_notch_const.$notch-safe-top;
  left: bas_notch_const.$notch-safe-left;
  right: bas_notch_const.$notch-safe-right;
  bottom: bas_notch_const.$notch-safe-bottom;
}

@mixin bas-notch-position--top() {
  top: 0;
  top: bas_notch_const.$notch-safe-top;
}

@mixin bas-notch-position--bottom() {
  bottom: 0;
  bottom: bas_notch_const.$notch-safe-bottom;
}

@mixin bas-notch-position--top-left-right() {
  top: 0;
  left: 0;
  right: 0;
  top: bas_notch_const.$notch-safe-top;
  left: bas_notch_const.$notch-safe-left;
  right: bas_notch_const.$notch-safe-right;
}

@mixin bas-notch-position--left-right-bottom() {
  left: 0;
  right: 0;
  bottom: 0;
  left: bas_notch_const.$notch-safe-left;
  right: bas_notch_const.$notch-safe-right;
  bottom: bas_notch_const.$notch-safe-bottom;
}

@mixin bas-append-ellipsis-two-dots() {
  &:not(:empty)::after {
    content: '..';
  }
}

@mixin bas-append-ellipsis-three-dots() {
  &:not(:empty)::after {
    content: '...';
  }
}
