@use 'defaults';
@use 'basstyle_const';
@use 'zones_const';
@use 'util';

$bmch-titles-top-margin: 0.2em;

$bmci-value-width: 2em;

.brs-scenes {

  // Create new scene, button and placeholders for content view

  .brs-scenes-favourites-editor-button {
    display: none;
  }

  .bmsdh-add {
    display: none;
  }

  .bmc-message-with-add {
    display: none;
  }

  .brs-scene-widget-indicator {
    display: none;
  }

  .bmc-message-without-add {
    display: block;
  }

  &.brs-can-add {

    .bmsdh-add {
      display: flex;
    }

    .bmc-message-with-add {
      display: block;
    }

    .bmc-message-without-add {
      display: none;
    }
  }

  &.brs-is-room {

    .brs-scenes-favourites-editor-button {
      display: block;
    }

    .brs-scene-widget-indicator {
      display: inline-block;
    }

    .slip-reordering {
      .brs-scene-widget-indicator {
        display: none;
      }
    }
  }

  // Empty placeholders

  .brs-placeholder-no-favourites,
  .brs-placeholder-no-non-favourites {
    display: none;
  }

  &.brs-has-no-favourites {

    .brs-placeholder-no-favourites {
      display: block;
    }
  }

  &.brs-has-no-non-favourites {

    .brs-placeholder-no-non-favourites {
      display: block;
    }
  }
}

// Custom drawer item logic

.brs-scenes {

  .bas-scenes--favourite {

    .bmsdi-position-icon {
      @include util.vertical-center();

      position: absolute;
    }
  }

  .bmsdi-position-icon {
    display: none;
  }

  &.brs-is-room {

    .bas-scenes--favourite {

      .bmsdi-position-icon {
        display: block;
      }

      .bmsdi-name {
        margin-left: basstyle_const.$bsqp-size + 8px;
      }

      &.slip-reordering {

        .bmsdi-position-icon {
          opacity: 0;
        }

        .bmsdi-name {
          margin-left: basstyle_const.$bsqp-size + 8px;
        }
      }
    }
  }
}

// bmc = Bas Main Content
// bmci = Bas Main Content Item
// bmch = Bas Main Content Header
// bmcis = Bas Main Content Item Section

.bmc-edit {
  &.bah-is-edit {

    &.bmc-can-activate {
      .bmcc-activate {
        display: none;
      }
    }

    &.bmc-can-remove {
      .bmcc-remove {
        display: inline-block;
      }
    }

    &.bmc-can-image {
      .bmcc-change-image {
        display: inline-block;
      }
    }

    &.bmc-can-content {
      .bmcc-content {
        display: inline-block;
      }
    }

    &.bmc-can-content {
      .bmci-order {
        display: block;
      }

      .bmci-step {

        .bmci-subtitle,
        .bmci-name {
          padding-left: 2 * basstyle_const.$bmc-order-size;
        }
      }
    }
  }
}

.bmc-scenes {

  .bmcc-favourite,
  .bmcc-activate,
  .bmcc-learn,
  .bmcch-badge-subtitle {
    display: none;
  }

  &.bmc-can-activate {
    .bmcc-activate {
      display: inline-block;
    }
  }

  &.bmc-can-learn {
    .bmcc-learn {
      display: inline-block;
    }
  }

  &.bmc-can-favourite {
    .bmcc-favourite {
      display: inline-block;
    }
  }

  &.bmc-has-badge {
    .bmcch-badge-subtitle {
      display: inline-block;
    }
  }

  &.brs-is-room {

    .bmcc-favourite {
      display: none;
    }

    .bmci-step.bas-step {
      .bas-scenes--scene-step-room,
      .bmci-name-divider {
        display: none;
      }
    }
  }
}

.bmc-scenes {

  .bmc-header {
    display: none;
  }

  .bmc-content {
    display: none;
  }

  .bmci-order,
  .bmcc-remove,
  .bmcc-content,
  .bmcc-content-always,
  .bmcc-change-image {
    display: none;
  }

  &.bmc-has-scene {

    .bmc-header {
      display: block;
    }

    .bmc-content {
      display: block;
    }
  }

  &.bmc-can-content {
    .bmcc-content-always {
      display: inline-block;
    }
  }
}

.bmc-wrapper {

  &.bmc-has-rooms {

    .bmc-header {
      display: block;
    }

    .bmc-content {
      display: flex;
    }
  }

  &.bmc-has-presets {

    .bmc-header {
      display: block;
    }

    .bmc-content {
      display: block;
    }
  }
}

$btd-scenes-thermostat-size: 280px;

.btd-thermostat-scene {
  &.bmc-scene-thermostat {

    .btd-control-buttons,
    .btd-activity {
      display: none;
    }

    .btd-slider-container {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      width: $btd-scenes-thermostat-size;
      height: $btd-scenes-thermostat-size;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1em;
      color: defaults.$color-background;
    }

    .btd-title-wrapper {
      flex: 0 0 auto;
      position: relative;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin: 0 1em;
      padding-top: 1em;
    }

    &.btw-can-set-point {
      .btd-setting-mode.btd-title-wrapper-border {
        border-top: 1px solid defaults.$color-foreground;
      }
    }

    &.btw-can-mode,
    &.btw-can-set-point {
      .btd-setting-fan-mode.btd-title-wrapper-border {
        border-top: 1px solid defaults.$color-foreground;
      }
    }

    &.btw-has-controls {
      .btd-settings-control {

        &::before {
          content: '';
          display: block;
          border-top: 1px solid defaults.$color-foreground;
          width: calc(100% - 2em);
          margin-left: 1em;
          margin-right: 1em;
        }
      }
    }

    .btd-text-block {
      color: defaults.$color-background;
    }

    .btd-text-block-selectable {
      color: defaults.$color-background-active;
      border-color: defaults.$color-library-control;
      background-color: transparent;

      svg {
        fill: defaults.$color-library-control;
      }

      &:active {
        @include basstyle_const.bs-btn-highlight-on-light();
      }
    }

    .btd-text-block-selected {
      @include basstyle_const.bs-btn-highlight-on-light();
      @include basstyle_const.bs-btn-instant();
    }

    .btd-text-block-selectable {
      margin: .9em;
    }

    .btd-toggle-inline {
      margin: .9em 0;
    }

    .btd-settings-container {
      width: 100%;
    }

    .btd-settings {
      border-top: 1px solid defaults.$color-foreground;
      background-color: transparent;
      border-radius: 0;
    }

    .btd-control-buttons {
      border-color: defaults.$color-library-control;

      .btd-icon {
        border-color: defaults.$color-library-control;

        svg {
          fill: defaults.$color-library-control;
        }
      }
    }

    .btd-setting-set-point,
    .btd-setting-mode,
    .btd-setting-fan-mode {
      display: none;
    }

    .btd-settings-mode,
    .btd-settings-controls,
    .btd-settings-fan,
    .btd-settings-louver {
      display: none;

      .btd-text-block-selectable {
        color: defaults.$color-foreground-dark;
        border-color: defaults.$color-foreground-dark;
      }
    }

    &.btw-has-temperature {
      .btd-current-temperature {
        display: none;
      }
    }

    &.btw-has-humidity {
      .btd-humidity-wrapper {
        display: none;
      }
    }

    &.btw-has-fan-options {
      .btd-settings-fan {
        display: block;
      }
    }

    &.btw-has-mode-options {
      .btd-settings-mode {
        display: block;
      }
    }

    &.btw-has-louver-options {
      .btd-settings-louver {
        display: block;
      }
    }

    &.btw-has-controls {
      .btd-settings-controls {
        display: block;
      }
    }

    .btd-target-temperature {
      top: 28%;
      left: 65%;

      @media (min-width: #{defaults.$media-query-tablet-portrait}) {
        left: 68%;
        top: 25%;
      }
    }

    &.btw-can-fan {
      .btd-settings-fan {
        .btd-text-block-selectable {
          @include basstyle_const.bs-btn-on-light();
        }
      }

      .btd-setting-fan-mode {
        display: flex;
      }
    }

    &.btw-can-mode {
      .btd-settings-mode {
        .btd-text-block-selectable {
          @include basstyle_const.bs-btn-on-light();
        }
      }

      .btd-setting-mode {
        display: flex;
      }
    }

    &.btw-can-louver {
      .btd-settings-louver {
        .btd-text-block-selectable {
          @include basstyle_const.bs-btn-on-light();
        }
      }

      .btd-setting-mode {
        display: flex;
      }
    }

    .btd-settings-fan,
    .btd-settings-mode,
    .btd-settings-louver {
      .btd-text-block-selectable {
        &.btd-text-block-selected {
          @include basstyle_const.bs-btn-highlight-on-light();
          @include basstyle_const.bs-btn-instant();
        }
      }
    }

    &.btw-can-set-point {
      .btd-control-buttons {
        display: block;
      }

      .btd-setting-set-point {
        display: flex;
      }
    }
  }
}

.bmc-scene-wrapper {
  @include util.absolute-match-parent();
}

.bmc-message {
  @include util.absolute-center();

  text-align: center;
  width: 100%;
  padding: 0 1em;
  box-sizing: border-box;
}

.bmci-order {
  @include util.vertical-center();

  position: absolute;
  width: basstyle_const.$bmc-order-size;
  height: 100%;

  svg {
    @include util.absolute-center();

    pointer-events: none;
    width: 100%;
  }
}

.bmcm-line {
  @include defaults.font-roboto-regular;

  color: defaults.$color-text-dark-transparent;
}

.bmcm-btn {
  display: inline-block;
  margin-top: .4em;
}

.bmc-header {

  .bmch-separator {
    display: none;
  }

  .bmch-subtitle-text {
    display: none;
  }

  .bmch-action-btn {
    display: none;
  }

  .bmcc-edit {
    display: none;
  }

  &.bmch-has-back {

    .bmch-title {
      transform: translateX(basstyle_const.$bmch-icon-size);
    }

    .bmch-title-left {
      transform: translateX(5px);
    }
  }

  &.bmch-has-subtitle {

    .bmch-separator {
      display: inline-block;
    }

    .bmch-subtitle-text {
      display: inline-block;
    }
  }

  &.bmch-has-action-btn {

    .bmch-title {
      margin-right: basstyle_const.$bmch-hor-spacing + 70px;
    }

    .bmch-action-btn {
      display: inline-block;
    }
  }

  &.bmcc-can-edit {
    .bmcc-edit {
      display: block;
    }
  }
}

.bmc-favourite {

  .bmcc-favourite {
    @include basstyle_const.bs-btn-full-highlight-on-light();
  }
}

.bmc-scenes-group {
  margin: 0 auto;
  height: calc(100% - 112px);
  overflow-y: auto;
  overflow-x: hidden;
}

.bmc-scenes-title {
  @include defaults.font-roboto-regular;

  position: relative;
  display: block;
  margin: 0.4em 0 0.5em;
  overflow: hidden;
  font-size: 1.4em;
}

.bmc-header-dark {
  background-color: defaults.$color-background-dark-transparent-light;
  color: defaults.$color-text-lighter;
}

.bmc-header-light {
  background-color: defaults.$color-background-dark-transparent-light;
  color: defaults.$color-text-black;
}

.bmc-header-relative {
  position: relative;
}

.bmch-right {
  @include util.vertical-center();
  @include util.button-cursor();

  position: absolute;
  right: zones_const.$bas-object-group-margin;
}

.bmch-left {
  @include util.vertical-center();
  @include util.button-cursor();

  position: absolute;
  left: zones_const.$bas-object-group-margin;
}

.bmch-text {
  @include defaults.font-roboto-regular;

  color: defaults.$color-foreground;
}

.bas-scenes-container {

  .bmch-toggle-state-edit {
    display: none;
  }

  &.bas-state-editing {
    .bmch-toggle-state-edit {
      @include basstyle_const.btbs-set-active;
    }
  }

  &.brs-show-edit {
    .bmch-toggle-state-edit {
      display: block;
    }
  }
}

.bmch-title {
  position: absolute;
  top: .7em;
  left: basstyle_const.$bmch-hor-spacing;
  right: basstyle_const.$bmch-hor-spacing;
  white-space: nowrap;
  transform: translateX(0);
  transition: transform defaults.$standard-animation-time;
}

.bmch-title-button {
  position: relative;
  display: inline-block;
}

.bmch-is-editing {

  .bmch-title-button {
    display: none;
  }

  .bmcch-title-form {
    display: block;
  }
}

.bmch-edit-button {
  @include util.vertical-center();

  position: absolute;
  right: basstyle_const.$bmch-hor-spacing;
}

.bmch-title-left {
  position: absolute;
  display: block;
  top: 0;
  left: -(basstyle_const.$bmch-hor-spacing + basstyle_const.$bmch-icon-size);
  width: basstyle_const.$bmch-icon-size;
  height: basstyle_const.$bmch-icon-size;
  transform: translateX(-(basstyle_const.$bmch-hor-spacing + basstyle_const.$bmch-icon-size));
  transition: transform defaults.$standard-animation-time;

  svg {
    @include util.absolute-center();

    width: 100%;
    height: 100%;
    fill: defaults.$color-library-control;
    transition: fill defaults.$active-animation-time;
  }
}

.bmch-title-text {
  @include defaults.font-roboto-regular;

  display: inline-block;
  width: 100%;
  margin-top: $bmch-titles-top-margin;
  vertical-align: middle;
}

.bmch-separator {
  display: inline-block;
  width: 1px;
  height: 1.4em;
  margin: 0 .6em;
  vertical-align: middle;
  background-color: defaults.$color-highlight-on-light;
}

.bmch-subtitle-text {
  @include defaults.font-roboto-light;

  display: inline-block;
  margin-top: $bmch-titles-top-margin;
  vertical-align: middle;
}

.bmch-action-btn {
  @include defaults.font-roboto-regular;

  position: absolute;
  display: inline-block;
  top: .7em;
  right: basstyle_const.$bmch-hor-spacing;
  margin-top: $bmch-titles-top-margin;
  vertical-align: middle;
  color: defaults.$color-highlight-on-light;
}

// Scene detail

$bmc-hor-spacing: 16px;
$bmc-btn-spacing: 4px;
$bmc-inner-spacing: 12px;
$bmcch-height: 112px;
$bmcch-height-phone: 166px;
$bmcch-image-size: 80px;
$bmcch-indicator-size: 12px;
$bmci-height: 50px;

.bmcc-element {
  display: block;
  width: 100%;
}

.bmcc-header {
  position: relative;
  height: $bmcch-height;

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: $bmc-hor-spacing;
    right: $bmc-hor-spacing;
    bottom: 0;
    height: 1px;
    background-color: defaults.$color-dark-separator;
  }
}

.bah-is-edit {
  @media (max-width: #{defaults.$media-phone-width}) {
    .bmcc-header {
      height: $bmcch-height-phone;
    }

    .bmc-message-wrapper--for-main-content-header {
      top: $bmcch-height-phone;
    }
  }
}

.bmcc-header-light {
  background-color: defaults.$color-library-list-border;

  &::after {
    content: none;
  }
}

.bmc-scroll-content {
  display: block;
  position: absolute;
  top: $bmcch-height;
  width: 100%;
  bottom: 0;
}

.bmcch-image {
  position: absolute;
  top: $bmc-hor-spacing;
  left: $bmc-hor-spacing;
  width: $bmcch-image-size;
  height: $bmcch-image-size;
  box-shadow: defaults.$box-shadow-cover-light;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bmcch-border {
  position: absolute;
  top: $bmc-hor-spacing;
  left: $bmc-hor-spacing;
  height: $bmcch-image-size;
}

.bmcc-edit-overlay {
  display: none;
  position: absolute;
  top: $bmc-hor-spacing;
  left: $bmc-hor-spacing;
  width: $bmcch-image-size;
  height: $bmcch-image-size;
  background-color: defaults.$color-black-darker-bg-trans;

  svg {
    @include util.absolute-center();

    width: 50%;
    fill: defaults.$color-dark-separator;
  }
}

.bmcch-content {
  position: absolute;
  top: 1.5em;
  left: $bmc-hor-spacing * 2 + $bmcch-image-size;
  right: $bmc-hor-spacing;
}

.bmcch-title {
  @include defaults.font-roboto-regular;

  max-width: 100%;
}

.bmcch-title-form {
  @include defaults.font-roboto-regular;

  display: none;

  @media (min-width: #{defaults.$media-phone-width}) {
    max-width: 200px;
  }
}

.bmcch-title-input {
  @include defaults.font-roboto-regular;

  width: 100%;
  height: 2em;
  padding: 0 .6em;
  border-radius: 5em;
  line-height: 2em;
  box-sizing: border-box;
}

.bmcch-subtitle {
  @include defaults.font-roboto-light;

  max-width: 100%;
  font-size: .8em;
}

.bmcch-badge-subtitle {
  display: inline-block;
  background-color: defaults.$color-text-grey;
  margin-top: 5px;
  font-size: .8em;
}

.bah-is-edit {
  &.bmc-can-template {

    .bmcc-edit-overlay {
      display: block;
    }
  }

  &.bmc-can-name {

    .bmcch-title {
      display: none;
    }

    .bmcch-title-form {
      display: block;
    }
  }
}

.bmc-is-edit {

  .bmc-scenes-group {
    display: none;
  }

  &.bmc-can-template {

    .bmc-scenes-group {
      display: block;
    }
  }

  &.bmc-can-name {

    .bmcch-title {
      display: none;
    }

    .bmcch-title-form {
      display: block;
    }
  }
}

.bmcch-indicator {
  position: absolute;
  top: 0;
  right: $bmc-hor-spacing;
  bottom: 0;
  width: $bmcch-indicator-size;

  svg {
    @include util.absolute-center();

    width: 100%;
    height: 100%;
    fill: defaults.$color-library-control;
  }
}

.bmc-message-wrapper--for-main-content-header {
  position: absolute;
  top: $bmcch-height;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  padding-bottom: 0;
  padding-bottom: env(safe-area-inset-bottom, 0);
}

// Content

.bmcc-controls {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: .6em $bmc-hor-spacing;
  box-sizing: border-box;
}

.bmcc-control-wrapper {
  position: absolute;
  right: 1em;
  bottom: 1em;
  height: defaults.$bs-btn-circle-size;
}

.bmcc-control {
  display: inline-block;
}

.bmcc-edit {
  position: absolute;
  top: .8em;
  right: basstyle_const.$bmch-hor-spacing;
}

.bmcc-edit-text {
  @include defaults.font-roboto-regular;

  position: absolute;
  top: 0;
  right: 0;
  color: defaults.$color-highlight-on-light;
}

.bmcc-new {
  flex: 0 0 auto;
}

// Lists

$bmci-inner-spacing-vert: 7px;
$bmci-inner-spacing-vert-em: .4em;

.bmc-list {
  position: relative;
  width: 100%;
  list-style-type: none;
}

.bmc-list-flex {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}

.bmc-list-item {
  position: relative;
  display: block;
  padding: $bmci-inner-spacing-vert $bmc-hor-spacing;
  box-sizing: border-box;

  &.no-vertical-padding {
    padding-left: 0;
    padding-right: 0;

    &::before {
      left: 10px;
      right: 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: $bmc-hor-spacing;
    right: $bmc-hor-spacing;
    bottom: 0;
    border-bottom: 1px solid defaults.$color-foreground;
  }

  &.bmci-no-before {
    &::before {
      content: none;
    }
  }

  &:last-child {
    &::before {
      content: none;
    }
  }

  &.bmci-no-active {
    &:active {
      color: initial;
    }
  }

  .bmci-btn-arrow {
    display: none;
  }

  &.bmci-arrow {

    .bmci-name {
      margin-right: defaults.$bs-btn-circle-size + 5px;
    }

    .bmci-btn-arrow {
      display: block;
    }
  }

  .bmci-radio-btn {
    display: none;
  }

  &.bmci-radio {

    .bmci-radio-btn {

      &::after {
        content: none;
      }
    }

    .bmci-radio-btn {
      display: block;
    }

    .bmci-name {
      margin-left: defaults.$bs-btn-radio-btn-size + 2 * $bmc-btn-spacing;
    }

    &.bmci-radio-selected {

      .bmci-radio-btn {

        &::after {
          content: '';
        }
      }
    }

    &.bmci-radio-disabled {
      pointer-events: none;

      .bmci-name {
        color: defaults.$color-text-light;
      }

      .bmci-radio-btn {
        border-color: defaults.$color-text-light;

        &::after {
          background-color: defaults.$color-text-light;
        }
      }
    }

    .bmci-icon {
      display: none;
      position: relative;
      flex: 0 0 24px;

      svg {
        @include util.absolute-center();

        width: 80%;
        fill: defaults.$color-background-light-more-transparent;
      }
    }

    &.bmci-show-icon {

      .bmci-icon {
        display: block;
      }
    }

    &.bmci-title {
      padding-bottom: 0;
      padding-top: 4px;
      height: #{$bmci-height - 10px};

      .bmci-name {
        margin-left: 0;
        font-size: 1.3em;
        margin-top: .3em;
        color: defaults.$color-text-dark-transparent;
      }

      .bmci-radio-btn {
        display: none;
      }

      + .bmci-list-item {
        &::before {
          content: none;
        }
      }
    }

    &.bmci-simple-slider {

      .bmci-slider {
        width: calc(
            100% -
            (
              #{$bmc-hor-spacing + defaults.$bs-btn-radio-btn-size} +
              #{$bmci-value-width + .3em}
            )
          );
        margin-left: defaults.$bs-btn-radio-btn-size + 2 * $bmc-btn-spacing;
      }
    }
  }

  &.bmci-orderable {

    &.slip-reordering {

      .bmci-order {
        fill: defaults.$color-highlight-on-light;
      }
    }
  }

  &.bmci-flex {
    display: flex;

    .bmci-name {
      flex: 1 1 auto;
    }
  }
}

// Bas Main Content Item types

.bmci-simple {
  height: $bmci-height;

  .bmci-radio-btn {
    top: $bmci-inner-spacing-vert + 7px;
  }

  .bmci-name {
    margin-top: $bmci-inner-spacing-vert-em + .1em;
  }

  .bmci-btn {
    top: $bmci-inner-spacing-vert;
  }

  &.bmci-radio {

    .bmci-name {
      margin-top: $bmci-inner-spacing-vert-em + .25em;
    }
  }
}

.bmci-title-subtitle {

  .bmci-name {
    margin-bottom: .2em;
  }

  .bmci-subtitle {
    margin-right: defaults.$bs-btn-circle-size + 2 * $bmc-btn-spacing;
  }

  .bmci-btn {
    top: $bmci-inner-spacing-vert;
  }

  &.bmci-orderable {

    .bmci-name,
    .bmci-subtitle {
      padding-left: 2 * basstyle_const.$bmc-order-size;
    }

    &.slip-reordering {

      .bmci-subtitle,
      .bmci-name {
        color: defaults.$color-highlight-on-light;
      }
    }
  }
}

.bmci-simple-toggle {
  height: $bmci-height;

  .bmci-name {
    margin-top: $bmci-inner-spacing-vert-em + .1em;
    margin-right: basstyle_const.$bas-button-toggle-width + 5px;;
  }

  .bmci-btn-toggle {
    top: $bmci-inner-spacing-vert;
  }
}

.bmci-simple-slider {
  height: $bmci-height;
  white-space: nowrap;
  padding: 0 16px;

  .grsw {
    padding: 7px 0;
  }

  .bmci-radio-btn {
    top: $bmci-inner-spacing-vert + 7px;
  }
}

.bmci-step {
  min-height: defaults.$bs-btn-circle-size + 2 * $bmci-inner-spacing-vert;

  .bmci-name {
    margin-top: .5em;
    margin-right: 0;
  }

  .bmci-subtitle {
    margin-right: 0;
  }

  .bmci-btn-plus,
  .bmci-btn-arrow,
  .bmci-btn-remove {
    top: $bmci-inner-spacing-vert;
  }

  .bmci-btn-minus {
    top: $bmci-inner-spacing-vert;
    right: defaults.$bs-btn-circle-size + $bmc-btn-spacing + $bmc-hor-spacing;
  }

  .bmci-input {
    display: none;
    text-align: right;
  }

  &.bmci-arrow {

    .bmci-name {
      margin-right: 0;
    }
  }

  &.bas-step {

    .bmci-name-divider,
    .bmci-subtitle,
    .bmci-btn-plus,
    .bmci-btn-minus,
    .bmci-btn-remove,
    .bmci-btn-arrow {
      display: none;
    }

    &.bas-step-has-title-divider {

      .bmci-name-divider {
        display: inline;
      }
    }

    &.bas-step-subtitle {
      .bmci-subtitle {
        display: block;
      }

      .bmci-name {
        margin-top: 0;
        margin-bottom: .2em;
      }
    }

    &.slip-reordering {
      .bmci-order {
        fill: defaults.$color-highlight-on-light;
      }

      .bmci-subtitle,
      .bmci-name {
        color: defaults.$color-highlight-on-light;
      }
    }
  }
}

.bmc-edit.bah-is-edit.bmc-can-content {
  .bmci-step.bas-step {

    &.bas-step-timer {

      .bmci-btn-plus,
      .bmci-btn-minus {
        display: block;
      }

      .bmci-name,
      .bmci-subtitle {
        margin-right: 2 * defaults.$bs-btn-circle-size + 3 * $bmc-btn-spacing;
      }
    }

    &.bas-step-arrow-button-show {

      .bmci-btn-arrow {
        display: block;
      }

      .bmci-name,
      .bmci-subtitle {
        margin-right: defaults.$bs-btn-circle-size + 2 * $bmc-btn-spacing;
      }
    }

    &.bas-step-remove-button-show {

      .bmci-btn-remove {
        display: block;
      }

      &.bas-step-timer {

        .bmci-btn-plus {
          right: defaults.$bs-btn-circle-size + $bmc-btn-spacing + $bmc-hor-spacing;
        }

        .bmci-btn-minus {
          right: 2 * defaults.$bs-btn-circle-size +  2 * $bmc-btn-spacing + $bmc-hor-spacing;
        }

        .bmci-name,
        .bmci-subtitle {
          margin-right: 3 * defaults.$bs-btn-circle-size + 4 * $bmc-btn-spacing;
        }
      }

      &.bas-step-arrow-button-show {

        .bmci-btn-arrow {
          right: defaults.$bs-btn-circle-size + $bmc-btn-spacing + $bmc-hor-spacing;
        }

        .bmci-name,
        .bmci-subtitle {
          margin-right: 2 * defaults.$bs-btn-circle-size + 3 * $bmc-btn-spacing;
        }
      }
    }
  }
}

// Bas Main Content Item components

.bmcis-title {
  margin-left: $bmc-hor-spacing;
  margin-right: $bmc-hor-spacing;
}

.bmcis-main-title {
  @include defaults.font-roboto-light;

  margin: .4em $bmc-hor-spacing;
  font-size: 1.2em;
}

.bmci-toggle {
  position: absolute;
  right: $bmc-hor-spacing;
}

.bmci-temperature-picker-icon,
.bmci-color-picker-icon {
  width: 100%;
}

.bmci-color-picker {
  position: absolute;
  width: defaults.$bs-btn-circle-size;
  height: defaults.$bs-btn-circle-size;
}

.bmci-radio-btn {
  position: absolute;
  left: $bmc-hor-spacing;
}

.bmci-btn-toggle {
  position: absolute;
  right: $bmc-hor-spacing;

  &.vert-middle {
    @include util.vertical-center();
  }
}

.bmci-name {
  @include defaults.font-roboto-regular;
}

.bmci-subtitle {
  @include defaults.font-roboto-light;

  font-size: .8em;
  color: defaults.$color-text-grey;
}

.bmci-loading {
  color: defaults.$color-background-shadow;
}

.bmci-btn {
  position: absolute;
  right: $bmc-hor-spacing;
}

.bmci-extra-btn {
  position: absolute;
  right: $bmc-hor-spacing + basstyle_const.$bas-button-toggle-total-width + $bmc-inner-spacing;
  top: 50%;
  transform: translateY(-50%);
}

.bmci-left-btn {
  right: $bmc-hor-spacing + defaults.$bs-btn-circle-size + $bmc-hor-spacing;
}

.bmci-rotate-90 {
  transform: rotate(90deg);
}

.bmci-rotate-270 {
  transform: rotate(270deg);
}

.bmci-slider-wrapper {
  position: absolute;
  width: 95%;
}

.bmci-dark-color {
  color: defaults.$color-background;
}

.bmci-slider {
  display: inline-block;
  width: calc(100% - #{$bmci-value-width + .3em});
  vertical-align: middle;
}

.bmci-value {
  @include defaults.font-roboto-regular;

  text-align: right;
  width: $bmci-value-width;
  display: inline-block;
  vertical-align: middle;

  &.value-left {
    text-align: left;
  }

  &.auto-width {
    width: auto;
  }
}

// Step

// bis = Bas Input Seconds

.bas-input-seconds {
  position: relative;
}

.bis-label {
  @include defaults.font-roboto-light;

  vertical-align: middle;
}

.bis-btn {
  @include defaults.font-roboto-regular;

  display: inline-block;
  color: defaults.$color-highlight-on-light;
  vertical-align: middle;
}

.bis-input {
  @include defaults.font-roboto-light;

  position: relative;
  display: inline-block;
  width: 3.8em;
  padding: .6em 1em;
  border-radius: 3em;
  box-sizing: border-box;
  text-align: right;
}

// Grid

$bmc-items-hor-spacing-outer: 10px;
$bmc-items-hor-spacing-inner: 20px;
$bmc-items-hor-spacing-inner-vert: 10px;

@function bmc-item-width ($num-of-items) {
  @return calc(
    (100% - #{$num-of-items * $bmc-items-hor-spacing-inner}) /
    #{$num-of-items}
  );
}

.bmc-items {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.bmc-items-grid {
  top: $bmc-items-hor-spacing-outer;
  left: $bmc-items-hor-spacing-outer;
  right: $bmc-items-hor-spacing-outer;
  margin-right: -$bmc-items-hor-spacing-inner;
  margin-bottom: -$bmc-items-hor-spacing-inner-vert;
}

.bmc-item {
  position: relative;
  display: inline-block;
}

.bmc-grid-item {
  width: bmc-item-width(2);
  margin-right: $bmc-items-hor-spacing-inner;
  margin-bottom: $bmc-items-hor-spacing-inner-vert;

  @media (min-width: #{defaults.$media-width-xsmall}) {
    width: bmc-item-width(3);
  }

  @media (min-width: #{defaults.$media-width-small}) {
    width: bmc-item-width(4);
  }

  @media (min-width: #{defaults.$media-width-medium}) {
    width: bmc-item-width(5);
  }

  @media (min-width: #{defaults.$media-width-large}) {
    width: bmc-item-width(6);
  }

  @media (min-width: #{defaults.$media-width-xlarge}) {
    width: bmc-item-width(7);
  }

  @media (min-width: #{defaults.$media-width-xxlarge}) {
    width: bmc-item-width(8);
  }

  .bmci-image {
    width: 100%;
    padding-bottom: 100%;
  }
}

.bmci-image {
  box-shadow: defaults.$box-shadow-cover-light;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

// Schedule

.bmsd-scheduler {

  .scheduler-types,
  .bmc-time,
  .bas-schedule-repeat-toggle,
  .bmci-btn.bas-scheduler-scene-button,
  .bmci-btn.bas-scheduler-time-button,
  .bas-scheduler-remove,
  .bas-scheduler-toggle,
  .bas-schedule-sunrise,
  .bas-schedule-sunset {
    display: none;
  }

  .bmc-slider {
    display: flex;
    background-color: transparent;
    color: defaults.$color-background;
  }

  &.bas-scheduler-can-remove {

    .bas-scheduler-remove {
      display: block;
    }
  }

  &.bas-scheduler-can-time {

    .bas-scheduler-time-button {
      display: block;
    }
  }

  &.bas-scheduler-can-scene {

    .bas-scheduler-scene-button {
      display: block;
    }
  }

  &.bas-scheduler-can-toggle {

    .bmsdi-name {
      margin-left: 56px;
    }

    .bas-scheduler-toggle {
      display: block;
    }

    .bas-scheduler-remove {
      right: 80px;
    }
  }

  &.bas-scheduler-show-timer {

    .bmc-slider-title {
      visibility: hidden;
    }

    .bmc-slider {
      display: none;
    }

    .bmc-time {
      display: block;
    }

    .bas-schedule-repeat-toggle {
      display: block;
    }
  }

  &.bas-scheduler-show-sunset {
    .bas-schedule-sunset {
      display: flex;
    }
  }

  &.bas-scheduler-show-sunrise {
    .bas-schedule-sunrise {
      display: block;
    }
  }

  .bmch-title-button {
    width: 100%;
  }

  .bmch-title {
    right: 110px;
  }

  .bmch-has-back {
    .bmch-title {
      right: 140px;
    }
  }

  &.bmch-can-sun {
    .scheduler-types {
      display: flex;
    }
  }
}

.scene-toggle {
  position: absolute;
  top: 10px;
  left: $bmc-hor-spacing;
}

.bmc-schedule-header {
  background-color: defaults.$color-library-header;
}

.bas-schedule-timing {

  .bas-schedule-days-list {
    display: none;
  }

  &.bas-schedule-repeat {

    .bas-schedule-date {
      display: none;
    }

    .bas-schedule-days-list {
      display: block;
    }
  }
}

.bas-scenes-container {
  @include util.absolute-match-parent();
}

.scheduler-slider {
  flex: 1 1 auto;
  margin-left: 16px;
  position: relative;
}

.scheduler-slider-value {
  @include defaults.font-roboto-light;

  flex: 0 0 auto;
  font-size: 0.4em;
  width: 1.4em;
  margin-left: 16px;
  text-align: right;
}

.scheduler-slider-unit {
  @include defaults.font-roboto-light;

  flex: 0 0 auto;
  font-size: 0.4em;
  margin-right: 16px;
}

.bas-schedule-sunrise,
.bas-schedule-sunset {
  @include util.absolute-center();

  top: 91%;
  font-size: 12px;
  line-height: 1em;
  color: defaults.$color-background-shadow;
}

.scheduler-types {
  display: flex;
  margin: 20px 16px;
  border-radius: 6px;
  border: 1px solid defaults.$color-highlight-on-light;
  overflow: hidden;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: 350px;
    margin: 20px auto;
  }
}

.scheduler-type {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  position: relative;
  display: inline-block;
  width: 33%;
  text-align: center;
  color: defaults.$color-highlight-on-light;
  border-right: 1px solid defaults.$color-highlight-on-light;
  padding: .5em 0;
  font-size: .8em;

  &:last-child {
    border-right: transparent;
  }
}

.bas-scheduler-type-selected {
  background-color: defaults.$color-highlight-on-light;
  color: defaults.$color-foreground;
}

.bmc-slider-title {
  @include defaults.font-roboto-regular;

  margin-left: 16px;
  margin-right: 16px;
}

.bmc-slider-remove-button {
  @include defaults.font-roboto-regular;

  border: 1px solid defaults.$color-highlight-on-light;
  color: defaults.$color-highlight-on-light;
  padding: .5em 0;
  border-radius: 100px;
  text-align: center;
  margin: 1em 16px 0;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: 250px;
    margin: 1em auto 0;
  }
}

//embedded settings view
.bas-scene-image-picker {
  font-size: 16px;

  .bip-image-item-wrapper,
  .modal-action-buttons {
    max-width: defaults.$media-phone-width;
  }

  .bs-title-line-txt {
    @media (max-width: #{defaults.$media-phone-width}) {
      font-size: basstyle_const.$bs-title-line-font-size;
    }
  }
}
