@use 'sass:color';

// Colors

$color-highlight-on-dark: #ff881d !default;
$color-highlight-on-light: #d86600 !default;
$color-highlight-transparent: rgba(216, 102, 0, 0.3) !default;
$color-highlight-on-dark-darker: color.adjust($color-highlight-on-dark, $lightness: -20%) !default;
$color-highlight-on-light-darker: color.adjust($color-highlight-on-light, $lightness: -10%) !default;
$color-foreground: #fff !default;
$color-foreground-light: #f1f1f1 !default;
$color-foreground-dark: #c0c0c0 !default;
$color-background: #000 !default;
$color-background-dark: #232323 !default;
$color-background-dark-fixed: #232323 !default;
$color-background-darker: #2e2e2e !default;
$color-background-dark-on-dark: #151515 !default;
$color-background-shadow: #888 !default;
$color-background-shadow-light: #adadad !default;
$color-background-shadow-dark: #666 !default;
$color-background-active: #444 !default;
$color-background-dark-transparent-light: rgba(#000, .3) !default;
$color-background-light-semi-transparent: rgba(#fff, .8) !default;
$color-background-light-more-transparent: rgba(#fff, .5) !default;
$color-background-light-very-transparent: rgba(#fff, .25) !default;
$color-background-light-really-transparent: rgba(#fff, .15) !default;
$color-background-light-practically-transparent: rgba(#fff, .02) !default;
$color-background-light-practically-less-transparent: rgba(#fff, .04) !default;
$color-separator-on-dark: #353535 !default;
$color-header: rgba($color-background, 0.6) !default;
$color-black-bg-trans: rgba(0, 0, 0, .25) !default;
$color-black-dark-bg-trans: rgba(0, 0, 0, .35) !default;
$color-black-trans-10: rgba(0, 0, 0, .1) !default;
$color-black-dark-bg-trans-dark: rgba(0, 0, 0, .5) !default;
$color-black-dark-bg-trans-overlay: rgba(0, 0, 0, .345) !default;
$color-black-darker-bg-trans: rgba(0, 0, 0, .7) !default;
$color-background-active-transparent: rgba($color-background, .2) !default;
$color-text-black: #000 !default;
$color-text-dark: #1f1f1f !default;
$color-text-dark-transparent: rgba($color-text-dark, .5) !default;
$color-text-dark-transparent-disabled: rgba($color-text-dark, .3) !default;
$color-text-light-transparent: rgba($color-foreground, 0.6) !default;
$color-text-light: #777 !default;
$color-text-light-transparent: rgba($color-text-light, .5) !default;
$color-text-light-secondary: #b7b7b7 !default;
$color-text-lighter: #c8c8c8 !default;
$color-text-lighter-inverse: #404040 !default;
$color-text-grey: #959595 !default;
$color-grey-bg: #ccc !default;
$color-accent-red: #900 !default;
$color-accent-red-active: rgba($color-accent-red, .5) !default;
$color-accent-red-active-deeper: rgba($color-accent-red, .7) !default;
$color-accent-red-bright: #d20000 !default;
$color-accent-red-bright--a7: rgba($color-accent-red-bright, .7) !default;
$color-accent-red-bright--a35: rgba($color-accent-red-bright, .35) !default;
$color-grey: #ababab !default;
$color-light-control-buttons-f: #ddd !default;
$color-light-control-buttons: rgba($color-foreground, 0.65) !default;
$color-light-control-buttons-active: rgba($color-highlight-on-dark, 0.65) !default;
$color-highlight-muted: rgba($color-highlight-on-dark, .6) !default;
$color-light-separator: rgba($color-foreground, 0.1) !default;
$color-highlight-selected: rgba($color-highlight-on-dark, 0.25) !default;
$color-foreground-selected: rgba($color-foreground, 0.2) !default;
$color-highlight-bg: rgba($color-highlight-on-dark, 0.1) !default;
$color-background-disabled: #898989 !default;
$color-dark-control-buttons: #999 !default;
$color-dark-control-buttons-disabled: #c2c2c2 !default;
$color-dark-separator: #c0c0c0 !default;
$color-highlight-disabled: #bebebe !default;
$color-highlight-disabled-transparent-lighter: rgba($color-highlight-disabled, 0.25) !default;
$color-highlight-disabled-transparent: rgba($color-highlight-disabled, 0.4) !default;
$color-foreground-disabled: #e5e5e5 !default;
$color-spotify-green: #1db954 !default;
$color-heating: #c62828 !default;
$color-cooling: #03a9f4 !default;
$color-neutral: #9e9e9e !default;
$color-thermostat-heating: rgba($color-heating, .3) !default;
$color-thermostat-cooling: rgba(#1976d2, .3) !default;
$color-remove-light: #b71c1c !default;
$color-remove-dark: #7f1515 !default;
$color-remove-light-darker: color.adjust($color-remove-light, $lightness: -10%);
$color-remove-dark-darker: color.adjust($color-remove-dark, $lightness: -10%);
$color-red-on-black: #ff2600 !default;
$color-red-on-black-highlight: #ff5737 !default;
$color-background-shadow-trans: rgba($color-background-shadow, .5);
$color-green: #1e9443 !default;
$color-green-light: #6be190 !default;
$color-red: #800001 !default;
$color-red-light: #ff4d4e !default;
$color-grey-transparent: rgba(120, 120, 120, .25);
$color-modal-background: rgba($color-background, .9);

// Library
$color-library-header: #ebebeb !default;
$color-library-header-secondary: #f7f7f7 !default;
$color-library-background: #ddd !default;
$color-library-control: #a0a0a0 !default;
$color-library-border: #d1d1d1 !default;
$color-library-list-control: #ababab !default;
$color-library-list-border: rgba($color-foreground, 0.6) !default;
$color-library-separator: #c5c5c5 !default;

$box-shadow-cover-light: 0 0 5px 0 $color-background-shadow;

// Animation time

$standard-animation-time: .2s !default;
$standard-animation-time-long: .5s !default;
$active-animation-time: 1s !default;
$animation-timing: cubic-bezier(.64, .09, .08, 1);

// Fonts

@mixin font-roboto-regular() {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

@mixin font-roboto-light() {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

@mixin font-roboto-bold() {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

// Sizes

$main-header-height: 68px !default;
$main-header-height-phone: 44px !default;

$main-footer-height: 70px !default;
$main-footer-height-phone: 49px !default;

$bmsc-header-height: 3.2em !default;

$bs-btn-circle-size: 36px !default;
$bs-btn-circle-big-size: 70px !default;
$bs-btn-radio-btn-size: 26px !default;
$bs-btn-radio-btn-inner-size: 20px !default;

$bs-card-between-spacing-phone: 8px !default;
$bs-card-between-spacing: 16px !default;
$bs-side-spacing-phone: 8px !default;
$bs-side-spacing: 16px !default;
$bs-card-inner-spacing-phone: 12px !default;
$bs-card-inner-spacing: 16px !default;

$bs-swiper-nav-size: 36px !default;
$bs-swiper-nav-icon-size: 36px !default;

$bs-min-modal-action-buttons-container-width: 240px !default;

$lisa-size: 360px;

// Masks

$navigation-bar-mask: linear-gradient(
  to right,
  transparent 0%,
  black 1em,
  black calc(100% - 1em),
  transparent 100%
);
$navigation-bar-mask-padding: 1em;

// Media queries

$media-phone-width: 667px;
$media-tablet-landscape: 1000px;
$max-width-ipad-pro-landscape: 1366px;
$min-aspect-ratio-device: '45/100';
$aspect-ratio-device-55: '55/100';
$media-query-tablet-portrait: 740px;
$media-query-tablet-landscape: 1000px;
$media-query-pc-landscape: 1200px;
$screen-size-330: 330px;
$screen-size-500: 500px;
$screen-size-600: 600px;
$screen-size-900: 900px;
$screen-size-1100: 1100px;
$screen-size-1250: 1250px;
$screen-size-1450: 1450px;
$screen-size-1600: 1600px;
$screen-size-1800: 1800px;

$media-lisa-height: 360px;

// Tile breakpoints

$media-width-xxxsmall: 350px;
$media-width-xxsmall: 400px;
$media-width-xsmall: 500px;
$media-width-small: 650px;
$media-width-medium: 850px;
$media-width-large: 1000px;
$media-width-xlarge: 1200px;
$media-width-xxlarge: 1600px;
$media-width-xxxlarge: 1850px;

// Debugging

$debug: false !default;
$show-ios-status-bar: false !default;
