@use 'sass:color';
@use 'sass:math';
@use 'defaults';
@use 'bas_notch_const';
@use 'basstyle_const';
@use 'util';

//rs = room-setting

$room-setting-group-margin: .5em;
$room-setting-group-margin-tablet: 2 * $room-setting-group-margin;
$rs-phone-buttons: 32px;
$rs-component-margin: 1em;
$rs-item-outer-padding: 1em;
$rs-slider-second-margin: .5em;
$rs-button-size: 2.15em;

$rs-shade-button-margin: .5em;
$rs-shade-button-margin-l: 1em;

.rs-item {

  $bmc-hor-spacing: 16px;

  &.brs-light {

    .rs-btn-color-circle,
    .rs-btn-circle,
    .rs-slider-wrapper,
    .rs-second-line,
    .rs-icon-hue,
    .rs-settings,
    .rs-separator,
    .rs-subtitle {
      display: none;
    }

    .light-group-show-color-icons {

      .rs-btn-color-circle,
      .rs-btn-circle,
      .rs-icon-hue {
        display: block;
      }
    }

    .rs-body {
      padding: 0;
    }

    .rs-radio-btn {

      &::after {
        content: none;
      }
    }

    .rs-btn-color-circle,
    .rs-btn-circle {
      margin-left: 1em;
      flex: 0 0 auto;
    }

    .rs-btn-color-circle {
      width: 24px;
      height: 24px;
    }

    &.brs-show-subtitle {
      .rs-subtitle {
        display: block;
      }
    }

    &.brs-show-separator {
      .rs-separator {
        display: block;
      }
    }

    &.brs-show-slider {

      .rs-body {
        padding: $rs-item-outer-padding;
      }

      .rs-body-slider {
        padding: 0 $rs-item-outer-padding;

        .grsw {
          padding: $rs-item-outer-padding 0;
        }
      }

      .rs-second-line {
        display: block;
      }

      .rs-slider-wrapper {
        display: flex;
      }
    }

    &.brs-show-temp,
    &.brs-show-white,
    &.brs-show-white-brightness {

      .rs-settings {
        display: block;
      }

      .rs-btn-circle {
        display: block;
      }
    }

    &.brs-show-generic {

      .rs-settings {
        display: block;
      }
    }

    &.brs-show-color {

      .rs-icon-sliders {
        display: none;
      }

      .rs-icon-hue,
      .rs-settings {
        display: block;
      }

      .rs-btn-circle {
        display: block;
      }
    }

    &.brs-show-color-circle {

      .rs-btn-color-circle {
        display: block;
      }
    }

    &.rs-radio-selected {

      .rs-radio-btn {

        &::after {
          content: '';
        }
      }
    }
  }

  &.brs-shade {

    .rs-button-auto,
    .rs-button-alarm,
    .rs-modal,
    .rs-separator,
    .rs-subtitle {
      display: none;
    }

    .rs-btn-circle {
      margin-left: $rs-shade-button-margin-l;
      flex: 0 0 auto;

      &.rs-body-button {
        flex: 0 0 calc((100% - #{$rs-shade-button-margin * 4}) / 3);
        box-sizing: border-box;
        width: 100%;
        margin: 0 $rs-shade-button-margin;

        &.rs-close {
          margin-left: 0;
        }

        &.rs-open {
          margin-right: 0;
        }

        @media (min-width: #{defaults.$media-phone-width}) {
          margin: 0 $rs-shade-button-margin-l;
          flex: 0 0 calc((100% - #{$rs-shade-button-margin-l * 4}) / 3);
        }
      }
    }

    .rs-open,
    .rs-close,
    .rs-stop {
      display: none;
    }

    .rs-body {
      padding: $rs-item-outer-padding;
    }

    .rs-radio-btn {

      &::after {
        content: none;
      }
    }

    &.bsd-modal-has {

      .rs-modal {
        display: block;
      }
    }

    &.bsd-auto-has {

      .rs-button-auto {
        display: block;
      }
    }

    &.bsd-warning-has {

      .rs-button-alarm {
        display: block;
      }

      &.bas-open-has,
      &.bsd-close-has,
      &.bsd-stop-has {

        .rs-open,
        .rs-stop,
        .rs-close {
          @include basstyle_const.bs-btn-on-light-disabled();
        }

        &.bsd-opened,
        &.bsd-closed {

          .rs-open,
          .rs-stop,
          .rs-close {
            @include basstyle_const.bs-btn-on-light-disabled();
          }
        }
      }
    }

    .rs-modal {
      &:active {
        @include basstyle_const.bs-btn-highlight-on-dark();
        @include basstyle_const.bs-btn-instant();
      }
    }

    &.bsd-open-has {

      &.bsd-opened {

        .rs-open {
          @include basstyle_const.bs-btn-highlight-on-dark();
        }
      }

      .rs-open {
        @include basstyle_const.bs-btn-on-dark();

        display: block;

        &:active {
          @include basstyle_const.bs-btn-active-highlight-transparent-fill();
        }
      }
    }

    &.bsd-close-has {

      &.bsd-closed {

        .rs-close {
          @include basstyle_const.bs-btn-highlight-on-dark();
        }
      }

      .rs-close {
        @include basstyle_const.bs-btn-on-dark();

        display: block;

        &:active {
          @include basstyle_const.bs-btn-active-highlight-transparent-fill();
        }
      }
    }

    &.bsd-stop-has {

      .rs-stop {
        @include basstyle_const.bs-btn-on-dark();

        display: block;

        &:active {
          @include basstyle_const.bs-btn-active-highlight-transparent-fill();
        }
      }
    }

    &.bsd-auto-active {

      .rs-button-auto {
        @include basstyle_const.bs-btn-highlight-on-dark();
      }
    }

    &.brs-show-subtitle {

      .rs-subtitle {
        display: block;
      }
    }

    &.brs-show-separator {

      .rs-separator {
        display: block;
      }
    }

    &.rs-radio-selected {

      .rs-radio-btn {

        &::after {
          content: '';
        }
      }
    }

    @media (min-width: #{defaults.$media-phone-width}) {
      .rs-second-line {
        justify-content: flex-end;
      }

      .rs-button-wrapper {
        width: 30em;
        max-width: 100%;
      }
    }
  }

  &.brs-thermostat-control {
    background-color: transparent;
  }

  &.brs-thermostat-control,
  &.brs-generic-device {

    .rs-single-control-radio-btn,
    .rs-slider-wrapper,
    .rs-slider-value,
    .rs-toggle,
    .rs-value,
    .rs-indicator,
    .rs-button-trigger,
    .rs-separator,
    .rs-subtitle,
    .rs-button-edit {
      display: none;
    }

    .rs-radio-btn {

      &::after {
        content: none;
      }
    }

    .rs-line-item {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.brs-single-control {

      .rs-body {
        padding: 0;
      }

      &.brs-show-slider {
        .rs-body {
          padding: 1em;
        }
      }
    }

    &.brs-show-separator,
    .brs-show-separator {
      .rs-separator {
        display: block;
      }
    }

    &.brs-show-value,
    .brs-show-value {
      .rs-value {
        display: flex;
      }
    }

    &.brs-show-indicator,
    .brs-show-indicator {
      .rs-indicator {
        display: block;
      }
    }

    &.brs-show-toggle,
    .brs-show-toggle {
      .rs-toggle {
        display: block;
      }
    }

    &.brs-show-slider,
    .brs-show-slider {
      .rs-slider-wrapper {
        display: flex;
      }
    }

    .brs-show-slider-value,
    &.brs-show-slider-value {
      .rs-slider-value {
        display: block;
      }
    }

    &.brs-show-trigger,
    .brs-show-trigger {
      .rs-button-trigger {
        display: block;
      }
    }

    &.brs-show-edit,
    .brs-show-edit {
      .rs-button-edit {
        display: block;
      }
    }

    &.brs-value-active,
    .brs-value-active {
      .rs-button-trigger {
        @include basstyle_const.bs-btn-highlight-on-light();
        @include basstyle_const.bs-btn-instant();
      }

      .rs-indicator::before {
        opacity: 1;
      }
    }

    &.brs-value-upper,
    .brs-value-upper {
      .rs-value {
        text-transform: uppercase;
      }
    }

    .rs-radio-button-visible {

      .rs-single-control-radio-btn {
        display: block;
      }
    }

    .rs-radio-selected {

      .rs-radio-btn {

        &::after {
          content: '';
        }
      }
    }
  }

  &.brs-generic-item {

    .rs-subtitle {
      display: none;
    }

    .rs-subtitle:not(:empty) {
      display: block;
    }

    .rs-name:not(:empty) {
      display: block;
    }

    .rs-line {
      height: 100%;
    }

    .rs-header {
      height: 59px;
    }
  }

  &.brs-light-color-item {
    background: none;
    margin: 0;

    &::before {
      content: '';
      position: absolute;
      left: $bmc-hor-spacing;
      right: $bmc-hor-spacing;
      bottom: 0;
      border-bottom: 1px solid defaults.$color-light-control-buttons;
    }

    &:last-child {
      &::before {
        content: none;
      }
    }

    .rs-header {
      background-color: transparent;
    }

    .rs-slider-value,
    .rs-subtitle,
    .rs-title {
      color: defaults.$color-background;
    }

    &.brs-show-slider {

      .rs-body {
        padding-top: 0;
      }
    }

    .rs-button-trigger {
      @include basstyle_const.bs-btn-on-light();

      &:active {
        @include basstyle_const.bs-btn-highlight-on-light();
        @include basstyle_const.bs-btn-instant();
      }
    }

    &.brs-value-active,
    .brs-value-active {
      .rs-button-trigger {
        @include basstyle_const.bs-btn-highlight-on-light();
        @include basstyle_const.bs-btn-instant();
      }
    }

    .rs-btn-circle,
    .rs-button-auto {
      border-color: defaults.$color-library-control;
      color: defaults.$color-background-active;

      svg {
        fill: defaults.$color-library-control;
      }

      &.rs-close,
      &.rs-open {
        @include basstyle_const.bs-btn-on-light();
      }

      &:active {
        @include basstyle_const.bs-btn-highlight-on-light();
        @include basstyle_const.bs-btn-instant();
      }
    }

    &.bsd-close-has,
    &.bsd-open-has {

      .rs-btn-circle {

        &:active {
          @include basstyle_const.bs-btn-highlight-on-light();
          @include basstyle_const.bs-btn-instant();
        }
      }
    }

    &.bsd-auto-active {

      .rs-button-auto {
        @include basstyle_const.bs-btn-highlight-on-light();
      }
    }

    &.bsd-open-has {

      &.bsd-opened {

        .rs-open {
          @include basstyle_const.bs-btn-highlight-on-light();
          @include basstyle_const.bs-btn-instant();
        }
      }
    }

    &.bsd-close-has {

      &.bsd-closed {

        .rs-close {
          @include basstyle_const.bs-btn-highlight-on-light();
          @include basstyle_const.bs-btn-instant();
        }
      }
    }
  }

  &.brs-header-item {
    flex: 0 0 auto;
    background: none;
    margin: 0;

    &::before {
      content: '';
      position: absolute;
      left: $bmc-hor-spacing;
      right: $bmc-hor-spacing;
      bottom: 0;
      border-bottom: 1px solid defaults.$color-foreground-dark;
    }

    &:last-child {
      &::before {
        content: none;
      }
    }

    .rs-header {
      background-color: transparent;
    }

    .rs-title {
      color: defaults.$color-background;
    }
  }

  &.bas-notch-margin:last-child {
    margin-bottom: 0;
    margin-bottom: bas_notch_const.$notch-safe-bottom;
  }

  &.rs-item-disabled {

    .rs-title,
    .rs-name {
      color: defaults.$color-text-light;
    }

    .rs-separator {
      background-color: defaults.$color-text-light;
    }

    .rs-btn-circle,
    .rs-button-auto,
    .rs-button-alarm,
    .rs-btn-color-circle,
    .rs-slider,
    .rs-toggle {
      pointer-events: none;
    }

    .rs-btn-circle,
    .rs-button-auto,
    .rs-button-alarm,
    .rs-btn-color-circle {
      opacity: .6;
    }

    .rs-slider {
      .grspr {
        background-color: defaults.$color-background-disabled;
      }
    }

    .rs-toggle {
      .bas-toggle {
        @include basstyle_const.bas-toggle-disabled();
      }
    }

    .rs-slider-value {
      color: defaults.$color-text-light-secondary;
    }

    // Specific

    &.brs-shade {
      &.bsd-open-has {
        .bsd-opened {
          .rs-open {
            color: white;
            border-color: white;
            font-weight: 900;
          }
        }
      }

      &.bsd-close-has {
        .bsd-closed {
          .rs-closed {
            color: defaults.$color-foreground;
            border-color: defaults.$color-foreground;
            font-weight: 900;
          }
        }
      }

      &.bsd-auto-active {

        .rs-button-auto {
          border-color: defaults.$color-foreground;
          color: defaults.$color-foreground;
          font-weight: 900;

          svg {
            fill: defaults.$color-foreground;
          }
        }
      }
    }
  }
}

.rs-modal {
  margin-left: $rs-component-margin;
}

.rs-button-alarm {
  position: relative;
  flex: 0 0 auto;
  width: $rs-phone-buttons * 0.5;
  height: $rs-phone-buttons * 0.5;
  margin-left: $rs-component-margin;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: defaults.$bs-btn-circle-size * 0.5;
    height: defaults.$bs-btn-circle-size * 0.5;
  }

  svg {
    @include util.absolute-center();

    fill: defaults.$color-highlight-on-dark;
    width: 100%;
    height: 100%;
  }
}

.rs-button-auto {
  @include defaults.font-roboto-regular;

  color: defaults.$color-light-control-buttons;
  border-radius: 9999px;
  border: 1px solid defaults.$color-light-control-buttons;
  transition:
    border defaults.$standard-animation-time,
    color defaults.$standard-animation-time;
  padding: .2em .6em;
  flex: 0 0 auto;
  text-align: center;
  margin-left: $rs-component-margin;

  &:active {
    color: defaults.$color-highlight-on-dark;
    border: 1px solid defaults.$color-highlight-on-dark;
  }
}

.rs-value {
  @include defaults.font-roboto-regular;

  flex: 0 0 auto;
  max-width: 80%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: baseline;
  margin-left: $rs-component-margin;
  color: defaults.$color-foreground;
}

.rs-value-grey {
  color: defaults.$color-text-grey;
}

.rs-indicator {
  flex: 0 0 auto;
  position: relative;
  width: basstyle_const.$bas-button-toggle-total-height;
  height: basstyle_const.$bas-button-toggle-total-height;
  margin-left: $rs-component-margin;
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: defaults.$color-dark-control-buttons;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    border-radius: 100%;
    background-color: defaults.$color-foreground;
    opacity: 0;
  }
}

.rs-value-value,
.rs-value-unit {
  text-align: right;
}

.rs-value-unit {
  @include defaults.font-roboto-light;
}

.brs-has-unit {
  .rs-value-unit {
    margin-left: .4em;
  }
}

.rs-button-trigger,
.rs-button-edit {
  flex: 0 0 auto;
  margin-left: $rs-component-margin;
  color: defaults.$color-light-control-buttons;
  border-color: defaults.$color-light-control-buttons;

  &::after {
    background-color: defaults.$color-light-control-buttons;
  }

  &:active {
    transition: color 0s, border-color 0s;
    color: defaults.$color-highlight-on-dark;
    border-color: defaults.$color-highlight-on-dark;
  }
}

.rs-button-edit {
  position: relative;
  height: $rs-button-size;
  width: $rs-button-size;
  box-sizing: border-box;

  svg {
    @include util.absolute-center;

    width: 75%;
    fill: defaults.$color-light-control-buttons;
  }

  &:active {
    svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

.rs-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  width: 100%;
}

.rs-body-button {
  flex: 0 1 auto;
  width: 100%;
  margin: 0 .5em;
  border-radius: 9999px;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin: 0 1em;
  }

  &.rs-close {
    margin-left: 0;
  }

  &.rs-open {
    margin-right: 0;
  }
}

.rs-toggle {
  flex: 0 0 auto;
  margin-left: $rs-component-margin;
}

.rs-slider-value {
  @include defaults.font-roboto-regular;

  flex: 0 0 auto;
  margin-left: $rs-component-margin*0.5;
  color: defaults.$color-foreground;
  width: 2em;
  text-align: right;
}

.rs-slider {
  flex: 1 1 auto;
}

.rs-slider-name {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  color: defaults.$color-foreground;
}

.rs-slider-wrapper {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  width: 100%;
}

.rs-separator {
  margin-left: .4em;
  margin-right: .4em;
  width: 1px;
  background-color: defaults.$color-grey;
  height: 1em;
}

.rs-icon {
  position: relative;
  flex: 0 0 auto;
  width: $rs-phone-buttons;
  height: $rs-phone-buttons;

  svg {
    @include util.absolute-center();

    width: 100%;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    width: defaults.$bs-btn-circle-size;
    height: defaults.$bs-btn-circle-size;
  }
}

.rs-inner-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rs-icon-hue {
  svg {
    width: 75%;
    height: 75%;
  }
}

.rs-btn-spacing-right {
  margin-right: $rs-component-margin;
}

.rs-radio-btn {
  flex: 0 0 auto;
  margin-right: $rs-component-margin;
}

.rs-subtitle {
  @include defaults.font-roboto-light;

  flex: 0 1 auto;
  color: defaults.$color-foreground-light;
  min-width: 0;
}

.rs-custom-name {
  flex: 0 1 auto;
  min-width: 0;
}

.rs-divider {
  flex: 0 0 auto;
  margin: 0 .5em;
}

.rs-name {
  flex: 0 1 auto;
  min-width: 0;
}

.rs-title {
  @include defaults.font-roboto-regular;

  display: flex;
  align-items: center;
  flex: 0 1 auto;
  color: defaults.$color-foreground;
  font-size: 1.2em;
  min-width: 0;
}

.rs-title-wrapper {
  display: inline-flex;
  justify-content: center;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
}

.rs-info-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
}

.rs-line {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  .bs-radio-btn {
    flex-shrink: 0;
    margin-left: 5px;
  }
}

.rs-body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $rs-item-outer-padding;
  box-sizing: border-box;

  &.btd-body {
    padding-left: 0.6em;
    padding-right: 0.5em;
  }
}

.rs-ghost {
  position: absolute;
  left: -$rs-component-margin*0.5;
  right: -$rs-component-margin*0.5;
  top: -$rs-component-margin*0.5;
  bottom: -$rs-component-margin*0.5;
}

.rs-header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: $rs-item-outer-padding;
  // IOS doesn't like rgba values with similar alpha values on top of each other
  background-color: defaults.$color-black-dark-bg-trans-overlay;
  box-sizing: border-box;
}

.rs-placeholder {
  @include defaults.font-roboto-regular();

  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: defaults.$color-text-light;
  text-align: center;
}

.rs-item {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  margin-bottom: .5em;
  background: defaults.$color-black-dark-bg-trans;
  box-sizing: border-box;
  font-size: .8em;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1em;
  }
}

.rs-percentage-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: defaults.$color-highlight-on-dark;
  opacity: 0.7;
}

.room-setting-group {
  width: 100%;
  flex: 1;
  min-height: 2em;

  &:first-child {
    margin-top: 1em;
  }

  &:last-child {
    margin-bottom: .5em;

    @media (min-width: #{defaults.$media-phone-width}) {
      margin-bottom: 1em;
    };
  }
}

// Light groups

$light-placeholder-height: 9em;

.light-groups {
  width: 100%;
  padding-top: $rs-item-outer-padding;
  margin-bottom: 1em;

  .light-group-placeholder {
    height: $light-placeholder-height;
    width: 100%;
    box-sizing: border-box;
    padding: 1em;
    position: absolute;
  }

  .light-group-header {
    padding: .5em 1em;

    @media (min-width: #{defaults.$media-phone-width}) {
      padding: .8em 1em;
    }
  }

  .collapsible-wrapper:not(:empty) + .light-group-placeholder {
    display: none;
  }

  .bmcch-title-form {
    max-width: 150px;
  }

  .rs-header .bmsdh-icon {
    transition-duration: 0.4s;
  }

  .modal-menu-input {
    background-color: transparent;
    border: 1px solid defaults.$color-text-lighter;
    color: defaults.$color-foreground;

    &::placeholder {
      color: defaults.$color-foreground;
    }
  }

  .light-group-remove {
    margin-left: $rs-component-margin;
  }

  .light-group-body {

    .rs-slider-value {
      font-size: 1.2em;

      @media (min-width: #{defaults.$media-phone-width}) {
        font-size: 1em;
      }
    }
  }

  .light-group-placeholder,
  .light-group-items {
    @include defaults.font-roboto-regular;

    color: defaults.$color-text-lighter;
  }

  &.add-drag-spacing-to-groups {

    .collapsible-wrapper,
    .light-group-items {

      &.add-collapsed-padding {
        position: relative;
        z-index: 1;
        padding-top: 4em;
        height: 0;
        margin-top: -4em;
      }
    }
  }

  .light-group-items {
    min-height: $light-placeholder-height;
    width: 100%;
    display: inline-block;

    &.hide-light-group {
      padding: 0;
    }

    .rs-item {
      display: flex;
      margin: 1em;
      width: auto;

      .rs-header {
        background-color: transparent;
      }
    }

  }

  .light-group-brightness-slider {
    background-color: transparent;
    margin: 0;
  }
}

.light-groups .bmsdh-icon,
.lights .bmsdh-icon {
  margin-right: $room-setting-group-margin;
  margin-left: 0;
}

.light-group-collapse-icon {
  width: 20px;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: 24px;
  }
}

.light-groups .collapsible-icon,
.lights .collapsible-icon {
  height: 1.8em;
}

.light-selected {
  background-color: rgba(0, 0, 0);
}

.sortable-ghost {
  opacity: 0.2;
}

.lights-settings-header {
  z-index: 1;
  background-color: defaults.$color-background-dark-transparent-light;
  color: defaults.$color-text-lighter;

  .bsh-element {
    padding: 0;
  }

  .bah-edit-add-light-group-button,
  .bah-edit-cancel {
    display: none;
  }

  .bah-title {
    color: defaults.$color-text-lighter;
  }

  &.bah-is-edit {
    .bah-edit-add-light-group-button,
    .bah-edit-cancel {
      display: block;
    }

    .bah-edit-done,
    .bah-edit-cancel {
      padding-left: $rs-item-outer-padding;
    }
  }

  .bah-edit-edit {
    padding-left: $rs-item-outer-padding;
  }

  .mh-icon {
    transition-duration: 0.4s;

    svg {
      fill: defaults.$color-text-lighter;
    }
  }
}

.room-setting-scrolling {
  padding: 0 $room-setting-group-margin;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding-bottom: bas_notch_const.$notch-safe-bottom;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding-left: $room-setting-group-margin-tablet;
    padding-right: $room-setting-group-margin-tablet;
  }

  .bmsd-header {
    background-color: transparent;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: bas_notch_const.$notch-safe-bottom;
  }
}

.room-setting-lights {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .lights-wrapper {
    padding: 0 $room-setting-group-margin;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: calc(#{bas_notch_const.$notch-safe-left} + $room-setting-group-margin);
    padding-right: calc(#{bas_notch_const.$notch-safe-right} + $room-setting-group-margin);

    @media (min-width: #{defaults.$media-phone-width}) {
      padding-left: calc(#{bas_notch_const.$notch-safe-left} + $room-setting-group-margin-tablet);
      padding-right: calc(#{bas_notch_const.$notch-safe-right} + $room-setting-group-margin-tablet);
    }
  }

}

// Modal

.rs-modal-title {
  @include defaults.font-roboto-regular();

  display: flex;
  align-items: center;
  color: defaults.$color-foreground;
  font-size: 1.4em;
  font-weight: bold;
}

.rs-modal-title-type {
  flex: 0 1 auto;
  min-width: 0;
}

.rs-modal-title-divider {
  margin-left: .4em;
  margin-right: .4em;
  width: 1px;
  background-color: defaults.$color-text-dark;
  height: 1em;
}

.rs-modal-title-name {
  flex: 0 1 auto;
  min-width: 0;
}

.rs-modal-title-location {
  margin-left: .4em;
  flex: 0 1 auto;
  min-width: 0;
  color: defaults.$color-foreground;
}

// Thermostats

$light-text-color: color.adjust(defaults.$color-foreground, $lightness: -15%);
$text-color: color.adjust(defaults.$color-foreground, $lightness: -30%);
$room-setting-container-margin: 2%;
$room-setting-body-padding: 0 2%;

.room-setting-item {
  position: relative;
  display: block;
  width: 100%;
  height: 4em;
  background: defaults.$color-black-dark-bg-trans;

  @media (max-width: #{defaults.$media-query-tablet-portrait}) {
    height: 8em;
    overflow: hidden;

    .room-setting-name-week {
      top: 25%;
      width: 50%;
    }

    .room-setting-name {
      top: 25%;
      width: 50%;
    }

    &::before {
      content: '';
      background-color: defaults.$color-black-dark-bg-trans;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 50%;
    }
  }
}

.room-setting-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4em;
  background: defaults.$color-black-darker-bg-trans;
  padding: $room-setting-body-padding;
  box-sizing: border-box;
}

.rsh-name {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  min-width: 0;
  color: defaults.$color-foreground;
  font-size: 1.2em;
}

.rsb-name-column {
  flex: 0 0 auto;
}

.rsb-time-column {
  flex: 1 1 auto;
  min-width: 0;
  position: relative;
}

.rsb-button-column {
  flex: 0 0 auto;
}

.rsb-row-item {
  height: 4em;
  border-bottom: 1px solid defaults.$color-foreground-dark;

  &:last-child {
    border-bottom: none;
  }
}

.rsb-row-hour {
  height: 4em;
}

.rsb-day-header {
  background-color: defaults.$color-black-dark-bg-trans;
  height: 4em;
  display: flex;
  align-items: center;
  padding: $room-setting-body-padding;
}

.rsb-day-body {
  height: 4em;
  position: relative;
}

.rsb-day-container {
  position: absolute;
  top: 4em;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.rsb-set-point-button {
  position: relative;
  width: 36px;
  height: 36px;
  // TODO Use defaults
  border: 1px solid #ff881d;
  border-radius: 100%;
  transition: background-color 1s;
  flex: 0 0 auto;
}

.rsb-button-row {
  width: 50px;
}

.rsb-set-point-row {
  @include util.button-cursor();

  position: relative;
}

.rsb-hour-list {
  display: flex;
  align-items: center;
}

.rsb-background-lines {
  @include util.vertical-center();

  display: flex;
  position: absolute;
  height: 32px;
  width: 100%;
  // Shift all items width half the width of 1 element to put it in the middle
  left: math.div(1 * 100%, 26);
}

.rsb-set-point-container {
  position: absolute;
  top: 0;
  bottom: 0;
  // You want to place all items in the middle so you need to remove the width of one element
  width: math.div(12 * 100%, 13);
  // Shift all items width half the width of 1 element to put it in the middle
  left: math.div(1 * 100%, 26);
}

.rsb-name-title {
  @include util.vertical-center();
  @include defaults.font-roboto-regular;

  font-size: 1.2em;
  color: defaults.$color-foreground;
  position: relative;
}

.rsb-name {
  @include defaults.font-roboto-regular;

  font-size: 1.2em;
  color: defaults.$color-foreground;
  flex: 1 1 auto;
}

.rsb-hour {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  display: inline-block;
  color: defaults.$color-light-control-buttons-f;
  text-align: center;
  //We need this so single digit numbers are the same width as double
  width: 0;
}

.rsb-line {
  flex: 1 1 auto;
  display: inline-block;
  // TODO Use defaults
  border-left: 1px solid rgba(defaults.$color-text-light, .5);
}

.rsh-toggle {
  flex: 0 0 auto;
}

.bas-app-header {
  .rsh-toggle {
    margin: 0 15px 0 10px;
  }
}

// TODO Use defaults
$timer-grid-color: #b3b3b3;

.rsi-scheduler {

  .rsb-name,
  .rsb-name-title {
    color: defaults.$color-foreground-dark;
  }

  .week-set-point {
    background-color: defaults.$color-text-light;
  }

  .set-point-temperature {
    color: defaults.$color-foreground-dark;
  }

  &.bas-scheduler--is-enabled {
    .rsb-name,
    .rsb-name-title {
      color: defaults.$color-foreground;
    }

    .week-set-point {
      background-color: defaults.$color-highlight-on-dark;
    }

    .set-point-temperature {
      color: defaults.$color-background;
    }
  }
}

$set-point-container-line-width: math.div(100%, 6);
$set-point-container-margin-p: 24px;
$set-point-container-margin: 48px;

$schedule-container-height: 350px;
$set-point-container-top-margin: 96px;
$set-point-container-hours-height: 72px;

.bas-timer {

  &.room-setting-container {
    width: 100%;
    margin: 0;
    top: 44px;

    @media (min-width: #{defaults.$media-phone-width}) {
      top: 3em;
    }
  }

  .rsb-day-header,
  .room-setting-body-phone,
  .room-setting-body-tablet {
    background-color: transparent;
  }

  .rsb-name-title,
  .rsb-name,
  .rsb-hour {
    color: defaults.$color-background;
  }

  .bas-scheduler--is-enabled {
    .rsb-name {
      color: defaults.$color-background;
    }

    .week-set-point {
      background-color: defaults.$color-highlight-on-light;
    }
  }

  .week-set-point-button {
    border-color: defaults.$color-highlight-on-light;

    svg {
      fill: defaults.$color-highlight-on-light;
    }
  }

  .set-point,
  .week-set-point-highlight,
  .week-set-point-selected::after {
    background-color: defaults.$color-highlight-on-light;
  }

  .set-point-selected {
    background-color: defaults.$color-highlight-on-light-darker;
  }

  .week-set-point-highlight {
    svg {
      fill: defaults.$color-foreground;
    }
  }

  .bs-btn-circle.bs-btn-highlight {
    border-color: defaults.$color-highlight-on-light;

    svg {
      fill: defaults.$color-highlight-on-light;
    }

    &:active {
      background-color: defaults.$color-highlight-on-light;

      svg {
        fill: defaults.$color-light-control-buttons;
      }
    }
  }

  .schedule-info {
    top: 16px;

    @media (min-width: #{defaults.$media-phone-width}) {
      left: $set-point-container-margin-p;
      right: $set-point-container-margin-p;
    }

    @media (min-width: #{defaults.$media-tablet-landscape}) {
      left: $set-point-container-margin;
      right: $set-point-container-margin;
    }
  }

  .schedule-remove {
    background-color: defaults.$color-remove-light;
    opacity: 0;
    top: 16px;

    @media (min-width: #{defaults.$media-phone-width}) {
      left: $set-point-container-margin-p;
      right: $set-point-container-margin-p;
    }

    @media (min-width: #{defaults.$media-tablet-landscape}) {
      left: $set-point-container-margin;
      right: $set-point-container-margin;
    }
  }

  .set-point-dragging {
    &.schedule-info {
      opacity: 0;
    }

    &.schedule-remove {
      opacity: 1;
    }
  }

  .set-point-deleted {

    &.schedule-remove {
      background-color: defaults.$color-remove-light-darker;
    }
  }

  .schedule-hours,
  .schedule-name {
    color: defaults.$color-background;
  }

  .room-setting-body-phone {
    top: 0;
    left: 10px;
    right: 10px;
    width: unset;
  }

  &.bas-object-group {
    font-size: 1em;
  }

  .set-point-container {
    top: 0;
    bottom: 0;
    margin: 96px $set-point-container-margin-p 0 $set-point-container-margin-p;
    background-size: $set-point-container-line-width 100%;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
    }

    @media (max-width: #{defaults.$media-phone-width}) {
      max-height: $schedule-container-height - $set-point-container-top-margin - $set-point-container-hours-height - 8px;
    }

    @media (min-width: #{defaults.$media-phone-width}) {
      margin: 96px $set-point-container-margin-p 80px $set-point-container-margin-p;
    }

    @media (min-width: #{defaults.$media-tablet-landscape}) {
      margin: 96px $set-point-container-margin 80px $set-point-container-margin;
    }
  }

  .schedule-container {
    max-height: $schedule-container-height;
  }

  .schedule-hours {
    bottom: 0;

    @media (min-width: #{defaults.$media-phone-width}) {
      left: $set-point-container-margin-p;
      right: $set-point-container-margin-p;
    }

    @media (min-width: #{defaults.$media-tablet-landscape}) {
      left: $set-point-container-margin;
      right: $set-point-container-margin;
    }
  }

  .rsb-day {
    border-bottom: 1px solid defaults.$color-foreground;

    &:last-child {
      border: none;
    }

    &.bas-notch-margin:last-child {
      margin-bottom: 0;
      margin-bottom: bas_notch_const.$notch-safe-bottom;
    }
  }
}

.room-setting-name {
  @include util.vertical-center();
  @include defaults.font-roboto-regular;

  display: block;
  position: absolute;
  left: 5%;
  color: defaults.$color-foreground;
  font-size: 1.2em;
}

.room-setting-name-week {
  left: 2%;
  width: 10%;
}

.room-setting-container {
  width: 100%;
  margin: 0;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: 100% - 2 * $room-setting-container-margin;
    height: 100% - 2 * $room-setting-container-margin;
    margin: $room-setting-container-margin;
  }
}

.room-setting-body-tablet {
  width: 100%;
  max-height: 100%;
  padding: $room-setting-body-padding;
  padding-bottom: .5em;
  background-color: defaults.$color-background-dark-transparent-light;
  flex-flow: row nowrap;
  box-sizing: border-box;
  display: none;
  overflow-y: auto;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: flex;
  }
}

.room-setting-body-phone {
  position: absolute;
  width: 100%;
  top: 4em;
  bottom: 0;
  background-color: defaults.$color-background-dark-transparent-light;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: none;
  }
}

$thermostat-hour-offset: math.div(-100%, 24);

.room-setting-hours {
  @include defaults.font-roboto-regular;

  position: absolute;
  top: 0;
  left: 17%;
  right: 18%;
  bottom: 0;
  white-space: nowrap;
  color: $text-color;
  transform: translate($thermostat-hour-offset, 0);

  @media (max-width: #{defaults.$media-query-tablet-portrait}) {
    left: 5%;
    right: 5%;
  }
}

.room-setting-item.room-setting-hours-wrapper {
  height: 1.5em;
  padding: .7em 0;

  &::before {
    content: none;
  }
}

.generic-device-v2 {
  background: transparent;

  .bas-title-wrapper {
    margin-bottom: 1rem;

    .bas-title {
      margin-left: 0;
    }

    &::after {
      margin-right: 0;
    }
  }

  .generic-device-v2-category {
    margin-bottom: 8px;
    background: rgba(0, 0, 0, 0.35);

    .rs-title .rs-name .rs-separator {
      display: block;
    }
  }
}

.scene-category-title {
  font-family: 'Roboto';
  margin-bottom: 12px;
  font-size: 18px;
}
