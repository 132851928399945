// Tiles - variables

$bas-tile-size: 200px;
$bas-tile-size-p: 120px;
$bas-tile-spacing-hor: 25px;
$bas-tile-spacing-ver: 25px;
$bas-tile-spacing-hor-p: 10px;
$bas-tile-spacing-ver-p: 10px;
$bas-tile-box-shadow-p: 0 4px 7px 0 rgba(0, 0, 0, .18),
  0 3px 10px 0 rgba(0, 0, 0, .15);
$bas-tile-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18),
  0 4px 15px 0 rgba(0, 0, 0, .15);
$bas-tile-overlay-background: rgba(black, .5);

$bas-tiles-empty-title-spacing: 2em;

// Outer margins on all sides of a tile so the tiles have some space in between
$bas-small-tile-outer-margin: 10px;
$bas-small-tile-outer-margin-tablet: 40px;

$bas-tile-resetter-margin: 5px;
