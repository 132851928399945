@use 'sass:math';
@use 'defaults';
@use 'util';

@mixin aspect-ratio($width, $height) {
  @include aspect-ratio-percent(#{( math.div($height, $width) * 100)+ '%'});
}

@mixin aspect-ratio-percent($heightPercentage) {
  height: unset;
  padding-top: $heightPercentage;
  position: relative;
}

@mixin before-background($color) {
  &::before {
    @include util.absolute-match-parent();

    content: '';
    background-color: $color;
  }
}

// Easily generate all grid position properties, given a element identifier
@mixin grid-id($id) {
  grid-column-start: col-#{$id}-start;
  grid-column-end: col-#{$id}-end;
  grid-row-start: row-#{$id}-start;
  grid-row-end: row-#{$id}-end;
}

@mixin reset-height() {
  min-height: initial;
  height: initial;
  max-height: initial;
  flex: initial;
}

// Titles
.bas-title-wrapper {
  @include defaults.font-roboto-light();
  @include util.ellipsis-overflow();

  font-size: 1.5em;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    font-size: 2em;
  }

  color: defaults.$color-text-light-transparent;
  display: flex;
  align-items: center;
  width: 100%;

  .bas-title {
    flex: 0 0 auto;
    margin-left: 0.5em;
  }

  // Horizontal line to fill all the space after the title
  &:not(.bas-rd-grid-element-music-title)::after {
    flex: 1 1 auto;
    content: '';
    height: 1px;
    background-color: defaults.$color-foreground-selected;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}
