@use 'sass:color';
@use 'defaults';
@use 'basstyle_const';
@use 'util';

// bsch = bas-scheduler

$bsch-spacing-outer: 16px;
$bsch-spacing-inner: 8px;

$bsch-x-axis-legend-height: 4em;

// Main scheduler

$bsch-point-size: 32px;
$bsche-point-size: 45px;

.bas-scheduler {

  // Positioning

  &.bsch--page {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    // Needed for correct height scaling on bsch-body
    height: 100%;

    @media (min-width: #{defaults.$media-phone-width}) {
      position: static;
      display: block;
      height: auto;
      width: 100%;
    }

    // Previously we used to set the 'height' property of '.bsch-body' and
    // '.bsch-entries-container' to 100% here for 'correct height scaling in
    //  flexbox' (?, whatever that meant).
    // This caused an issue however on Ellie (webview 75) where the 'height'
    //  property would get priority over the flexbox determined height, causing
    //  non-scrollable overflow.
    // Removing the 'height' property on only 'bsch-body' caused an issue on
    //  iOS 12, but removing the 'height' properties on both 'bsch-body' and
    //  'bsch-entries-container' and replacing them with explicit overflow
    //  properties gave the correct result on all platforms.

    .bsch-entries {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  // Horizontal separator lines in phone view

  &.bsch--hor-separator-phone {

    .bsch-entry-column-element {
      position: relative;
      padding-bottom: $bsch-spacing-inner;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: $bsch-spacing-outer;
        right: $bsch-spacing-outer;
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }

      @media (min-width: #{defaults.$media-phone-width}) {
        position: static;
        padding-bottom: 0;

        &::before {
          content: none;
        }
      }
    }
  }

  // Animating bsch-points (can cause delays when transition points data

  &.bsch--points-can-animate {

    .bsch-point {
      transition:
        color defaults.$standard-animation-time,
        background-color defaults.$standard-animation-time;
    }
  }

  // Header

  &:not(.bsch--has-header) {

    .bsch-header {
      display: none;
    }
  }

  // Filters

  &:not(.bas-scheduler--has-filters) {

    .bsch-filters {
      display: none;
    }
  }

  // Lines

  &:not(.bsch--has-lines) {

    .bsch-lines-header,
    .bsch-entry-lines {
      display: none;
    }
  }

  &.bsch--has-lines {

    .bsch-points-container {
      top: -100%;
    }
  }

  // Enabled

  // &.bas-scheduler--is-enabled {

  //   // Only color changes for now
  // }
}

.bsch-outer-padding {
  padding: $bsch-spacing-outer;
}

.bsch-header {
  @include defaults.font-roboto-regular;

  font-size: 1.2em;
  min-height: basstyle_const.$bas-button-toggle-total-height + 2 * $bsch-spacing-outer;
}

.bsch-filters {
  margin-top: $bsch-spacing-outer;
  margin-bottom: $bsch-spacing-outer;
  margin-left: $bsch-spacing-outer;
  margin-right: $bsch-spacing-outer;
}

.bsch-body {
  @media (min-width: #{defaults.$media-phone-width}) {
    padding-left: $bsch-spacing-outer + $bsch-spacing-inner;
    padding-right: $bsch-spacing-outer + $bsch-spacing-inner;
    padding-bottom: $bsch-spacing-inner;
  }
}

.bsch-entries-container {

  .bsch-line-text {
    visibility: hidden;
  }

  .bsch-line-item {

    &:nth-child(4n-3) {
      .bsch-line-text {
        visibility: visible;
      }
    }

    // 300px = min width for showing 8 time labels comfortably
    @media (min-width: 300px) {

      &:nth-child(2n-1) {
        .bsch-line-text {
          visibility: visible;
        }
      }
    }
  }
}

.bmc-content {

  .bsch-entries-container {

    // Master pane is visible: less space available, show time labels at
    //  different media queries
    @media (min-width: defaults.$media-phone-width) {

      .bsch-line-item {

        .bsch-line-text {
          visibility: hidden;
        }

        &:nth-child(4n-3) {
          .bsch-line-text {
            visibility: visible;
          }
        }

        // Tablet
        @media (min-width: defaults.$media-query-tablet-portrait) {

          &:nth-child(4n-3) {
            .bsch-line-text {
              visibility: visible;
            }
          }
        }

        // Tablet landscape
        @media (min-width: defaults.$media-query-tablet-landscape) {

          &:nth-child(2n-1) {
            .bsch-line-text {
              visibility: visible;
            }
          }
        }

        // Bigger
        @media (min-width: defaults.$media-query-pc-landscape) {

          &:nth-child(2n) {
            .bsch-line-text {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.bsche-points-container {

  // No longer show/hide time labels based on media queries, always hide labels
  //  at minor lines

  .bsch-line-item {

    .bsch-line-text {
      visibility: visible;
    }

    &.bsch-line--minor {
      .bsch-line-text {
        visibility: hidden;
      }
    }
  }
}

.bsch-line-item {
  .bsch-line-text {
    align-items: baseline;

    .bsch-line-subtext {
      opacity: .5;
      font-size: .6em;
      padding: 0 1px;
      width: 0;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  &.bsch-line-subtext-before {
    .bsch-line-subtext {
      direction: rtl;
      order: -1;
    }
  }
}

.bsch-body-left {
  display: none;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;
  }
}

.bsch-body-middle {
  display: flex;
  flex-flow: column nowrap;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;
  }
}

.bsch-body-right {
  display: none;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;
  }
}

.bsch-lines-header {
  @include defaults.font-roboto-regular;

  font-size: .8em;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1em;
  }
}

.bsch-lines-header-vertical-spacing {
  padding-top: $bsch-spacing-outer;
  padding-bottom: $bsch-spacing-outer;
}

.bsch-lines-header-horizontal-spacing {
  padding-left: $bsch-spacing-outer + $bsch-point-size * 0.5;
  padding-right: $bsch-spacing-outer + $bsch-point-size * 0.5;
}

.bsch-entry-column-element {
  @media (min-width: #{defaults.$media-phone-width}) {
    height: $bsch-point-size + 2 * $bsch-spacing-outer;
    max-height: $bsch-point-size + 2 * $bsch-spacing-outer;
    min-height: $bsch-point-size + 2 * $bsch-spacing-outer;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  &:last-child {
    @media (min-width: #{defaults.$media-phone-width}) {
      border-bottom: none;
    }
  }
}

.bsch-entry-header {
  @include defaults.font-roboto-regular;

  min-height: defaults.$bs-btn-circle-size + 2 * $bsch-spacing-inner;
  padding-top: $bsch-spacing-inner;
  padding-bottom: $bsch-spacing-inner;
  padding-left: $bsch-spacing-outer;
  padding-right: $bsch-spacing-outer;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: none;
  }
}

.bsch-entry-title {
  transition: color defaults.$standard-animation-time;
  margin-right: $bsch-spacing-outer;
}

.bsch-entry-body {
  height: $bsch-point-size + 2 * $bsch-spacing-inner;
  padding-left: $bsch-spacing-outer + $bsch-point-size * 0.5;
  padding-right: $bsch-spacing-outer + $bsch-point-size * 0.5;
  overflow: hidden;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: $bsch-point-size + 2 * $bsch-spacing-outer;
  }
}

.bsch-line {
  position: relative;
  height: $bsch-point-size;
  border-left-width: 1px;
  border-left-style: solid;
}

.bsch-points-container {
  left: -$bsch-point-size * 0.5;
}

.bsch-point {
  @include defaults.font-roboto-regular;

  position: absolute;
  top: $bsch-spacing-inner;
  width: $bsch-point-size;
  height: $bsch-point-size;
  border-radius: 100%;
  text-align: center;

  @media (min-width: #{defaults.$media-phone-width}) {
    top: $bsch-spacing-outer;
  }
}

.bsch-entry-selector {
  transition: background-color defaults.$standard-animation-time;

  &::before {
    @include util.absolute-center;

    content: '';
    width: 60%;
    height: 60%;
    border-radius: 100%;
    opacity: 0;
    transition: opacity defaults.$standard-animation-time;
  }
}

// Copy selector logic

.bsch-entry-selector-copy {
  opacity: 1;
}

.bsch-entry-selector-paste {
  opacity: 0;
}

.bsch-entry-selector::before {
  opacity: 0;
}

.bsch-entry-selector--copy-mode {

  .bsch-entry-selector {
    &:active {
      .bsch-entry-selector-paste svg {
        fill: defaults.$color-foreground;
      }
    }
  }

  .bsch-entry-selector-icon {
    opacity: 0;
  }

  .bsch-entry-selector-paste {
    opacity: 1;
  }

  &.bsch-entry-selector--added {

    .bsch-entry-selector::before {
      opacity: 1;
    }
  }

  &.bsch-entry-selector--selected {

    .bsch-entry-selector::before {
      opacity: 0;
    }

    .bsch-entry-selector-copy {
      opacity: 1;
    }

    .bsch-entry-selector-paste {
      opacity: 0;
    }

    &.bsch-entry-selector--selected-valid {

      .bsch-entry-selector-copy {
        opacity: 1;
      }
    }
  }
}

// Color themes

.bas-scheduler.bsch--dark {

  .bsch-header {
    color: defaults.$color-foreground;
    background-color: defaults.$color-black-darker-bg-trans;
  }

  .bsch-filters {
    @include basstyle_const.bssc-highlight-on-dark;
  }

  .bsch-body {
    background-color: defaults.$color-background-dark-transparent-light;
  }

  .bsch-lines-header {
    color: defaults.$color-light-control-buttons-f;
  }

  .bsch-entry-column-element {
    border-bottom-color: defaults.$color-background-shadow;
  }

  .bsch-entry-header {
    color: defaults.$color-foreground;
    background-color: defaults.$color-black-dark-bg-trans;
  }

  .bsch-entry-title {
    color: defaults.$color-text-grey;
  }

  .bsch-line {
    border-color: defaults.$color-text-lighter-inverse;
  }

  .bsch-point {
    color: defaults.$color-foreground-dark;
    background-color: defaults.$color-text-light;

    // &.bsch-point--mode-any {
    //   // Empty
    // }

    // &.bsch-point--mode-heating {
    //   // Empty
    // }

    // &.bsch-point--mode-cooling {
    //   // Empty
    // }

    // &.bsch-point--mode-auto {
    //   // Empty
    // }
  }

  .bsch-entry-selector {
    border: none;

    svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }

  &.bas-scheduler--is-enabled {

    .bsch-entry-title {
      color: defaults.$color-foreground;
    }

    .bsch-point {
      color: defaults.$color-foreground;
      background-color: defaults.$color-highlight-on-dark;

      // &.bsch-point--mode-any {
      //   // Empty
      // }

      &.bsch-point--mode-heating {
        color: defaults.$color-foreground;
        background-color: defaults.$color-heating;
      }

      &.bsch-point--mode-cooling {
        color: defaults.$color-foreground;
        background-color: defaults.$color-cooling;
      }

      &.bsch-point--off {
        color: defaults.$color-foreground;
        background-color: gray;
        font-size: .8em;
      }

      // &.bsch-point--mode-auto {
      //   // Empty
      // }
    }
  }

  .bsch-entry-selector--copy-mode .bsch-entry-selector {

    &:active {
      background-color: defaults.$color-highlight-on-dark;
    }
  }

  .bsch-entry-selector--selected {

    .bsch-entry-selector {
      background-color: defaults.$color-highlight-on-dark;

      svg {
        fill: defaults.$color-foreground;
      }
    }
  }
}

.bas-scheduler.bsch--light {

  .bsch-lines-header {
    color: defaults.$color-text-black;
  }

  .bsch-filters {
    @include basstyle_const.bssc-highlight-on-light;
  }

  .bsch-entry-column-element {
    border-bottom-color: defaults.$color-foreground;

    &::before {
      border-bottom-color: defaults.$color-foreground;
    }

    &:last-child::before {
      border: none;
    }
  }

  .bsch-entry-header {
    color: defaults.$color-text-black;
  }

  .bsch-entry-title {
    color: defaults.$color-text-lighter-inverse;
  }

  .bsch-line {
    border-color: defaults.$color-grey;
  }

  .bsch-point {
    color: defaults.$color-foreground-dark;
    background-color: defaults.$color-text-light;
    box-shadow: 0 2px 2px 0 defaults.$color-background-active-transparent;
  }

  .bsch-entry-selector {
    @include basstyle_const.bs-btn-highlight-on-light;
  }

  &.bas-scheduler--is-enabled {

    .bsch-entry-title {
      color: defaults.$color-text-black;
    }

    .bsch-point {
      color: defaults.$color-text-black;
      background-color: defaults.$color-highlight-on-light;
    }
  }

  .bsch-entry-selector--copy-mode .bsch-entry-selector {

    &:active {
      background-color: defaults.$color-highlight-on-light;
    }
  }

  .bsch-entry-selector--selected {

    .bsch-entry-selector {
      background-color: defaults.$color-highlight-on-light;

      svg {
        fill: defaults.$color-foreground;
      }
    }
  }
}

// Entry scheduler

// bsche = bas-scheduler-entry

$bsche-header-top-offset: $bsch-spacing-outer;
$bsche-filters-height: 30px;
$bsche-header-height: defaults.$bs-btn-circle-size + $bsch-spacing-outer;

$bsche-side-spacing: $bsche-point-size * 0.5 + $bsch-spacing-inner;

.bas-entry-scheduler {

  .bsche-remove {
    opacity: 0;
  }

  .bsche--show-remove {

    .bsche-remove {
      opacity: 1;
    }
  }

  &.bsche--page {
    height: 100%;

    .bsche-container {
      height: 100%;
    }
  }

  // Filters

  &:not(.bas-scheduler--has-filters) {

    .bsche-filters {
      display: none;
    }
  }

  &.bas-scheduler--has-filters {

    .bsche-header-container {
      top: $bsche-header-top-offset + $bsche-filters-height + $bsch-spacing-outer;
    }

    .bsche-points-container {
      top: $bsche-header-height + $bsche-header-top-offset + $bsche-filters-height + $bsch-spacing-outer + 2 * $bsch-spacing-outer;
    }
  }
}

.bsche-container {

  .bsche-btn-add-overlay {

    &::before {
      opacity: 0;
      transition: opacity defaults.$standard-animation-time;
    }
  }

  .bsche-btn--cross {
    opacity: 0;
    transition: opacity defaults.$standard-animation-time;
  }

  .bsche-btn-add-type {

    .bsche-btn-add-type-text {
      opacity: 0;
      transition: opacity defaults.$standard-animation-time;
      white-space: nowrap;
    }

    .bsche-btn {
      transform: scale(0);
      transition: transform defaults.$standard-animation-time;
    }
  }

  &.bsche--show-add-btn-overlay {

    .bsche-btn-add-overlay {
      pointer-events: auto;

      &::before {
        opacity: 1;
      }
    }

    .bsche-btn--cross {
      opacity: 1;
    }
  }

  &.bsche--show-add-bnt-options {

    .bsche-btn-add-type {

      .bsche-btn-add-type-text {
        opacity: 1;
      }

      .bsche-btn {
        transform: scale(1);
      }
    }
  }
}

.bsche-filters {
  position: absolute;
  top: $bsche-header-top-offset;
  left: $bsche-side-spacing;
  right: $bsche-side-spacing;
  height: $bsche-filters-height;
  z-index: 1;
}

.bsche-header-container {
  position: absolute;
  height: $bsche-header-height;
  top: $bsche-header-top-offset;
  left: $bsche-side-spacing;
  right: $bsche-side-spacing;
  z-index: 1;
}

.bsche-header {
  height: 100%;
}

.bsche-title {
  font-size: 1.2em;
  margin-right: .4em;
}

.bsche-btn-add-overlay {
  z-index: 2;
  pointer-events: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: defaults.$color-black-dark-bg-trans-dark;
  }
}

.bsche-remove {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition:
    opacity defaults.$standard-animation-time,
    background-color defaults.$standard-animation-time,
    color defaults.$standard-animation-time;
  pointer-events: none;

  &.bsche-remove--show {
    opacity: 1;
  }
}

.bsche-btn-add-types-container {
  position: absolute;
  top: defaults.$bs-btn-circle-size + $bsch-spacing-inner;
  left: 0;
  width: 100%;
}

.bsche-btn-add-type {
  margin-top: $bsch-spacing-outer;
}

.bsche-btn-add-type-text {
  margin-right: $bsch-spacing-outer;
  // iOS fix
  z-index: 1;
}

.bsche-points-wrapper {
  overflow-x: auto;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;

  @media (min-width: #{defaults.$media-phone-width}) {
    overflow-x: visible;
  }
}

.bsche-points-container {
  position: absolute;
  // Spacing between header and points container 2 * spacing-outer
  top: $bsche-header-height + $bsche-header-top-offset + 2 * $bsch-spacing-outer;
  bottom: $bsch-x-axis-legend-height;
  left: 0;
  width: 200%;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: 100%;
  }
}

.bsche-verticals,
.bsche-horizontals {
  position: absolute;
  top: 0;
  bottom: 0;
  left: $bsche-side-spacing;
  right: $bsche-side-spacing;
}

.bsche-vertical-line {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  border-left-width: 1px;
  border-left-style: solid;

  &.bsch-line--minor {
    border-left-style: dashed;
  }
}

.bsche-horizontal-line {
  position: relative;
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
}

.bsche-vertical-line-text {
  text-align: center;
  position: relative;
  top: $bsch-x-axis-legend-height;
  height: $bsch-x-axis-legend-height;
}

.bsche-vertical-legend {
  height: $bsch-x-axis-legend-height;
}

.bsche-lines-footer {
  padding-left: $bsch-spacing-outer;
  padding-right: $bsch-spacing-outer;
  padding-top: $bsch-spacing-inner;
  padding-bottom: $bsch-spacing-inner;
}

.bsche-point {
  @include util.button-cursor;

  position: absolute;
  top: -$bsche-point-size * 0.5;
  left: -$bsche-point-size * 0.5 + $bsche-side-spacing;
  width: $bsche-point-size;
  height: $bsche-point-size;
  border-radius: 100%;
  opacity: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    opacity: 0;
  }

  &.bschp-point--can-animate {
    transition: opacity defaults.$standard-animation-time;

    &::after {
      transition: opacity defaults.$standard-animation-time;
    }
  }

  &.bschp-point--can-animate-all {
    transition:
      opacity defaults.$standard-animation-time,
      transform defaults.$standard-animation-time;

    &::after {
      transition:
        opacity defaults.$standard-animation-time,
        transform defaults.$standard-animation-time;
    }
  }
}

.bsche-point-balloon {
  position: absolute;
  bottom: 120%;
  min-height: 1em;
  border-radius: 5px;
  pointer-events: none;
  padding-top: .4em;
  padding-bottom: .4em;
  padding-left: 1em;
  padding-right: 1em;
  white-space: nowrap;
}

.bsche-point-label {
  position: absolute;
  top: 110%;
  font-size: .8em;
  min-height: 1em;
  border-radius: 5px;
  pointer-events: none;
  padding-top: .4em;
  padding-bottom: .4em;
  padding-left: .4em;
  padding-right: .4em;
  white-space: nowrap;
  transition: opacity defaults.$standard-animation-time;
}

.bas-entry-scheduler.bsche--dark {

  .bsche-filters {
    @include basstyle_const.bssc-highlight-on-dark;
  }

  .bsche-btn-style {
    @include basstyle_const.bs-btn-highlight-on-dark;
    @include basstyle_const.bs-btn-active-full-highlight-on-dark;
  }

  .bsche-title {
    color: defaults.$color-foreground;
  }

  .bsche-line-text,
  .bsche-vertical-line-text {
    color: defaults.$color-light-control-buttons-f;
  }

  .bsche-remove {
    color: defaults.$color-foreground;
    background-color: defaults.$color-remove-dark;
    box-shadow: 0 2px 2px 0 defaults.$color-background-active-transparent;

    &.bsche-remove--active {
      color: defaults.$color-foreground-dark;
      background-color: defaults.$color-remove-dark-darker;
      box-shadow: 0 2px 2px 0 defaults.$color-header;
    }
  }

  .bsche-btn-add-type-text {
    color: defaults.$color-foreground;
  }

  .bsche-btn-add-type {

    &.bas-point-type--heating {

      .bsche-btn--plus {
        @include basstyle_const.bs-btn-heating-on-dark;
      }
    }

    &.bas-point-type--cooling {

      .bsche-btn--plus {
        @include basstyle_const.bs-btn-cooling-on-dark;
      }
    }

    &.bas-point-type--off {

      .bsche-btn--plus {
        @include basstyle_const.bs-btn-off-on-dark;
      }
    }

    // &.bas-point-type--auto {

    //   .bsche-btn--plus {
    //     // Empty
    //   }
    // }
  }

  .bsche-vertical-line,
  .bsche-horizontal-line {
    border-color: defaults.$color-background-shadow;

    &.bsch-line--minor {
      border-color: defaults.$color-background-active;
    }
  }

  .bsche-point {
    color: defaults.$color-text-black;
    background-color: defaults.$color-highlight-on-dark;

    &::after {
      background-color: defaults.$color-highlight-on-dark-darker;
    }

    // &.bsch-point--mode-any {
    //   // Empty
    // }

    &.bsch-point--mode-heating {
      color: defaults.$color-foreground;
      background-color: defaults.$color-heating;

      &::after {
        background-color: color.adjust(defaults.$color-heating, $lightness: -20%);
      }
    }

    &.bsch-point--mode-cooling {
      color: defaults.$color-foreground;
      background-color: defaults.$color-cooling;

      &::after {
        background-color: color.adjust(defaults.$color-cooling, $lightness: -20%);
      }
    }

    &.bsch-point--off {
      color: defaults.$color-foreground;
      background-color: gray;

      &::after {
        background-color: color.adjust(gray, $lightness: -10%);
      }
    }

    // &.bsch-point--mode-auto {
    //   // Empty
    // }
  }

  .bsche-point-balloon {
    color: defaults.$color-foreground;
    background-color: defaults.$color-black-dark-bg-trans-dark;
  }

  .bsche-point-label {
    color: defaults.$color-foreground-dark;
    background-color: defaults.$color-black-dark-bg-trans-dark;
  }
}

.bas-entry-scheduler.bsche--light {

  .bsche-filters {
    @include basstyle_const.bssc-highlight-on-light;
  }

  .bsche-btn-style {
    @include basstyle_const.bs-btn-highlight-on-light;
    @include basstyle_const.bs-btn-active-full-highlight-on-light;
  }

  .bsche-title {
    color: defaults.$color-text-black;
  }

  .bsche-line-text,
  .bsche-vertical-line-text {
    color: defaults.$color-text-lighter-inverse;
  }

  .bsche-remove {
    color: defaults.$color-foreground;
    background-color: defaults.$color-remove-light;
    box-shadow: 0 2px 2px 0 defaults.$color-background-active-transparent;

    &.bsche-remove--active {
      color: defaults.$color-foreground-dark;
      background-color: defaults.$color-remove-light-darker;
      box-shadow: 0 2px 2px 0 defaults.$color-header;
    }
  }

  .bsche-vertical-line,
  .bsche-horizontal-line {
    border-color: defaults.$color-text-light-secondary;

    &.bsch-line--minor {
      border-color: defaults.$color-dark-control-buttons-disabled;
    }
  }

  .bsche-point {
    color: defaults.$color-text-black;
    background-color: defaults.$color-highlight-on-light;

    &::after {
      background-color: defaults.$color-highlight-on-light-darker;
    }
  }

  .bsche-point-balloon {
    color: defaults.$color-foreground;
    background-color: defaults.$color-black-dark-bg-trans-dark;
  }

  .bsche-point-label {
    color: defaults.$color-foreground-light;
    background-color: defaults.$color-black-dark-bg-trans-dark;
  }
}

// Point

// bschp = bas-scheduler-point

.bsch--point {
  z-index: 1;

  .bsche-point-balloon {
    opacity: 0;
  }

  .bsche-point-label {
    opacity: 1;
  }

  &.bschp--pressed {
    z-index: 2;

    &::after {
      opacity: 1;
    }

    .bsche-point-label {
      opacity: 0;
    }
  }

  &.bschp--show-balloon {

    .bsche-point-balloon {
      opacity: 1;
    }

    .bsche-point-label {
      opacity: 0;
    }
  }

  &.bschp--semi-transparent {
    opacity: 0.5;

    .bsche-point-balloon {
      opacity: 0;
    }

    .bsche-point-label {
      opacity: 0;
    }
  }
}

.bas-timer-day {
  position: relative;
  height: 400px;
}

.clear-schedule {
  cursor: pointer;
  margin-top: .2em;
}
