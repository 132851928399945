@use 'defaults';

.bas-body {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: defaults.$color-background;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-size-adjust: none;
  // Fix for font changes during animation
  -webkit-font-smoothing: subpixel-antialiased;
  transform: translateZ(0);
}

// Override browser defaults

::selection {
  background: defaults.$color-background;
  color: defaults.$color-foreground;
}

::selection {
  background: defaults.$color-background;
  color: defaults.$color-foreground;
}

:-moz-ui-invalid:not(output) {
  box-shadow: none;
}

select {
  appearance: none;
}

input {
  -webkit-touch-callout: default !important;
  user-select: text !important;
}

input::first-line {
  display: inline-block;
}

input[type='time'],
input[type='date'] {
  appearance: none;
  user-select: none !important;
  border-radius: 0;

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

input[type='text'],
input[type='number'],
input[type='search'],
input[type='email'],
input[type='tel'],
input[type='url'],
input[type='password'] {
  // iOS fix, otherwise box-shadow will not work
  appearance: none;
}

input[type='time']::-webkit-clear-button,
input[type='date']::-webkit-clear-button {
  appearance: none;
  display: none;
}

input[type='text']::placeholder,
input[type='number']::placeholder,
input[type='search']::placeholder,
input[type='email']::placeholder,
input[type='tel']::placeholder,
input[type='url']::placeholder,
input[type='password']::placeholder {
  opacity: 0.45;
  user-select: text;
  -webkit-touch-callout: default;
}

input[type='range'] {
  user-select: none !important;
  -webkit-touch-callout: none !important;
}

// TODO Remove, used in player
.highlight:active {
  color: defaults.$color-light-control-buttons-active !important;
  fill: defaults.$color-light-control-buttons-active;

  > svg {
    fill: defaults.$color-light-control-buttons-active !important;
  }
}

// ngAnimate

.animate-in {
  &.ng-enter-prepare,
  &.ng-enter {
    transition: opacity defaults.$standard-animation-time ease-out;
    opacity: 0;

    &.ng-enter-active {
      opacity: 1;
    }
  }
}

.animate-out {
  &.ng-leave-prepare,
  &.ng-leave {
    transition: opacity defaults.$standard-animation-time ease-out;
    opacity: 1;

    &.ng-leave-active {
      opacity: 0;
    }
  }
}
