@use 'util';

/* stylelint-disable selector-class-pattern */
.bas-color-picker .iro__slider {
  /* stylelint-enable selector-class-pattern */
  display: none;
}

.bas-color-picker svg {
  @include util.absolute-center();
}

.bas-color-picker.bas-color-disabled {
  pointer-events: none;
}
