@use 'defaults';
@use 'util';

@mixin bas-tile-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-bottom: 100%;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    &::before {
      @include util.absolute-match-parent();

      content: '';
      background-color: defaults.$color-black-bg-trans;
    }
  }
}
