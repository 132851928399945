@use 'sass:color';
@use 'sass:math';
@use 'defaults';
@use 'tiles_const';
@use 'basstyle_const';
@use 'bas_tile_util';
@use 'util';

// Order of layers is from big to small:
// Group > Segment > Wrapper > Tile

// Group: Groups everything in a top and bottom part

// Segment: Groups everything underneath one title

// Wrapper: Groups everything together of the same type on top of 1 background
// Basically the same as a segment but without the title

// Tile: The clickable square that makes up most of the content

// Outer margins of a wrapper so the wrappers have some space in between
// Also used for margins to the edges of the screen
$bas-tile-wrapper-outside-margin: 2%;
$bas-tile-wrapper-inside-margin: 15px;

$bas-tile-indicator-size: 12px;
$bas-tile-indicator-margin-v: 6px;
$bas-tile-indicator-margin-h: 4px;

@function bas-tile-wrapper-width ($number-of-tiles,
$number-of-total-tiles,
$number-of-segments) {

  // Usable width without the segment margins (in- and outside margins)
  // Divide by amount of tiles in total to get the width of a tile
  // Multiply by the amount of tiles inside the segment to have content width
  // Add margins left and right
  @return calc(
    (
      #{100% - ($number-of-segments + 1) * $bas-tile-wrapper-outside-margin} -
      #{2 * $number-of-segments * $bas-tile-wrapper-inside-margin}
    )
    *
    #{math.div($number-of-tiles, $number-of-total-tiles)} +
    #{2 * $bas-tile-wrapper-inside-margin}
  );
}

@function bas-tile-wrapper-width-tablet ($number-of-tiles,
$number-of-total-tiles,
$number-of-segments) {

  // Usable width without the segment margins (outside margins)
  // Divide by amount of tiles in total to get the width of a tile
  // Multiply by the amount of tiles inside the segment to have content width
  @return (100% - ($number-of-segments + 1) * $bas-tile-wrapper-outside-margin) *
    math.div($number-of-tiles, $number-of-total-tiles); }

@function bas-tile-width ($num-of-hor-tiles, $spacing-hor: null) {
  @if ($spacing-hor == null) {
    $spacing-hor: tiles_const.$bas-tile-spacing-hor;
  }

  @return calc(#{math.div(100%, $num-of-hor-tiles)} - #{$spacing-hor});
}

@function bas-widget-width ($num-of-hor-tiles, $spacing-hor: null) {
  @if ($spacing-hor == null) {
    $spacing-hor: tiles_const.$bas-tile-spacing-hor;
  }

  @return calc(#{math.div(100%, ($num-of-hor-tiles*0.5))} - #{$spacing-hor});
}

@function bas-tile-wrapper-height ($multiplier) {

  $btw-inside-margins: 2 * #{$bas-tile-wrapper-inside-margin};

  @return calc((100% - #{$btw-inside-margins}) * #{$multiplier} + #{$btw-inside-margins});
}

// Content-wrapper

$bas-tile-content-wrapper-padding-top: 6px;
$bas-tile-content-wrapper-padding-top-tablet: 16px;
$bas-tile-content-wrapper-padding-top-tab-landscape: 32px;

.bas-tile-content-wrapper {
  width: 100%;
  padding-top: $bas-tile-content-wrapper-padding-top;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    -webkit-overflow-scrolling: touch;
    white-space: normal;
    padding-top: $bas-tile-content-wrapper-padding-top-tablet;
    display: block;
  }

  @media (min-width: #{defaults.$media-query-tablet-landscape}) {
    padding-top: $bas-tile-content-wrapper-padding-top-tab-landscape;
  }
}

// Groups

@mixin bas-tile-group() {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
}

$btg-animation-time: 2s;
$btg-perspective: 1000px;

// Segment

@mixin bas-tile-segment() {
  display: inline-block;
  position: relative;
  width: bas-tile-wrapper-width(2, 2, 1);
  margin-left: $bas-tile-wrapper-outside-margin;
  margin-right: $bas-tile-wrapper-outside-margin;
  white-space: normal;
  vertical-align: top;
  font-size: initial;
}

// 'backface-visibility: hidden' causes elements that are not rotated
//  towards the screen to not render, and have their render delayed until after
//  the transition is complete (in chrome and android, this works fine in
//  Firefox). To fix this we do something funky (???) with the visibility
//  property and transition that too. I don't know why but this causes the
//  render of rotated elements with backface-visibility to not be delayed.

.bas-music-widget-wrapper {
  .btm-player {
    transform: rotateY(180deg);
    transition-duration: $btg-animation-time;
    transition-property: transform, visibility;
    backface-visibility: hidden;
    visibility: hidden;

    .btm-message {
      display: block;
    }

    .btmp-bottom,
    .btmp-image,
    .widget-player-controls {
      display: none;
    }
  }

  .bas-music-widget-flipside {
    transform: rotateY(0deg);
    transition-duration: $btg-animation-time;
    transition-property: transform, visibility;
    backface-visibility: hidden;
  }

  &.btm-state-now-playing {

    .btm-player {
      transform: rotateY(0deg);
      visibility: visible;
    }

    .bas-music-widget-flipside {
      transform: rotateY(-180deg);
      visibility: hidden;
    }
  }

  &.btm-show-controls {

    &.bpy-message-hide {

      &.btm-exact-volume-control-available {

        .btmp-bottom {
          display: flex;
        }
      }
    }

    .btmp-icon-muted {
      opacity: 1;
    }

    &.btm-audible {

      .btmp-icon-muted {
        opacity: 0;
      }

      .btmp-icon-audible {
        opacity: 1;
      }
    }
  }

  &.bpy-message-hide {

    .btm-message {
      display: none;
    }

    .btmp-image,
    .widget-player-controls {
      display: block;
    }
  }
}

.btm-icon-play,
.btm-icon-heart {
  display: none;
}

.btm-show-button {

  .btm-icon-play {
    display: inline-block;
  }

  .btm-icon-heart {
    display: none;
  }

  &.btm-state-now-playing {

    .btm-icon-play {
      display: none;
    }

    .btm-icon-heart {
      display: inline-block;
    }
  }
}

// Segment - title

.bas-tile-segment-title {
  width: calc(100% - #{2 * tiles_const.$bas-small-tile-outer-margin});
  font-size: 1.5em;
  margin-top: 0;
  margin-left: tiles_const.$bas-small-tile-outer-margin;
  margin-bottom: 0.1em;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    font-size: 2em;
    width: calc(100% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    margin-left: tiles_const.$bas-small-tile-outer-margin-tablet*0.5;
  }
}

.btm-show-button {
  .bas-tile-segment-title-music {
    width: calc(100% - #{2 * tiles_const.$bas-small-tile-outer-margin-tablet});

    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      margin-bottom: 0.5em;
      width: calc(100% - #{2.5 * tiles_const.$bas-small-tile-outer-margin-tablet});
    }
  }
}

.btm-message {
  font-size: 16px;
}

.btm-player {

  .btm-player-inner {
    height: 100%;
  }
}

.bas-tile-favourites {

  .btm-message {
    display: none;
  }

  &.btm-show-favourite-message {
    .btm-message {
      display: block;
    }
  }
}

// Wrapper

.bas-tile-wrapper {
  // In phone view takes up 66% of the right side
  width: math.div(2* 100%, 3);
  float: right;
  position: relative;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    // In portrait a nice cube which takes up the entire width of the segment
    width: 100%;
  }

  @media (min-width: #{defaults.$media-query-tablet-landscape}) {
    // In landscape a nice cube which takes up the half the width of the segment
    width: 50%;
  }
}

.bas-music-widget {
  @include bas_tile_util.bas-tile-wrapper();

  transform-style: preserve-3d;
  perspective: $btg-perspective;
  z-index: 1;

  &::before {
    content: none;
  }
}

.bas-tile-message-scenes {
  display: none;
}

.bas-tile-message {
  @include util.absolute-center();

  font-size: 16px;
  color: defaults.$color-foreground;
  text-align: center;
  width: 90%;
}

.bas-tile-message-img {
  position: relative;
  width: 100%;
  max-width: 256px;
  fill: defaults.$color-highlight-muted;

  svg {
    @include util.absolute-center();

    width: 100%;
  }
}

.bas-tile-message-img-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}

.bas-tile-message-title {
  @include defaults.font-roboto-bold;

  font-size: 1.4em;
  margin-bottom: .6em;
  max-width: 100%;

  &:empty {
    display: none;
  }
}

.bas-tile-message-line {
  @include defaults.font-roboto-light();

  font-size: .9em;
  max-width: 100%;

  &:empty {
    display: none;
  }
}

.bas-tile-message-line-spaced {
  margin-top: 1.4em;
}

.bas-tile-message-line-padding-top {
  padding-top: .6em;
}

.bas-tile-message-line-fixed-height {
  @include util.empty-no-collapse;

  &:empty {
    display: block;
  }
}

.bas-tile-message-action {
  display: inline-block;
  margin-top: 1.4em;

  &:empty {
    display: none;
  }
}

.bas-tile-message-img {
  display: none;
}

.bas-tile-message-img--has {

  .bas-tile-message-img {
    display: none;

    @media (min-height: #{defaults.$screen-size-600}) {
      display: block;
    }
  }
}

.bas-tile-message-img--music {

  .bas-tile-message-img {
    svg {
      width: 50%;
    }
  }
}

$bas-browser-tile-extra: 2%;

// All these changes are for the browser horizontal sliders
// Most of them is adding some space at the bottom so the sliders can fit
.bas-device-browser {
  .schedule-container {
    &.bas-browser-scroll-bar {
      @media (min-width: #{defaults.$media-phone-width}) {
        overflow-x: hidden;
      }
    }
  }

  .bas-browser-scroll-bar {

    &.bas-library-navigation-bar,
    &.bas-objects-header {
      margin-bottom: 10px;
    }

    &.bsp-scene-wrapper {
      padding-bottom: 0;
    }
  }

  .bas-home-tile-wrapper {
    padding-bottom: 4px;
    margin-bottom: 4px;
  }
}

$bas-tile-temperature-margins: 0
  (tiles_const.$bas-small-tile-outer-margin * 0.5)
  1em
  (tiles_const.$bas-small-tile-outer-margin * 0.5);

// This makes sure height and width calculations start fresh
// Given most heights are actually padding-bottom calculations

.bas-tile-resetter {
  @include util.absolute-match-parent();

  font-size: 0;
}

// Small tile

@mixin bas-small-tile {
  @include util.button-cursor();

  width: calc(50% - #{tiles_const.$bas-small-tile-outer-margin});
  padding-bottom: calc(50% - #{tiles_const.$bas-small-tile-outer-margin});
  margin: tiles_const.$bas-small-tile-outer-margin * 0.5;
  position: relative;
  display: inline-block;
  background-color: defaults.$color-black-dark-bg-trans;
  overflow: hidden;
  box-shadow: tiles_const.$bas-tile-box-shadow;
  font-size: initial;

  &.bas-tile-invisible {
    @include util.default-cursor();

    opacity: 0;
  }

  &::after {
    @include util.absolute-match-parent();

    content: '';
    transition: background-color .5s ease-in-out;
  }

  &:active {
    &::after {
      transition: background-color 0s ease-in-out;
      background-color: defaults.$color-background-active-transparent;
    }
  }

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    width: calc(50% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    padding-bottom: calc(50% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    margin: tiles_const.$bas-small-tile-outer-margin-tablet * 0.5;
  }
}

.bas-on-off-tile {
  @include bas-small-tile();

  float: left;

  .bas-tile-svg {

    svg {
      top: 15%;
      width: 50%;
      height: 50%;
    }
  }

  @media (max-width: #{defaults.$media-query-tablet-portrait}) {
    width: calc(#{100% / 3} - #{tiles_const.$bas-small-tile-outer-margin});
    padding-bottom: calc(#{100% / 3} - #{tiles_const.$bas-small-tile-outer-margin});
    clear: left;
  }

  @media (min-width: #{defaults.$media-query-tablet-landscape}) {
    // Tiles are in a 1x4 format
    width: calc(25% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    padding-bottom: calc(25% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    clear: left;
  }
}

.bas-small-tile {
  @include bas-small-tile();
}

.bas-small-comfort-tile {
  @include bas-small-tile();
}

// This cannot handle font size changes for the title
$bas-segment-title-height: 41px;
$bsm-outer-margin: tiles_const.$bas-tile-resetter-margin;
$btmp-icon-size: 24px;

.btm-player {
  @include util.absolute-match-parent();

  display: block;
  transition: transform $btg-animation-time;
  transform: rotateY(180deg);

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {

    &::before {
      @include util.absolute-match-parent();

      content: '';
      background-color: defaults.$color-black-bg-trans;
    }
  }
}

.btmp-image {
  @include util.absolute-match-parent();

  display: block;

  svg {
    fill: defaults.$color-highlight-on-dark;
  }

  .bas-image.bas-image-box-shadow {
    box-shadow: none;
  }
}

$bottom-side-padding: 1em;
$bottom-volume-height: 40px;
$bottom-title-height: 40px;

.btmp-bottom {
  display: none;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 $bottom-side-padding;
  background-color: tiles_const.$bas-tile-overlay-background;
  height: $bottom-volume-height;
  transition: background-color defaults.$standard-animation-time-long;
}

.btmp-icon-container {
  @include util.button-cursor();

  position: relative;
  flex: 0 0 auto;
  width: $btmp-icon-size;
  height: $btmp-icon-size;
  fill: defaults.$color-light-control-buttons;
  transition: fill defaults.$standard-animation-time;

  &:active {
    fill: defaults.$color-highlight-on-dark;
    transition: fill 0s;
  }
}

.btmp-icon {
  @include util.absolute-center();

  width: 100%;
  height: 100%;
  transition: opacity defaults.$standard-animation-time linear;

  &:active {
    transition: opacity defaults.$standard-animation-time linear;
  }
}

.btmp-icon-muted {
  opacity: 1;
}

.btmp-icon-audible {
  opacity: 0;
}

.btmp-slider {
  flex: 1 1 auto;
  height: 100%;
  margin: 0 .1em 0 1em;
}

.btmp-label {
  @include defaults.font-roboto-light;

  width: 2em;
  font-size: 1.4em;
  text-align: right;
  color: defaults.$color-foreground;
}

.bas-music-widget-flipside {
  @include util.absolute-match-parent();

  transform: rotateY(0deg);
}

.bas-tile-favourites {
  @include util.absolute-match-parent();

  display: block;
  font-size: 0;

  // Don't hide overflow on screens smaller than tablet-portrait,
  //  no visible edge and much less padding on the tiles

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {

    // We only have a visible edge on the favourite tiles section
    //  in tablet-portrait layout or bigger (due to background-color)
    //  -> hide overflow
    overflow: hidden;

    &::before {
      @include util.absolute-match-parent();

      content: '';
      background-color: defaults.$color-black-bg-trans;
    }
  }
}

.bas-tile-player-favorite {
  @include util.absolute-center();

  width: 70%;
  fill: defaults.$color-light-control-buttons;
}

.bas-tile-player-play {
  @include util.absolute-center();

  width: 60%;
  fill: defaults.$color-light-control-buttons;
}

$btm-title-button-size: 40px;

.btm-title-button {
  position: relative;
  display: none;
  width: $btm-title-button-size;
  height: 100%;
  float: right;
  margin-right: tiles_const.$bas-small-tile-outer-margin;

  @media (min-width: #{defaults.$media-query-tablet-portrait}) {
    margin-right: tiles_const.$bas-small-tile-outer-margin-tablet * 0.5;
  }
}

// Tile

@mixin tile-size() {
  width: bas-tile-width(2, tiles_const.$bas-tile-spacing-hor-p);
  padding-bottom: bas-tile-width(2, tiles_const.$bas-tile-spacing-hor-p);
  margin-right: tiles_const.$bas-tile-spacing-hor-p;
  margin-bottom: tiles_const.$bas-tile-spacing-ver-p;

  @media (min-width: defaults.$screen-size-500) {
    width: bas-tile-width(3, tiles_const.$bas-tile-spacing-hor-p);
    padding-bottom: bas-tile-width(3, tiles_const.$bas-tile-spacing-hor-p);
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    width: bas-tile-width(4);
    padding-bottom: bas-tile-width(4);
    margin-right: tiles_const.$bas-tile-spacing-hor;
    margin-bottom: tiles_const.$bas-tile-spacing-ver;
  }

  @media (min-width: defaults.$screen-size-900) {
    width: bas-tile-width(5);
    padding-bottom: bas-tile-width(5);
  }

  @media (min-width: defaults.$screen-size-1100) {
    width: bas-tile-width(6);
    padding-bottom: bas-tile-width(6);
  }

  @media (min-width: defaults.$screen-size-1250) {
    width: bas-tile-width(7);
    padding-bottom: bas-tile-width(7);
  }

  @media (min-width: defaults.$screen-size-1450) {
    width: bas-tile-width(8);
    padding-bottom: bas-tile-width(8);
  }

  @media (min-width: defaults.$screen-size-1600) {
    width: bas-tile-width(9);
    padding-bottom: bas-tile-width(9);
  }

  @media (min-width: defaults.$screen-size-1600) {
    width: bas-tile-width(10);
    padding-bottom: bas-tile-width(10);
  }
}

.bas-tile {
  @include tile-size();
  @include util.button-cursor();

  position: relative;
  display: inline-block;
  background-color: defaults.$color-black-dark-bg-trans;
  overflow: hidden;
  box-shadow: tiles_const.$bas-tile-box-shadow;

  &.small-bas-tile {
    // TODO: When using 'max-width' we should always add ' - 1' to the values to
    //  get consistent breakpoints when compared to 'min-width' media queries.
    //  Since this is often forgotten and also goes against a mobile-first
    //  approach, we should try to only use 'min-width' media queries instead.
    @media (max-width: #{defaults.$media-phone-width - 1}) {
      width: bas-tile-width(3, tiles_const.$bas-tile-spacing-hor-p);
      padding-bottom: bas-tile-width(3, tiles_const.$bas-tile-spacing-hor-p);
      margin-right: tiles_const.$bas-tile-spacing-hor-p;
      margin-bottom: tiles_const.$bas-tile-spacing-ver-p;
    }
  }
}

$bas-tile-separator-hor-spacing: tiles_const.$bas-tile-spacing-hor + 2px;
$bas-tile-separator-hor-p-spacing: tiles_const.$bas-tile-spacing-hor-p + 2px;

.bas-tile-separator {
  display: inline-block;
  border: 1px solid defaults.$color-light-control-buttons;
  padding-bottom: bas-tile-width(3, $bas-tile-separator-hor-p-spacing);
  margin-bottom: tiles_const.$bas-tile-spacing-hor-p;
  // -1px is to compensate for the border
  margin-left: -(tiles_const.$bas-tile-spacing-hor-p * 0.5) - 1px;
  margin-right: (tiles_const.$bas-tile-spacing-hor-p * 0.5) - 1px;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding-bottom: bas-tile-width(4, $bas-tile-separator-hor-spacing);

    @media (min-width: defaults.$screen-size-900) {
      padding-bottom: bas-tile-width(5, $bas-tile-separator-hor-spacing);
    }

    @media (min-width: defaults.$screen-size-1100) {
      padding-bottom: bas-tile-width(6, $bas-tile-separator-hor-spacing);
    }

    @media (min-width: defaults.$screen-size-1250) {
      padding-bottom: bas-tile-width(7, $bas-tile-separator-hor-spacing);
    }

    @media (min-width: defaults.$screen-size-1450) {
      padding-bottom: bas-tile-width(8, $bas-tile-separator-hor-spacing);
    }

    @media (min-width: defaults.$screen-size-1600) {
      padding-bottom: bas-tile-width(9, $bas-tile-separator-hor-spacing);
    }

    @media (min-width: defaults.$screen-size-1800) {
      padding-bottom: bas-tile-width(10, $bas-tile-separator-hor-spacing);
    }

    // -1px is to compensate for the border
    margin-bottom: tiles_const.$bas-tile-spacing-hor;
    margin-left: -(tiles_const.$bas-tile-spacing-hor * 0.5) - 1px;
    margin-right: (tiles_const.$bas-tile-spacing-hor * 0.5) - 1px;
  }
}

// Tile - background

.bas-tile-bg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bas-tile-border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3px;
}

.bas-tile-gradient-overlay {
  &::after {
    content: none;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0.4) 20%,
        rgba(0, 0, 0, 0.1) 33%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    transform: translate3d(0, 0, 1px);
  }
}

.bas-tile-bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

// Tile - title

$bas-tile-title-ver-padding: .6em;

.bas-tile-title {
  @include defaults.font-roboto-regular;

  position: absolute;
  display: none;
  bottom: 0;
  width: 100%;
  color: defaults.$color-foreground;
  text-align: left;
  z-index: 1;

  &.bas-tile-title-small {
    font-size: 1em;
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: 1.4em;
  }
}

.bas-tile-has-title {

  .bas-image--valid:not(.bas-image--default) {
    &.bas-tile-gradient-overlay::after {
      content: '';
    }
  }

  .bas-svg-valid {
    &.bas-tile-gradient-overlay::after {
      content: '';
    }
  }

  .bas-tile-title {
    display: block;
  }
}

.bas-on-off-tile,
.bas-small-comfort-tile,
.bas-small-tile,
.small-bas-tile {
  .bas-tile-title {
    font-size: .8em;

    @media (min-width: #{defaults.$media-phone-width}) {
      font-size: 1em;
    }
  }
}

$bas-tile-favourite-size: 1.2em;

.bas-tile-title-txt,
.bas-tile-subtitle-txt {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: $bas-tile-title-ver-padding .4em $bas-tile-title-ver-padding .6em;
  // TODO Use defaults
  color: color.adjust(defaults.$color-foreground, $lightness: -15%);
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
  transition: color 1s;
  transform: translateZ(1px);
}

.bas-tile-subtitle-txt {
  transform: translateY(6px);
  padding-bottom: 0;
  color: color.adjust(defaults.$color-foreground, $lightness: -36%);
  font-size: .8em;
}

.bas-tile-has-subtitle {
  .bas-tile-title {
    transform: translateY(4px);
    font-size: 0.95em;
  }
}

.bas-tile-has-icon {
  .bas-tile-title-txt {
    padding: .4em $bas-tile-title-ver-padding;
    text-shadow: none;
  }
}

// Tile overlay button

.bt-overlay-button {
  @include util.absolute-match-parent();

  display: none;
  background-color: defaults.$color-black-darker-bg-trans;

  svg {
    @include util.absolute-center();

    top: 45%;
    width: 40%;
    fill: defaults.$color-dark-separator;
  }
}

.bas-state-editing {

  .bas-tile {

    &.bmc-can-edit {

      .bas-tile-edit {
        display: block;
      }
    }
  }
}

// Tile - SVGs

.bas-tile-svg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .bas-tile-svg-wrapper > svg,
  > svg {
    position: absolute;
    top: 0;
    left: 50%;
    width: 80%;
    height: 80%;
    transform: translateX(-50%);
    fill: defaults.$color-light-control-buttons-f;
  }
}

.bas-tile-top-offset {
  .bas-tile-svg {
    svg {
      top: 4%;
    }
  }
}

.bas-tile-svg-75 {
  .bas-tile-svg {
    svg {
      width: 75%;
    }
  }
}

.bas-tile-svg-70 {
  .bas-tile-svg {
    svg {
      width: 70%;
    }
  }
}

.bas-tile-svg-65 {
  .bas-tile-svg {
    svg {
      width: 65%;
    }
  }
}

.bas-tile-svg-60 {
  .bas-tile-svg {
    svg {
      width: 60%;
    }
  }
}

.bas-tile-svg-55 {
  .bas-tile-svg {
    svg {
      width: 55%;
    }
  }
}

.bas-tile-svg-50 {
  .bas-tile-svg {
    svg {
      width: 50%;
    }
  }
}

.bas-tile-svg-45 {
  .bas-tile-svg {
    svg {
      width: 45%;
    }
  }
}

.bas-tile-svg-40 {
  .bas-tile-svg {
    svg {
      width: 40%;
    }
  }
}

.bas-small-comfort-tile.bti-music .bas-tile-svg {
  > svg {
    top: 12%;
    width: 60%;
    height: 60%;
  }
}

.bas-tile-on-off-svg {
  @include util.absolute-center();

  display: none;
  width: 50%;
  top: 46%;

  svg {
    width: 100%;
    height: 100%;
  }
}

.bas-tile-show-on-off {
  .bas-tile-on-off-svg {
    display: block;
  }
}

// Tile - highlight, selection & spinner

.bas-tile {

  &::after {
    @include util.absolute-match-parent();

    content: '';
    pointer-events: none;
    transition: background-color .5s ease-in-out;
  }
}

.bas-tile-bg-overlay {
  @include util.absolute-match-parent();
}

.bas-tile-activities {

  .bas-tile-bg-overlay {
    transition: background-color defaults.$standard-animation-time;
    // TODO Use defaults
    background-color: rgba(0, 0, 0, .35);
  }

  .bas-tile-activity-light {
    display: none;
  }

  &.bas-room--lights--has-active {

    .bas-tile-bg-overlay {
      background-color: transparent;
    }

    .bas-tile-activity-light {
      display: block;
    }
  }
}

$bas-tile-activity-size: 25px;
$bas-tile-activity-margin: 4px;

.bas-tile-activity-light {
  width: $bas-tile-activity-size;
  height: $bas-tile-activity-size;
  position: absolute;
  top: $bas-tile-activity-margin;
  right: $bas-tile-activity-margin;
  border-radius: 100%;
  background-color: defaults.$color-header;

  svg {
    @include util.absolute-center();

    fill: defaults.$color-foreground;
    height: 60%;
    width: 60%;
  }
}

.bas-tile-highlight {
  .bas-tile-title-txt {
    color: defaults.$color-highlight-on-dark;
  }

  svg {
    fill: defaults.$color-highlight-on-dark;
  }
}

.bas-tile-spinner-wrapper {
  @include util.absolute-center();

  display: none;
  width: 25%;
  height: 25%;
  z-index: 1;

  .bas-spinner-swoop .bas-spinner-swoop-circle .bas-spinner-swoop-circle-inner {
    border-width: 3px;
  }
}

.bas-tile-spinner-visible {

  &.bas-tile,
  &.bas-small-tile {
    &::after {
      transition: background-color defaults.$standard-animation-time ease-in-out;
      background-color: defaults.$color-black-dark-bg-trans-dark;
    }
  }

  .bas-tile-spinner-wrapper {
    display: block;
  }
}

.bas-tile {

  &:active {
    &::after {
      transition: background-color 0s ease-in-out;
      background-color: defaults.$color-background-active-transparent;
    }
  }

  &.bas-tile-has-long-press {

    &:active {
      &::after {
        transition: background-color 0.5s ease-in-out;
        background-color: defaults.$color-black-dark-bg-trans-dark;
      }
    }
  }

  &.bas-tile-is-pressed {
    &::after {
      transition: background-color .8s;
      background-color: defaults.$color-black-darker-bg-trans;
    }

    .bas-tile-title-txt {
      color: defaults.$color-highlight-on-dark;
    }
  }
}

// Widget

$bas-widget-height-multiplier: 1.25;

@mixin widget-size() {
  width: calc(100% - #{2 * tiles_const.$bas-tile-spacing-hor-p});
  padding-bottom: bas-widget-width(math.div(2, $bas-widget-height-multiplier), tiles_const.$bas-tile-spacing-hor-p);
  margin-right: tiles_const.$bas-tile-spacing-hor-p;
  margin-top: tiles_const.$bas-tile-spacing-ver-p;

  @media (min-width: defaults.$screen-size-330) {
    padding-bottom: bas-widget-width(math.div(3, $bas-widget-height-multiplier), tiles_const.$bas-tile-spacing-hor-p);
  }

  @media (min-width: defaults.$screen-size-500) {
    padding-bottom: bas-widget-width(math.div(4, $bas-widget-height-multiplier), tiles_const.$bas-tile-spacing-hor-p);
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    width: bas-widget-width(4);
    padding-bottom: bas-widget-width(math.div(5, $bas-widget-height-multiplier));
    margin-right: tiles_const.$bas-tile-spacing-hor;
    margin-top: tiles_const.$bas-tile-spacing-ver;
  }

  @media (min-width: defaults.$screen-size-900) {
    width: bas-widget-width(5);
    padding-bottom: bas-widget-width(math.div(6, $bas-widget-height-multiplier));
  }

  @media (min-width: defaults.$screen-size-1100) {
    width: bas-widget-width(6);
    padding-bottom: bas-widget-width(math.div(7, $bas-widget-height-multiplier));
  }

  @media (min-width: defaults.$screen-size-1250) {
    width: bas-widget-width(7);
    padding-bottom: bas-widget-width(math.div(8, $bas-widget-height-multiplier));
  }

  @media (min-width: defaults.$screen-size-1450) {
    width: bas-widget-width(8);
    padding-bottom: bas-widget-width(math.div(9, $bas-widget-height-multiplier));
  }

  @media (min-width: defaults.$screen-size-1600) {
    width: bas-widget-width(9);
    padding-bottom: bas-widget-width(math.div(10, $bas-widget-height-multiplier));
  }

  @media (min-width: defaults.$screen-size-1800) {
    width: bas-widget-width(10);
    padding-bottom: bas-widget-width(math.div(11, $bas-widget-height-multiplier));
  }
}

.bas-widget {
  @include widget-size();
  @include defaults.font-roboto-regular;

  position: relative;
  display: block;
  float: right;
  margin-bottom: 0;
  color: defaults.$color-foreground;
  fill: defaults.$color-foreground;

  .bas-weather-top-widget {
    height: 52%;
  }
}

.bas-tiles-empty-title {
  margin-top: tiles_const.$bas-tiles-empty-title-spacing;
  margin-bottom: tiles_const.$bas-tiles-empty-title-spacing;
}

.bas-tiles-container {
  margin-right: -(tiles_const.$bas-tile-spacing-hor-p);
  margin-bottom: -(tiles_const.$bas-tile-spacing-ver-p);

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-right: -(tiles_const.$bas-tile-spacing-hor);
    margin-bottom: -(tiles_const.$bas-tile-spacing-ver);
  }
}

.bas-object-group-top-margin {
  margin-top: tiles_const.$bas-tile-spacing-ver-p;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-top: tiles_const.$bas-tile-spacing-ver;
  }
}

// TODO move to proper scss file

// Height of icon and temp classes are divided by $bas-widget-height-multiplier
// in order to keep them square
// info and forecast classes can fill in the rest as required

.bas-widget-warning {
  @include util.absolute-center();
  @include defaults.font-roboto-regular;

  display: none;
  color: defaults.$color-foreground;
  font-size: 1.5em;

  &.bas-widget-no-connection {
    display: block;
  }
}

$widget-icon-size: 45%;
$widget-temperature-size: 45%;
$widget-temperature-margin: 100% - $widget-icon-size - $widget-temperature-size;
$widget-font-size: 20px;
$widget-font-size-small: 14px;

.bas-widget-forecasts {
  display: block;
  position: relative;
  margin-top: 5%;
  height: 43%;
  width: 100%;
}

.bas-widget-forecast-item {
  position: relative;
  display: inline-block;
  width: 25%;
  height: 100%;
  border-right: 1px solid white;
  box-sizing: border-box;
  font-size: $widget-font-size;
  vertical-align: top;

  &:last-child {
    border-right: none;
  }

  .bas-widget-forecast-icon {
    width: 100%;
    height: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .bas-widget-text {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 10%;
  }

  .bas-widget-title {
    @include util.absolute-center();

    font-size: .7em;
    max-width: calc(100% - .6em);
  }
}

.bas-widget-details-button {
  position: absolute;
  right: 0;
  height: 25px;
  width: 25px;

  svg {
    @include util.absolute-center();

    width: 100%;
  }
}
