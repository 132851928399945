@use 'defaults';
@use 'util';

$invalid-input-box-shadow: 0 0 10px 8px defaults.$color-accent-red-bright--a7;

.bas-live {

  .brs-title-back,
  .bas-live-unsupported,
  .bas-live-login,
  .bas-live-logout,
  .bas-live-account-spinner,
  .bas-live-no-notification-permission {
    display: none;
  }

  .bas-live-login-state {
    padding-top: 1em;

    @media (min-height: 600px) {
      padding-top: 2em;
    }
  }

  .bas-live-account-details {
    display: block;
  }

  &.brs-title-back--show {
    &.bas-live--show-back {

      .brs-title-back {
        display: flex;
      }

      &.bas-live--is-performing-an-action {
        .brs-title-back {
          display: none;
        }
      }
    }
  }

  &.bas-live--login-show {

    .bas-live-login {
      display: block;
    }
  }

  &.bas-live--unsupported-show {

    .bas-live-unsupported {
      display: block;
    }
  }

  &.bas-live--logout-show {

    .bas-live-logout {
      display: block;
    }
  }

  &.bas-live--checking-session {

    .bas-live-login,
    .bas-live-logout {
      display: none;
    }
  }

  &.bas-live--retrieving-account-details {

    .bas-live-account-spinner {
      display: block;
    }

    .bas-live-account-details {
      display: none;
    }
  }

  &.bas-live--is-performing-an-action {
    .brs-title-back {
      display: none;
    }
  }

  &:not(.bas-live--has-notification-permission) {

    .bas-live-no-notification-permission {
      display: inline-block;
    }

    .bas-live-notification-toggle {
      pointer-events: none;
    }
  }
}

.bas-live-login {

  .bas-live-login-spinner {
    display: none;
  }

  .bas-live-login-button-text {
    display: inline-block;
  }

  &.bas-live--is-performing-an-action,
  &.bas-live--logging-in {

    .bas-live-login-spinner {
      display: inline-block;
    }

    .bas-live-login-button-text {
      display: none;
    }
  }
}

.bas-live-sign-up {

  .bas-live-sign-up-spinner {
    display: none;
  }

  .bas-live-sign-up-button-text {
    display: inline-block;
  }

  &.bas-live--is-performing-an-action,
  &.bas-live--registering {

    .bas-live-sign-up-spinner {
      display: inline-block;
    }

    .bas-live-sign-up-button-text {
      display: none;
    }
  }
}

.bas-live-verify {

  .bas-live-verify-spinner {
    display: none;
  }

  .bas-live-verify-button-text {
    display: inline-block;
  }

  .bas-live-verify-email {
    display: none;
  }

  &.bas-live-verify--show-email {

    .bas-live-verify-email {
      display: block;
    }
  }

  &.bas-live--is-performing-an-action,
  &.bas-live--verifying {

    .bas-live-verify-spinner {
      display: inline-block;
    }

    .bas-live-verify-button-text {
      display: none;
    }
  }
}

.bas-live-forgot {

  .bas-live-forgot-spinner {
    display: none;
  }

  .bas-live-forgot-button-text {
    display: inline-block;
  }

  .bas-live-forgot-email {
    display: none;
  }

  &.bas-live-forgot--show-email {

    .bas-live-forgot-email {
      display: block;
    }
  }

  &.bas-live--is-performing-an-action,
  &.bas-live--resetting {

    .bas-live-forgot-spinner {
      display: inline-block;
    }

    .bas-live-forgot-button-text {
      display: none;
    }
  }
}

.bas-live-change {

  .bas-live-change-spinner {
    display: none;
  }

  .bas-live-change-button-text {
    display: inline-block;
  }

  &.bas-live--is-performing-an-action {

    .bas-live-change-spinner {
      display: inline-block;
    }

    .bas-live-change-button-text {
      display: none;
    }
  }
}

.bas-live-icon {
  width: 150px;
}

.basalte-live-logo {
  position: relative;
  font-size: 1em;
  text-align: center;
}

.bas-live-spinner {
  position: relative;
  width: 2em;
  height: 2em;
}

.bas-live-account-details-spinner {
  position: relative;
}

.bas-live-login-spinner,
.bas-live-register-spinner {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.bas-live-login-button-text,
.bas-live-register-button-text {
  display: inline-block;
  vertical-align: top;
}

.bas-input-validation {
  transition: box-shadow defaults.$standard-animation-time;

  &.ng-dirty {

    &.ng-invalid {
      box-shadow: $invalid-input-box-shadow;
    }
  }
}

.bas-form-input-validation {

  &.ng-submitted {

    .bas-input-validation {

      &.ng-invalid {
        box-shadow: $invalid-input-box-shadow;
      }
    }
  }
}

.bas-current-core-indicator-container {
  width: 18px;
  margin-right: 10px;
}

.bas-current-core-indicator {
  height: 8px;
  width: 8px;
  margin-left: 10px;
  background-color: defaults.$color-highlight-on-light;
  border-radius: 50%;
}

.live-project-setting-entry {

  .bas-current-core-indicator-container,
  .bas-current-core-indicator,
  .bas-project-online-cloud-icon,
  .bas-project-integrator-access-granted-icon {
    display: none;
  }

  .bas-project-offline-cloud-icon,
  .bas-project-integrator-access-disabled-icon {
    display: block;
  }

  &.bas-project--is-online {

    .bas-project-online-cloud-icon {
      display: block;
    }

    .bas-project-offline-cloud-icon {
      display: none;
    }
  }

  &.bas-project--integrator-access-granted {

    .bas-project-integrator-access-granted-icon {
      display: block;
    }

    .bas-project-integrator-access-disabled-icon {
      display: none;
    }
  }

  &.bas-is-current-core {
    .bas-current-core-indicator {
      display: block;
    }
  }

  &.bas-has-current-core {
    .bas-current-core-indicator-container {
      display: block;
    }
  }
}

.bas-device--live-only {

  .bas-continue-without-live {
    display: none;
  }
}

// iOS splash screen uses a large square picture to handle all different screens
// To match the splash screen, use the same image with background-size: cover
.bas-device-ios {

  // Values for flex-basis were experimentally determined

  .bas-splash-logo-container {
    // Width < height
    // Height determines logo size
    flex-basis: 4vh;

    &::before {
      content: '';
      position: absolute;
      top: auto;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('img/ios/Default@2x~universal~anyany.png');
      z-index: -1;
    }

    @media (min-aspect-ratio: 1/1) {
      // Width > height
      // Width determines logo size
      flex-basis: 4vw;
    }
  }

  .bas-splash-logo {
    display: none;
  }
}
