@use 'defaults';

$bas-button-padding: 1px;
$bas-button-radius: 15px;
$bas-button-border-size: 1px;
$bas-button-font-size: 1em;

$bas-button-toggle-transition-time: .2s;
$bas-button-toggle-handle-height: 24px;
$bas-button-toggle-handle-width: 24px;
$bas-button-toggle-white-space: 18px;

$bas-button-toggle-width: $bas-button-toggle-handle-width + $bas-button-toggle-white-space;

$bas-button-toggle-total-width: $bas-button-toggle-width +
  2 * $bas-button-border-size +
  2 * $bas-button-padding;

$bas-button-toggle-total-height: $bas-button-toggle-handle-height +
  2 * $bas-button-border-size +
  2 * $bas-button-padding;

$bas-button-join-padding: $bas-button-padding .5em;

$bs-title-line-font-size: 1.333333em;

// Take into account the 1px border around the button
$bas-toggle-margin: 0 1px;

$bas-title-line-spacing: .5em;

$bmch-hor-spacing: 16px;
$bmch-icon-size: 24px;
$bmc-order-size: 16px;

$bas-fade-pulse-duration: 1.8s;

$bas-input-icon-size: 1.2em;
$bas-input-icon-side-spacing: .3em;

$outline-button-width: 44px;
$outline-button-border-width: 2px;
$outline-button-border: solid $outline-button-border-width defaults.$color-grey-transparent;
$outline-button-spacing: 10px;

// Button

@mixin bs-btn-animate {
  transition:
    color defaults.$standard-animation-time,
    border-color defaults.$standard-animation-time,
    background-color defaults.$standard-animation-time;

  svg {
    transition: fill defaults.$standard-animation-time;
  }

  &.bas-btn-active,
  &:active,
  &.active {
    transition: color 0s, background-color 0s;

    svg {
      transition: fill 0s;
    }
  }

  &.bas-btn-active-fill {
    transition: color 0s, background-color defaults.$standard-animation-time;
  }
}

@mixin bs-btn-grey {
  border-color: defaults.$color-grey;
  color: defaults.$color-foreground;

  svg {
    fill: defaults.$color-grey;
  }
}

@mixin bs-btn-green {
  border-color: defaults.$color-green;
  color: defaults.$color-green;
  background-color: defaults.$color-green;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-green-light {
  border-color: defaults.$color-green-light;
  color: defaults.$color-green-light;
  background-color: defaults.$color-green-light;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-red {
  border-color: defaults.$color-red;
  color: defaults.$color-red;
  background-color: defaults.$color-red;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-red-light {
  border-color: defaults.$color-red-light;
  color: defaults.$color-red-light;
  background-color: defaults.$color-red-light;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-heating-on-dark {
  border-color: defaults.$color-heating;
  color: defaults.$color-heating;
  background-color: defaults.$color-heating;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-cooling-on-dark {
  border-color: defaults.$color-cooling;
  color: defaults.$color-cooling;
  background-color: defaults.$color-cooling;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-off-on-dark {
  border-color: defaults.$color-grey;
  color: defaults.$color-grey;
  background-color: defaults.$color-grey;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-full-highlight-on-dark {
  border-color: defaults.$color-highlight-on-dark;
  background-color: defaults.$color-highlight-on-dark;
  color: defaults.$color-foreground;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-full-highlight-on-light {
  border-color: defaults.$color-highlight-on-light;
  background-color: defaults.$color-highlight-on-light;
  color: defaults.$color-foreground;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-active-highlight-transparent-fill {
  border-color: defaults.$color-highlight-on-dark;
  background-color: defaults.$color-highlight-transparent;
  color: defaults.$color-highlight-on-dark;

  svg {
    fill: defaults.$color-highlight-on-dark;
  }

  &.bas-btn-active {
    border-color: defaults.$color-highlight-on-dark;
    background-color: defaults.$color-highlight-transparent;
    color: defaults.$color-highlight-on-dark;
  }
}

@mixin btbs-set-active {
  .btbs-default {
    display: none;
  }

  .btbs-active {
    display: block;
  }
}

@mixin bs-btn-on-light-disabled {
  border-color: defaults.$color-library-control;
  color: defaults.$color-library-control;

  svg {
    fill: defaults.$color-library-control;
  }
}

@mixin bs-btn-foreground {
  border-color: defaults.$color-foreground;
  color: defaults.$color-foreground;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-on-dark {
  border-color: defaults.$color-light-control-buttons;
  color: defaults.$color-foreground;

  svg {
    fill: defaults.$color-foreground;
  }
}

@mixin bs-btn-on-light {
  border-color: defaults.$color-library-control;
  color: defaults.$color-background-active;

  svg {
    fill: defaults.$color-background-active;
  }
}

@mixin bs-btn-highlight-on-dark {
  border-color: defaults.$color-highlight-on-dark;
  color: defaults.$color-highlight-on-dark;

  svg {
    fill: defaults.$color-highlight-on-dark;
  }
}

@mixin bs-btn-highlight-on-light {
  border-color: defaults.$color-highlight-on-light;
  color: defaults.$color-highlight-on-light;

  svg {
    fill: defaults.$color-highlight-on-light;
  }
}

@mixin bs-btn-active-highlight-on-dark {

  &.bas-btn-active,
  &:active,
  &.active {
    color: defaults.$color-highlight-on-dark;
    border-color: defaults.$color-highlight-on-dark;

    svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }

  &.bas-btn-active-fill {
    background-color: defaults.$color-highlight-on-dark;
    color: defaults.$color-foreground;
  }
}

@mixin bs-btn-active-highlight-on-light {

  &.bas-btn-active,
  &:active,
  &.active {
    color: defaults.$color-highlight-on-light;
    border-color: defaults.$color-highlight-on-light;

    svg {
      fill: defaults.$color-highlight-on-light;
    }
  }

  &.bas-btn-active-fill {
    background-color: defaults.$color-highlight-on-light;
    color: defaults.$color-foreground;
  }
}

@mixin bas-btn-white-on-dark-disabled {
  color: defaults.$color-text-grey;
  border-color: defaults.$color-text-light;

  svg {
    fill: defaults.$color-text-grey;
  }

  &.bas-btn-active,
  &:active,
  &.active {
    color: defaults.$color-text-grey;
    border-color: defaults.$color-text-light;

    svg {
      fill: defaults.$color-text-grey;
    }
  }
}

@mixin bs-btn-active-full-highlight-on-dark {

  &.bas-btn-active,
  &:active,
  &.active {
    color: defaults.$color-foreground;
    border-color: defaults.$color-highlight-on-dark;
    background-color: defaults.$color-highlight-on-dark;

    svg {
      fill: defaults.$color-foreground;
    }
  }
}

@mixin bs-btn-active-full-highlight-on-light {

  &.bas-btn-active,
  &:active,
  &.active {
    color: defaults.$color-foreground;
    border-color: defaults.$color-highlight-on-light;
    background-color: defaults.$color-highlight-on-light;

    svg {
      fill: defaults.$color-foreground;
    }
  }
}

@mixin bs-btn-instant {
  transition: color 0s, border-color 0s, background-color 0s;

  svg {
    transition: fill 0s;
  }
}

@mixin bs-btn-fast {
  transition: color defaults.$standard-animation-time,
    background-color defaults.$standard-animation-time,
    border-color defaults.$standard-animation-time;

  svg {
    transition: fill defaults.$standard-animation-time;
  }
}

// Segmented control

@mixin bssc-highlight-on-dark {
  border-color: defaults.$color-highlight-on-dark;

  .bssc-el {
    color: defaults.$color-highlight-on-dark;
    border-right-color: defaults.$color-highlight-on-dark;

    &:active,
    &.active {
      color: defaults.$color-foreground;
      background-color: defaults.$color-highlight-on-dark;
    }
  }
}

@mixin bssc-highlight-on-light {
  border-color: defaults.$color-highlight-on-light;

  .bssc-el {
    color: defaults.$color-highlight-on-light;
    border-right-color: defaults.$color-highlight-on-light;

    &:active,
    &.active {
      color: defaults.$color-foreground;
      background-color: defaults.$color-highlight-on-light;
    }
  }
}

// Label

@mixin bs-lbl--grey-on-dark {
  color: defaults.$color-grey;
  border-color: defaults.$color-grey;
}

@keyframes bas-kf-fade-pulse {
  0% { opacity: 0; }

  25% { opacity: 0; }

  50% { opacity: 1; }

  75% { opacity: 1; }

  100% { opacity: 0; }
}

@mixin bs-pulse-fade() {
  animation-name: bas-kf-fade-pulse;
  animation-duration: $bas-fade-pulse-duration;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

// Quad position indicator

$bsqp-size: 20px;

// Toggle

@mixin bas-toggle-disabled() {
  border-color: defaults.$color-highlight-disabled-transparent-lighter;
  background-color: defaults.$color-highlight-disabled-transparent;
  pointer-events: none;
  cursor: pointer;

  > .toggle-background {
    background-color: defaults.$color-highlight-disabled;
  }

  > .toggle-handle {
    box-shadow: 0 0 4px defaults.$color-black-bg-trans;
    background-color: defaults.$color-foreground-disabled;
  }
}

// Scroll bar

@mixin bas-browser-scroll-bar {
  -ms-overflow-style: auto;
  overflow-x: auto;
  overflow-y: visible;

  &::-webkit-scrollbar {
    display: block;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9999px;
  }
}

@mixin bas-browser-scroll-bar-transparent {

  &::-webkit-scrollbar-thumb {
    background-color: defaults.$color-background-shadow-trans;
  }
}

@mixin bas-browser-scroll-bar-grey {

  &::-webkit-scrollbar-thumb {
    background-color: defaults.$color-background-active;
  }
}
