@use 'sass:math';
@use 'defaults';
@use 'bas_notch_const';
@use 'weather_const';
@use 'grid';
@use 'util';

$weather-small-font-size: 14px;
$weather-normal-font-size: 20px;
$weather-big-font-size: 70px;

$swiper-pagination-height: 8px;
$swiper-pagination-margin: 6px;

// Padding & margin
$margin-between: 0.7em;
$item-padding: 1em;

// General (bas-weather = bw)
.bw {
  @include defaults.font-roboto-regular;

  display: flex;
  justify-content: unset;
  overflow: auto;
  scrollbar-width: none; // Needed for Firefox
  flex-direction: column;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  padding-bottom: bas_notch_const.$notch-safe-bottom;
  color: defaults.$color-foreground;
  max-height: 100%;
  max-width: 800px;
  margin: auto;
  -webkit-overflow-scrolling: touch;

  &,
  * {
    box-sizing: border-box;
  }

  svg {
    fill: defaults.$color-foreground;
  }
}

// carousel
.bas-weather-sources {
  height: 100%;

  .swiper-container {
    margin-left: 0;
    margin-right: 0;
  }

  .swiper-wrapper {
    height: calc(100% - #{$swiper-pagination-height + $swiper-pagination-margin * 2});
  }

  .swiper-pagination {
    width: 100%;
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.bas-weather-source {
  .bw-current-info-top-left-title,
  .bw-current-apparent-temperature,
  .bw-current-top-humidity,
  .bw-current-info-bottom-left-title,
  .bw-current-info-bottom-right-title,
  .bw-current-icon,
  .bas-widget-icon,
  .bw-current-rain-icon,
  .bw-current-frost-icon,
  .bw-current-extra-element-humidity,
  .bw-current-extra-element-wind,
  .bw-current-extra-element-precipitation,
  .bw-current-extra-element-sun,
  .bw-current-extra-element-uv,
  .bw-current-extra-element-pressure,
  .bw-current-extra-element-brightness,
  .bw-temperature,
  .bw-secondary-icon-wind {
    display: none;
  }

  &.bw-has-current-info-top-left-title {
    .bw-current-info-top-left-title {
      display: block;
    }
  }

  &.bw-show-humidity-widget {

    &.bw-has-humidity {
      .bw-current-top-humidity {
        display: block;
      }
    }
  }

  &.bw-show-apparent-temperature {

    &.bw-has-apparent-temperature {
      .bw-current-apparent-temperature {
        display: block;
      }
    }
  }

  &.bw-has-current-info-bottom-left-title {
    .bw-current-info-bottom-left-title {
      display: block;
    }
  }

  &.bw-has-multiple-services {

    &.bw-has-current-info-bottom-right-title {

      .bw-current-info-bottom-right-title {
        display: block;
      }
    }
  }

  &.bw-has-icon {
    .bw-current-icon,
    .bas-widget-icon {
      display: block;
    }
  }

  &.bw-has-rain {
    &.bw-has-rain-enabled {
      .bw-current-rain-icon {
        display: block;
      }
    }
  }

  &.bw-has-frost {
    &.bw-has-frost-enabled {
      .bw-current-frost-icon {
        display: block;
      }
    }
  }

  &.bw-has-humidity {
    .bw-current-extra-element-humidity {
      display: block;
    }
  }

  &.bw-has-wind-speed {
    .bw-current-extra-element-wind {
      display: block;
    }
  }

  &.bw-has-precipitation {
    .bw-current-extra-element-precipitation {
      display: block;
    }
  }

  &.bw-has-sun-rise-set {
    .bw-current-extra-element-sun {
      display: block;
    }
  }

  &.bw-has-uv {
    .bw-current-extra-element-uv {
      display: block;
    }
  }

  &.bw-has-pressure {
    .bw-current-extra-element-pressure {
      display: block;
    }
  }

  &.bw-has-brightness {
    .bw-current-extra-element-brightness {
      display: block;
    }
  }

  &.bw-has-temperature {
    .bw-temperature {
      display: block;
    }
  }

  &.bw-has-wind-direction {
    .bw-secondary-icon-wind {
      display: block;
    }

    .bw-secondary-icon-wind-gust {
      display: none;
    }
  }
}

.bw-item {
  margin: $margin-between $margin-between 0 $margin-between;
  padding: $item-padding;
}

// Weather icon
.bw-icon {
  @include weather_const.weather-icon(); }

// Today section
.bw-today {
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: bas_notch_const.$notch-safe-bottom;
  -webkit-overflow-scrolling: touch;

  // Never incomplete items on mobile: bw-today takes up whole screen so
  //  hourly moves to bottom of screen
  min-height: calc(100vh - #{defaults.$main-header-height-phone});
  min-height: calc(100vh - #{defaults.$main-header-height-phone} - #{bas_notch_const.$notch-safe-bottom} - #{bas_notch_const.$notch-safe-top});

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-bottom: 0;
    min-height: initial;
  }
}

// Main weather information

.bw-current-main {
  margin: 10px 10px 0;
  font-size: 1.1em;
  padding: 0;
  height: calc((125vw - 10px) * 0.52);

  @media (min-width: defaults.$screen-size-330) {
    height: calc((83.33333vw - 10px) * 0.52);
  }

  @media (min-width: defaults.$screen-size-500) {
    height: calc((62.5vw - 10px) * 0.52);
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    height: 9.5em;
    font-size: 1.3em;
    padding-bottom: 0;
  }

  @media (min-width: defaults.$media-tablet-landscape) and (orientation: landscape) {
    font-size: 1em;
  }
}

.bas-weather-top-widget {
  height: 100%;
  position: relative;
}

.bw-current-main-top {
  display: flex;
  height: 70%;
}

.bw-current-icons {
  display: flex;
  position: relative;
  width: 45%;
  height: 100%;

  .bw-current-icon {
    width: 100%;
    height: 100%;

    svg {
      height: 150%;
      width: 150%;
    }
  }

  .bw-current-small-icon {
    width: 50%;
    height: 100%;

    svg {
      height: 120%;
      width: 120%;
    }

    // frost icon looks bigger than other icons
    &.bw-current-frost-icon {

      svg {
        height: 108%;
        width: 108%;
      }
    }

    &.bw-current-rain-icon {

      svg {
        top: 48%;
      }
    }
  }
}

.bw-current-info {
  width: 90%;
  height: 28.070175438%;
  margin: 0 auto;
  font-size: $weather-normal-font-size;
  line-height: normal;

  .bw-current-info-top,
  .bw-current-info-bottom {
    display: flex;
    height: 50%;
  }

  .bw-current-info-top-left-title,
  .bw-current-info-bottom-left-title {
    flex: 1 1 auto;
    min-width: 0;
    margin-right: 20px;
  }

  .bw-current-info-top-right-title,
  .bw-current-info-bottom-right-title {
    flex: 1 0 auto;
    text-align: end;
    bottom: 2.3em;
    font-weight: 100;

    .bw-current-top-humidity {
      font-size: $weather-normal-font-size;
      font-weight: 400;

      .bw-text {
        display: inline-block;
        text-align: right;
        vertical-align: middle;
      }

      .bas-top-humidity-icon {
        display: inline-block;
        width: 20px;
        margin-right: 10px;
        vertical-align: middle;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .bw-current-info-top-left-title {
    @include defaults.font-roboto-light; }

  .bw-current-info-bottom-left-title {
    align-self: flex-end;
  }

  .bw-current-info-top-left-title,
  .bw-current-info-top-right-title,
  .bw-current-info-bottom-left-title,
  .bw-current-info-bottom-right-title {
    align-self: baseline;

    .bw-text {
      @include util.ellipsis-overflow();
    }
  }
}

.bw-temperature {
  display: inline-block;
  position: relative;
  width: 45%;
  margin-left: 10%;
  vertical-align: top;

  .bas-widget-text {
    @include util.absolute-center;

    font-size: $weather-big-font-size;
  }
}

@media (min-width: defaults.$media-phone-width) {
  .bw-current-main-top {
    justify-content: space-between;
  }

  .bw-fullscreen {
    .bw-temperature,
    .bw-current-icon {
      max-width: 10em;
    }
  }
}

.bas-widget-text-unit {
  font-size: 0.7em;
}

.bw-current-info-top-right-title,
.bw-current-info-bottom-right-title {
  font-size: $weather-small-font-size;
}

// Extra weather information elements
.bw-current-extra {
  margin-top: 0;
  margin-bottom: $swiper-pagination-margin;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-left: 0;
}

.bw-current-extra-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 0.8em;
  position: relative;
}

.bw-current-extra-element {
  padding: 0.5em;
  position: relative;
  background: defaults.$color-background-dark-transparent-light;
  font-size: 0.8em;
  margin-bottom: 3.5%;
  flex-basis: calc(#{100% / 3} - #{$margin-between});
  margin-left: $margin-between;

  @media (min-width: defaults.$screen-size-330) {
    font-size: 1em;
  }

  @media (min-width: defaults.$media-phone-width) {
    flex-basis: calc(#{100% / 6} - #{$margin-between});
    margin-bottom: 0;
    font-size: 0.9em;
  }

  @media (min-width: defaults.$media-query-tablet-landscape) and
    (orientation: landscape) {
    flex-basis: calc(#{100% / 3} - #{$margin-between});
    margin-bottom: 3.5%;
    font-size: 1em;

    @media (max-width: defaults.$media-query-pc-landscape) {
      font-size: 0.8em;
    }
  }
}

.bw-square-info-element {
  @include grid.aspect-ratio(1, 1);
}

.bw-square-info-element-content {
  @include util.absolute-match-parent;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .bw-square-info-element-icon {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bw-square-info-element-text-content {
    flex: 0 0 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.bw-current-extra-element-sun {
  .bw-square-info-element-text-content {
    flex-basis: 100%;
  }
}

.bas-chart-title {
  @include util.ellipsis-overflow();

  color: defaults.$color-text-light-transparent;
  text-align: center;
  font-size: 0.9em;
}

.bas-chart-text {
  font-size: 0.9em;
  text-align: center;
  font-weight: bold;
}

.bw-secondary-icon {
  height: 1.8em;
  max-height: 1.8em;
  width: 100%;
  margin: 0 auto;
  flex: 0 0 auto;
  position: relative;
  overflow: hidden;

  svg {
    @include util.absolute-center;
  }
}

.bw-secondary-icon-humidity {
  svg {
    height: 75%;
  }
}

.bw-secondary-icon-rain {
  transform: translate3d(0, -3px, 0);
}

.bw-secondary-icon-wind {
  transform-origin: 50% 45%;

  svg {
    height: 90%;
  }
}

.bw-secondary-icon-wind-gust {
  svg {
    height: 225%;
  }
}

.bw-secondary-icon-pressure {
  svg {
    height: 70%;
  }
}

.bw-current-sun {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .bw-current-sun-item {
    text-align: center;
  }
}

.bw-current-sun-icon {
  display: inline-block;
  height: 1.4em;
  width: 1.4em;
  margin-bottom: -0.23em;

  svg {
    width: 100%;
  }
}

$bw-hourly-mask-size: 5px;
$bw-hourly-mask: linear-gradient(
  to right,
  transparent 0,
  black $bw-hourly-mask-size,
  black calc(100% - #{$bw-hourly-mask-size}),
  transparent 100%
);

// Hourly forecast
.bw-hourly {
  background-color: defaults.$color-black-bg-trans;
  white-space: nowrap;
  max-width: 100%;
  padding: 1em 1em 0.65em;
  margin-bottom: 0;
  margin-top: $swiper-pagination-margin;
  font-size: 0.8em;
  position: relative;

  @media (min-width: defaults.$screen-size-330) {
    font-size: 1em;
  }
}

.bw-hourly .bas-browser-scroll-bar {
  padding: 0 0 1.15em;
  overflow-x: auto;
  mask: $bw-hourly-mask;
  scroll-snap-type: x;
  -webkit-overflow-scrolling: touch;
}

.bas-device-browser {
  .bw-hourly .bas-browser-scroll-bar {
    padding-bottom: 0.65em;
  }
}

.bw-hourly-item {
  display: inline-block;
  text-align: center;
  scroll-snap-align: start;
}

.bw-hourly-item-icon {
  height: 4em;
  margin: auto;

  svg {
    width: 100%;
    height: 100%;
  }
}

.bw-hourly-item-time {
  opacity: 0.7;
}

.bw-hourly-unavailable-text {
  display: none;
  align-items: center;
  height: 4em;
  justify-content: center;
  word-break: break-word;
  white-space: normal;
  text-align: center;
}

.bw-hourly-unavailable {
  .bas-browser-scroll-bar {
    display: none;
  }

  .bw-hourly-unavailable-text {
    display: flex;
  }
}

// Sideways scroll indication
.bw {
  .bas-scroll-indicator {
    svg {
      fill: defaults.$color-background-light-very-transparent;
    }
  }
}

// Downward scroll indication
.bw-scroll-indicator {
  margin: 0;
  padding: 0;

  @media (min-width: defaults.$media-phone-width) {
    display: none;
  }
}

// Daily forecast
.bw-forecast {
  margin-bottom: $margin-between;
  overflow: unset;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: defaults.$media-phone-width - 1) {
    padding-top: 0;
    margin-top: 0;
  }
}

.bw-forecast-unavailable-text {
  display: none;
}

.bw-forecast-unavailable {
  .bw-forecast-unavailable-text {
    display: flex;
    align-items: center;
    height: 4em;
    justify-content: center;
    word-break: break-word;
    white-space: normal;
    text-align: center;
  }
}

.bw-forecast-item {
  border: white 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: justify;
  align-items: center;
  flex: 1 1 auto;
  min-height: 4em;
  max-height: 6.5em;

  &:not(:last-of-type) {
    border-bottom: 1px solid defaults.$color-text-light-transparent;
  }
}

.bw-forecast-info-item {
  &.bw-forecast-wind,
  &.bw-forecast-uv {
    flex: 1 0 auto;
  }
}

.bw-forecast-icon-title {
  display: flex;
}

.bw-forecast-icon {
  min-width: 3em;
  height: 4.5em;
  margin: auto 0.4em auto -0.6em;
}

.bw-forecast-title {
  @include util.ellipsis-overflow();

  font-weight: bold;
  min-width: 6em;
  margin: auto;
}

.bw-forecast-wind {
  text-align: center;
}

.bw-wind-icon {
  display: inline-block;
  height: 1em;
  width: 1.5em;

  // In this case we do need to use margin and transform to correctly position
  //  this icon, because it is just too small
  margin-bottom: -0.1em;
  transform: scale(2.1);
}

.bw-forecast-wind-text {
  min-width: 5.5em;
  text-align: center;
  display: inline-block;
}

.bw-wind-direction-icon {
  display: inline-block;
  padding: 0.3em;
  transform-origin: 50% 45%;

  svg {
    height: 1em;
  }
}

.bw-forecast-uv {
  text-align: center;
}

.bw-forecast-sun {
  text-align: end;
  flex: 1 1 0;
}

.bw-forecast-sun-icon {
  display: inline-block;
  height: 1em;
  width: 1.2em;
  position: relative;
  margin-bottom: -0.09em;

  svg {
    @include util.absolute-center();

    height: 100%;
    width: 100%;
  }
}

.bw-forecast-temperatures {
  font-weight: bold;
  flex: 0 1 6em;
  text-align: center;
}

.bw-forecast-high {
  color: defaults.$color-foreground;
}

.bw-forecast-low {
  font-weight: bold;
  color: defaults.$color-text-light-transparent;
}

.bw-forecast-line-break {
  display: none;
}

// Wrapping to second line
@media (max-width: 592px) {
  .bw-forecast-item {
    min-height: 4em;
    height: unset;
    max-height: unset;
    font-size: 0.95em;
  }

  .bw-forecast-icon-title {
    order: 1;
  }

  .bw-forecast-uv {
    order: 3;
    text-align: end;
    color: defaults.$color-text-lighter;

    svg {
      fill: defaults.$color-text-lighter;
    }
  }

  .bw-forecast-temperatures {
    order: 2;
    flex: 1 1 3em;

    .bw-forecast-temperatures-item {
      display: inline-block;

      &.bw-forecast-high {
        padding-right: 0.5em;
      }
    }
  }

  .bw-forecast-line-break {
    order: 3;
    display: unset;
    flex-basis: 100%;
    padding: 0;
  }

  .bw-forecast-wind {
    order: 4;
    text-align: start;
    margin-bottom: 0.7em;
    color: defaults.$color-text-lighter;

    svg {
      fill: defaults.$color-text-light-secondary;
    }
  }

  .bw-forecast-sun {
    order: 4;
    padding: 0.3em 0;
    margin-bottom: 0.7em;
    color: defaults.$color-text-lighter;

    .bw-forecast-sun-item {
      display: inline-block;

      &.bw-forecast-sunrise {
        padding-right: 0.5em;
      }
    }

    svg {
      fill: defaults.$color-text-lighter;
    }
  }
}

// Landscape layout
@media (min-width: defaults.$media-tablet-landscape) and (orientation: landscape) {
  .bw {
    flex-direction: row;
    max-width: 1280px;
    height: 100%;
    max-height: 50em;
  }

  .bw-today {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none; // Needed for Firefox
  }

  // Main weather information
  .bw-current {
    flex: 1 0 auto;
  }

  // Hourly forecast
  .bw-hourly {
    margin-top: $margin-between;
    margin-bottom: $margin-between;
    flex: 0 0 auto;
  }

  // Daily forecast
  .bw-forecast {
    margin-left: 0 !important;
    width: 70%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
  }
}

// Never incomplete hourly items: portrait
@media (min-width: 768px) {
  .bw-hourly-item {
    width: math.percentage(math.div(1, 11));
  }
}

@media (max-width: 768px) {
  .bw-hourly-item {
    width: math.percentage(1*0.1);
  }
}

@media (max-width: 688px) {

  .bw-hourly-item {
    width: math.percentage(1*0.125);
  }
}

@media (max-width: 608px) {
  .bw-hourly-item {
    width: math.percentage(math.div(1, 7));
  }
}

@media (max-width: 528px) {
  .bw-hourly-item {
    width: math.percentage(math.div(1, 6));
  }
}

@media (max-width: 448px) {
  .bw-hourly-item {
    width: math.percentage(1*0.2);
  }
}

@media (max-width: 368px) {
  .bw-hourly-item {
    width: math.percentage(1*0.25);
  }
}

@media (max-width: 288px) {
  .bw-hourly-item {
    width: math.percentage(math.div(1, 3));
  }
}

@media (max-width: 224px) {
  .bw-hourly-item {
    width: math.percentage(1*0.5);
  }
}

// Never incomplete hourly items: landscape
@media (min-width: defaults.$media-tablet-landscape) and (orientation: landscape) {
  @media (min-width: 1281px) {
    .bw-hourly-item {
      width: math.percentage(math.div(1, 6));
    }
  }

  @media (max-width: 1280px) {

    .bw-hourly-item {
      width: math.percentage(1*0.2);
    }
  }

  @media (max-width: 1120px) {

    .bw-hourly-item {
      width: math.percentage(1*0.25);
    }
  }
}

// Wind-compass = wc
.bas-wc-direction {
  opacity: 0.7;
  font-size: 0.3em;
}

.bas-wc-top {
  font-size: 0.4em;
}

.bas-wc-bottom {
  font-size: 0.28em;
  opacity: 0.7;
}

.bas-wc g {
  transform-origin: center center;

  // SVG Alignment properties
  text-anchor: middle;
}

.bas-wc-hidden {
  // SVG Styling properties
  fill: none;
  fill-opacity: 0;
  stroke: none;
}
