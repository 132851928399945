@use 'sass:math';
@use 'defaults';
@use 'tiles_const';
@use 'bas_notch_const';
@use 'util';

// bml = bas-modal
// bmlc = bas-modal-center
// bmlcb = bas-modal-card-body
// brs = bas-room-setting
// tim = text-input-modal

$modal-center-min-width-phone: 90%;
$modal-center-max-width-phone: 90%;

$modal-icon-height: 30px;
$modal-icon-height-big: 75px;

$modal-title-margin: 0 0 1em 0;
$modal-text-margin: 0 0 3em 0;

$modal-error-type-font: 1em;
$modal-title-font: 1.5em;

$modal-manual-input-height: 2.2em;
$modal-manual-input-padding: 0 1em;

$modal-action-buttons-margin: 0 auto 0 auto;
$modal-action-buttons-margin-phone: auto auto 60px auto;

$modal-action-button-font: .9em;
$modal-action-button-margin: .4em 1em;
$modal-action-button-circle-margin: 0 auto 10px auto;
$modal-action-button-circle-diameter: 64px;

$modal-font-size: 1em;

$modal-items-width-phone: 95%;

$bip-option-padding: .5em;
$bip-option-margin: .35em;

.modal {
  @include util.absolute-match-parent();

  display: flex;
  justify-content: center;
  font-size: 1rem;
  z-index: 4;
  transform: translate3d(0, 0, 0);
}

.modal-bg {
  @include util.absolute-match-parent();

  background-color: defaults.$color-modal-background;
}

.splash-modal {
  z-index: 3;
}

.bas-template-modal {
  &.bas-modal-is-splash-modal {
    z-index: 3;
  }

  &.bas-modal-has-header-icon {
    .modal-header {
      display: block;
    }
  }

  &.bas-modal-has-title {
    .modal-body-title {
      display: block;
    }
  }

  &.bas-modal-has-subtitle {
    .modal-body-subtitle {
      display: block;
    }
  }

  &.bas-modal-has-extra-button {
    .modal-body-extra-option {
      display: block;
    }
  }

  &.bas-modal-has-button {
    .modal-action-button {
      display: inline-block;
    }
  }

  &.bas-modal-has-button-active {
    .modal-action-button-active {
      display: inline-block;
    }
  }

  &.bas-modal-button-active--disabled {
    .modal-action-button-active {

      > .modal-action-button-circle {
        border-color: defaults.$color-highlight-on-dark-darker;
        transition-duration: unset;

        > svg {
          fill: defaults.$color-highlight-on-dark-darker;
          transition-duration: unset;
        }

        &:active {
          border-color: defaults.$color-highlight-on-dark-darker;

          > svg {
            fill: defaults.$color-highlight-on-dark-darker;
          }
        }
      }

      .modal-action-button-text {
        color: defaults.$color-text-light;
        transition-duration: unset;
      }
    }
  }

  &.bas-modal-has-button.bas-modal-has-button-active {
    .modal-action-buttons {
      display: flex;
      justify-content: space-between;

      @media (max-width: #{defaults.$media-phone-width}) {
        justify-content: space-around;
      }

      &.action-buttons-space-around {
        justify-content: space-around;
      }
    }
  }
}

.modal-center {
  @include defaults.font-roboto-regular;

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  height: 100%;
  text-align: center;
  font-size: $modal-error-type-font;
  color: defaults.$color-foreground;
  fill: defaults.$color-foreground;

  &::after {
    content: '';
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    flex-basis: bas_notch_const.$notch-safe-bottom;
  }

  &::before {
    content: '';
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    flex-basis: bas_notch_const.$notch-safe-bottom;
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    min-width: $modal-center-min-width-phone;
    max-width: $modal-center-max-width-phone;

    &::after {
      content: '';
      flex-grow: 0;
    }

    &::before {
      content: '';
      flex-grow: 0;
    }
  }
}

.bas-message-modal {

  .modal-body {
    margin-top: 2em;
  }

  .modal-body-title {
    margin-bottom: 1em;
  }

  &.bas-modal-has-title {
    .modal-body-title {
      display: block;
    }
  }
}

.modal-center-text {
  text-align: center;
  line-height: 100vh;
}

$modal-border-spacing: 20px;

.modal-center-scroll {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  top: bas_notch_const.$notch-safe-top;
  bottom: bas_notch_const.$notch-safe-bottom;
  left: 0;
  right: 0;
  margin: auto;
  max-width: calc(100% - #{$modal-border-spacing});
  max-height: calc(100% - #{$modal-border-spacing});
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  line-height: normal;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    flex: 1;
  }

  .modal-card {
    pointer-events: initial;
  }
}

.modal-center-static {
  position: relative;
  display: inline-block;
  max-width: 90%;
  max-height: 90%;
  vertical-align: middle;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  line-height: normal;
}

.bmlc-medium {
  width: 400px;
}

.bmlc-large {
  width: 800px;
}

$modal-card-padding: 1.4em;

.modal-card {
  position: relative;
  display: block;
  width: 100%;
  background-color: defaults.$color-background-dark-fixed;
  border-radius: 10px;
  padding: $modal-card-padding;
  box-sizing: border-box;
  color: defaults.$color-foreground;

  .bmc-list-item {
    &.bmci-radio.bmci-title .bmci-name {
      color: defaults.$color-text-light-transparent;
    }

    &::before {
      content: none;
    }
  }

  .dsp-block-label,
  .bip-name {
    color: defaults.$color-foreground;
  }
}

@mixin modal-section () {
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
}

// Section fillers

.modal-section-filler-top {
  @media (max-width: #{defaults.$media-phone-width}) {
    flex: 5 5 auto;
  }
}

.modal-section-filler-middle {
  @media (max-width: #{defaults.$media-phone-width}) {
    flex: 4 4 auto;
  }
}

.modal-section-filler-bottom {
  @media (max-width: #{defaults.$media-phone-width}) {
    flex: 1 1 auto;
  }
}

// Header

.modal-header {
  @include modal-section();

  display: none;
  margin: $modal-title-margin;
  font-size: $modal-title-font;
}

.modal-header-icon {
  > svg {
    height: $modal-icon-height;
  }
}

.modal-header-icon-big {
  > svg {
    height: $modal-icon-height-big;
  }
}

// Body

$modal-body-spacing: 1em;

$modal-body-header-margin: 0 auto 2 * $modal-body-spacing auto;
$modal-body-title-margin: 0 auto $modal-body-spacing * 0.5 auto;
$modal-body-input-margin: 0 auto $modal-body-spacing auto;

$modal-body-extra-option-margin-value: .4em;
$modal-body-extra-option-margin: $modal-body-extra-option-margin-value 0 .2em 0;
$modal-body-extra-option-padding: $modal-body-spacing - $modal-body-extra-option-margin-value .4em;

.modal-body {
  @include defaults.font-roboto-light;

  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
  margin: $modal-text-margin;

  @media (max-width: #{defaults.$media-phone-width}) {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.modal-body-login {
  @include modal-section();
  @include defaults.font-roboto-light;

  @media (max-width: #{defaults.$media-phone-width}) {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@mixin modal-body-text() {
  @include defaults.font-roboto-light;

  position: static;
  display: none;
  color: defaults.$color-foreground;
}

.modal-body-header {
  margin: $modal-body-header-margin;
}

.modal-body-title {
  @include modal-body-text();
  @include defaults.font-roboto-regular;

  margin: $modal-body-title-margin;
  font-size: 1.5em;
}

.modal-body-subtitle {
  @include modal-body-text();

  color: defaults.$color-text-light-secondary;
}

.modal-body-message-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 3em;
  margin: $modal-body-header-margin;
}

.modal-body-message {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.bas-modal-spinner-show {
  .modal-action-button-active {
    .modal-action-button-circle {
      display: none;
    }
  }

  .modal-action-spinner-button {
    display: block;
  }

  .modal-spinner-wrapper {
    display: inline-block;
  }
}

.modal-card-settings-body,
.modal-card-body-footer {
  transition:
    filter defaults.$standard-animation-time,
    opacity defaults.$standard-animation-time;
}

.bas-modal-disabled {

  .modal-card-settings-body,
  .modal-card-body-footer .bs-btn-base {
    opacity: .8;
    pointer-events: none;
  }

  .bmci-radio-btn {
    border-color: defaults.$color-background-disabled;

    &::after {
      background-color: defaults.$color-background-disabled;
    }
  }

  .bs-btn-base {
    border-color: defaults.$color-background-disabled;
  }
}

.modal-body-input {
  @include defaults.font-roboto-regular;

  position: static;
  display: block;
  margin: $modal-body-input-margin;
  border-radius: 5em;
  height: $modal-manual-input-height;
  padding: $modal-manual-input-padding;
}

.modal-body-line {
  @include modal-body-text();

  display: block;
}

.modal-body-extra-option {
  @include modal-body-text();
  @include defaults.font-roboto-regular;

  margin: $modal-body-extra-option-margin;
  padding: $modal-body-extra-option-padding;
  color: defaults.$color-highlight-on-dark;

  &:active {
    color: defaults.$color-highlight-selected;
  }
}

// Action buttons

$modal-action-button-icon-size: 25%;
$modal-action-button-active-icon-size: 35%;
$modal-action-button-circle-border: 1px solid defaults.$color-foreground;

.modal-action-buttons {
  @include modal-section();

  display: block;
  min-width: defaults.$bs-min-modal-action-buttons-container-width;
  margin: $modal-action-buttons-margin;
}

@mixin modal-action-button () {
  display: none;
  position: relative;

  @media (max-width : #{defaults.$media-phone-width}) {
    margin: $modal-action-button-margin;
  }
}

@mixin modal-action-button-circle () {
  @include util.button-cursor();

  position: relative;
  display: block;
  margin: $modal-action-button-circle-margin;
  width: $modal-action-button-circle-diameter;
  height: $modal-action-button-circle-diameter;
  border-radius: 50%;
  border: $modal-action-button-circle-border;
  transition-duration: defaults.$standard-animation-time;

  > svg {
    @include util.absolute-center();

    width: $modal-action-button-icon-size;
    fill: defaults.$color-foreground;
  }

  &:active {
    border-color: defaults.$color-foreground-selected;
    transition-duration: unset;

    > svg {
      fill: defaults.$color-foreground-selected;
      transition-duration: unset;
    }
  }
}

@mixin modal-action-button-circle-active () {
  border-color: defaults.$color-highlight-on-dark;

  > svg {
    width: $modal-action-button-active-icon-size;
    fill: defaults.$color-highlight-on-dark;
  }

  &:active {
    border-color: defaults.$color-highlight-selected;

    > svg {
      fill: defaults.$color-highlight-selected;
    }
  }
}

.modal-action-spinner-button {
  @include modal-action-button-circle();
  @include modal-action-button-circle-active();

  display: none;
}

.modal-action-spinner {
  @include util.absolute-center();

  height: 40%;
  width: 100%;

  .bas-spinner-blade {
    background-color: defaults.$color-highlight-on-dark;
  }
}

.modal-spinner-wrapper {
  position: relative;
  display: none;
  width: 35px;
  height: 35px;
  vertical-align: bottom;

  .modal-spinner {
    @include util.absolute-center();

    width: 20px;
    height: 20px;

    .bas-spinner-blade {
      background-color: defaults.$color-highlight-on-light;
    }
  }
}

.modal-action-button {
  @include modal-action-button();
}

.modal-action-button-active {
  @include modal-action-button();

  > .modal-action-button-circle {
    @include modal-action-button-circle-active();
  }
}

.modal-action-button-circle {
  @include modal-action-button-circle();
}

.modal-action-button-text {
  @include defaults.font-roboto-regular;

  display: block;
  position: static;
  font-size: $modal-action-button-font;
  text-align: center;
  color: defaults.$color-light-control-buttons;
  transition-duration: defaults.$standard-animation-time;
}

.modal-hidden {
  display: none;
}

$modal-card-header-close-size: 40px;
$modal-card-header-align-left-spacing: 32px;
$modal-card-header-align-left-total: $modal-card-header-close-size + $modal-card-header-align-left-spacing;

.modal-card-header {
  width: 100%;
  margin-bottom: 1em;
  padding-bottom: .6em;
}

.modal-card-header-title-wrapper {
  display: inline-block;
  max-width: calc(100% - #{2 * $modal-card-header-close-size});
  flex-direction: column;

  &.modal-card-header-title-wrapper--align-left {
    display: inline-flex;
    text-align: left;
    margin-top: -8px;
    margin-left: $modal-card-header-align-left-spacing;
    width: calc(100% - #{$modal-card-header-align-left-total});
    max-width: calc(100% - #{$modal-card-header-align-left-total});
  }
}

.modal-card-header-title {
  @include defaults.font-roboto-regular;

  position: static;
  display: inline;
  font-size: 1.4em;
  color: defaults.$color-foreground;
  font-weight: bold;

  .modal-card-header-title-separator {
    background-color: defaults.$color-highlight-on-light;
    margin: 0 .05em;
    display: inline-block;
    width: 1px;
    height: .4em;
    font-size: 1.8em;
  }

  .modal-card-header-title-sub {
    color: defaults.$color-background-light-more-transparent;
    font-weight: normal;
  }
}

.modal-card-header-subtitle {
  @include defaults.font-roboto-regular;
  @include util.ellipsis-overflow;

  position: static;
  display: inline;
  font-size: .9em;
  color: defaults.$color-text-light-transparent;
}

.modal-card-header-title-hidden {
  display: none;
}

.modal-card-header-close {
  position: absolute;
  display: block;
  left: $modal-card-padding;
  top: $modal-card-padding - .4em;
  width: $modal-card-header-close-size;
  height: $modal-card-header-close-size;

  > svg {
    @include util.absolute-center();

    width: 80%;
    fill: defaults.$color-library-control;
    transition: fill defaults.$active-animation-time;
  }

  &:active {

    > svg {
      transition: fill 0s;
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

.modal-card-header-icon-right {
  left: auto;
  right: $modal-card-padding;
}

.modal-card-body {
  position: relative;
  display: block;
}

.bmlc-text-left {
  text-align: left;
}

.modal-card-body-section {
  margin-bottom: 1.4em;

  &:last-child {
    margin-bottom: 0;
  }
}

.modal-card-generic-control {
  .grsttv {
    display: none;
  }
}

// mcbs = modal-card-body-section

.mcbs-title {
  @include defaults.font-roboto-regular;

  font-size: 1.2em;
}

.mcbs-label-text-entry {
  margin-bottom: .6em;

  .mcbs-title {
    display: block;
    font-size: 1em;
    margin-left: .4em;
    margin-bottom: .4em;
    text-align: left;
  }

  .bs-input {
    width: 100%;
    margin-bottom: 1em;
  }
}

$modal-card-border-margin: .8em;

.modal-card-settings-body {
  padding-bottom: $modal-card-border-margin;
  margin-bottom: $modal-card-border-margin;
}

.modal-card-body-compact {
  .modal-card-body-section:not(:last-child) {
    margin-bottom: $modal-card-border-margin;
  }

  .modal-card-settings-body {
    padding-bottom: $modal-card-border-margin * 0.5;
    margin-bottom: $modal-card-border-margin * 0.5;
  }
}

.modal-card-body-footer {
  text-align: right;
}

.mcbf-entry {
  display: inline-block;
}

.mcbf-button {
  background-color: transparent;
}

.mcbf-text-message {
  @include defaults.font-roboto-light;

  font-size: .8em;
  color: defaults.$color-accent-red;
  margin-right: .4em;
}

$keypad-button-size: 54px;
$keypad-spacing: 18px;
$keypad-backspace-touch-expand: 20px;
$keypad-backspace-size: 35px;
$keypad-spacing: 25px;
$keypad-total-width: #{3 * $keypad-button-size + 2 * $keypad-spacing};

.modal-card-value {
  display: flex;
  color: defaults.$color-text-dark;
  padding: 5px 5px 5px 15px;
  background-color: defaults.$color-foreground;
  border-radius: 9999px;
  width: $keypad-total-width;
  box-sizing: border-box;
  margin: 0 auto #{$keypad-spacing * .5};
  transition: box-shadow .1s;

  .modal-card-value-number {
    @include defaults.font-roboto-regular;
    @include util.empty-no-collapse;

    font-size: 2em;
    flex-grow: 1;
    text-align: right;
    overflow: hidden;
  }

  .modal-card-value-backspace {
    padding: $keypad-backspace-touch-expand;
    margin: -$keypad-backspace-touch-expand;
    flex: 0 0 $keypad-backspace-size;
    height: $keypad-backspace-size;
    cursor: pointer;

    .modal-card-value-backspace-inner {
      position: relative;
      width: 100%;
      height: 100%;

      svg {
        @include util.absolute-center;

        fill: defaults.$color-background-shadow-light;
        transition: fill defaults.$standard-animation-time;
        width: 50%;
      }
    }

    &:active svg {
      transition: none;
      fill: defaults.$color-background-shadow-dark;
    }
  }

  @media (min-height: defaults.$screen-size-600) {
    margin: 5px auto #{$keypad-spacing * .5};
  }
}

.modal-card-value-error {
  @include defaults.font-roboto-regular;
  @include util.empty-no-collapse;

  color: defaults.$color-red-light;
  text-align: center;
  padding: 3px 0;
  transition: opacity defaults.$standard-animation-time;
}

.modal-card-keypad {
  display: flex;
  flex-wrap: wrap;
  width: #{$keypad-button-size * 3 + 2 * $keypad-spacing};
  margin: #{$keypad-spacing * .5} auto 0;

  .modal-card-keypad-button {
    @include defaults.font-roboto-regular;

    cursor: pointer;
    height: $keypad-button-size;
    width: $keypad-button-size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    border: solid 1px defaults.$color-background-shadow-dark;
    transition: border-color defaults.$standard-animation-time, fill defaults.$standard-animation-time;
    position: relative;
    box-sizing: border-box;
    margin: 0 $keypad-spacing $keypad-spacing 0;

    svg {
      transition: fill defaults.$standard-animation-time;
    }

    &:active {
      border-color: defaults.$color-highlight-on-dark;
      transition: none;

      svg {
        fill: defaults.$color-highlight-on-dark;
        transition: none;
      }
    }

    // Remove right margin for every third child (right side)
    &:nth-child(3n) {
      margin-right: 0;
    }

    // Remove bottom margin for bottom row
    &:nth-last-child(-n+3) {
      margin-bottom: 0;
    }
  }

  .modal-card-keypad-button-comma,
  .modal-card-keypad-button-sign {
    visibility: hidden;
  }
}

.modal-card-value-bounds {
  display: none;
}

// Input-modal specific
.im-show-comma {

  .modal-card-keypad-button-comma {
    visibility: visible;
  }
}

.im-show-sign {

  .modal-card-keypad-button-sign {
    visibility: visible;
  }
}

.im-hide-error {

  .mcbf-text-message {
    display: none;
  }
}

.im-input-invalid {
  .tim-button {
    opacity: 0.5;
    pointer-events: none;
  }
}

.im-show-bounds {
  .modal-card-value-bounds {
    display: flex;
  }
}

.bas-progress {
  display: none;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.im-show-circle-progress {
  .modal-card-value,
  .modal-card-keypad {
    display: none;
  }

  .bas-progress {
    display: block;

    .bas-progress-text-secondary {
      font-size: 2px;
    }
  }
}

.modal-dismiss-button {
  display: none;
}

.im-show-cancel-button {
  display: block;

}

.bas-multi-modal-screen-container {
  display: flex;
  height: 25em;
  transition: height defaults.$standard-animation-time ease-in-out;
}

.bas-multi-modal-screen {
  flex: 0 0 100%;
  overflow-y: auto;
  align-self: start;
  box-sizing: border-box;
  transition: transform defaults.$standard-animation-time ease-in-out;
  max-height: 100%;
  mask: linear-gradient(to bottom, transparent 0% , black 10px, black calc(100% - 10px));
}

.bas-multi-modal-screen-inner {
  .bmc-list {
    padding: 1em 0 1.4em;
  }
}

.bas-multi-modal-save,
.bas-multi-modal-next,
.bas-multi-modal-previous {
  opacity: 0.5;
  display: none;

  &:active {
    color: defaults.$color-highlight-on-dark;
  }
}

.bas-multi-modal-save {
  display: none;
}

.bas-multi-modal-option {

  .bas-multi-modal-option-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;

    &-svg {
      height: 100%;
    }

    svg {
      @include util.absolute-center();

      width: 65%;
      fill: defaults.$color-text-light-transparent;
    }
  }

  // Lines at top instead of bottom -> this way we can hide the separator above
  //  and underneath in css

  &::before {
    content: none;
  }
}

// 79px: : +- space taken up by modal header (modal card top padding,
//  title height, title bottom padding and title bottom margin)
$modal-header-height: 79px;

.bas-multi-modal {

  &.bas-multi-modal-has-previous {
    .bas-multi-modal-previous {
      display: inline-block;
    }
  }

  &.bas-multi-modal-has-next {
    .bas-multi-modal-next {
      display: inline-block;
    }
  }

  &.bas-multi-modal-has-save {
    .bas-multi-modal-save {
      display: inline-block;
    }
  }

  &.bas-multi-modal-can-previous {
    .bas-multi-modal-previous {
      opacity: 1;
    }
  }

  &.bas-multi-modal-can-next {
    .bas-multi-modal-next {
      opacity: 1;
    }
  }

  &.bas-multi-modal-can-save {
    .bas-multi-modal-save {
      opacity: 1;
    }
  }

  &:not(bas-multi-modal-has-bottom-buttons) {
    .modal-card-settings-body {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  &.bas-multi-modal-has-bottom-buttons {

    .bas-multi-modal-screen {
      mask: linear-gradient(to bottom, transparent 0% , black 10px, black calc(100% - 10px), transparent 100%);
    }
  }

  &.bas-multi-modal-has-save.bas-multi-modal- {
    .bas-multi-modal-save {
      display: none;
    }

    .modal-card-settings-body {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  &.bas-multi-modal-full-height {

    .modal-card-body {
      margin-top: -1em;
      margin-bottom: -1.4em;
    }

    &.bas-multi-modal-has-bottom-buttons {
      .modal-card-body {
        margin-bottom: 0;
      }

      .modal-card-settings-body {
        padding-bottom: 0;
        margin-bottom: $modal-card-border-margin;
      }
    }
  }

  &.bas-multi-modal-use-dynamic-height {
    .bas-multi-modal-screen-container {
      height: unset;
    }
  }

  &.bas-multi-modal-pre-render {

    .bas-multi-modal-screen-container {
      height: auto;
      // 75vh: complete modal should at max take up 75% of vertical viewport
      max-height: calc(75vh - #{$modal-header-height});

      .bas-multi-modal-screen:not(:first-child) {
        display: none;
      }
    }
  }

  .modal-card-body {
    text-align: left;
    overflow: hidden;
  }
}

.bas-device-lisa {
  .bas-multi-modal.bas-multi-modal-pre-render {
    .bas-multi-modal-screen-container {
      max-height: calc(100vh - #{$modal-header-height});
    }
  }
}

// Time-input-modal specific
.tim-time {
  @include defaults.font-roboto-regular();

  display: flex;
  justify-content: center;
  font-size: 1.8em;

  &.ampm-before {
    .tim-time-element-am-pm {
      order: -1;
    }
  }

  .tim-time-colon {
    display: flex;
    align-items: center;
  }

  .tim-time-element {
    width: 3em;

    &.tim-time-element-hour {
      margin-right: -5px;
    }

    &.tim-time-element-minutes {
      margin-left: -5px;
    }

    .tim-time-increase,
    .tim-time-decrease {
      width: 100%;
      height: 40px;
      position: relative;

      svg {
        @include util.absolute-center();

        height: 80%;
        fill: defaults.$color-text-light;
        transition: fill defaults.$standard-animation-time;
      }

      &:active {
        svg {
          fill: defaults.$color-highlight-on-light;
          transition: none;
        }
      }
    }

    .tim-time-label {
      color: defaults.$color-foreground;
    }
  }

  &.tim-time-24hr-clock {

    &.tim-time-element-am-pm {
      display: none;
    }
  }
}

// AV-Modal specific

.modal-avselect {

  .modal-card-header {
    padding-bottom: 0;
    border-bottom: none;
  }

  .modal-card-body-section {
    display: flex;
    flex-direction: column;
  }

  .modal-source-preview {
    margin: 0.5em 0 1em;
    background-color: defaults.$color-background-shadow-dark;
    display: flex;

    .modal-source-preview-cover {
      position: relative;
      height: 78px;
      flex: 0 0 78px;
      background-color: defaults.$color-background-active;
      border-right: 2px solid transparent;
    }

    .modal-source-preview-info {
      @include defaults.font-roboto-regular();

      flex: 1 1 auto;
      width: 0;
      padding: 1em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: defaults.$color-foreground;

      .modal-source-preview-info-title {
        @include util.ellipsis-overflow();

        font-weight: bold;
        max-width: 100%;
      }

      .modal-source-preview-info-artist {
        @include util.ellipsis-overflow();

        font-size: .8em;
        color: defaults.$color-dark-control-buttons-disabled;
        max-width: 100%;
      }
    }

    &.modal-source-preview--unavailable {

      .modal-source-preview-info-title,
      .modal-source-preview-info-artist {
        color: defaults.$color-foreground-selected;
      }
    }
  }

  .modal-room-select {
    padding: .5em 0;
    max-height: calc(100vh - 25em);
    overflow: auto;
    mask: linear-gradient(to bottom, transparent 0%, black 10px, black calc(100% - 10px), transparent 100%);
  }

  .modal-card {
    max-height: 100%;
  }

  .modal-card-settings-body {
    padding-bottom: 0;
  }

  .modal-avselect-leave,
  .modal-avselect-disband {
    display: none;
  }

  &.modal-avselect-can-leave {

    .modal-avselect-leave {
      display: inline-block;
    }
  }

  &.modal-avselect-can-disband {

    .modal-avselect-disband {
      display: inline-block;
    }
  }
}

$color-picker-size: 300px;

.modal-card-color-picker {
  position: relative;
  height: $color-picker-size;
  width: $color-picker-size;
  margin: 0 auto;

  // stylelint-disable-next-line selector-class-pattern
  .IroWheelHue {
    // Kinda hacky, can break on Iro updates
    box-shadow: 0 0 24px defaults.$color-black-trans-10;
  }
}

$color-picker-temperature-preview-size: 80px;

.modal-card-color-temperature-preview {
  border-radius: 9999px;
  margin: 32px auto 24px;
  width: $color-picker-temperature-preview-size;
  height: $color-picker-temperature-preview-size;
  box-shadow: 0 0 10px defaults.$color-black-trans-10;
}

.modal-card-position,
.modal-card-rotation,
.modal-card-brightness,
.modal-card-color-mode,
.modal-card-color-temp,
.modal-card-color-white,
.modal-card-color,
.modal-card-height {
  display: none;
}

.bsd-position-has {
  .modal-card-position {
    display: block;
  }

  &.bsd-rotation-has {
    .modal-card-position {
      margin-bottom: 1.4em;
    }
  }
}

.bsd-rotation-has {
  .modal-card-rotation {
    display: block;
  }
}

.brs-show-color {
  .modal-card-brightness,
  .modal-card-color {
    display: block;
  }
}

.brs-show-temp {
  .modal-card-brightness,
  .modal-card-color-temp {
    display: block;
  }
}

.brs-show-white {
  .modal-card-color-white {
    display: block;
  }
}

.brs-show-white-brightness {
  .modal-card-color-white {
    display: none;
  }

  .modal-card-brightness {
    display: block;
  }
}

.modal-card-color-label,
.modal-card-color-temperature-label {
  margin-bottom: 10px;
}

.brs-show-mode {
  .modal-card-color-mode {
    display: block;
  }

  .modal-card-color-temperature-preview {
    margin-top: 16px;
  }

  .modal-card-color-label,
  .modal-card-color-temperature-label {
    display: none;
  }
}

// DSP

.dsp-section-startup {
  display: none;
  padding-bottom: 0;

  &:last-child {
    padding-bottom: 0;
    border: none;
  }
}

.dsp-section-stereo-widening {
  display: none;
}

$dsp-block-outer-spacing-md: 1em;
$dsp-block-outer-spacing-lg: 2em;

$dsp-arrow-icon-width: 30px;

$dsp-equaliser-height: 280px;
$dsp-equaliser-bottom-spacing: 20px;

$dsp-slider-width: 3px;
$dsp-slider-ticks-width: 2px;

.dsp-block {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: calc(100% - #{2 * $dsp-block-outer-spacing-md});
  margin: 0 $dsp-block-outer-spacing-md;
  text-align: left;

  @media (max-width : #{defaults.$media-phone-width}) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
}

.dsp-block-label {
  @include defaults.font-roboto-regular;

  position: static;
  display: block;
  color: defaults.$color-text-dark;
}

.dsp-block-slider-container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.dsp-block-slider {
  flex: 1 1 auto;

  .grshor .grstr {
    height: $dsp-slider-width;
  }

  .grshor .grsttl {
    width: $dsp-slider-ticks-width;
  }

  .grstr,
  .grshor .grsttl {
    background-color: defaults.$color-grey;
  }
}

.dsp-block-slider-value {
  @include defaults.font-roboto-light;

  flex: 0 0 auto;
  width: $dsp-block-outer-spacing-lg;
  margin-left: .8em;
  text-align: end;
  vertical-align: middle;
}

.dsp-block-slider-value-center {
  @include defaults.font-roboto-light;

  width: 100%;
  text-align: center;
}

.dsp-block-slider-value-type {
  @include defaults.font-roboto-light;
  @include util.ellipsis-overflow;

  flex: 0 0 auto;
  max-width: 4em;
  margin-left: .2em;
  vertical-align: middle;
}

.dsp-block--on-dark {

  .dsp-block-label,
  .dsp-block-slider-value {
    color: defaults.$color-foreground;
  }
}

.dsp-treble,
.dsp-bass,
.dsp-startup {
  display: none;
}

.room-settings-body {
  &.bas-room--music--has-treble {
    .dsp-treble {
      display: inline-block;
    }
  }

  &.bas-room--music--has-bass {
    .dsp-bass {
      display: inline-block;
    }
  }

  &.bas-room--music--has-startup-volume {

    .dsp-section-startup {
      display: block;
    }

    .dsp-startup {
      display: inline-block;
    }
  }

  &.bas-room--music--has-stereo-widening {

    .dsp-section-stereo-widening {
      display: block;
    }

    .dsp-stereo-widening {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.room-settings-dsp {
  display: flex;
}

.dsp-section-equalisers {

  .dsp-block-slider-container {
    flex-direction: column;

    .dsp-block-slider {
      height: $dsp-equaliser-height;
      padding-top: $dsp-block-outer-spacing-md;
      padding-bottom: $dsp-block-outer-spacing-md;

      .grstr {
        width: $dsp-slider-width;
        background-color: defaults.$color-grey;
      }
    }

    .dsp-block-slider-value {
      margin-left: 0;
      font-size: $modal-font-size;
      color: defaults.$color-background-shadow-light;
      text-align: center;
      width: 100%;
    }
  }
}

.dsp-modal {
  mask: none;
  overflow: hidden;
}

.dsp-modal-can-next {
  .dsp-modal {
    margin-right: $dsp-block-outer-spacing-lg;
  }
}

.dsp-modal-can-previous {
  .dsp-modal {
    margin-right: 0;
    padding-right: $dsp-block-outer-spacing-lg;
  }

  .dsp-equaliser-modal {
    padding-right: $dsp-block-outer-spacing-lg;
  }
}

.dsp-modal-can-next.dsp-modal-can-previous {
  .dsp-equaliser-modal {
    padding-right: 0;
    margin-right: $dsp-block-outer-spacing-lg;
  }
}

.dsp-modal-show-eq {
  display: flex;
  padding-bottom: $dsp-block-outer-spacing-lg;
  border-bottom: 1px solid defaults.$color-text-lighter-inverse;

  .dsp-block-label {
    transition-duration: defaults.$active-animation-time;
  }

  .dsp-modal-show-eq-arrow-icon {
    width: $dsp-arrow-icon-width;
    height: $dsp-arrow-icon-width;

    svg {
      width: 100%;
      fill: defaults.$color-library-control;
      transition: fill defaults.$active-animation-time;
    }
  }
}

.dsp-equaliser-modal {
  overflow: hidden;

  .dsp-header-equaliser {

    .bbs-flex {
      padding-left: $dsp-block-outer-spacing-md;
      padding-right: $dsp-block-outer-spacing-md;
    }

    .bbs-name-title {
      transition-duration: defaults.$active-animation-time;
    }

    .bbs-name-subtext {
      color: defaults.$color-background-shadow-light;
    }

    .bbs-arrow {
      flex-basis: $dsp-arrow-icon-width;

      svg {
        width: 100%;
        fill: defaults.$color-library-control;
        transition-duration: defaults.$active-animation-time;
      }
    }
  }

  .dsp-frequency-legend {
    @include defaults.font-roboto-light;

    color: defaults.$color-background-shadow-light;
    padding-top: $dsp-block-outer-spacing-md;
  }
}

.dsp-modal-navigation:active {

  .dsp-block-label,
  .bbs-name-title {
    color: defaults.$color-highlight-on-dark;
    transition-duration: 0s;
  }

  .dsp-modal-show-eq-arrow-icon > svg,
  .bbs-flex .bbs-arrow > svg {
    transition: fill 0s;
    fill: defaults.$color-highlight-on-dark;
  }
}

// Server status

.bmlcb-title {
  @include defaults.font-roboto-regular;

  font-size: 1.2em;
  margin-bottom: .6em;
}

$bmlcb-outer-spacing: 10px;

.bmlcbc-list {
  margin-left: $bmlcb-outer-spacing;
  list-style-type: none;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.bas-error {
  margin-bottom: .6em;

  &:last-child {
    margin-bottom: 0;
  }
}

.bas-error-comp {
  @include defaults.font-roboto-regular;

  font-size: .9em;
  color: defaults.$color-foreground;
}

.bas-error-value {
  @include defaults.font-roboto-light;

  font-size: .8em;
  color: defaults.$color-foreground;
}

.bml-ok {
  @include defaults.font-roboto-light;

  margin-left: $bmlcb-outer-spacing;
}

// Scene picker and custom image picker

$bsp-favourite-section-size-p: math.div(2* 100%, 3);
$bsp-favourite-section-size: 50%;
$bsp-favourite-section-size-l: math.div(100%, 3);
$bsp-favourite-scene-margin: 10px;
$bsp-favourite-scene-margin-p: $bsp-favourite-scene-margin *0.5;

.bsp-favourite-section {
  width: $bsp-favourite-section-size-p;
  margin-left: (100% - $bsp-favourite-section-size-p) *0.5;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: $bsp-favourite-section-size;
    margin-left: (100% - $bsp-favourite-section-size) *0.5;
  }

  @media (min-width: #{defaults.$media-phone-width}) and (orientation: landscape) {
    width: $bsp-favourite-section-size-l;
    margin-left: (100% - $bsp-favourite-section-size-l) *0.5;
  }
}

$sp-favourite-box-shadow: 0 0 0 $bsp-favourite-scene-margin-p defaults.$color-highlight-muted;

.bas-image-picker {

  .bip-arrow,
  .bip-new-image,
  .bip-image-button-options-wrapper,
  .bip-camera-warning,
  .bip-library-warning {
    display: none;
  }

  &.bas-modal-has-two-options {
    .bip-image-options-wrapper {
      height: #{(2 * ($bip-option-margin + $bip-option-padding) + 1em) * 2};
    }
  }

  &.bas-modal-has-three-options {
    .bip-image-options-wrapper {
      height: #{(2 * ($bip-option-margin + $bip-option-padding) + 1em) * 3};
    }
  }

  &.bas-modal-no-camera {
    .bip-camera-pictures {
      display: none;
    }
  }

  &.bas-modal-no-clear {
    .bip-clear-image {
      display: none;
    }
  }

  &.bas-modal-has-new-image {
    .bip-arrow {
      display: block;
    }

    .bip-image-button-options-wrapper {
      display: flex;
    }

    .bip-new-image {
      display: inline-block;
    }

    .bip-clear-image,
    .bip-camera-pictures,
    .bip-library-pictures {
      display: none;
    }
  }

  &.bas-modal-show-camera-warning {
    .bip-camera-warning {
      display: block;
    }
  }

  &.bas-modal-show-library-warning {
    .bip-library-warning {
      display: block;
    }
  }
}

.bip-library-pictures {

  .input {
    display: none;
  }
}

.bsp-scene-tile,
.bip-image-tile {
  position: relative;
  display: inline-block;
  margin: $bsp-favourite-scene-margin-p;
  background-color: defaults.$color-background-active;
  box-shadow: tiles_const.$bas-tile-box-shadow-p;

  @media (min-width: #{defaults.$media-phone-width}) {
    box-shadow: tiles_const.$bas-tile-box-shadow;
  }
}

.bip-image-tile {
  display: block;
}

.bsp-favourite-scene {
  width: calc(50% - #{2 * $bsp-favourite-scene-margin-p});
  padding-bottom: calc(50% - #{2 * $bsp-favourite-scene-margin-p});

  @media (min-width: #{defaults.$media-phone-width}) {
    width: calc(50% - #{2 * $bsp-favourite-scene-margin});
    padding-bottom: calc(50% - #{2 * $bsp-favourite-scene-margin});
    margin: $bsp-favourite-scene-margin;
  }

  &.bsp-current-favourite {
    box-shadow: $sp-favourite-box-shadow;
  }

  .bas-tile-title {
    font-size: 1em;
  }
}

.bsp-scene-wrapper,
.bip-image-wrapper {
  white-space: nowrap;
  overflow-x: auto;
  height: $bsp-favourite-section-size-p * 0.5;
  scroll-snap-type: x;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5em;
  margin-bottom: 0;
}

.bsp-scene-wrapper {
  @media (min-width: #{defaults.$media-phone-width}) {
    height: $bsp-favourite-section-size * 0.5;
  }

  @media (min-width: #{defaults.$media-phone-width}) and (orientation: landscape) {
    height: $bsp-favourite-section-size-l * 0.5;
  }
}

.bsp-scene-item-wrapper {
  display: inline-block;
  scroll-snap-align: center;
  width: 33.33333%;

  @media (min-width: #{defaults.$media-phone-width}) {
    scroll-snap-align: start;
    width: 25%;
  }

  @media (min-width: #{defaults.$media-phone-width}) and (orientation: landscape) {
    width: 16.66666%;
  }
}

.bip-center-wrapper {
  display: flex;
  justify-content: center;
}

.bip-image-item-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2em;
}

$bsp-favourite-scene-dimension: calc(100% - #{$bsp-favourite-scene-margin});
$bsp-favourite-scene-dimension-l: calc(100% - #{2 * $bsp-favourite-scene-margin});

$bip-image-dimension: calc(48% - #{$bsp-favourite-scene-margin});
$bip-image-dimension-l: calc(48% - #{2 * $bsp-favourite-scene-margin});

.bsp-scene,
.bip-image {
  width: $bsp-favourite-scene-dimension;
  padding-bottom: $bsp-favourite-scene-dimension;
  overflow: hidden;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: $bsp-favourite-scene-dimension-l;
    padding-bottom: $bsp-favourite-scene-dimension-l;
  }

  .bas-tile-title {
    font-size: 1em;
  }
}

.bip-image {
  width: $bip-image-dimension;
  padding-bottom: $bip-image-dimension;
  overflow: hidden;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: $bip-image-dimension-l;
    padding-bottom: $bip-image-dimension-l;
  }

  .bas-tile-title {
    font-size: 1em;
  }

  .bas-image {

    svg {
      top: 50%;
    }
  }
}

.bsp-icon,
.bip-icon {
  @include util.absolute-match-parent();

  svg {
    @include util.absolute-center();

    width: 40%;
    fill: defaults.$color-foreground-dark;
  }
}

.modal {
  .bas-scroll-indicator {
    svg {
      fill: defaults.$color-library-control;
    }
  }
}

.bip-image-options-wrapper {
  display: block;
  width: 100%;
  height: #{(2 * ($bip-option-margin + $bip-option-padding) + 1em) * 4};

  .bip-image-option {
    @include util.button-cursor();

    display: flex;
    position: relative;
    align-items: center;
    padding: $bip-option-padding 0;

    .bip-arrow-indicator {
      width: 30px;
      height: 30px;

      > svg {
        fill: defaults.$color-library-control;
        transition: fill 0s;
      }
    }

    &:active {

      .bip-arrow-indicator {

        > svg {
          fill: defaults.$color-highlight-on-dark;
          transition: fill 0s;
        }
      }
    }
  }

  .bip-top-border {
    border-top: 1px solid defaults.$color-background-shadow-dark;
  }

  .bip-image-button-options-wrapper {
    justify-content: flex-end;
    align-items: center;
  }
}

.bip-name {
  @include defaults.font-roboto-light;

  margin: $bip-option-margin 0;
  color: defaults.$color-background;
  transition: color defaults.$standard-animation-time;
}

.bip-name-flex {
  flex: 1 1 auto;
  text-align: left;
}

.bas-file-selector {
  position: absolute;
  width: 100%;
  height: 100%;

  .bas-file-input {
    @include util.absolute-match-parent();
    @include util.button-cursor();

    opacity: 0;
  }
}

.bip-arrow {
  position: relative;
  display: block;
  width: 25px;
  height: 25px;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-library-control;
    transition: fill defaults.$active-animation-time;
    transform: translate(-50%, -50%) rotate(180deg);
  }
}

.bip-state {
  position: relative;
  width: 100%;
  height: 2em;

  .bip-name {
    font-size: 1em;
    color: defaults.$color-background-shadow;
  }
}

.bip-warning {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;

  > svg {
    @include util.absolute-center();

    width: 60%;
    fill: defaults.$color-highlight-on-light;
    transition: fill defaults.$active-animation-time;
    transform: translate(-50%, -50%);
  }
}

.bip-decline-option {

  .bip-action-button-circle {
    border-color: defaults.$color-background-shadow;

    > svg {
      fill: defaults.$color-background-shadow;
    }
  }

  &.bas-modal-has-button-disabled {
    .bip-action-button-circle {
      border-color: defaults.$color-background-shadow-light;

      > svg {
        fill: defaults.$color-background-shadow-light;
      }
    }
  }
}

.bip-button-circle {

  > svg {
    width: 30%;
  }
}

.bas-file-drop-zone-active {
  outline: 2px dashed defaults.$color-background-active;
  outline-offset: 10px;
}
