@use 'defaults';
@use 'bas_notch_const';
@use 'basstyle_const';
@use 'util';

// bls = bas loading spinner
// bb = bas basic
// bbc = bas basic collection
// bbs = bas basic setting
// bbsi = bas basic setting item

$bb-outer-spacing: 16px;
$bb-inner-spacing: 10px;
$bb-inner-spacing-small: 6px;

$bls-size: 36px;

$bbs-types-padding: auto;
$bbs-types-max-width: 250px;
$bbs-indentation-padding: 26px;

$bbs-flex-height: 2.9rem;
$bbs-small-height: 2rem;

$bls-spinner-color: #aaa;

.bas-settings-drawer {

  .bas-settings-exclamation {
    display: none;
  }

  &.bas-settings-has-update {

    .bas-settings-about {

      .bas-settings-exclamation {
        display: block;
      }
    }
  }
}

.bas-settings-content,
.bas-settings-drawer {

  &.bas-settings-device-only {

    .bas-non-device-setting {
      display: none;
    }
  }
}

.bas-settings-exclamation {

  svg {
    width: 16px;
  }
}

.bas-settings-link {
  color: defaults.$color-text-dark;

  &:link,
  &:visited,
  &:focus {
    color: defaults.$color-text-dark;
  }

  &:hover {
    color: defaults.$color-text-black;
  }

  &:active {
    color: defaults.$color-text-black;
  }
}

.bas-settings-content {

  &.bgs-single-room {
    .bbs-non-single-room-setting {
      display: none;
    }
  }

  .bs-title-line-txt {
    font-size: 1.2em;
  }
}

//embedded settings view
.bas-project-settings {

  .bas-room-image-settings {
    margin: 16px;

    .bip-image-item-wrapper,
    .modal-action-buttons {
      max-width: defaults.$media-phone-width;
    }
  }

  .bs-title-line-txt {
    @media (max-width: #{defaults.$media-phone-width}) {
      font-size: basstyle_const.$bs-title-line-font-size;
    }
  }

  // disable activity light
  .bas-tile {
    font-size: 14px;

    .bas-tile-bg-overlay {
      display: none;
    }

    .bas-tile-activity-light {
      display: none;
    }
  }
}

.bls {
  position: relative;
  width: $bls-size;
  height: $bls-size;

  .bas-spinner-swoop .bas-spinner-swoop-circle .bas-spinner-swoop-circle-inner {
    border-top-color: $bls-spinner-color;
    border-left-color: $bls-spinner-color;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
}

.bls-center {
  @include util.absolute-center();
}

.bbs {
  display: block;
  margin-top: $bb-outer-spacing;
  margin-left: $bb-outer-spacing;
  margin-right: $bb-outer-spacing;
  min-height: basstyle_const.$bas-button-toggle-total-height;

  &.bbs-centered {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: $bb-outer-spacing*0.5 $bb-outer-spacing $bb-outer-spacing*0.5 $bb-outer-spacing;
  }

  &.bbs-extra-small {
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.bbs-active-select {
    transition: background-color 0.5s, opacity 0.5s;

    &:active {
      background-color: defaults.$color-background-active-transparent;
      transition: background-color 0s, opacity 0.5s;
    }
  }

  &.bbs-action {

    .bbs-name,
    .bbs-name .bbs-name-subtext {
      color: defaults.$color-accent-red;
    }

    &.bbs-disabled {
      .bbs-name,
      .bbs-name .bbs-name-subtext {
        color: defaults.$color-black-dark-bg-trans;
      }
    }
  }
}

.bbs-separated ~ .bbs-separated {
  margin-top: 1px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - #{2 * $bb-outer-spacing});
    height: 1px;
    top: -1px;
    left: $bb-outer-spacing;
    background-color: defaults.$color-text-lighter;
  }
}

.bbs-title {
  margin-top: $bb-outer-spacing;
  margin-left: $bb-outer-spacing;
  margin-right: $bb-outer-spacing;
  min-height: basstyle_const.$bas-button-toggle-total-height;

  &.bbs-title-centered {
    margin-top: #{$bb-outer-spacing*.5};
    margin-bottom: $bb-outer-spacing * 0.5;
  }
}

.bbs-text {
  @include defaults.font-roboto-regular;

  font-size: 0.9em;
  color: defaults.$color-text-dark;

  &.bbs-text-greyed-out {
    color: defaults.$color-black-dark-bg-trans-dark;
  }

  .bbs-text-link {
    color: defaults.$color-highlight-on-light;
    padding-top: 10px;
  }
}

.bbs-spacing-top {
  margin-top: $bb-outer-spacing;
}

.bbs-section {
  margin-bottom: $bb-outer-spacing * 2;

  &.bbs-section-centered {
    margin-bottom: $bb-outer-spacing;
  }
}

.bbs-item {
  margin-left: $bb-outer-spacing + 10px;
}

.bbs-margin-bottom {
  margin-bottom: $bb-outer-spacing;
}

.bbs-double-margin-top {
  margin-top: $bb-outer-spacing * 2;
}

.bbs-simple-toggle {
  position: relative;

  .bbs-name {
    position: relative;
    top: .3em;
    margin-right: basstyle_const.$bas-button-toggle-total-width + $bb-inner-spacing;
  }

  .bbs-toggle {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.bbs-centered {

    .bbs-name {
      margin-right: basstyle_const.$bas-button-toggle-total-width;
    }

    .bbs-toggle {
      top: 50%;
      transform: translate3d(0, -50%, 0);
      right: $bb-outer-spacing;
    }
  }
}

#bas-body .bbs-types {
  display: flex;
  border-radius: 6px;
  border: 1px solid defaults.$color-highlight-on-light;
  overflow: hidden;
  margin-bottom: $bb-outer-spacing;
  margin-left: $bbs-types-padding;
  margin-right: $bbs-types-padding;
  padding: 0;
  width: $bbs-types-max-width;
  max-width: calc(100% - #{2 * $bbs-indentation-padding});
  transition: all 0.5s;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin: 20px auto;
  }
}

.bbs-type {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  position: relative;
  display: inline-block;
  width: 50%;
  text-align: center;
  color: defaults.$color-highlight-on-light;
  border-right: 1px solid defaults.$color-highlight-on-light;
  padding: .5em 0;
  font-size: .8em;

  &:last-child {
    border-right: transparent;
  }
}

.bbs-type-selected {
  background-color: defaults.$color-highlight-on-light;
  color: defaults.$color-foreground;
}

.bbs-standard-setting,
.bbs-ellie-setting,
.bbs-lisa-setting,
.bbs-lena-setting {
  display: none;

  &.bbs-flex-button {
    display: none;
  }
}

.bbs-browser-setting {
  display: none;
}

.bas-device-normal {
  .bbs-standard-setting {
    display: block;

    &.bbs-flex-button {
      display: flex;
    }
  }
}

.bas-device-ellie {
  .bbs-ellie-setting {
    display: block;

    &.bbs-flex-button {
      display: flex;
    }
  }

  .bbs-non-ellie-setting {
    display: none;
  }
}

.bas-device-ellie-v1 {
  .bbs-non-ellie-v1-setting {
    display: none;
  }
}

.bas-device-lisa {
  .bbs-lisa-setting {
    display: block;

    &.bbs-flex-button {
      display: flex;
    }
  }

  .bbs-non-lisa-setting {
    display: none;
  }
}

.bas-device-lena {
  .bbs-lena-setting {
    display: block;

    &.bbs-flex-button {
      display: flex;
    }
  }

  .bbs-non-lena-setting {
    display: none;
  }
}

.bas-device-core-client {
  .bbs-core-client-setting {
    display: block;

    &.bbs-flex-button {
      display: flex;
    }
  }

  .bbs-non-core-client-setting {
    display: none;
  }
}

.bas-device-ellie,
.bas-device-lisa,
.bas-device-lena,
.bas-device-browser {
  .bbs-device-setting {
    display: none;
  }
}

.bas-device-browser {
  .bbs-browser-setting {
    display: block;
  }
}

.bbs-flex {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  // All bbs-flex are consistent height, regardless of their content
  height: $bbs-flex-height;

  .bbs-name {
    flex: 1 1 auto;
    position: relative;
  }

  .bbs-name-subtext {
    font-weight: 100;
    font-size: .81rem;
    color: defaults.$color-text-dark-transparent;
    padding-top: 3px;

    &:empty {
      display: none;
    }

    &.bbs-name-subtext--no-hide-empty:empty {
      display: initial;
    }
  }

  .bbs-name-title {
    font-weight: lighter;
  }

  .bbs-name-title,
  .bbs-name-subtext {
    @include util.ellipsis-overflow();
  }

  &.bbs-border-top {
    min-height: basstyle_const.$bas-button-toggle-total-height;
    margin-top: 1px;

    &::after {
      content: '';
      display: block;
      background-color: defaults.$color-text-light-transparent;
      height: 1px;
      position: absolute;
      left: $bb-outer-spacing;
      right: $bb-outer-spacing;
      top: -1px;
    }
  }
}

.bbs-small {
  height: $bbs-small-height;
}

.bbs-extra-small {
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.bbs-network {

  .bbs-network-spinner {
    display: none;

    .bas-spinner-blade {
      background-color: defaults.$color-highlight-on-light;
    }
  }

  &.bbs-input-disabled {

    .bss-name-input {
      color: defaults.$color-background-shadow;
    }

    .bbs-ip-address,
    .bbs-subnet-mask,
    .bbs-gateway,
    .bbs-dns {
      opacity: 0.4;
    }

    .bbs-active-select {
      transition: background-color 0s, opacity .5s;

      &:active {
        background-color: unset;
        transition: background-color 0s, opacity .5s;
      }
    }
  }

  .bbs-set {
    min-width: 75px;
    text-align: center;
  }

  &.bbs-network-dhcp-selected,
  &.bbs-button-disabled {
    .bbs-ip-address,
    .bbs-subnet-mask,
    .bbs-gateway,
    .bbs-dns {
      opacity: 0.4;
    }
  }

  &.bbs-button-disabled {

    .bbs-set {
      color: defaults.$color-background-shadow;
      border-color: defaults.$color-background-shadow;
      background-color: transparent;
    }

    .bbs-network-spinner {
      display: block;
    }

    .bbs-types {
      opacity: 0.4;
      filter: grayscale(100%);
    }

    .bbs-flex {
      &.bbs-active-select {
        &:active {
          background-color: initial;
        }
      }
    }
  }

  .bas-network-error {
    visibility: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    white-space: normal;
  }

  .bbs-network-set-disabled {
    .bbs-set {
      color: defaults.$color-background-shadow;
      border-color: defaults.$color-background-shadow;
      background-color: transparent;
    }

    .bas-network-error {
      visibility: visible;
    }
  }
}

.bbs-flex-input {
  position: relative;
  display: flex;
  flex-flow: row nowrap;

  .bbs-name {
    flex: 1 1 auto;
    position: relative;
    top: .3em;
  }

  .bbs-form {
    flex: 0 0 auto;
  }
}

.bbs-flex-button {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  .bbs-name {
    flex: 1 1 auto;
  }

  .bbs-spinner {
    flex: 0 0 auto;
  }

  .bbs-button {
    flex: 0 0 auto;
  }

  .bbs-value {
    flex: 0 0 auto;
  }
}

.bbs-slider-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.bbs-slider {
  flex: 1 1 auto;
}

.bbs-slider-value {
  @include defaults.font-roboto-regular;

  flex: 0 0 auto;
  width: 2em;
  text-align: right;
}

.bbs-slider-unit {
  @include defaults.font-roboto-regular;

  flex: 0 0 auto;
  min-width: 0;
}

.bbs-wide-button {
  @include defaults.font-roboto-regular;
  @include util.button-cursor();

  border: 1px solid defaults.$color-highlight-on-light;
  color: defaults.$color-highlight-on-light;
  padding: .3em .5em;
  border-radius: 100px;
  text-align: center;
  min-height: 0;
  font-size: .9em;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: 250px;
    margin: 1em auto 0;
  }

  &:active {
    color: defaults.$color-light-control-buttons;
    background-color: defaults.$color-highlight-on-light;
  }
}

.bbs-name {
  @include defaults.font-roboto-light;
}

.bbs-arrow {
  position: relative;
  flex: 0 0 defaults.$bs-btn-radio-btn-size;

  svg {
    @include util.absolute-center();

    // Best looking arrow size
    width: 84%;
    fill: defaults.$color-background-shadow-dark;
  }
}

.bbs-disabled {

  .bbs-name,
  .bbs-slider-value {
    color: defaults.$color-black-dark-bg-trans;
  }
}

.bbs-value {
  @include defaults.font-roboto-light;

  text-align: right;
}

.bbs-button {
  font-size: .9em;
}

.bbs-input {
  height: 100%;
  border-radius: 10em;
  text-align: center;
  width: 150px;
}

.bbsi {
  @include defaults.font-roboto-light;

  margin-top: $bb-inner-spacing;
  color: defaults.$color-text-dark;

  &.bbsi-title {
    margin-top: $bb-outer-spacing;
  }

  &.bbsi-small {
    margin-top: $bb-inner-spacing-small;
  }

  &.bbsi-no-top-margin {
    margin-top: 0;
  }
}

.bbsi-small-font {
  font-size: .9em;
}

.bbsi-subtitle {
  margin-top: 4px;
  margin-bottom: 8px;
  font-size: .75em;
}

.bbsi-flex {
  display: flex;
  flex-flow: row nowrap;

  .bbsi-name {
    flex: 1 1 auto;
  }

  .bbsi-value {
    flex: 0 0 auto;
  }

  .bbsi-name,
  .bbsi-value {
    &--success {
      color: defaults.$color-green-light;
    }

    &--danger {
      color: defaults.$color-red-on-black;
    }
  }

  .bbs-button {
    flex: 0 0 auto;
  }

  &.bbsi-flex-justify-center {
    justify-content: center;
  }
}

.bbsi-heading {
  margin-top: $bb-outer-spacing;

  .bbsi-name,
  .bbsi-value {
    color: defaults.$color-grey;
  }
}

.bbse {
  margin-top: 16px;
  margin-left: 10px;
}

.bbs-flex-center {
  align-self: center;
}

.bbs-icon {
  position: relative;
  flex: 0 0 auto;
  width: defaults.$bs-btn-circle-size;
  height: defaults.$bs-btn-circle-size;
  background-color: transparent;
  box-sizing: border-box;

  svg {
    @include util.absolute-center();

    width: 50%;
    height: 50%;
    fill: defaults.$color-highlight-on-light;
  }
}

.bbsi-bold {
  @include defaults.font-roboto-regular;
}

.bbsi-spinner {
  position: relative;
  width: 1.1em;
  height: 1.1em;
  margin-left: .4em;
  margin-right: .4em;

  .bas-spinner-blade {
    background-color: defaults.$color-highlight-on-light;
  }
}

.bbsi-emblem {
  @include defaults.font-roboto-light;

  padding-bottom: 0;
  margin-right: .4em;
  font-size: .75em;
}

.bbsi-warning {
  position: relative;
  width: 1em;
  height: 1em;
  margin-left: .4em;
  box-sizing: border-box;

  svg {
    @include util.absolute-center;

    width: 100%;
    height: 100%;
    fill: defaults.$color-highlight-on-light;
  }
}

.bbc-title {
  @include defaults.font-roboto-regular;

  margin: $bb-outer-spacing;
}

.bbc-body {
  @include defaults.font-roboto-light;

  margin-bottom: $bb-outer-spacing * .5;
}

.bbc-link {
  @include defaults.font-roboto-regular;

  display: block;
  color: defaults.$color-highlight-on-light;
  text-decoration: none;
  margin-bottom: $bb-outer-spacing;
  transition: color defaults.$standard-animation-time;

  &:link,
  &:visited {
    color: defaults.$color-highlight-on-light;
  }

  &:hover,
  &:active {
    transition: color 0s;
    color: defaults.$color-highlight-on-light-darker;
  }
}

.bbc-list {
  list-style-type: none;
}

.bbc-list-ordered {
  margin-left: 2 * $bb-outer-spacing;
}

.bbc-list-item-ordered {
  @include defaults.font-roboto-light;

  margin-right: $bb-outer-spacing;
}

.bbol-spotify {
  margin-left: 2 * $bb-outer-spacing;
}

.bbcio-spotify {
  padding-left: $bb-inner-spacing;
  margin-bottom: $bb-inner-spacing;
}

$bb-item-btn-ver-spacing: 5px;

.bbc-list-item {
  position: relative;
  width: 100%;
  height: defaults.$bs-btn-circle-size + 2 * $bb-item-btn-ver-spacing;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: $bb-outer-spacing;
    right: $bb-inner-spacing + defaults.$bs-btn-circle-size + $bb-outer-spacing;
    height: 1px;
    background-color: defaults.$color-foreground;
  }

  &:last-child {

    &::before {
      content: none;
    }
  }

  &.bas-notch-margin:last-child {
    margin-bottom: 0;
    margin-bottom: bas_notch_const.$notch-safe-bottom;
  }
}

.bbc-center-logo {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;

  svg {
    @include util.absolute-center();
  }
}

.bb-logo-spotify {
  width: 100px;
  height: 30px;

  svg {
    width: 100px;
    fill: defaults.$color-spotify-green;
  }
}

$bbci-name-top-margin: .9em;

.bbci-name {
  @include defaults.font-roboto-light;

  display: inline-block;
  margin-top: $bbci-name-top-margin;
  margin-left: $bb-outer-spacing;
}

.bbci-subtitle {
  @include defaults.font-roboto-light;

  display: inline-block;
  margin-top: $bbci-name-top-margin;
  margin-left: .4em;
  text-align: right;
  color: defaults.$color-text-light;
}

.bbci-btn-warning {
  display: none;
}

.bbci-btn-show-warning {
  .bbci-btn-warning {
    display: block;
  }
}

.bbci-spinner {
  flex: 0 0 auto;
  margin-top: 13px;
  margin-left: 10px;
}

.bbci-icon {
  position: relative;
  flex: 0 0 auto;
  width: defaults.$bs-btn-circle-size;
  height: defaults.$bs-btn-circle-size;
  margin-top: 5px;
  margin-left: 10px;
  background-color: transparent;
  box-sizing: border-box;

  svg {
    @include util.absolute-center();

    width: 50%;
    height: 50%;
    fill: defaults.$color-highlight-on-light;
  }
}

.bbci-name-simple {
  margin-right: $bb-inner-spacing + defaults.$bs-btn-circle-size + $bb-outer-spacing;
}

.bbci-btn-simple {
  position: absolute;
  top: $bb-item-btn-ver-spacing;
  right: $bb-outer-spacing;
}

.bbci-flex {
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
}

.bbci-name-flex,
.bbci-subtitle-flex {
  flex: 1 1 auto;
}

.bbci-btn-flex {
  flex: 0 0 auto;
  margin-top: $bb-item-btn-ver-spacing;
  margin-left: $bb-inner-spacing;
  margin-right: $bb-outer-spacing;
}

.bbci-tablet {
  display: none;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;
  }
}

.bbci-phone {
  display: block;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: none;
  }
}

.bb-settings-streams {

  .bbs-streams-content {
    display: none;
  }

  .bbs-spinner {
    display: block;
  }

  &.bbs-streams-loaded {

    .bbs-streams-content {
      display: block;
    }

    .bbs-spinner {
      display: none;
    }
  }
}

.bb-spotify-streams {
  display: none;
}

.bb-spotify-streams {
  &.bb-spotify-allow-streams {
    display: block;
  }
}

.bbci-deezer {
  &.bsc-deezer-linked {
    .bs-svg-pencil {
      opacity: 0;
    }

    .bs-svg-thrash {
      opacity: 1;
    }
  }
}

.bbci-tidal {
  &.bsc-tidal-linked {
    .bs-svg-pencil {
      opacity: 0;
    }

    .bs-svg-thrash {
      opacity: 1;
    }
  }
}

.bbci-spotify {
  &.bsc-spotify-web-linked {
    .bs-svg-pencil {
      opacity: 0;
    }

    .bs-svg-thrash {
      opacity: 1;
    }
  }
}

.bbci-deezer,
.bbci-default-room,
.bbci-tidal,
.bbci-spotify {

  .bs-svg-pencil,
  .bs-svg-thrash {
    transition: opacity defaults.$standard-animation-time linear;
  }

  .bs-svg-pencil {
    opacity: 1;
  }

  .bs-svg-thrash {
    opacity: 0;
  }
}

.bbci-stream {

  .bbci-spinner {
    display: none;
  }

  &.bbci-show-spinner {

    .bbci-spinner {
      display: block;
    }

    .bbci-subtitle {
      display: none;
    }
  }
}

.bas-debug-code {
  font-family: 'Courier New', sans-serif, monospace;
  font-size: 1.6rem;
  color: defaults.$color-foreground;
}

.bas-server {

  .bas-server-spinner {
    display: none;
  }

  .bas-server-emblem {
    display: none;
  }

  .bas-server-warning {
    display: none;
  }

  .bas-server-ok {
    display: block;
  }

  &.bas-server-is-checking-for-update,
  &.bas-server-is-downloading-update {

    .bas-server-spinner {
      display: block;
    }
  }

  &.bas-server-has-update {

    .bas-server-emblem {
      display: block;
    }
  }

  &.bas-server-has-errors {

    .bbsi-server-status {
      @include util.button-cursor;
    }

    .bas-server-warning {
      display: block;
    }

    .bas-server-ok {
      display: none;
    }
  }
}

.bas-core-client-about {

  .bas-core-client-emblem {
    display: none;
  }

  .bas-core-client-spinner {
    display: none;
  }

  &.bas-core-client-has-new-version {
    .bas-core-client-emblem {
      display: block;
    }
  }

  &.bas-core-client-update-progress {
    .bas-core-client-spinner {
      display: block;
    }
  }
}

.bas-integrator-access-info {
  display: none;
}

.bas-integrator-access-info--show {

  .bas-integrator-access-info {
    display: block;
  }
}

.apple-logo {
  width: 14px;
  fill: defaults.$color-foreground;
}
