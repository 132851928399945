@use 'defaults';
@use 'bas_notch_const';
@use 'util';

$brh-item-height-total: 60px;
$brh-item-height-total-p: 48px;
$brh-title-height: 20px;
$brh-title-height-p: 16px;
$brh-context-size: 24px;
$brh-title-vertical-spacing: ($brh-item-height-total - $brh-title-height) * 0.5;
$brh-title-vertical-spacing-p: ($brh-item-height-total-p - $brh-title-height-p) * 0.5;
$brh-context-vertical-spacing: ($brh-item-height-total - $brh-context-size) * 0.5;
$brh-context-vertical-spacing-p: ($brh-item-height-total-p - $brh-context-size) * 0.5;

$bri-side-spacing: 10px;
$bri-content-side-spacing: 6px;
$bri-action-size: 24px;

$brf-action-size: 48px;
$brf-side-spacing: 16px;
$brf-bottom-spacing: 10px;
$brf-action-size-p: 36px;
$brf-side-spacing-p: 14px;
$brf-bottom-spacing-p: 10px;

$login-profile-avatar-size: 144px;
$login-profile-side-spacing: 12px;

.br-text {
  @include defaults.font-roboto-light;

  color: defaults.$color-foreground;
}

.br-text-highlight {
  @include defaults.font-roboto-light;

  color: defaults.$color-highlight-on-light;
}

.br-text-muted {
  @include defaults.font-roboto-light;

  color: defaults.$color-text-grey;
}

.bas-root-header {
  background-color: transparent;

  .mhm-title {
    vertical-align: middle;

    @media (max-height: #{defaults.$media-lisa-height}) {
      padding-left: 0.5em;
    }
  }
}

.brh-left {
  float: left;
}

.brh-right {
  float: right;
}

.brh-item {
  position: relative;

  svg {
    fill: defaults.$color-foreground;
  }
}

.brh-title {
  @include defaults.font-roboto-regular();
  // Derived from height: 16px and icon proportions
  width: 71px;
  height: $brh-title-height-p;
  padding: $brh-title-vertical-spacing-p 16px;
  color: defaults.$color-foreground;

  @media (min-width: #{defaults.$media-phone-width}) {
    // Derived from height: 20px and icon proportions
    width: 89px;
    height: $brh-title-height;
    padding: $brh-title-vertical-spacing 20px;
  }
}

.brh-context {
  @include util.button-cursor;

  width: $brh-context-size;
  height: $brh-context-size;
  padding: $brh-context-vertical-spacing-p 16px;

  &:active {

    svg {
      fill: defaults.$color-highlight-on-light;
    }
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    padding: $brh-context-vertical-spacing 20px;
  }
}

.bas-root-section {

  &.bas-notch-padding--all {

    .bas-absolute-notch-child {
      @include util.bas-notch-padding--all;

      box-sizing: border-box;
    }
  }
}

.brs-center {
  width: 80%;
}

.brs-button-width {
  width: 90vw;
  max-width: 300px;
}

.brs-max-width-300 {
  max-width: 300px;
}

.br-title {
  font-size: 1.3em;
}

.br-title-large {
  font-size: 1.3em;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1.5em;
  }
}

.br-title-flexible-font {
  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1.3em;
  }
}

.br-subtitle {
  color: defaults.$color-text-grey;
}

.br-action {
  margin-top: 1em;
  width: 100%;
}

.br-line {
  width: 100%;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  background-color: defaults.$color-background-active;

  @media (max-width: defaults.$media-width-xxxsmall) {
    margin-bottom: 1.2em;
    margin-top: 1.2em;
  }
}

.br-spacing-vertical-large {
  margin-top: 36px;
  margin-bottom: 36px;
}

.br-spacing-vertical {
  margin-top: 24px;
  margin-bottom: 24px;
}

.br-spacing-vertical-small {
  margin-top: 12px;
  margin-bottom: 12px;
}

.br-button-override {
  font-size: 1em;
  background-color: transparent;
}

.br-button-sides {
  padding-left: 16px;
  padding-right: 16px;
}

.br-button-below {
  padding-bottom: 10px;

  @media (max-height: #{defaults.$media-width-xsmall}) {
    padding-bottom: 20px;
  }
}

.brs-title-back {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  padding-right: 12px;

  svg {
    align-self: center;
    width: 24px;
    height: 24px;
    fill: defaults.$color-dark-control-buttons;
  }
}

.bas-profile-page-container,
.bd-swiper-container {

  .swiper-button-next,
  .swiper-button-prev {
    opacity: 1;
    transition: opacity defaults.$standard-animation-time;

    &.swiper-button-disabled {
      opacity: 0;
    }
  }
}

.bas-profile-page-container {
  .swiper-button-next,
  .swiper-button-prev {
    @media (min-width: #{defaults.$media-phone-width}) {
      display: none;
    }
  }
}

// Login

// 30% from the top is +- at the same height as the profile avatars.
$bas-login-top-offset: 30%;
$bas-login-spinner-size: 50px;

.br-profiles {
  .bas-login--spinner,
  .bas-login--retry-users {
    display: none;
  }

  .bas-login--retry-users {
    @include util.absolute-match-parent;

    color: defaults.$color-foreground;
  }

  .bas-login--spinner {
    width: $bas-login-spinner-size;
    height: $bas-login-spinner-size;
    margin-bottom: 24px;
  }

  &.bas-login--show-retry-users {

    .bas-login--retry-users {
      display: flex;
    }

    .bas-login--profiles {
      display: none;
    }
  }

  &.bas-login--show-spinner {

    .bas-login--spinner {
      display: block;
    }

    .bas-login-profile {
      display: none;
    }
  }
}

.br-profiles-container {

  .br-profile-avatar {
    width: 0;
    height: 0;
  }

  .br-profile {
    margin-top: 12px;
  }

  // Experimentally determined

  @media (min-height: #{$login-profile-avatar-size + 30px}) {

    .br-profile-avatar {
      width: 64px;
      height: 64px;
    }
  }

  @media (min-height: #{$login-profile-avatar-size + 70px}) {

    .br-profile-avatar {
      width: 92px;
      height: 92px;
    }
  }

  @media (min-height: #{$login-profile-avatar-size + 140px}) {

    .br-profile {
      margin-top: $brh-item-height-total-p;
    }

    .br-profile-avatar {
      width: $login-profile-avatar-size;
      height: $login-profile-avatar-size;
    }
  }

  @media (min-width: #{defaults.$media-phone-width}) {

    .br-profiles-wrapper {
      flex-flow: row wrap;
      justify-content: center;
      padding-top: 12px;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      @media (min-height: 400px) {
        padding-top: 24px;
      }
    }

    .br-profile {
      height: auto;
      max-width: $login-profile-avatar-size;
      margin-top: 0;
      margin-left: $login-profile-side-spacing;
      margin-right: $login-profile-side-spacing;
    }
  }
}

.bas-login--cancel {
  width: auto;
  margin: 0 auto;
}

.br-profile {

  &:active {

    .br-profile-avatar {
      border-color: defaults.$color-highlight-on-light;
      transition: border-color 0s;

      svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }
  }
}

.br-profile-avatar {
  position: relative;
  width: $login-profile-avatar-size;
  height: $login-profile-avatar-size;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  border-width: 6px;
  border-style: solid;
  border-color: defaults.$color-background-disabled;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
  transition: border-color defaults.$standard-animation-time;
  // Needed for iOS,
  // otherwise profile picture will show outside circle despite overflow: hidden
  z-index: 0;

  .br-profile-avatar-img {
    background-size: cover;
    background-position: center;
    background-image: none;
    position: relative;
    height: 100%;
    width: 100%;

    &::after {
      @include util.absolute-match-parent();

      content: '';
      transition: opacity defaults.$standard-animation-time ease-in-out;
      background-color: defaults.$color-black-darker-bg-trans;
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      border-radius: 100%;
    }
  }

  .br-profile-avatar-spinner {
    @include util.absolute-center();

    width: 25%;
    height: 25%;
    opacity: 0;
    transition: opacity defaults.$standard-animation-time ease-in-out;
    pointer-events: none;
  }

  svg {
    @include util.absolute-center();

    display: block;
    width: 90%;
    margin-top: 5%;
    fill: defaults.$color-background-disabled;
    z-index: -1;
    transition: fill defaults.$standard-animation-time;
  }
}

.br-profile-username {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 100%;
  word-wrap: break-word;
  margin-top: 10px;
}

.brp-lock {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 19px;
  margin-top: -2px;
  vertical-align: top;

  svg {
    @include util.absolute-center;

    width: 100%;
    height: 100%;
    fill: defaults.$color-background-disabled;
  }
}

.brp-name {
  @include defaults.font-roboto-light;

  display: inline-block;
  color: defaults.$color-foreground;
  vertical-align: top;
  white-space: normal;
  word-wrap: break-word;
  max-width: 100%;
}

.br-input-form {
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-top: 12px;
    margin-left: 0;
    margin-right: 0;
  }
}

.bas-login-profile {

  .brp-lock {
    display: none;
  }

  .bas-login-profile-password-field {
    // We use 'height: 0; and 'overflow: hidden;' here instead of
    //  'display: none;' or 'visibility: hidden;', since invisible elements can
    //  not be focused.
    height: 0;
    overflow: hidden;
  }

  &.bas-login-profile-has-password {

    .brp-lock {
      display: inline-block;
    }

    .brp-name {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  &.bas-login-profile-selected {

    .br-profile-avatar {
      border-color: defaults.$color-highlight-on-dark;

      svg {
        fill: defaults.$color-highlight-on-dark;
      }
    }

    &.bas-login-profile-has-password {

      .bas-login-profile-password-field {
        height: auto;
      }
    }
  }
}

.bas-profiles--loading {
  pointer-events: none;

  .bas-login-profile-selected {

    .br-profile-avatar {

      .br-profile-avatar-img {

        &::after {
          opacity: 1;
        }
      }

      .br-profile-avatar-spinner {
        opacity: 1;
      }
    }
  }
}
