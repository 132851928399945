@use 'defaults';
@use 'bas_notch_const';
@use 'util';

$login-font: 24px;
$login-font-phone: 16px;

$login-header-font: 1.4em;
$login-header-margin: .4em;

$login-user-arrow-size: 4em;
$login-user-arrow-margin-value: .25em;
$login-user-arrow-margin: auto $login-user-arrow-margin-value;
$login-user-arrow-width-total: $login-user-arrow-size + 2 * $login-user-arrow-margin-value;

$login-users-font: 1em;
$login-user-width: 8em;
$login-user-max-width: 80%;
$login-user-padding: 1em;
$login-user-flex-basis: calc(100% - 2 * #{$login-user-arrow-width-total});

$login-user-color: defaults.$color-background-disabled;
$login-user-avatar-halo-size: .85em;
$login-user-avatar-border-width: .3em;
$login-user-avatar-margin: auto auto auto (-$login-user-avatar-border-width);
$login-user-avatar-border: $login-user-avatar-border-width solid $login-user-color;
$login-user-avatar-border-active: $login-user-avatar-border-width solid defaults.$color-foreground;
$login-user-avatar-box-shadow-active: 0 0 $login-user-avatar-halo-size rgba(defaults.$color-foreground, 0.75);
$login-user-avatar-image-padding: $login-user-avatar-border-width;

$login-user-avatar-border-width-rounded: $login-user-avatar-border-width - .1em;
//$login-user-avatar-svg-width: 100%;
$login-user-avatar-svg-width: calc(100% - #{$login-user-avatar-border-width-rounded});
$login-user-avatar-svg-height: 100%;

$login-username-margin: .2em 0;
$login-username-font: .8em;
$login-username-fill: $login-user-color;

$login-username-lock-width: .8em;
$login-username-lock-height: $login-username-lock-width;

$login-credentials-margin: 1em auto;

$login-credentials-element-margin-side-value: .2em;
$login-credentials-element-margin-height-value: .4em;
$login-password-margin: $login-credentials-element-margin-height-value $login-credentials-element-margin-side-value;
$login-password-margin-phone: $login-credentials-element-margin-height-value auto;
$login-password-border-radius: 5px;
$login-password-background-color: defaults.$color-foreground;

$login-password-input-margin: 0 0 0 .4em;
$login-password-input-padding: .5em 0;
$login-password-input-font: .8em;

$login-password-button-width: 20px;
$login-password-button-height: $login-password-button-width;
$login-password-button-padding: .4em;
$login-password-fill: #a9a9a9;

$login-remember-margin: $login-credentials-element-margin-height-value $login-credentials-element-margin-side-value;
$login-remember-font: .8em;

$login-remember-checkbox-width: 19px;
$login-remember-checkbox-height: $login-remember-checkbox-width;
$login-remember-checkbox-border: 3px solid rgba(defaults.$color-foreground, .8);
$login-remember-checkbox-border-radius: 5px;
$login-remember-checkbox-box-shadow: inset 0 2px 3px rgba(0, 0, 0, .2);
$login-remember-checkbox-check-width: 14px;
$login-remember-checkbox-check-height: $login-remember-checkbox-check-width;

$login-remember-text-margin: 0 .4em;

$login-placeholder-color: rgba(defaults.$color-foreground, .5);

$login-footer-font: .8em;
$login-footer-margin: .6em;
$login-footer-server-icon-height: 1.8em;
$login-footer-server-icon-width: $login-footer-server-icon-height;
$login-footer-server-icon-fill: defaults.$color-foreground;

.login {
  @include defaults.font-roboto-light;

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: $login-font;
  color: defaults.$color-foreground;

  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $login-font-phone;
  }
}

.login-notch {
  box-sizing: content-box;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-top: bas_notch_const.$notch-safe-top;
  padding-bottom: bas_notch_const.$notch-safe-bottom;
  height: 100%;
  height: calc(100% - #{bas_notch_const.$notch-safe-top} - #{bas_notch_const.$notch-safe-bottom});
}

// Main login sections
//////////////////////

.login-section {
  position: relative;
  flex: 0 0 auto;
  align-self: stretch;
  text-align: center;
}

.login-users-wrapper {
  flex: 1 1 auto;
  position: relative;
}

.login-wrapper {
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.login-header {
  font-size: $login-header-font;
  margin: $login-header-margin;
}

.login-placeholder {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  color: $login-placeholder-color;
  text-align: center;
}

.login-message {
  flex: 1 1 auto;
}

.login-users {
  mask: linear-gradient(
      to top,
      transparent 0%,
      black 5%,
      black 95%,
      transparent 100%
    );
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  font-size: $login-users-font;

  &::-webkit-scrollbar { // !!! Webkit/Blink only !!!
    display: none;
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.login-footer {
  margin: $login-footer-margin;
  font-size: $login-footer-font;
  text-align: left;
  vertical-align: middle;
}

// Login users
//////////////

// Navigating arrows

.login-user-arrow {
  position: relative;
  display: none;
  width: $login-user-arrow-size;
  height: 100%;
  fill: defaults.$color-light-control-buttons;
  transition: opacity defaults.$standard-animation-time;

  @media (max-width: #{defaults.$media-phone-width}) {
    display: inline-block;
    flex: 0 0 auto;
  }

  &.login-user-arrow-hidden {
    opacity: 0;
  }

  svg {
    @include util.absolute-center();

    left: 20%;
    display: block;
    width: 100%;
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$active-animation-time ease-out;
  }

  &:active,
  :active {
    fill: defaults.$color-highlight-on-dark;
    transition: fill 0s ease-out;
  }
}

.login-users-filler {
  flex: 1 1 auto;
}

.login-users-container {
  flex: 1 1 auto;
  display: block;
  position: relative;
  list-style-type: none;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar { // !!! Webkit/Blink only !!!
    display: none;
  }
}

.login-user-wrapper {

  .swiper-button-next,
  .swiper-button-prev {
    background-image: none;

    @media (min-width: #{defaults.$media-phone-width}) {
      display: none;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  .swiper-wrapper {
    @media (min-width: #{defaults.$media-phone-width}) {
      display: block;
    }
  }
}

.login-user-wrapper {
  @include util.absolute-center();

  position: relative;
  display: block;
  text-align: center;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  @media (max-width: #{defaults.$media-phone-width}) {
    overflow-y: hidden;
    white-space: nowrap;
    transition: transform 1s;
  }
}

// Actual user

.login-user {
  @include util.button-cursor();

  position: relative;
  display: inline-block;
  width: $login-user-width;
  padding: $login-user-padding;
  overflow: hidden;
  vertical-align: top;
  box-sizing: border-box;

  @media (max-width: #{defaults.$media-phone-width}) {
    max-width: $login-user-max-width;
    margin: auto calc((100% - #{$login-user-width}) / 2);
  }

  &.active {
    .login-user-avatar {
      border: $login-user-avatar-border-active;
      box-shadow: $login-user-avatar-box-shadow-active;
      fill: defaults.$color-foreground;
    }

    .login-user-avatar-placeholder {
      border: $login-user-avatar-border-active;
      box-shadow: $login-user-avatar-box-shadow-active;
    }

    .login-user-username {
      fill: defaults.$color-foreground;
    }
  }
}

// Avatar

$login-user-avatar-placeholder-top: 60%;

.login-user-avatar {
  position: relative;
  display: block;
  margin: $login-user-avatar-margin;
  width: 100%;
  padding-bottom: 100%;
  border: $login-user-avatar-border;
  border-radius: 100%;
  overflow: hidden;
  fill: $login-user-color;

  > svg {
    @include util.absolute-center();

    width: $login-user-avatar-svg-width;
    height: $login-user-avatar-svg-height;
    top: $login-user-avatar-placeholder-top;
  }
}

.login-user-avatar-placeholder {
  position: relative;
  display: block;
  width: 100%;
  border: $login-user-avatar-border;
  border-radius: 100%;
  box-shadow: none;
  box-sizing: border-box;
}

.login-user-avatar-image {
  position: absolute;
  display: block;
  width: 100%;
  top: 50%;
  left: 50%;
  padding: $login-user-avatar-image-padding;
  border-radius: 100%;
  transform: translate(-50%, -50%);
}

// Username

.login-user-username-lock {
  position: relative;
  display: inline;

  svg {
    display: inline;
    height: $login-username-lock-height;
    width: $login-username-lock-width;
  }
}

.login-user-username {
  @include defaults.font-roboto-light;

  position: relative;
  display: inline-block;
  width: 100%;
  margin: $login-username-margin;
  font-size: $login-username-font;
  fill: $login-username-fill;
  vertical-align: middle;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3.9em;
  mask: linear-gradient(to bottom, black 66%, transparent 100%);
}

// Login Credentials
////////////////////

.login-credentials {
  flex: 0 0 auto;
  position: relative;
  display: block;
  margin: $login-credentials-margin;
  text-align: center;
}

// Password

.login-credentials-password {
  @include defaults.font-roboto-regular;

  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  margin: $login-password-margin;
  border-radius: $login-password-border-radius;
  background-color: $login-password-background-color;
  vertical-align: middle;
  white-space: nowrap;
}

.login-credentials-password-input {
  position: relative;
  flex: 1 1 auto;
  display: inline-block;
  margin: $login-password-input-margin;
  padding: $login-password-input-padding;
  background-color: transparent;
  border: none;
  vertical-align: middle;
  font-size: $login-password-input-font;
}

.login-credentials-password-button {
  position: relative;
  flex: 0 0 auto;
  display: inline-block;
  height: $login-password-button-height;
  width: $login-password-button-width;
  padding: $login-password-button-padding;
  fill: $login-password-fill;
  vertical-align: middle;

  svg {
    display: block;
    height: $login-password-button-height;
    width: $login-password-button-width;
    vertical-align: middle;
  }
}

// Remember

.login-credentials-remember {
  position: relative;
  display: inline-block;
  margin: $login-remember-margin;
  font-size: $login-remember-font;
  vertical-align: middle;
}

.login-credentials-remember-checkbox {
  position: relative;
  display: inline-block;
  height: $login-remember-checkbox-height;
  width: $login-remember-checkbox-width;
  border: $login-remember-checkbox-border;
  border-radius: $login-remember-checkbox-border-radius;
  box-shadow: $login-remember-checkbox-box-shadow;
  background-color: defaults.$color-foreground;
  vertical-align: middle;

  svg {
    @include util.absolute-center();

    display: none;
    height: $login-remember-checkbox-check-height;
    width: $login-remember-checkbox-check-width;
  }

  &.active {
    svg {
      display: block;
    }
  }
}

.login-credentials-remember-text {
  display: inline-block;
  margin: $login-remember-text-margin;
  vertical-align: middle;
}

$server-box-padding: .4em;

.server-box {
  @include util.button-cursor();

  position: relative;
  display: inline-block;
  padding: $server-box-padding;
  color: defaults.$color-foreground;
  vertical-align: middle;

  svg {
    fill: defaults.$color-foreground;
    border-color: defaults.$color-foreground;
  }

  &:active {
    color: defaults.$color-highlight-on-dark;

    svg {
      fill: defaults.$color-highlight-on-dark;
      border-color: defaults.$color-highlight-on-dark;
    }
  }
}

$login-server-icon-basis: 36px;
$server-icon-margin: 0 .4em 0 0;

.server-icon {
  position: relative;
  display: inline-block;
  width: $login-server-icon-basis;
  margin: $server-icon-margin;
  vertical-align: middle;
}

$server-connect-font-size: 1em;
$server-connect-color: defaults.$color-foreground;

.server-connect {
  @include defaults.font-roboto-regular;

  position: relative;
  display: inline-block;
  text-align: left;
  font-size: $server-connect-font-size;
  vertical-align: middle;
}
