@use 'util';

.no-scroll {
  overflow-x: hidden;
  overflow-y: hidden;
}

.bas-fake-hide {
  @include util.bas-fake-hide();
}

.bas-fake-show {
  @include util.bas-fake-show();
}

.swiper-container {

  &.bas-swiper-container {

    // Since Swiper v5, override some styling to keep custom prev and next buttons
    .swiper-button-next,
    .swiper-button-prev {
      justify-content: normal;

      &::before,
      &::after {
        content: none;
      }
    }
  }
}
