@use 'defaults';
@use 'basstyle_const';
@use 'util';

.root-body-modal-on-top {
  > .modal {
    z-index: 5;
  }
}

.bas-body {

  &:not(.bas-device--supports-live).bas-device--only-web-rtc {

    .bas-connect--main-modal-button {
      display: none;
    }
  }
}

.bas-main-header {
  background-color: defaults.$color-header;
}

$main-transition-time: .3s !default;
$main-transition-timing: ease;

.main {
  @include util.default-cursor();

  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.bas-state-transition {

  .bmc-content,
  .main-section-container {
    animation-timing-function: ease;
    transform: translate3d(0, 0, 0);
  }

  &.bas-state-transition-fade {

    .main-section-container {
      opacity: 1;

      &.ng-enter-prepare {
        opacity: 0;
      }

      &.ng-enter {
        animation: $main-transition-time fade-in;
      }

      &.ng-leave-prepare {
        opacity: 1;
      }

      &.ng-leave {
        animation: $main-transition-time fade-out;
      }
    }
  }

  &.bas-state-transition-in {

    .main-section-container {

      &.ng-enter-prepare {
        transform: translate3d(100%, 0, 0);
      }

      &.ng-enter {
        animation: $main-transition-time slide-in-right;
      }

      &.ng-leave-prepare {
        transform: translate3d(0, 0, 0);
      }

      &.ng-leave {
        animation: $main-transition-time slide-out-right;
      }
    }
  }

  &.bas-state-transition-out {

    .main-section-container {

      &.ng-enter-prepare {
        transform: translate3d(-100%, 0, 0);
      }

      &.ng-enter {
        animation: $main-transition-time slide-in-left;
      }

      &.ng-leave-prepare {
        transform: translate3d(0, 0, 0);
      }

      &.ng-leave {
        animation: $main-transition-time slide-out-left;
      }
    }
  }

  &.bas-state-content-transition-in {

    .bmc-content {

      &.ng-enter {
        animation: $main-transition-time slide-in-right;
      }

      &.ng-leave {
        animation: $main-transition-time slide-out-right;
      }
    }
  }

  &.bas-state-content-transition-out {

    .bmc-content {

      &.ng-enter {
        animation: $main-transition-time slide-in-left;
      }

      &.ng-leave {
        animation: $main-transition-time slide-out-left;
      }
    }
  }
}

.main-header {
  position: relative;
  flex: 0 0 auto;
}

.main-section {
  position: relative;
  flex: 1 1 auto;
  transform: translate3d(0, 0, 0);
}

.main-section-container {
  @include util.absolute-match-parent();

  overflow: hidden;
}

.main-background {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: defaults.$color-background-dark;
  transform: translate3d(0, 0, 0);

  * {
    display: none;
  }
}

.bas-pref-show-background-enabled {
  .main-background {
    * {
      display: block;
    }
  }
}

.bas-device-browser {

  .bas-browser-scroll-bar {
    @include basstyle_const.bas-browser-scroll-bar;
    @include basstyle_const.bas-browser-scroll-bar-transparent;
  }

  .bas-browser-scroll-bar-solid {
    @include basstyle_const.bas-browser-scroll-bar;
    @include basstyle_const.bas-browser-scroll-bar-grey;
  }
}
