* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  /* stylelint-disable property-no-vendor-prefix */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  /* stylelint-enable property-no-vendor-prefix */
  user-select: none;
  -webkit-touch-callout: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  line-height: 1.2;
}

body {
  background-color: #000;
}

// style-*device*.scss will be imported at build time
@import '@@device-style'
