
.bas-device-ios {

  // Fixes flickering of text on room tiles during scrolling

  .bas-tile {
    .bas-tile-gradient-overlay::after,
    .bas-tile-bg-overlay,
    .bas-tile-title,
    .bas-tile-activity-light {
      transform: translate3d(0, 0, 0);
    }
  }
}
