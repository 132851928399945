@use 'defaults';
@use 'util';
@use '@basalte/range-slider/src/range_slider' with (
  $grs-wrapper-padding: 0,
  $grs-thumb-size: 20px,
  $grs-track-size: 2px,
  $grs-container-size: 40px,
  $grs-track-color: defaults.$color-background-shadow,
  $grs-progress-color: defaults.$color-highlight-on-dark,
  $grs-tick-line-width: 1px,
  $grs-tick-line-height: 9px,
);

// Override slider css for our use case
// Use ID selector: specificity override
#bas-body {
  .bas-slider-dark {
    .grspr {
      background-color: defaults.$color-highlight-on-light;
    }
  }

  .grsw {
    // When overflow is hidden, there is a visible slider thumb shadow cutoff
    overflow: visible;
  }
}
