@use 'defaults';

.bas-progress {
  display: block;
}

.bas-progress-circle-bg {
  // SVG Styling properties
  fill: none;
  stroke: defaults.$color-background-light-very-transparent;
  stroke-width: 1;
}

.bas-progress-circle {
  // SVG Styling properties
  fill: none;
  stroke-width: 1;
  stroke-linecap: round;
  transition: 1s linear;
}

.bas-progress-circle {
  // SVG Styling properties
  stroke: defaults.$color-highlight-on-dark;
}

.bas-progress-text-main {
  font-size: 0.6em;
  transform-origin: center center;

  // SVG Styling properties
  fill: defaults.$color-highlight-on-dark;

  // SVG alignment properties
  text-anchor: middle;
  dominant-baseline: middle;
}

.bas-progress-text-secondary {
  font-size: 0.3em;
  transform-origin: center center;

  // SVG Styling properties
  fill: defaults.$color-text-light-transparent;

  // SVG alignment properties
  text-anchor: middle;
}
