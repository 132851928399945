@use 'defaults';
@use 'util';

// Library Drawer

// bsc = bas source css
// bsc = bas source css item

.bsc-library {

  .bsci-local,
  .bsci-deezer,
  .bsci-tidal,
  .bsci-spotify {
    display: none;
  }

  &.bsc-has-content {
    .bsci-local {
      display: block;
    }
  }

  &.bsc-deezer-linked {
    .bsci-deezer {
      display: block;
    }
  }

  &.bsc-tidal-linked {
    .bsci-tidal {
      display: block;
    }
  }

  &.bsc-spotify-web-linked {
    .bsci-spotify {
      display: block;
    }
  }
}

// Library - Common

// Library - Common - Header

$bas-library-subtitle-color: #707070 !default;

$bas-library-header-search-icon-size: 24px;
$bas-library-header-outside-spacing: 0.6em;
$bas-library-header-icon-spacing: 0.4em;
$bas-library-header-icon-size: 32px;
$bas-library-header-icon-margin: 0
  $bas-library-header-icon-spacing
  0
  $bas-library-header-icon-spacing;
$bas-library-header-icon-first-margin: 0
  $bas-library-header-icon-spacing
  0
  $bas-library-header-outside-spacing;
$bas-library-header-icon-last-margin: 0
  $bas-library-header-outside-spacing
  0
  $bas-library-header-icon-spacing;
$bas-library-header-icon-fade-out: defaults.$standard-animation-time;

$bas-library-header-content-padding: 0
  $bas-library-header-icon-spacing
  0
  0;

$bas-library-header-content-padding-no-navigation: 0
  $bas-library-header-icon-spacing
  0
  $bas-library-header-outside-spacing;

$bas-library-header-nav-item-space: #{$bas-library-header-icon-size} +
  #{2 * $bas-library-header-icon-spacing};

$bas-library-header-nav-item-outside-extra-space: $bas-library-header-outside-spacing -
  $bas-library-header-icon-spacing;

$bas-library-header-button-x-offset-hidden: calc(
  2 * (#{$bas-library-header-nav-item-space}) +
  #{$bas-library-header-nav-item-outside-extra-space}
);

$bas-library-header-button-x-offset-single-hidden: calc(
  #{$bas-library-header-nav-item-space} +
  #{$bas-library-header-nav-item-outside-extra-space}
);

$bas-library-header-button-x-offset-hidden-neg: calc(
  -1 * (2 * (#{$bas-library-header-nav-item-space}) +
  #{$bas-library-header-nav-item-outside-extra-space})
);

$bas-library-header-content-x-offset-single-hidden-neg: calc(
  -1 *
  (
    #{$bas-library-header-nav-item-space} +
    #{$bas-library-header-nav-item-outside-extra-space} -
    #{$bas-library-header-icon-spacing}
  )
);

$bas-library-header-content-x-offset-hidden-neg: calc(
  -1 *
  (
    2 * (#{$bas-library-header-nav-item-space}) +
    #{$bas-library-header-nav-item-outside-extra-space} -
    #{$bas-library-header-icon-spacing}
  )
);

$bas-library-header-title-padding-value-inner: .6em;
$bas-library-header-title-padding-value-outer: .3em;
$bas-library-header-title-padding: 0
  $bas-library-header-title-padding-value-inner
  0

  0;
$bas-library-header-subtitle-padding: 0
  $bas-library-header-title-padding-value-outer
  0
  $bas-library-header-title-padding-value-inner;
$bas-library-header-subtitle-padding-phone: 0
  $bas-library-header-title-padding-value-outer
  0
  0;

$bas-library-content-subtitle-border: 1px solid defaults.$color-highlight-on-light;

@mixin bas-library-header-icon-active () {
  > svg {
    fill: defaults.$color-highlight-on-light;
    transition: fill 0s ease-out;
  }
}

@mixin bas-library-header-navigation-init () {
  > .bas-library-header-back {
    display: none;
    left: $bas-library-header-button-x-offset-hidden-neg;
    transform: translate3d(0, 0, 0);
    transition: transform defaults.$standard-animation-time;
  }

  > .bas-library-header-home {
    display: none;
    left: $bas-library-header-button-x-offset-hidden-neg;
    transform: translate3d(0, 0, 0);
    transition: transform defaults.$standard-animation-time;
  }

  > .bas-library-header-content {
    padding: $bas-library-header-content-padding;
    left: 0;
    transform: translate3d(0, 0, 0);
    transition: transform defaults.$standard-animation-time;
  }
}

@mixin bas-library-header-active-navigation-double () {
  > .bas-library-header-back {
    transform: translate3d($bas-library-header-button-x-offset-hidden, 0, 0);
  }

  > .bas-library-header-home {
    transform: translate3d($bas-library-header-button-x-offset-hidden, 0, 0);
  }

  > .bas-library-header-content {
    transform: translate3d($bas-library-header-button-x-offset-hidden, 0, 0);
  }
}

@mixin settings-header () {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: defaults.$main-header-height-phone;
  min-height: $bas-library-header-icon-size;
  background-color: defaults.$color-library-header;
  border-bottom: 1px solid defaults.$color-library-border;
  width: 100%;
  z-index: 3;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: defaults.$bmsc-header-height;
  }

  &.bas-library-header-enable {
    @include bas-library-header-navigation-init();
  }

  &.bas-library-header-show-back {
    > .bas-library-header-back {
      display: block;
    }
  }

  &.bas-library-header-show-home {
    > .bas-library-header-home {
      display: block;
    }
  }

  &.bas-library-header-has-subtitle {
    > .bas-library-header-content > .bas-library-header-content-subtitle {
      display: inline;
    }
  }

  &.bas-library-header-can-search {
    > .bas-library-header-search-button {
      display: block;
    }

    > .bas-library-header-search {
      display: block;
    }
  }

  // Single navigation button
  &.bas-library-header-show-back {
    > .bas-library-header-content {
      left: $bas-library-header-content-x-offset-single-hidden-neg;
    }
  }

  // Double navigation button
  &.bas-library-header-show-back.bas-library-header-show-home {
    > .bas-library-header-content {
      left: $bas-library-header-content-x-offset-hidden-neg;
    }
  }

  &.bah-active-search {
    > .bas-library-header-search-button {
      @include bas-library-header-icon-active();
    }
  }

  &.bas-library-header-active-navigation {

    // Single navigation button
    &.bas-library-header-show-back {
      @include bas-library-header-active-navigation-double();

      > .bas-library-header-content {
        transform: translate3d(
            $bas-library-header-button-x-offset-single-hidden,
            0,
            0
          );
      }
    }

    // Double navigation button
    &.bas-library-header-show-back.bas-library-header-show-home {
      @include bas-library-header-active-navigation-double();
    }
  }
}

@mixin bas-library-header () {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: defaults.$main-header-height-phone;
  min-height: $bas-library-header-icon-size;
  background-color: defaults.$color-library-header;
  border-bottom: 1px solid defaults.$color-library-border;
  top: 0;
  left: 0;
  right: 0;
  bottom: defaults.$main-header-height-phone;
  z-index: 3;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: defaults.$bmsc-header-height;
    bottom: defaults.$bmsc-header-height;
  }

  &.bas-library-header-enable {
    @include bas-library-header-navigation-init();
  }

  &.bas-library-header-show-back {
    > .bas-library-header-back {
      display: block;
    }
  }

  &.bas-library-header-show-home {
    > .bas-library-header-home {
      display: block;
    }
  }

  &.bas-library-header-has-subtitle {
    > .bas-library-header-content > .bas-library-header-content-subtitle {
      display: inline;
    }
  }

  &.bas-library-header-show-view {
    > .bas-library-header-view-button {
      display: block;
    }
  }

  &.bas-library-header-view-button-list-show {
    .bas-library-header-view-button-list {
      > svg {
        opacity: 1;
      }
    }
  }

  &.bas-library-header-view-button-grid-show {
    .bas-library-header-view-button-grid {
      > svg {
        opacity: 1;
      }
    }
  }

  &.bas-library-header-can-search {
    > .bas-library-header-search-button {
      display: block;
    }

    > .bas-library-header-search {
      display: block;
    }
  }

  // Single navigation button
  &.bas-library-header-show-back {
    > .bas-library-header-content {
      left: $bas-library-header-content-x-offset-single-hidden-neg;
    }
  }

  // Double navigation button
  &.bas-library-header-show-back.bas-library-header-show-home {
    > .bas-library-header-content {
      left: $bas-library-header-content-x-offset-hidden-neg;
    }
  }

  &.bah-active-search {
    > .bas-library-header-search-button {
      @include bas-library-header-icon-active();
    }
  }

  &.bas-library-header-active-navigation {

    // Single navigation button
    &.bas-library-header-show-back {
      @include bas-library-header-active-navigation-double();

      > .bas-library-header-content {
        transform: translate3d(
            $bas-library-header-button-x-offset-single-hidden,
            0,
            0
          );
      }
    }

    // Double navigation button
    &.bas-library-header-show-back.bas-library-header-show-home {
      @include bas-library-header-active-navigation-double();
    }
  }
}

@mixin bas-library-header-icon () {
  position: relative;
  display: block;
  flex: 0 0 auto;
  width: $bas-library-header-icon-size;
  margin: $bas-library-header-icon-margin;

  &:first-child {
    margin: $bas-library-header-icon-first-margin;
  }

  &:last-child {
    margin: $bas-library-header-icon-last-margin;
  }

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-library-control;
  }

  &:active,
  &.active {
    @include bas-library-header-icon-active();
  }
}

@mixin bas-library-header-icon-fade () {
  @include bas-library-header-icon();

  > svg {
    transition: fill $bas-library-header-icon-fade-out ease-out;
  }
}

// Library - Common - Header - Content

@mixin bas-library-header-content () {
  position: relative;
  flex: 1 1 auto;
  align-self: center;
  min-width: 0;
}

@mixin bas-library-header-content-title () {
  @include defaults.font-roboto-regular;

  display: inline;
  padding: $bas-library-header-title-padding;
}

@mixin bas-library-header-content-subtitle () {
  @include defaults.font-roboto-light;

  display: none;
  padding: $bas-library-header-subtitle-padding;
  border-left: $bas-library-content-subtitle-border;
}

// Library - Common - Header - Search

$bas-library-search-height: defaults.$main-header-height-phone * 0.9;
$bas-library-tabs-height: defaults.$main-header-height-phone * 0.8;

$bas-library-search-input-ratio: 0.8;
$bas-library-search-input-height: $bas-library-search-height * $bas-library-search-input-ratio;
$bas-library-search-input-width: calc(100% - 2 * #{$bas-library-header-outside-spacing});
$bas-library-search-input-radius: 5em;
$bas-library-search-input-padding: 0 .9em;

@mixin bas-library-header-search () {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: $bas-library-search-height;
  transform: translate3d(0, 0, 0);
  transition: transform defaults.$standard-animation-time;
  background-color: defaults.$color-library-header;
  z-index: 2;

  &.bah-active-search {
    transform: translate3d(0, defaults.$main-header-height-phone, 0);

    @media (min-width: #{defaults.$media-phone-width}) {
      transform: translate3d(0, defaults.$bmsc-header-height, 0);
    }
  }

  .search-history-wrapper {
    display: none;
  }

  &.bah-show-history {
    .search-history-wrapper {
      display: block;
    }
  }
}

@mixin bas-library-header-search-input () {
  @include util.absolute-center();

  display: block;
  width: $bas-library-search-input-width;
  height: $bas-library-search-input-height;
  padding: $bas-library-search-input-padding;
  border-radius: $bas-library-search-input-radius;
  line-height: $bas-library-search-input-height;
  font-size: .9em;
  // Needed for iOS
  background-color: defaults.$color-foreground;
}

// Library - Common - Controls

$bas-list-control-size: 36px;
$bas-list-control-inner-width: 50%;
$bas-list-control-margin: 0
  0
  0
  .8em;
$bas-list-control-radius: 100%;
$bas-list-control-border-highlight: 1px solid defaults.$color-highlight-on-light;
$bas-list-control-border-grey: 1px solid defaults.$color-library-list-control;

@mixin bas-list-item-control () {
  @include util.button-cursor();

  position: relative;
  flex: 0 0 auto;
  width: $bas-list-control-size;
  height: $bas-list-control-size;
  margin: $bas-list-control-margin;
  border-radius: $bas-list-control-radius;

  > svg {
    @include util.absolute-center();

    width: $bas-list-control-inner-width;
  }
}

@mixin bas-list-item-control-highlight () {
  @include bas-list-item-control();

  border: $bas-list-control-border-highlight;
  background-color: transparent;
  transition: background-color $bas-library-header-icon-fade-out ease-out;

  > svg {
    fill: defaults.$color-highlight-on-light;
    transition: fill $bas-library-header-icon-fade-out ease-out;
  }

  &:active,
  &.active {
    background-color: defaults.$color-highlight-on-light;

    > svg {
      fill: defaults.$color-light-control-buttons;
    }
  }
}

@mixin bas-list-item-control-grey () {
  @include bas-list-item-control();

  border: $bas-list-control-border-grey;

  > svg {
    fill: defaults.$color-library-list-control;
  }

  &:active,
  &.active {
    border: $bas-list-control-border-highlight;

    > svg {
      fill: defaults.$color-highlight-on-light;
    }
  }
}

// Library - Common - Body

$bas-library-body-slide-animation-time: .4s;
$bas-library-body-slide-animation-horizontal: 110%;
$bas-library-body-slide-animation-shadow: 0 0 20px defaults.$color-background-shadow;

$bas-library-body-top-spacing: .4em;
$bas-library-body-side-spacing: .5em;
$bas-library-page-padding: $bas-library-body-top-spacing
  0
  0
  0;

$bas-library-page-overlay-opacity: .3;

@mixin bas-library-body () {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 0;
  top: defaults.$main-header-height-phone;
  width: 100%;
  overflow: hidden;

  @media (min-width: #{defaults.$media-phone-width}) {
    top: defaults.$bmsc-header-height;
  }

  &.bas-library-body-scrolling .bas-library-element-ghost:active {
    transition: background-color .5s ease-in-out;
    background-color: transparent;
  }

  &.bas-library-body-lock-scrolling .bas-library-page-collections {
    overflow-y: hidden;
  }

  // Search toggle

  &.bah-active-search {
    .bas-library-pages {
      transform: translate3d(0, $bas-library-search-height, 0);
    }

    .bas-library-page-collection:last-child {
      padding: 0 0 $bas-library-search-height 0;
    }
  }

  // Library message

  &.bas-library-page-has-message {
    .bas-library-page-message {
      display: block;
    }

    &.bas-library-page-message-has-spinner {
      .bas-library-page-message-spinner {
        display: block;
      }
    }
  }

  // Ng-animate-swap Animation

  .bas-library-page.ng-enter,
  .bas-library-page.ng-leave,
  .bas-library-page-message-container.ng-enter,
  .bas-library-page-message-container.ng-leave {
    transition: transform $bas-library-body-slide-animation-time;
  }

  &.bas-library-body-add {

    .bas-library-page:first-child,
    .bas-library-page-message-container:first-child {
      z-index: 1;
    }

    .bas-library-page.ng-enter,
    .bas-library-page.ng-enter-prepare {
      box-shadow: $bas-library-body-slide-animation-shadow;
    }

    .bas-library-page.ng-enter,
    .bas-library-page.ng-enter-prepare,
    .bas-library-page-message-container.ng-enter,
    .bas-library-page-message-container.ng-enter-prepare {
      transform: translate3d($bas-library-body-slide-animation-horizontal, 0, 0);
    }

    .bas-library-page.ng-enter-active,
    .bas-library-page-message-container.ng-enter-active {
      transform: translate3d(0, 0, 0);
    }

    .bas-library-page.ng-leave,
    .bas-library-page.ng-leave-prepare,
    .bas-library-page-message-container.ng-leave,
    .bas-library-page-message-container.ng-leave-prepare {
      transform: translate3d(0, 0, 0);
    }

    .bas-library-page.ng-leave-active,
    .bas-library-page-message-container.ng-leave-active {
      transform: translate3d(-$bas-library-body-slide-animation-horizontal, 0, 0);
    }
  }

  &.bas-library-body-remove {

    .bas-library-page:last-child,
    .bas-library-page-message-container:first-child {
      z-index: 1;
    }

    .bas-library-page.ng-leave,
    .bas-library-page.ng-leave-prepare {
      box-shadow: $bas-library-body-slide-animation-shadow;
    }

    .bas-library-page.ng-enter,
    .bas-library-page.ng-enter-prepare,
    .bas-library-page-message-container.ng-enter,
    .bas-library-page-message-container.ng-enter-prepare {
      transform: translate3d(-$bas-library-body-slide-animation-horizontal, 0, 0);
    }

    .bas-library-page.ng-enter-active,
    .bas-library-page-message-container.ng-enter-active {
      transform: translate3d(0, 0, 0);
    }

    .bas-library-page.ng-leave,
    .bas-library-page.ng-leave-prepare,
    .bas-library-page-message-container.ng-leave,
    .bas-library-page-message-container.ng-leave-prepare {
      transform: translate3d(0, 0, 0);
    }

    .bas-library-page.ng-leave-active,
    .bas-library-page-message-container.ng-leave-active {
      transform: translate3d($bas-library-body-slide-animation-horizontal, 0, 0);
    }
  }
}

@mixin bas-library-pages () {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  transition: transform defaults.$standard-animation-time;
}

@mixin bas-library-page () {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  // Detail

  &.bas-library-page-has-detail {
    > .bas-library-page-detail-header {
      display: block;
    }
  }

  // Overlay

  &.bas-library-page-has-overlay {
    > .bas-library-page-detail-header {
      opacity: $bas-library-page-overlay-opacity;
    }

    > .bas-library-page-collections {
      opacity: $bas-library-page-overlay-opacity;
    }
  }
}

// Library - Common - Body - Message container

@mixin bas-library-page-message-container () {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

// Library - Common - Body - Message

$bas-library-message-top: 40%;
$bas-library-message-width: 60%;
$bas-library-message-min-width: 100px;

$bas-library-message-spinner-color: defaults.$color-background-shadow;
$bas-library-message-spinner-size: 40px;
$bas-library-message-spinner-margin: 0
  auto
  .6em
  auto;

$bas-library-message-text-font: 1em;

@mixin bas-library-page-message() {
  @include util.absolute-center();

  display: none;
  top: $bas-library-message-top;
  width: $bas-library-message-width;
  min-width: $bas-library-message-min-width;
  pointer-events: none;
}

@mixin bas-library-page-message-spinner() {
  display: none;
  position: relative;
  width: $bas-library-message-spinner-size;
  height: $bas-library-message-spinner-size;
  margin: $bas-library-message-spinner-margin;

  .bas-spinner-swoop .bas-spinner-swoop-circle .bas-spinner-swoop-circle-inner {
    border-top-color: $bas-library-message-spinner-color;
    border-left-color: $bas-library-message-spinner-color;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
}

@mixin bas-library-page-message-text() {
  @include defaults.font-roboto-light;

  display: block;
  margin: auto;
  font-size: $bas-library-message-text-font;
  color: defaults.$color-text-grey;
  text-align: center;
}

// Library - Common - Body - Detail header

@mixin bas-library-page-detail-header() {
  display: none;
  position: relative;
  opacity: 1;
  transition: opacity defaults.$standard-animation-time;
  will-change: opacity;
}

// Library - Common - Body - Collections

@mixin bas-library-page-collections() {
  display: block;
  flex: 1 1 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  list-style-type: none;
  box-sizing: border-box;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity defaults.$standard-animation-time;
  will-change: transform, opacity, padding;
}

// Library - Common - Body - Collection

@mixin grid-width-margin () {
  width: 120px;
  margin: 0 calc((50% / 13) - 60px) 0;

  //12
  @media (max-width : #{2500px}) {
    width: 120px;
    margin: 0 calc((50% / 12) - 60px) 0;
  }

  //11
  @media (max-width : #{2300px}) {
    width: 120px;
    margin: 0 calc((50% / 11) - 60px) 0;
  }

  //10
  @media (max-width : #{2100px}) {
    width: 120px;
    margin: 0 calc((50% / 10) - 60px) 0;
  }

  //9
  @media (max-width : #{1950px}) {
    width: 120px;
    margin: 0 calc((50% / 9) - 60px) 0;
  }

  //8
  @media (max-width : #{1800px}) {
    width: 120px;
    margin: 0 calc((50% / 8) - 60px) 0;
  }

  //7
  @media (max-width : #{1600px}) {
    width: 120px;
    margin: 0 calc((50% / 7) - 60px) 0;
  }

  //6
  @media (max-width : #{1400px}) {
    width: 120px;
    margin: 0 calc((50% / 6) - 60px) 0;
  }

  //5
  @media (max-width : #{1200px}) {
    width: 120px;
    margin: 0 calc((50% / 5) - 60px) 0;
  }

  //4
  @media (max-width : #{1000px}) {
    width: 22%;
    margin: 1.5% 1.5% 0;
  }

  //3
  @media (max-width : #{840px}) {
    width: 30%;
    margin: calc(5%/3) calc(5%/3) 0;
  }

  @media (max-width : #{defaults.$media-phone-width}) {

    //4
    width: 22%;
    margin: 1.5% 1.5% 0;

    //3
    @media (max-width : #{550px}) {
      width: 30%;
      margin: calc(5%/3) calc(5%/3) 0;
    }

    //2
    @media (max-width : #{375px}) {
      width: 45%;
      margin: 2.5% 2.5% 0;
    }
  }
}

@mixin bas-library-page-collection() {
  &.bas-library-collection-has-header {
    > .bas-library-page-collection-header {
      display: flex;
    }
  }

  &.bas-library-collection-is-editing {

    .bas-collection-detail-title-input {
      display: inline;
    }

    .bas-collection-detail-control-remove {
      display: block;
    }

    .bas-collection-detail-title {
      display: none;
    }

    .bas-library-element {
      .bas-library-element-order-control,
      .bas-library-element-order {
        display: block;
      }

      .bas-library-element-cover {
        display: none;
      }

      .bas-library-element-controls {

        .bas-library-element-controls-select {
          display: none;
        }

        .bas-library-element-controls-context {
          display: none;
        }
      }
    }

    &.bas-collection-detail-is-locked {
      .bas-collection-detail-control-remove {
        display: none;
      }
    }
  }

  &.bas-collection-detail-is-locked {
    .bas-collection-detail-locked {
      display: inline;
    }
  }

  &.bas-library-collection-can-grid.bas-library-collection-grid {

    .bas-library-page-collection-list {
      padding: 1em 1em 0;

      .bas-library-element {
        @include grid-width-margin();

        display: inline-flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        border-bottom: none;
        min-height: unset;
        max-height: unset;
        height: auto;
        padding: 0;

        .bas-library-element-ghost-border {
          border-bottom: none;
        }

        .bas-library-element-cover {
          flex: 6 1 auto;
          width: 100%;
          max-width: none;
          height: auto;
          margin: 0;
        }

        .bas-library-element-radio-cover {
          flex: 6 1 auto;
          width: 100%;
          max-width: none;
          height: auto;
          margin: 0;
        }

        .bas-library-element-body {
          flex: 1 1 auto;
          margin: 0.4em 0 .8em;
          width: 100%;
          text-align: left;

          .bas-library-element-body-title {
            font-size: .8em;
          }

          .bas-library-element-body-title-owner {
            font-size: .8em;
          }

          .bas-library-element-body-subtitle {
            font-size: .7em;
          }
        }

        .bas-library-element-extra {
          display: none;
        }

        .bas-library-element-controls {
          display: none;
        }

      }
    }
  }
}

@mixin bas-collection-detail-title-input () {
  @include defaults.font-roboto-regular;

  display: none;
  height: $bas-library-search-input-height;
  padding: $bas-library-search-input-padding;
  border-radius: $bas-library-search-input-radius;
  line-height: $bas-library-search-input-height;
}

.bas-collection-detail-title-input {
  @include bas-collection-detail-title-input();
}

.bas-collection-detail-title-form {
  display: inline;
}

// Library - Common - Body - Collection - List

@mixin bas-library-page-collection-list() {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;
}

// Library - Common - Body - Collection - Element

$bas-library-element-height: 3.5em;
$bas-library-element-height-max: 4em;
$bas-library-element-height-min: 2.5em;

// Flex

$bas-library-element-body-flex: 20 1 0;
$bas-library-element-extra-flex: .5 1 auto;
$bas-library-element-controls-flex: 0 0 auto;

$bas-library-element-spacing: .6em;
$bas-library-element-spacing-cover: .6em;
$bas-library-element-item-padding: $bas-library-element-spacing
  $bas-library-body-side-spacing;
$bas-library-element-item-padding-cover: $bas-library-element-spacing-cover
  $bas-library-body-side-spacing;
$bas-library-element-border: 1px solid rgba(#fff, 0.6);

@mixin bas-library-element-height () {
  height: $bas-library-element-height;
  max-height: $bas-library-element-height-max;
  min-height: $bas-library-element-height-min;
}

@mixin bas-library-element () {
  @include bas-library-element-height();

  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: $bas-library-element-item-padding;

  &:last-child {
    > .bas-library-element-ghost > .bas-library-element-ghost-border {
      border-bottom: none;
    }
  }

  .bas-library-has-cover {
    &.bas-library-element-cover {
      display: block;
    }
  }

  // Extra
  &.bas-library-element-has-duration {
    > .bas-library-element-extra {
      display: block;

      @media (max-width: #{defaults.$media-phone-width}) {
        display: none;
      }
    }
  }

  &.bas-library-element-greyed-out {
    .bas-library-element-body {
      color: defaults.$color-background-disabled;
    }
  }

  // Controls - Favourite
  &.bas-library-element-can-favourite {
    > .bas-library-element-controls > .bas-library-element-controls-favourite {
      display: block;
    }
  }

  // Controls - Selection
  &.bas-library-element-can-select {
    > .bas-library-element-controls > .bas-library-element-controls-select {
      display: block;
    }
  }

  // Controls - Context
  &.bas-library-element-has-context {
    > .bas-library-element-controls > .bas-library-element-controls-context {
      display: block;
    }
  }

  // Controls - Browse
  &.bas-library-element-can-browse {
    > .bas-library-element-controls > .bas-library-element-controls-browse {
      display: block;

      svg {
        width: 70%;
      }
    }
  }

  // Controls - Play
  &.bas-library-element-can-play {
    > .bas-library-element-controls > .bas-library-element-controls-play {
      display: block;
    }
  }
}

$bas-library-element-cover-flex: 1 1 auto;
$bas-library-element-cover-cover-size: 8%;
$bas-library-element-cover-cover-size-minimum: 2.8em;
$bas-library-element-cover-cover-size-maximum: $bas-library-element-height-max;
$bas-library-element-cover-margin-size: .4em;
$bas-library-element-cover-cover-margin: 0
  $bas-library-element-cover-margin-size
  0
  0;

$side-queue-item-order-size: 12px;

@mixin bas-library-element-cover {
  display: none;
  flex: $bas-library-element-cover-flex;
  width: $bas-library-element-cover-cover-size;
  min-width: $bas-library-element-cover-cover-size-minimum;
  max-width: $bas-library-element-cover-cover-size-maximum;
  margin: $bas-library-element-cover-cover-margin;
}

@mixin bas-library-element-order {
  position: relative;
  flex: 0 0 auto;
  display: none;
  width: $side-queue-item-order-size;
  height: 100%;
  margin: $bas-library-element-cover-cover-margin;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-text-grey;
  }
}

.bas-library-cover-multiple-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  box-shadow: defaults.$box-shadow-cover-light;
}

.bas-library-cover-multiple-element {
  position: absolute;
  display: inline-block;
  width: 50%;
  height: 50%;
  background-size: cover;

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}

.bas-library-element-cover-background {
  position: relative;
  display: block;
  padding-bottom: 100%;
  background: no-repeat local center;
  background-size: cover;
  box-shadow: defaults.$box-shadow-cover-light;

  svg {
    position: absolute;
  }
}

@mixin bas-library-element-ghost () {
  @include util.button-cursor();

  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: background-color .5s ease-in-out;

  &:active {
    transition: background-color 0s ease-in-out;
    background-color: rgba(#000, 0.1);
  }
}

@mixin bas-library-element-ghost-border () {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: $bas-library-body-side-spacing;
  right: $bas-library-body-side-spacing;
  border-bottom: $bas-library-element-border;
}

$bas-library-element-body-outer-spacing: .4em;

$bas-library-element-cover-size: 8%;
$bas-library-element-cover-size-min: 3em;
$bas-library-element-cover-size-max: 4.5em;
$bas-library-element-cover-margin: 0
  $bas-library-element-body-outer-spacing
  0
  0;

$bas-library-element-cover-art-size: 80%;
$bas-library-element-cover-art-background-size: 80%;

@mixin bas-library-element-radio-cover-art () {
  display: block;
  margin: auto;
  width: $bas-library-element-cover-art-size;
  padding-bottom: $bas-library-element-cover-art-size;
  background: defaults.$color-foreground no-repeat local center;
  background-size: $bas-library-element-cover-art-background-size;
  box-shadow: defaults.$box-shadow-cover-light;
}

$bas-library-element-body-spacing: .6em;
$bas-library-element-body-margin: 0
  $bas-library-element-body-spacing
  0
  $bas-library-element-body-spacing;
$bas-library-element-body-title-font: 1em;
$bas-library-element-body-subtitle-font: .8em;

@mixin bas-library-element-body () {
  @include util.ellipsis-overflow();

  flex: $bas-library-element-body-flex;
  align-self: center;
  min-width: 0;
  margin: $bas-library-element-body-margin;
}

@mixin bas-library-element-body-title () {
  @include util.ellipsis-overflow();
  @include defaults.font-roboto-regular;

  display: inline;
  font-size: $bas-library-element-body-title-font;
}

@mixin bas-library-element-body-title-owner () {
  @include util.ellipsis-overflow();
  @include defaults.font-roboto-regular;

  display: none;
  font-size: $bas-library-element-body-title-font;
  color: $bas-library-subtitle-color;
}

.bas-library-element-body-title-has-owner {
  .bas-library-element-body-title-owner {
    display: inline;
  }
}

@mixin bas-library-element-body-subtitle () {
  @include util.ellipsis-overflow();
  @include defaults.font-roboto-light;

  display: block;
  font-size: $bas-library-element-body-subtitle-font;
}

$bas-library-element-extra-margin: 0
  .2em
  0
  $bas-library-element-body-outer-spacing;

@mixin bas-library-element-extra () {
  @include util.ellipsis-overflow();
  @include defaults.font-roboto-light;

  display: none;
  flex: $bas-library-element-extra-flex;
  min-width: 0;
  align-self: center;
  margin: $bas-library-element-extra-margin;
  color: defaults.$color-text-grey;
}

@mixin bas-library-element-controls () {
  position: relative;
  flex: $bas-library-element-controls-flex;
  align-self: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

@mixin bas-library-element-controls-favourite () {
  @include bas-list-item-control-highlight();

  display: none;
}

@mixin bas-library-element-controls-select () {
  @include bas-list-item-control-highlight();

  display: none;
}

@mixin bas-library-element-controls-play () {
  @include bas-list-item-control-highlight();

  display: none;
}

@mixin bas-library-element-controls-context () {
  @include bas-list-item-control-grey();

  display: none;
}

@mixin bas-library-element-controls-browse () {
  @include bas-list-item-control-grey();

  display: none;
}

// Library - Common - Classes

.settings-header {
  @include settings-header();
}

.bas-library-header {
  @include bas-library-header();
}

.bas-library-header-back {
  @include bas-library-header-icon-fade();
  @include util.button-cursor();
}

.bas-library-header-home {
  @include bas-library-header-icon-fade();
  @include util.button-cursor();
}

.bas-library-header-content {
  @include bas-library-header-content();
  @include util.ellipsis-overflow();
}

.bas-library-header-content-title {
  @include bas-library-header-content-title();
  @include util.ellipsis-overflow();
}

.bas-library-header-content-subtitle {
  @include bas-library-header-content-subtitle();
  @include util.ellipsis-overflow();
}

.bas-library-header-view-button {
  @include bas-library-header-icon();
  @include util.button-cursor();

  display: none;
  width: $bas-library-header-search-icon-size;
  margin: 0
    $bas-library-header-icon-spacing + 1em
    0
    $bas-library-header-icon-spacing;

  svg {
    transition: fill $bas-library-header-icon-fade-out ease-out;
  }

  &:active {
    svg {
      fill: defaults.$color-highlight-on-light;
    }
  }
}

.bas-library-header-view-button-grid {
  > svg {
    @include util.absolute-center();

    transition: opacity .2s;
    opacity: 0;
    width: 100%;
    fill: rgba(defaults.$color-library-control, 0.6);
  }
}

.bas-library-header-view-button-list {
  > svg {
    @include util.absolute-center();

    transition: opacity .2s;
    opacity: 0;
    width: 100%;
    fill: rgba(defaults.$color-library-control, 0.6);
  }
}

.bas-library-header-search-button {
  @include bas-library-header-icon-fade();
  @include util.button-cursor();

  display: none;
  width: $bas-library-header-search-icon-size;
}

.bas-library-header-search {
  @include bas-library-header-search();
}

.bas-library-header-search-input {
  @include bas-library-header-search-input();
}

.bas-library-body {
  @include bas-library-body();
}

.bas-library-pages {
  @include bas-library-pages();
}

.bas-device-ios {

  .bas-library-header-search {
    transition: none;

    &.bah-active-search {
      top: defaults.$main-header-height-phone;
      transform: none;

      @media (min-width: #{defaults.$media-phone-width}) {
        top: defaults.$bmsc-header-height;
        transform: none;
      }
    }
  }

  .bas-library-body {
    transition: none;

    &.bah-active-search {
      .bas-library-pages {
        top: $bas-library-search-height;
        transform: none;
      }
    }
  }
}

.search-history-wrapper {
  display: block;
  position: absolute;
  left: 1.5em;
  right: 1.5em;
  top: ($bas-library-search-input-ratio + (1 - $bas-library-search-input-ratio) * 0.5) * $bas-library-search-height;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.18), 0 20px 20px rgba(0, 0, 0, 0.15);
}

.search-history {
  @include defaults.font-roboto-light;
  @include util.ellipsis-overflow();
  @include util.button-cursor();

  border-bottom: 1px defaults.$color-library-background solid;
  padding: 0.5em 0;
  margin: 0 .7em;

  &:last-child {
    border: none;
  }
}

.bas-library-page {
  @include bas-library-page();
}

.bas-library-page-message-container {
  @include bas-library-page-message-container();
}

.bas-library-page-message {
  @include bas-library-page-message();
}

.bas-library-page-message-spinner {
  @include bas-library-page-message-spinner();
}

.bas-library-page-message-text {
  @include bas-library-page-message-text();
}

.bas-library-page-detail-header {
  @include bas-library-page-detail-header();
}

.bas-library-page-collections {
  @include bas-library-page-collections();
}

.bas-library-page-collection {
  @include bas-library-page-collection();
}

.bas-library-page-collection-header {
  margin-left: .4em;
  margin-right: .4em;
  display: none;
}

.bas-library-page-collection-list {
  @include bas-library-page-collection-list();
}

.bas-library-element {
  @include bas-library-element();
}

.bas-library-element-ghost {
  @include bas-library-element-ghost();
}

.bas-library-element-ghost-border {
  @include bas-library-element-ghost-border();
}

.bas-library-element-radio-cover-art {
  @include bas-library-element-radio-cover-art();
}

.bas-library-element-order-control {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(#{$bas-library-body-side-spacing + $bas-library-element-cover-margin-size} + #{$side-queue-item-order-size});
  height: 100%;
}

.bas-library-element-order {
  @include bas-library-element-order();
}

.bas-library-element-cover {
  @include bas-library-element-cover();
}

.bas-library-element-body {
  @include bas-library-element-body();
}

.bas-library-element-body-title {
  @include bas-library-element-body-title();
}

.bas-library-element-body-title-owner {
  @include bas-library-element-body-title-owner();
}

.bas-library-element-body-subtitle {
  @include bas-library-element-body-subtitle();
}

.bas-library-element-extra {
  @include bas-library-element-extra();
}

.bas-library-element-controls {
  @include bas-library-element-controls();
}

.bas-library-element-controls-favourite {
  @include bas-library-element-controls-favourite();
}

.bas-library-element-controls-select {
  @include bas-library-element-controls-select();
}

.bas-library-element-controls-play {
  @include bas-library-element-controls-play();
}

.bas-library-element-controls-context {
  @include bas-library-element-controls-context();
}

.bas-library-element-controls-browse {
  @include bas-library-element-controls-browse();
}

// Library - Deezer - Bas Collection - Detail

$bas-collection-detail-top-margin: .8em;
$bas-collection-detail-margin: $bas-collection-detail-top-margin
  0
  1em
  0;

.bas-collection-detail {
  position: relative;
  display: block;
  width: 100%;
  margin: $bas-collection-detail-margin;

  &.bas-collection-detail-has-cover {
    .bas-collection-detail-content-cover {
      display: block;
    }
  }

  &.bas-collection-detail-has-number {
    .bas-collection-detail-content-number {
      display: inline-block;
    }
  }

  &.bas-collection-detail-has-play {
    .bas-collection-detail-control-play {
      display: block;
    }
  }

  &.bas-collection-detail-has-asano-favourite {
    .bas-collection-detail-control-favourite {
      display: block;
    }
  }

  &.bas-collection-detail-has-share {
    .bas-collection-detail-control-share {
      display: block;
    }
  }

  &.bas-collection-detail-has-select {
    .bas-collection-detail-control-select {
      display: block;
    }
  }

  &.bas-collection-detail-has-edit {
    .bas-collection-detail-control-edit {
      display: block;
    }
  }

  &.bas-collection-detail-has-context {
    .bas-collection-detail-control-context {
      display: block;
    }
  }

  &.bas-collection-detail-show-spinner {
    .bas-collection-detail-spinner {
      display: block;
    }

    .bas-collection-detail-control-edit-svg {
      display: none;
    }
  }

  &.bas-collection-detail-show-selection-spinner {
    .bas-collection-detail-selection-spinner {
      display: block;
    }

    .bas-collection-detail-control-selection-svg {
      display: none;
    }
  }
}

$bas-library-tag-radius: .6em;
$bas-library-tag-padding: .2em .4em .3em .4em;
$bas-library-tag-font-size: .8em;

.bas-library-page-detail-tag {
  @include defaults.font-roboto-light;

  display: none;
  vertical-align: bottom;
  padding: $bas-library-tag-padding;
  border-radius: $bas-library-tag-radius;
  border: 1px solid $bas-library-subtitle-color;
  font-size: $bas-library-tag-font-size;
  color: $bas-library-subtitle-color;
}

.bas-collection-detail-has-tag {
  .bas-library-page-detail-tag {
    display: inline-block;
  }
}

.bas-collection-loading {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0.6em auto;

  >div {
    background-color: $bas-library-message-spinner-color;
  }
}

.bas-collection-detail-content {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

$bas-collection-detail-cover-width: 15%;
$bas-collection-detail-cover-min-width: 3em;
$bas-collection-detail-cover-margin: 0
  .6em
  0
  1em;

.bas-collection-detail-content-cover {
  display: none;
  flex: 1 1 auto;
  width: $bas-collection-detail-cover-width;
  min-width: $bas-collection-detail-cover-min-width;
  height: 100%;
  margin: $bas-collection-detail-cover-margin;
  background: no-repeat local center;
  background-size: contain;
}

.bas-collection-detail-content-info {
  position: relative;
  display: block;
  flex: 20 1 0;
  min-width: 0;
}

.bas-collection-detail-title-wrapper {
  margin: .5em 0;
  display: flex;
  align-items: baseline;
}

.bas-collection-detail-title {
  @include defaults.font-roboto-regular;

  display: inline;
}

.bas-collection-detail-content-number {
  flex: 0 0 auto;
  margin: 0 0.4em;
  display: none;
}

.bas-collection-detail-subtitle {
  @include defaults.font-roboto-light;

  display: inline;
  color: $bas-library-subtitle-color;
  vertical-align: bottom;
}

.bas-collection-detail-subtitle-extra {
  @include defaults.font-roboto-light;

  display: none;
  color: $bas-library-subtitle-color;
  vertical-align: bottom;
}

.bas-collection-detail-subtitle-has-extra {
  .bas-collection-detail-subtitle-extra {
    display: inline;
  }
}

.bas-collection-detail-locked {
  @include defaults.font-roboto-light;

  display: none;

  > svg {
    width: 2%;
    fill: $bas-library-subtitle-color;
  }
}

.bas-collection-detail-controls {
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: $bas-collection-detail-top-margin $bas-library-body-side-spacing 0;
  box-sizing: border-box;
}

.bas-collection-detail-control-play {
  @include bas-list-item-control-highlight();

  display: none;
}

.bas-collection-detail-control-favourite {
  @include bas-list-item-control-highlight();

  display: none;
}

.bas-collection-detail-control-share {
  @include bas-list-item-control-highlight();

  display: none;
}

.bas-collection-detail-control-select {
  @include bas-list-item-control-highlight();

  display: none;

  &:active,
  &.active {

    .bas-collection-detail-control-selection-svg {
      background-color: defaults.$color-highlight-on-light;

      > svg {
        fill: defaults.$color-light-control-buttons;
      }
    }
  }

  &.active {
    .bas-collection-detail-selection-spinner {
      .bas-spinner-blade {
        background-color: defaults.$color-light-control-buttons;
      }
    }
  }
}

.bas-collection-detail-control-edit {
  @include bas-list-item-control-highlight();

  display: none;

  > svg {
    width: 80%;
  }
}

.bas-collection-detail-control-edit-svg {

  > svg {
    @include util.absolute-center();

    fill: defaults.$color-highlight-on-light;
    transition: fill $bas-library-header-icon-fade-out ease-out;
    width: 80%;
  }

  &:active,
  &.active {
    background-color: defaults.$color-highlight-on-light;

    > svg {
      fill: defaults.$color-light-control-buttons;
    }
  }
}

.bas-collection-detail-control-selection-svg {

  > svg {
    @include util.absolute-center();

    fill: defaults.$color-highlight-on-light;
    transition: fill $bas-library-header-icon-fade-out ease-out;
    width: 50%;
  }
}

.bas-collection-detail-spinner,
.bas-collection-detail-selection-spinner {
  @include util.absolute-center();

  width: 40px;
  height: 20px;
  display: none;

  .bas-spinner-blade {
    background-color: defaults.$color-highlight-on-light;
  }
}

.bas-collection-detail-control-remove {
  @include bas-list-item-control-highlight();

  display: none;

  > svg {
    width: 80%;
  }
}

.bas-collection-detail-control-context {
  @include bas-list-item-control-grey();

  display: none;
}

// Library - Deezer - Bas detail categories

$bas-detail-categories-padding: .4em
  .4em
  .4em
  .4em;

$bas-detail-category-margin-value: .6em;
$bas-detail-category-margin: 0
  $bas-detail-category-margin-value
  0
  $bas-detail-category-margin-value;
$bas-detail-category-margin-first: 0
  $bas-detail-category-margin-value
  0
  0;
$bas-detail-category-margin-last: 0
  0
  0
  $bas-detail-category-margin-value;
$bas-detail-category-padding: .1em
  .5em
  .2em
  .5em;
$bas-detail-category-radius: 5em;
$bas-detail-category-border: 1px solid defaults.$color-highlight-on-light;

.bas-collection-detail-categories {
  position: relative;
  display: none;
  width: 100%;
  padding: $bas-detail-categories-padding;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  list-style-type: none;
  box-sizing: border-box;
  white-space: nowrap;
}

.bas-collection-detail-category {
  @include defaults.font-roboto-regular;

  display: inline-block;
  margin: $bas-detail-category-margin;
  padding: $bas-detail-category-padding;
  color: defaults.$color-highlight-on-light;
  border: $bas-detail-category-border;
  border-radius: $bas-detail-category-radius;

  &:first-child {
    margin: $bas-detail-category-margin-first;
  }

  &:last-child {
    margin: $bas-detail-category-margin-last;
  }

  &.active {
    color: defaults.$color-foreground;
    background-color: defaults.$color-highlight-on-light;
  }
}

// Library - Template - Tabs

$library-header-navigation-background-color: #f7f7f7;
$library-header-navigation-padding-vertical: .4em;

$bas-library-header-height: 3em;

.bas-library-navigation-bar {
  position: relative;
  display: block;
  width: 100%;
  padding: $library-header-navigation-padding-vertical defaults.$navigation-bar-mask-padding;
  list-style-type: none;
  background-color: $library-header-navigation-background-color;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  mask: defaults.$navigation-bar-mask;
}

.bas-library-navigation-bar-wrapper {
  display: block;
  flex: 0 0 auto;
  width: 100%;
  background-color: $library-header-navigation-background-color;
  transition: transform defaults.$standard-animation-time;
  z-index: 1;
}

.bas-mini-player--mobile {
  display: none;
}

@media (min-height: defaults.$screen-size-600) {
  .bas-mini-player--mobile {
    display: block;
  }

  .bas-mini-player--drawer {
    display: none;
  }

  @media (min-width: defaults.$media-phone-width) {

    .bas-mini-player--mobile {
      display: none;
    }

    .bas-mini-player--drawer {
      display: block;
    }
  }
}
