@use 'defaults';
@use 'util';

$bas-mini-player-margin-value: 1em;

$bas-mini-player-cover-size: 3em;
$bas-mini-player-cover-size-phone: 3.75em;
$bas-mini-player-cover-margin: 0 .6em 0 $bas-mini-player-margin-value;

$bas-mini-player-logo-size: 22px;
$bas-mini-player-logo-margin: $bas-mini-player-logo-size*0.5;

$bas-mini-player-title-margin: 0 .4em;
$bas-mini-player-title-margin-phone: 0 .8em;
$bas-mini-player-title-sub-font: .8em;
$bas-mini-player-title-sub-font-phone: 1.1em;
$bas-mini-player-title-main-font: .8em;
$bas-mini-player-title-main-font-phone: 1.1em;

$bas-mini-player-control-size: 20px;
$bas-mini-player-control-padding: 0 .4em;
$bas-mini-player-control-padding-last: 0 $bas-mini-player-margin-value 0 .4em;

.bas-mini-player-info {
  @include util.button-cursor();

  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: defaults.$color-foreground-selected;
    transition: opacity defaults.$standard-animation-time ease-in-out;
  }

  &:active {
    &::before {
      opacity: 1;
    }

    .bas-mini-player-title-main,
    .bas-mini-player-title-sub {
      color: defaults.$color-highlight-on-dark;
    }
  }

  @media (min-width: #{defaults.$media-phone-width}) and (orientation: portrait) {
    height: 3em;
    margin-top: .5em;
    width: 100%;
  }
}

.bas-mini-player-cover {
  position: relative;
  flex: 0 0 auto;
  width: $bas-mini-player-cover-size-phone;
  height: $bas-mini-player-cover-size-phone;
  margin: $bas-mini-player-cover-margin;
  background: no-repeat local center;
  background-size: contain;
  z-index: 1;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: $bas-mini-player-cover-size;
    height: $bas-mini-player-cover-size;
  }
}

.bas-mini-player-logo {
  display: none;
  position: relative;
  flex: 0 0 auto;
  width: $bas-mini-player-logo-size;
  height: $bas-mini-player-logo-size;
  margin: $bas-mini-player-logo-margin;

  svg {
    width: 100%;
  }
}

.bpy-logo-deezer {
  .bas-mini-player-logo {
    display: block;

    svg {
      @include util.absolute-center();

      fill: defaults.$color-spotify-green;
    }
  }
}

.bpy-logo-spotify {
  .bas-mini-player-logo {
    display: block;

    svg {
      @include util.absolute-center();

      fill: defaults.$color-spotify-green;
    }
  }
}

.bpy-logo-tidal {
  .bas-mini-player-logo {
    display: block;

    svg {
      @include util.absolute-center();

      // TODO Use defaults
      fill: white;
    }
  }
}

.bpy-logo-aap {
  .bas-mini-player-logo {
    display: block;

    svg {
      fill: defaults.$color-light-control-buttons;
    }
  }
}

.bas-mini-player-title {
  flex: 1 1 auto;
  min-width: 0;
  z-index: 1;
}

.bas-mini-player-title-main {
  overflow: hidden;

  .bmq-title {
    @include defaults.font-roboto-regular;

    font-size: $bas-mini-player-title-main-font-phone;
    color: defaults.$color-foreground;

    @media (min-width: #{defaults.$media-phone-width}) {
      font-size: $bas-mini-player-title-main-font;
    }
  }

  &.bmq-too-small {

    .bmq-1,
    .bmqc-invisible {
      padding-right: 2em;
    }

    .bmq-2 {
      display: inline-flex;
    }
  }
}

.bas-mini-player-title-sub {
  overflow: hidden;

  .bmq-title {
    @include defaults.font-roboto-light;

    font-size: $bas-mini-player-title-sub-font-phone;
    color: defaults.$color-foreground;

    @media (min-width: #{defaults.$media-phone-width}) {
      font-size: $bas-mini-player-title-sub-font;
    }
  }

  &.bmq-too-small {

    .bmq-1,
    .bmqc-invisible {
      padding-right: 2em;
    }

    .bmq-2 {
      display: inline-flex;
    }
  }
}

.bas-mini-player-control {
  @include util.button-cursor();

  position: relative;
  flex: 0 0 auto;
  width: $bas-mini-player-control-size;
  height: 100%;
  padding: $bas-mini-player-control-padding;
  z-index: 1;
  display: none;

  &:active {
    svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }

  &:last-child {
    padding: $bas-mini-player-control-padding-last;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;

    @media (orientation: portrait) {
      height: 40px;
    }
  }
}

.bas-mini-player-control-svg-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
  }
}

.bas-mini-player-play-pause {
  @include util.absolute-center();

  width: 100%;
  height: 100%;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
  }

  &:active {
    > svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

.bmp-settings {
  display: none;

  &.bpy-has-song {
    display: flex;
  }

  .bmp-play-pause {
    display: none;
  }

  &.bas-can-play-pause {
    .bmp-play-pause {
      display: block;
    }
  }

  .bmp-previous {
    display: none;
  }

  &.bpy-can-previous {
    .bmp-previous {
      display: block;
    }
  }

  .bmp-next {
    display: none;
  }

  &.bpy-can-next {
    .bmp-next {
      display: block;
    }
  }

  .bmp-paused {
    display: none;
  }

  &.bas-paused {
    .bmp-paused {
      display: block;
    }

    .bmp-play {
      display: none;
    }
  }
}
