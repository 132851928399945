@use 'defaults';
@use 'bas_notch_const';
@use 'util';

$main-footer-font-size: 1rem;
$main-footer-font-size-phone: .75rem;

$main-footer-title-color: rgba(defaults.$color-foreground, 0.5);
$main-footer-subtitle-color: defaults.$color-foreground;
$main-footer-background: rgba(defaults.$color-foreground, 0.1);
$main-footer-highlight: rgba(defaults.$color-highlight-on-dark, 0.5);
$main-footer-fill-disabled: rgba(defaults.$color-foreground, 0.3);
$main-footer-background-disabled: rgba(defaults.$color-foreground, 0.05);
$main-footer-background-active: rgba(defaults.$color-foreground, 0.2);

@mixin bmfe-active() {
  &:active {
    background-color: $main-footer-background-active;
  }
}

@mixin bmfe-selected() {
  fill: defaults.$color-highlight-on-dark;
  color: defaults.$color-highlight-on-dark;

  .bmfe-title {
    color: $main-footer-highlight;
  }

  .bmfe-subtitle {
    color: defaults.$color-highlight-on-dark;
  }
}

@mixin bmfe-enabled() {
  background-color: $main-footer-background;
  fill: defaults.$color-light-control-buttons;
  color: $main-footer-title-color;
  pointer-events: unset;

  .bmfe-title {
    color: $main-footer-title-color;
  }

  .bmfe-subtitle {
    color: $main-footer-subtitle-color;
  }
}

@mixin bmfe-disabled() {
  background-color: $main-footer-background-disabled;
  fill: $main-footer-fill-disabled;
  color: $main-footer-fill-disabled;
  pointer-events: none;

  .bmfe-title {
    color: $main-footer-fill-disabled;
  }

  .bmfe-subtitle {
    color: $main-footer-fill-disabled;
  }
}

.main-footer {
  @include defaults.font-roboto-regular;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  height: defaults.$main-footer-height-phone;
  height: calc(#{defaults.$main-footer-height-phone} + #{bas_notch_const.$notch-safe-bottom});
  box-sizing: border-box;
  fill: defaults.$color-light-control-buttons;
  color: $main-footer-title-color;
  text-align: center;
  font-size: $main-footer-font-size-phone;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: defaults.$main-footer-height;
    height: calc(#{defaults.$main-footer-height} + #{bas_notch_const.$notch-safe-bottom});
    font-size: $main-footer-font-size;
  }
}

.music-footer {
  .bmfe-sources,
  .bmfe-library,
  .bmfe-favourites,
  .bme-favourites-star {
    display: none;
  }

  .bmfe {
    @include bmfe-active();
  }

  .bmfe-sources,
  .bmfe-sources:active,
  .bmfe-favourites,
  .bmfe-favourites:active,
  .bmfe-library,
  .bmfe-library:active {
    @include bmfe-disabled();
  }

  .bmfe-favourites--star {
    .bme-favourites-heart {
      display: none;
    }

    .bme-favourites-star {
      display: block;
    }
  }

  &.main-footer-sources-enabled {

    .bmfe-sources {
      display: block;
    }

    &.main-footer-sources-allowed {

      .bmfe-sources {
        @include bmfe-enabled();
        @include bmfe-active();
      }

      &.main-footer-sources-active {

        .bmfe-sources {
          @include bmfe-selected();
        }
      }
    }
  }

  &.main-footer-library-enabled {

    .bmfe-library {
      display: block;
    }

    &.main-footer-library-allowed {

      .bmfe-library {
        @include bmfe-enabled();
        @include bmfe-active();
      }

      &.main-footer-library-active {

        .bmfe-library {
          @include bmfe-selected();
        }
      }
    }
  }

  &.main-footer-favourites-enabled {

    .bmfe-favourites {
      display: block;
    }

    &.main-footer-favourites-allowed {

      .bmfe-favourites {
        @include bmfe-enabled();
        @include bmfe-active();
      }

      &.main-footer-favourites-active {

        .bmfe-favourites {
          @include bmfe-selected();
        }
      }
    }
  }

  &.main-footer-player-active {

    .bmfe-player {
      @include bmfe-selected();

      .bas-equalizer-block {
        background-color: defaults.$color-highlight-on-dark;
      }
    }
  }
}

$bmfe-icon-width: 100%;
$bmfe-icon-height: 70%;
$bmfe-min-width: 75px;

.bmfe {
  position: relative;
  flex: 1 0 $bmfe-min-width;
  background-color: $main-footer-background;

  svg {
    @include util.absolute-center();

    width: 100%;
    height: 100%;
  }
}

.bmfe-center {
  position: relative;
  width: 100%;
  height: 0.8 * defaults.$main-footer-height-phone;
  top: 0.1 * defaults.$main-footer-height-phone;

  svg {
    width: $bmfe-icon-width;
    height: $bmfe-icon-height;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    height: 0.8 * defaults.$main-footer-height;
    top: 0.1 * defaults.$main-footer-height;
  }
}

.bmfe-icon {
  position: relative;
  display: block;
  margin: auto;
  width: $bmfe-icon-width;
  height: $bmfe-icon-height;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: none;
  }

  > svg {
    @include util.absolute-center();

    width: 100%;
    height: 100%;
  }
}

.bmfe-text {
  display: block;
  text-align: center;
}

.bmfe-title {
  display: none;
  color: $main-footer-title-color;
  font-size: 1em;
  padding-top: 0.1em;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;
  }
}

.bmfe-subtitle {
  color: $main-footer-subtitle-color;
  padding-top: 0.2em;
  font-size: .9em;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1.4em;
    padding-top: 0.1em;
  }
}

.bmfe-subtitle-icon {
  position: relative;
  display: none;
  width: 1em;
  height: 1em;
  margin: .1em .1em 0 0;
  vertical-align: middle;
  white-space: nowrap;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: inline-block;
  }
}

.bmfe-subtitle-text {
  display: inline;
  vertical-align: middle;
}

.bmfe-logo {
  display: none;
  flex: 0 0 30px;
  fill: defaults.$color-foreground;
  background-color: $main-footer-background !important;

  @media (min-width: #{defaults.$media-phone-width}) {
    display: block;
  }
}

.bmfe-sources {
  overflow: hidden;
  flex: 1 1 auto;
  min-width: $bmfe-min-width;

  @media (min-width: #{defaults.$media-phone-width}) {
    flex: 3 1 auto;
  }
}
