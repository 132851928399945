@use 'defaults';
@use 'bas_notch_const';
@use 'util';

$monitor-box-padding: 20px;
$monitor-box-padding-x: 8px;
$monitor-item-spacing: 15px;
$monitor-icon-size: 36px;
$bas-monitor-icon-flow-spacing: 6px;

.bas-object-group {
  @media (min-width: #{defaults.$media-phone-width}) {

    &:nth-child(2n+1) {

      .bas-monitor-info {
        order: 5;
        margin-left: $monitor-item-spacing;
        margin-right: 0;
      }
    }
  }
}

.bas-monitor-container {
  @include defaults.font-roboto-regular();

  color: defaults.$color-foreground;

  &.bas-object-container {
    margin: 0;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    display: flex;
    flex-flow: row nowrap;
  }
}

.bas-monitor-info {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: $monitor-box-padding;
  padding-left: 10%;
  padding-right: 10%;
  background-color: defaults.$color-black-bg-trans;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 200px;
    margin-right: $monitor-item-spacing;
    padding-left: $monitor-box-padding;
    padding-right: $monitor-box-padding;
  }
}

.bas-monitor-data {
  position: relative;
  background-color: defaults.$color-black-bg-trans;
  margin-top: 20px;
  padding: 12px $monitor-box-padding-x;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 0;
    padding-left: $monitor-box-padding;
    padding-right: $monitor-box-padding;
  }
}

.bas-monitor-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.bas-monitor-btn {
  font-size: 12px;
  border-radius: 99px;
  border: 1px solid #3f3f3f;
  padding: 6px 12px;
  color: #aaa;
  background-color: rgba(0, 0, 0, .3);
  cursor: pointer;
  margin-top: 4px;
  white-space: nowrap;
  transition: border-color .2s, color .2s;

  &:hover {
    color: #fff;
    border-color: #696969;
  }
  
  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 14px;
    margin-top: 12px;
  }
}

.bas-monitor-current-value {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;

  @media (min-width: #{defaults.$media-phone-width}) {
    flex-flow: column nowrap;
  }
}

.bas-monitor-current-value-number {
  width: 100%;
  text-align: center;
  font-size: 3.4em;
}

.bas-monitor-current-value-unit {
  width: 100%;
  text-align: center;
  color: defaults.$color-foreground-dark;
  margin-left: 1rem;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-left: unset;
  }
}

.bas-monitor-current-icons {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-right: 1em;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-right: unset;
    margin-top: -1rem;
    margin-bottom: 1rem;
    padding-left: $monitor-icon-size;
  }
}

.bas-monitor-current-icon {
  position: relative;
  width: $monitor-icon-size;
  height: $monitor-icon-size;

  svg {
    fill: defaults.$color-light-control-buttons;
  }

  .bas-icon--up,
  .bas-icon--down {
    margin-left: -8px;
  }

  .bas-icon--up {
    top: -$bas-monitor-icon-flow-spacing;

    svg {
      fill: defaults.$color-green;
    }
  }

  .bas-icon--down {
    top: $bas-monitor-icon-flow-spacing;

    svg {
      fill: defaults.$color-accent-red;
    }
  }
}

.bas-monitor-current-flow-icon {
  position: relative;
}

.bas-monitor-current-text {
  position: relative;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-top: $monitor-box-padding;
  }
}

.bas-monitor-current-title {
  width: 100%;
  text-align: center;
  font-size: 1.8em;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1.5em;
  }
}

.bas-monitor-current-subtitle {
  width: 100%;
  text-align: center;
  color: defaults.$color-foreground-dark;
}

.bas-monitor-chart-wrapper {
  width: 100%;
  height: 280px;
  position: relative;
}

.bas-monitor-chart {
  width: 100%;
  height: 100%;
}

.bas-monitor-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .3);
  backdrop-filter: blur(4px);
  opacity: 0;
  transition: opacity defaults.$standard-animation-time defaults.$animation-timing;
  pointer-events: none;

  &.is-loading {
    transition-delay: 150ms;
    opacity: 1;
  }
}

.bas-monitor--options {
  width: 10em;
}

.bas-monitor-detail-data {
  flex: 1 1 auto;
  min-height: 0;
  position: relative;
  background-color: defaults.$color-black-bg-trans;
  padding-top: $monitor-box-padding;
  padding-left: 0;
  padding-right: $monitor-box-padding-x;
  padding-bottom: $monitor-box-padding;
  padding-left: bas_notch_const.$notch-safe-left;
  padding-right: calc(#{$monitor-box-padding-x} + #{bas_notch_const.$notch-safe-right});
  padding-bottom: calc(#{$monitor-box-padding} + #{bas_notch_const.$notch-safe-bottom});
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: visible;
  -webkit-overflow-scrolling: touch;
}

.bas-monitor-detail-data-container {
  position: relative;
  width: 100%;
  height: 80%;

  @media (min-aspect-ratio: 9/16) {
    height: 100%;
  }
}

.bas-monitor-detail-title {
  @include defaults.font-roboto-regular();

  position: absolute;
  top: 10px;
  left: 2.3em;

  @media (min-width: #{defaults.$media-phone-width}) {
    left: 3em;
  }
}

.bas-monitor-detail-divider {
  display: inline-block;
  color: defaults.$color-foreground;
  margin: 0 .3em;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1.3em;
  }
}

.bas-monitor-detail-type {
  display: inline-block;
  color: defaults.$color-foreground;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1.3em;
  }
}

.bas-monitor-detail-select {
  background-color: transparent;
  display: inline-block;
  color: defaults.$color-foreground;
  width: 10rem;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1.3em;
  }
}

.bas-monitor-detail-option {
  @include defaults.font-roboto-regular();

  color: defaults.$color-background;
}

.bas-monitor-no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.bas-monitor-no-data-title {
  @include defaults.font-roboto-regular();

  color: #aaa;
}

.bas-monitor-detail-arrow {
  display: inline-block;
  position: relative;
  width: .8em;
  height: .8em;

  svg {
    @include util.absolute-center();

    width: 100%;
    height: 100%;
    fill: defaults.$color-light-control-buttons;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    width: 1em;
    height: 1em;
  }
}

.bas-energy-meter {
  + .bas-energy-meter {
    margin-top: 40px;
  }

  .bas-monitor-current-icon,
  .bas-monitor-current-icon-container {
    display: none;
  }

  &.bas-energy-meter--energy {

    .bas-monitor-current-icon,
    .bas-icon--bolt {
      display: block;
    }
  }

  &.bas-energy-meter--gas {

    .bas-monitor-current-icon,
    .bas-icon--fire {
      display: block;
    }
  }

  &.bas-energy-meter--water {

    .bas-monitor-current-icon,
    .bas-icon--droplet {
      display: block;
    }
  }

  &.bas-energy-meter--flow-up {

    .bas-monitor-current-icon,
    .bas-icon--up {
      display: block;
    }
  }

  &.bas-energy-meter--flow-down {

    .bas-monitor-current-icon,
    .bas-icon--down {
      display: block;
    }
  }
}
