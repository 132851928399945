@use 'sass:math';
@use 'defaults';
@use 'bas_notch_const';
@use 'tiles_const';
@use 'util';

$bas-home-margins: tiles_const.$bas-tile-spacing-hor;
$bas-home-margins-phone: 16px;

@mixin bas-home-scenes-spacing() {
  .bas-home-tile-wrapper {
    padding-bottom: 10px;
    margin-bottom: 4px;
  }
}

.bas-home-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: y mandatory;

  .bas-home-info-divider,
  .bas-home-info {
    display: none;
  }

  .bas-home-scenes {
    display: none;
  }

  .bas-home-scenes-scroll-indicator {
    display: none;
  }

  .bas-home-down {
    margin-top: 10px;
  }

  &.bas-message-show {

    .bas-home-info-divider,
    .bas-home-info {
      display: block;
    }
  }

  &.brs-has-favourites {

    .bas-home-scenes {
      display: block;
    }

    .bas-home-down {
      margin-top: 0;
    }

    @media (max-width: #{defaults.$media-phone-width}) {

      &.brs--favourites-count-at-least-4 {

        .bas-home-scenes {
          @include bas-home-scenes-spacing();
        }

        .bas-home-scenes-scroll-indicator {
          display: block;
        }
      }
    }

    @media (max-width: #{defaults.$screen-size-900}) {

      &.brs--favourites-count-at-least-5 {

        .bas-home-scenes {
          @include bas-home-scenes-spacing();
        }

        .bas-home-scenes-scroll-indicator {
          display: block;
        }
      }
    }

    @media (max-width: #{defaults.$screen-size-1100}) {

      &.brs--favourites-count-at-least-6 {

        .bas-home-scenes {
          @include bas-home-scenes-spacing();
        }

        .bas-home-scenes-scroll-indicator {
          display: block;
        }
      }
    }

    @media (max-width: #{defaults.$screen-size-1250}) {

      &.brs--favourites-count-at-least-7 {

        .bas-home-scenes {
          @include bas-home-scenes-spacing();
        }

        .bas-home-scenes-scroll-indicator {
          display: block;
        }
      }
    }

    @media (max-width: #{defaults.$screen-size-1450}) {

      &.brs--favourites-count-at-least-8 {

        .bas-home-scenes {
          @include bas-home-scenes-spacing();
        }

        .bas-home-scenes-scroll-indicator {
          display: block;
        }
      }
    }

    @media (max-width: #{defaults.$screen-size-1600}) {

      &.brs--favourites-count-at-least-9 {

        .bas-home-scenes {
          @include bas-home-scenes-spacing();
        }

        .bas-home-scenes-scroll-indicator {
          display: block;
        }
      }
    }

    @media (min-width: #{defaults.$screen-size-1600}) {

      &.brs--favourites-count-at-least-10 {

        .bas-home-scenes {
          @include bas-home-scenes-spacing();
        }

        .bas-home-scenes-scroll-indicator {
          display: block;
        }
      }
    }
  }
}

$bas-height-fraction: .7;
$bas-forecast-height: math.div(50%, $bas-height-fraction);
$bas-title-height: math.div(50%, $bas-height-fraction);
$bas-item-height: 100% * $bas-height-fraction;

.bas-home-block {
  scroll-snap-align: start;

  &.bas-home-hide-week-overview {
    .bas-widget-forecasts {
      display: none;
    }
  }

  .bas-widget-details-button {
    bottom: -25px;
  }

  &.bas-home-hide-temperature-overview {

    .bas-widget-temp-text {
      display: none;
    }

    .bas-widget-forecast-item {
      height: $bas-item-height;
    }

    .bas-widget-forecast-icon {
      height: $bas-forecast-height;
    }

    .bas-widget-title {
      top: $bas-title-height;
    }

    .bas-widget-details-button {
      bottom: 30px;
    }

    &.bas-home-hide-week-overview {
      .bas-widget-details-button {
        bottom: 43%;
      }
    }
  }
}

.bas-home-block {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bas-home-shortcuts {
  height: 100%;
  width: 100%;
  padding: $bas-home-margins-phone $bas-home-margins-phone 0;
  background-color: defaults.$color-background-active-transparent;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding: $bas-home-margins $bas-home-margins 0;
  }
}

.bas-home-banner {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: defaults.$color-background-active-transparent;
}

.bas-home-info {
  position: relative;
  padding: 10px 0;
  overflow: visible;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin: 0 $bas-home-margins;
  }
}

.bas-home-info-divider {
  border-bottom: 1px solid defaults.$color-foreground-selected;
  margin: 0 $bas-home-margins;
}

$swiper-slider-margin: 40px;

.bas-home-info {

  .bas-home-info-text {
    display: none;
  }

  .swiper-slide {
    width: calc(100% - #{2 * $swiper-slider-margin}) !important;
    margin: 0 $swiper-slider-margin;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: none;

    &.swiper-button-disabled {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .login-user-arrow {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    fill: defaults.$color-light-control-buttons;
    transition: opacity defaults.$standard-animation-time;

    @media (max-width: #{defaults.$media-phone-width}) {
      display: inline-block;
      flex: 0 0 auto;
    }

    &.login-user-arrow-hidden {
      opacity: 0;
    }

    svg {
      @include util.absolute-center();

      display: block;
      width: 100%;
      fill: defaults.$color-light-control-buttons;
      transition: fill defaults.$active-animation-time ease-out;
    }

    &:active,
    :active {
      fill: defaults.$color-highlight-on-dark;
      transition: fill 0s ease-out;
    }
  }

  @media (min-width: #{defaults.$media-phone-width}) {

    .bas-home-info-text-phone {
      display: none;
    }

    .bas-home-info-text {
      display: block;
    }
  }
}

.bas-home-info-title {
  @include defaults.font-roboto-bold;

  color: defaults.$color-foreground;
}

.bas-home-info-title-margin {
  margin-bottom: .4em;
}

.bas-home-info-text {
  @include defaults.font-roboto-regular;

  color: defaults.$color-foreground;
}

.bas-home-info-text-phone {
  @include defaults.font-roboto-regular;

  color: defaults.$color-foreground;
}

.bas-home-info-button {
  flex: 0 0 auto;
  position: relative;
  border-radius: 100%;
  border: 1px solid defaults.$color-foreground;
  width: 32px;
  height: 32px;
  transition: border-color defaults.$standard-animation-time;

  svg {
    @include util.absolute-center();

    width: 60%;
    height: 60%;
    fill: defaults.$color-foreground;
    transition: fill defaults.$standard-animation-time;
  }

  &:active {
    border-color: defaults.$color-highlight-on-dark;

    svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

$bas-home-scenes-padding: tiles_const.$bas-tile-spacing-hor-p;
$bas-home-margins-phone-minus-shadow: $bas-home-margins-phone - $bas-home-scenes-padding;
$bas-home-margins-minus-shadow: $bas-home-margins - $bas-home-scenes-padding;

.bas-home-scenes {
  position: relative;
  margin: $bas-home-margins-phone-minus-shadow $bas-home-margins-phone-minus-shadow 0;
  overflow: hidden;
  mask: linear-gradient(to right, transparent 0, black $bas-home-scenes-padding, black calc(100% - #{$bas-home-scenes-padding}), transparent 100%);

  @media (min-width: #{defaults.$media-phone-width}) {
    margin: $bas-home-margins-minus-shadow $bas-home-margins-minus-shadow 0;
  }
}

.bas-scroll-indicator {

  svg {
    fill: defaults.$color-background-light-more-transparent;
  }
}

.bas-home-tile-wrapper {
  position: relative;
  white-space: nowrap;
  margin-right: -(tiles_const.$bas-tile-spacing-hor-p);
  padding: $bas-home-scenes-padding;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x;
  scroll-padding: $bas-home-scenes-padding;

  .bas-tile {
    scroll-snap-align: start;
    margin-bottom: 5px;
  }

  .bas-tile-separator {
    margin-bottom: 5px;
  }

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-right: -(tiles_const.$bas-tile-spacing-hor);
  }
}

.bas-home-down {
  @include util.button-cursor();
}

.bas-downwards-scroll-indicator,
.bas-upwards-scroll-indicator {
  position: relative;
  display: block;
  text-align: center;
  width: 100%;
  min-height: 28px;

  @media (min-width: #{defaults.$media-phone-width}) {
    height: 34px;
  }

  svg {
    @include util.absolute-center();

    height: 100%;
    fill: defaults.$color-light-control-buttons;
  }
}

.bas-home-up {
  @include util.button-cursor();

  position: relative;
  display: inline-block;
  text-align: center;
  width: 100%;
  flex: 0 0 34px;
  margin-bottom: bas_notch_const.$notch-safe-bottom;

  svg {
    @include util.absolute-center();

    height: 100%;
    fill: defaults.$color-light-control-buttons;
  }
}

.bas-home-up-spacer {
  flex: 1 0 5px;
}

// TEMP CODE

.bas-tile-temp-wrapper {
  @include util.absolute-match-parent();
}

.bas-tile-svg-temp,
.bas-tile-svg-temp-list {
  display: inline-block;
  width: 49%;
  position: absolute;
  height: 100%;
  left: 5%;

  > svg {
    fill: defaults.$color-light-control-buttons-f;
    width: 100%;
    height: 100%;
  }
}

.bas-tile-svg-temp-list {
  left: 45%;
}

.bas-room-content {

  .bas-tile-temperature {

    &.tablet-view,
    &.phone-view {
      display: none;
    }
  }

  &.bas-room--thermostat--has {

    .bas-tile-temperature {
      display: block;
    }
  }
}

.bas-tile-empty {
  opacity: 0;
}

.bas-camera-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
  height: 100%;

  &.bas-notch-margin {
    height: 100%;
    height: calc(100% - #{bas_notch_const.$notch-safe-bottom});
    padding-bottom: 0;
    padding-bottom: bas_notch_const.$notch-safe-bottom;
  }
}

.bas-camera {
  flex: 0 0 auto;
  width: 100%;

  @media (min-width: #{defaults.$media-phone-width}) {
    width: 48%;
  }

  &:last-child {
    margin-bottom: 16px;
  }
}

.bas-camera-image {
  position: relative;
  padding-bottom: 100% * math.div(9, 16);
  background-repeat: no-repeat;
  background-size: cover;
}

.bas-camera-image-square {
  padding-bottom: 100%;
}

.bas-camera-image-detail {
  @include util.absolute-center();

  width: 100%;
}

.callhistory-item-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: white;
  }
}

.bas-home-shortcuts {

  .bti-music {

    // Correct music icon in home tile
    svg {
      top: 2%;
    }
  }
}
