@use 'util';

@mixin weather-icon {
  position: relative;
  overflow: hidden;

  svg {
    @include util.absolute-center;

    height: 155%;
    max-width: 155%;
  }
}
