@use 'defaults';
@use 'util';

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.clear {
  clear: both;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.after--clear-both {
  &::after {
    content: '';
    clear: both;
  }
}

.outline-none {
  outline: none;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-collapse {
  visibility: collapse;
}

.visibility-visible {
  visibility: visible;
}

.text-decoration-none {
  text-decoration: none;
}

.font-initial {
  font-size: initial;
}

.font-size--0-8rem {
  font-size: 0.8rem;
}

.font-size--0-9rem {
  font-size: 0.9rem;
}

.font-size--1rem {
  font-size: 1rem;
}

.font-size--1-1rem {
  font-size: 1.1rem;
}

.font-size--1-2rem {
  font-size: 1.2rem;
}

.font-size--1-3rem {
  font-size: 1.3rem;
}

.font-size--1-4rem {
  font-size: 1.4rem;
}

.font-size--0-8em {
  font-size: .8em;
}

.font-size--0-9em {
  font-size: .9em;
}

.font-size--1em {
  font-size: 1em;
}

.font-size--1-1em {
  font-size: 1.1em;
}

.font-size--1-2em {
  font-size: 1.2em;
}

.font-size--1-3em {
  font-size: 1.3em;
}

.font-size--1-4em {
  font-size: 1.4em;
}

.width-100 {
  width: 100%;
}

.width-0 {
  width: 0;
}

.width-50 {
  width: 50%;
}

.width-75 {
  width: 75%;
}

.width-90 {
  width: 90%;
}

.width-1em {
  width: 1em;
}

.width-1-25em {
  width: 1.25em;
}

.width-2em {
  width: 2em;
}

.min-width-0 {
  min-width: 0;
}

.height-100 {
  height: 100%;
}

.height-0 {
  height: 0;
}

.height-1em {
  height: 1em;
}

.height-1-25em {
  height: 1.25em;
}

.width-48px {
  width: 48px;
}

.width-36px {
  width: 36px;
}

.max-height-100 {
  max-height: 100%;
}

.size--95em {
  width: .95em;
  height: .95em;
}

.size-1em {
  width: 1em;
  height: 1em;
}

.size-1-6em {
  width: 1.6em;
  height: 1.6em;
}

.size-1-8em {
  width: 1.8em;
  height: 1.8em;
}

.size-72px {
  width: 72px;
  height: 72px;
}

// Margin rem

.margin-bottom-0-4rem {
  margin-bottom: .4rem;
}

.margin-bottom-0-8rem {
  margin-bottom: .8rem;
}

.margin-bottom-1rem {
  margin-bottom: 1rem;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

.margin-top-0-4rem {
  margin-top: .4rem;
}

.margin-top-0-8rem {
  margin-top: .8rem;
}

.margin-top-1rem {
  margin-top: 1rem;
}

.margin-top-2rem {
  margin-top: 2rem;
}

.margin-left-0-4rem {
  margin-left: .4rem;
}

.margin-left-0-8rem {
  margin-left: .8rem;
}

.margin-left-1rem {
  margin-left: 1rem;
}

.margin-left-2rem {
  margin-left: 2rem;
}

.margin-right-0-4rem {
  margin-right: .4rem;
}

.margin-right-0-8rem {
  margin-right: .8rem;
}

.margin-right-1rem {
  margin-right: 1rem;
}

.margin-left-right-0-4rem {
  margin-left: .4rem;
  margin-right: .4rem;
}

.margin-left-right-0-8rem {
  margin-left: .8rem;
  margin-right: .8rem;
}

.margin-left-right-1rem {
  margin-left: 1rem;
  margin-right: 1rem;
}

.margin-left-right-2rem {
  margin-left: 2rem;
  margin-right: 2rem;
}

// Margin

.margin-horizontal-auto {
  margin-left: auto;
  margin-right: auto;
}

// Margins em

.margin-bottom-0-4em {
  margin-bottom: .4em;
}

.margin-bottom-0-8em {
  margin-bottom: .8em;
}

.margin-top-0-4em {
  margin-top: .4em;
}

.margin-top-0-8em {
  margin-top: .8em;
}

.margin-left-0-4em {
  margin-left: .4em;
}

.margin-left-0-8em {
  margin-left: .8em;
}

.margin-left-2em {
  margin-left: 2em;
}

.margin-right-0-4em {
  margin-right: .4em;
}

.margin-right-0-8em {
  margin-right: .8em;
}

.margin-left-right-0-4em {
  margin-left: .4em;
  margin-right: .4em;
}

.margin-left-right-0-8em {
  margin-left: .8em;
  margin-right: .8em;
}

.margin-left-right-2em {
  margin-left: 2em;
  margin-right: 2em;
}

// Padding

.padding-bottom-100 {
  padding-bottom: 100%;
}

.padding-top-inherit {
  padding-top: inherit;
}

// Padding rem

.padding-0-4rem {
  padding: .4rem;
}

.padding-0-8rem {
  padding: .8rem;
}

.padding-bottom-0-4rem {
  padding-bottom: .4rem;
}

.padding-bottom-0-8rem {
  padding-bottom: .8rem;
}

.padding-bottom-1-2rem {
  padding-bottom: 1.2rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-top-1-2rem {
  padding-top: 1.2rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-left-right-1rem {
  padding-left: 1rem;
  padding-right: 1rem;
}

.padding-top-bottom-0-4rem {
  padding-top: .4rem;
  padding-bottom: .4rem;
}

// Padding em

.padding-0-4em {
  padding: .4em;
}

.padding-0-8em {
  padding: .8em;
}

.padding-bottom-0-4em {
  padding-bottom: .4em;
}

.padding-bottom-0-8em {
  padding-bottom: .8em;
}

.padding-bottom-1-2em {
  padding-bottom: 1.2em;
}

.padding-left-1em {
  padding-left: 1em;
}

.padding-left-2em {
  padding-left: 2em;
}

.padding-top-1-2em {
  padding-top: 1.2em;
}

.padding-left-right-1em {
  padding-left: 1em;
  padding-right: 1em;
}

.padding-top-bottom-0-4em {
  padding-top: .4em;
  padding-bottom: .4em;
}

.bg-color-background-dark-fixed {
  background-color: defaults.$color-background-dark-fixed;
}

.color-text-grey {
  color: defaults.$color-text-grey;
}

.color-foreground {
  color: defaults.$color-foreground;
}

.color-foreground-dark {
  color: defaults.$color-foreground-dark;
}

.box-sizing-border-box {
  box-sizing: border-box;
}

.border-radius-full {
  border-radius: 9999px;
}

.border-right-2px-solid-transparent {
  border-right-width: 2px;
  border-right-style: solid;
  border-right-color: transparent;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ellipsis-overflow {
  @include util.ellipsis-overflow;
}

.cursor-pointer {
  cursor: pointer;
}

.list-style-none {
  list-style-type: none;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.vertical-align-top {
  vertical-align: top;
}

.overflow-wrap--anywhere {
  overflow-wrap: anywhere;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.opacity-0 {
  opacity: 0;
}

.translate-3d {
  transform: translate3d(0, 0, 0);
}

.bas-absolute-0-0 {
  @include util.absolute-0-0;
}

.bas-absolute-0-0-bottom {
  @include util.absolute-0-0-bottom;
}

.bas-absolute-center {
  @include util.absolute-center;
}

.bas-absolute-100-100 {
  @include util.absolute-match-parent;
}

.display-flex {
  display: flex;
}

.flex-container-row-nowrap {
  display: flex;
  flex-flow: row nowrap;
}

.flex-container-column {
  display: flex;
  flex-flow: column nowrap;
}

.flex-container-column-center {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.flex-1-1-auto {
  flex: 1 1 auto;
}

.flex-0-1-auto {
  flex: 0 1 auto;
}

.flex-0-0-auto {
  flex: 0 0 auto;
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-0-0-auto-center {
  flex: 0 0 auto;
  align-self: center;
}

.flex-0-0-auto-stretch {
  flex: 0 0 auto;
  align-self: stretch;
}

.flex-simple-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.before--flex-1-1-auto {
  &::before {
    content: '';
    flex: 1 1 auto;
  }
}

.font-weight--bold {
  font-weight: bold;
}

.bas-transition-opacity {
  transition: opacity defaults.$standard-animation-time;
}

.bg-center-cover {
  background-size: cover;
  background-position: center;
  background-image: none;
}

.bas-horizontal-scroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.bas-inner-svg-100 {
  position: relative;
  width: 100%;
  height: 100%;

  svg {
    @include util.absolute-center;

    width: 100%;
  }
}

// Force override scroll snap type to disable it
.disable-scroll-snap {
  scroll-snap-type: none !important;
}

// Force override overflow to disable scroll
.disable-scroll {
  overflow: hidden !important;
}

.pointer-events-none {
  pointer-events: none;
}

.bas-font-roboto-regular {
  @include defaults.font-roboto-regular;
}

.bas-font-roboto-light {
  @include defaults.font-roboto-light;
}

.bas-font-roboto-bold {
  @include defaults.font-roboto-bold;
}

.bas-text-uppercase {
  text-transform: uppercase;
}

.bas-text-lowercase {
  text-transform: lowercase;
}

.bas-text-capitalize {
  text-transform: capitalize;
}

.bas--no-swipe {
  // Empty
}

.bas--no-reorder {
  // Empty
}

.bas-scroll-y--width--media-phone {
  @media (min-width: #{defaults.$media-phone-width}) {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.bas-position--top-0 {
  top: 0;
}

.bas-position--bottom-0 {
  bottom: 0;
}

.bas-position--left-0 {
  left: 0;
}

.bas-position--right-0 {
  right: 0;
}

.bas-notch-position--all {
  @include util.bas-notch-position--all;
}

.bas-notch-position--top {
  @include util.bas-notch-position--top;
}

.bas-notch-position--bottom {
  @include util.bas-notch-position--bottom;
}

.bas-notch-position--top-left-right {
  @include util.bas-notch-position--top-left-right;
}

.bas-notch-position--left-right-bottom {
  @include util.bas-notch-position--left-right-bottom;
}

.bas-notch-padding--all {
  @include util.bas-notch-padding--all;
}

.bas-notch-padding--top {
  @include util.bas-notch-padding--top;
}

.bas-notch-padding--bottom {
  @include util.bas-notch-padding--bottom;
}

.bas-notch-padding--left-bottom {
  @include util.bas-notch-padding--left-bottom;
}

.bas-notch-padding--right-bottom {
  @include util.bas-notch-padding--right-bottom;
}

.bas-notch-padding--top-left-right {
  @include util.bas-notch-padding--top-left-right;
}

.bas-notch-padding--left-right-bottom {
  @include util.bas-notch-padding--left-right-bottom;
}

.bas-append-ellipsis-three-dots {
  @include util.bas-append-ellipsis-three-dots;
}

.bas-append-ellipsis-two-dots {
  @include util.bas-append-ellipsis-two-dots;
}

.bas-empty--no-collapse {
  @include util.empty-no-collapse;
}

.bas-square {
  position: relative;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.bas-error-halo {
  box-shadow: 0 0 10px 8px defaults.$color-accent-red-bright--a35;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-in-right {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-right {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slide-in-left {
  from {
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slide-out-left {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}
