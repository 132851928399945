@use 'defaults';
@use 'basstyle_const';
@use 'util';

.bs-color-foreground {
  color: defaults.$color-foreground;
}

.bs-color-foreground-light {
  color: defaults.$color-foreground-light;
}

.bs-color-highlight-on-dark {
  color: defaults.$color-highlight-on-dark;
}

.bs-color-highlight-on-light {
  color: defaults.$color-highlight-on-light;
}

.bs-color-highlight-muted {
  color: defaults.$color-highlight-muted;
}

.bs-color-text-light {
  color: defaults.$color-text-light;
}

.bs-color-text-lighter {
  color: defaults.$color-text-lighter;
}

.bs-color-text-light-secondary {
  color: defaults.$color-text-light-secondary;
}

.bs-color-text-lighter-inverse {
  color: defaults.$color-text-lighter-inverse;
}

.bs-color-text-grey {
  color: defaults.$color-text-grey;
}

.bs-svg-fill-highlight-on-light {
  svg {
    fill: defaults.$color-highlight-on-light;
  }
}

.bs-svg-fill-highlight-on-dark {
  svg {
    fill: defaults.$color-highlight-on-dark;
  }
}

// Badge

.bs-badge {
  @include defaults.font-roboto-regular;

  padding: .2em .5em .3em;
  border-radius: 1em;
  white-space: nowrap;
  color: defaults.$color-foreground;
  background-color: defaults.$color-highlight-on-dark;
}

.bs-badge-dark {
  background-color: defaults.$color-highlight-on-light;
}

.bs-badge--vertical-center {
  padding-top: .25em;
  padding-bottom: .25em;
}

// Text button

.bs-btn-txt {
  @include defaults.font-roboto-regular;
  @include util.button-cursor;

  padding: .45em 1em;
  border-radius: 50em;

  &.bs-btn-txt-light {
    @include defaults.font-roboto-light;
  }

  &.bs-btn-grey {
    color: defaults.$color-text-grey;

    &::before {
      background-color: defaults.$color-background-light-really-transparent;
    }

    &.bas-btn-active,
    &:active,
    &.active {
      color: defaults.$color-foreground;
    }
  }

  &.bs-btn-highlight-on-dark {
    color: defaults.$color-highlight-on-dark;

    &::before {
      background-color: defaults.$color-highlight-selected;
    }
  }

  &.bs-btn-active-effect {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -.55em;
      left: -.4em;
      bottom: -.55em;
      right: -.4em;
      border-radius: 50em;
      opacity: 0;
    }

    &.bs-btn-active-effect-small {
      &::before {
        @include util.absolute-match-parent;
      }
    }
  }

  &.bs-btn-animate {
    transition: color defaults.$standard-animation-time;

    &::before {
      transition: opacity defaults.$standard-animation-time;
    }

    &:hover,
    &:active,
    &.bas-btn-active,
    &.active {
      transition: color 0s;

      &::before {
        opacity: 1;
        transition: opacity 0s;
      }
    }
  }
}

// Button

.bs-btn-base {
  @include defaults.font-roboto-regular;
  @include util.button-cursor;

  padding: .45em 1em;
  border: 1px solid;
  border-radius: 50em;
  text-align: center;
  line-height: initial;
  transition: color defaults.$active-animation-time,
    border-color defaults.$active-animation-time,
    background-color defaults.$standard-animation-time;

  &.bs-btn-grey {
    @include basstyle_const.bs-btn-grey;
  }

  &.bs-btn-dark-grey {
    border-color: defaults.$color-background-active;
  }

  &.bs-btn-highlight-dark {
    @include basstyle_const.bs-btn-highlight-on-light;
  }
}

.bs-btn-dark {
  border-color: defaults.$color-highlight-on-light;
  color: defaults.$color-background;

  &.bas-btn-active,
  &:active {
    @include basstyle_const.bs-btn-instant;

    color: defaults.$color-highlight-on-light;
  }
}

.bs-btn-light {
  border-color: defaults.$color-highlight-on-dark;
  color: defaults.$color-foreground;

  &.bas-btn-active,
  &:active {
    @include basstyle_const.bs-btn-instant;

    color: defaults.$color-highlight-on-dark;
  }
}

.bs-btn-light-on-light {
  border-color: defaults.$color-highlight-on-light;
  color: defaults.$color-foreground;

  &.bas-btn-active,
  &:active {
    @include basstyle_const.bs-btn-instant;

    color: defaults.$color-highlight-on-light;
  }
}

.bs-btn-white-on-dark {
  border-color: defaults.$color-text-light-secondary;
  color: defaults.$color-foreground;

  &.bas-btn-active,
  &:active {
    @include basstyle_const.bs-btn-active-highlight-transparent-fill;
  }

  &:disabled {
    @include basstyle_const.bas-btn-white-on-dark-disabled;
  }
}

.bs-btn-red-on-black {
  border-color: defaults.$color-red-on-black;
  color: defaults.$color-red-on-black;

  &.bas-btn-active,
  &:active {
    @include basstyle_const.bs-btn-instant;

    border-color: defaults.$color-red-on-black-highlight;
    color: defaults.$color-red-on-black-highlight;
  }
}

.bs-btn-circle {
  position: relative;
  width: defaults.$bs-btn-circle-size;
  height: defaults.$bs-btn-circle-size;
  border-width: 1px;
  border-style: solid;
  border-radius: 100%;
  background-color: transparent;
  box-sizing: border-box;

  svg {
    @include util.absolute-center;

    width: 50%;
    height: 50%;
  }

  &.bs-btn-animate {
    @include basstyle_const.bs-btn-animate;

    &.bs-btn-grey {
      transition: border-color defaults.$standard-animation-time;
    }
  }

  &.bs-btn-grey {
    @include basstyle_const.bs-btn-grey;

    &.bas-btn-active,
    &:active,
    &.active {
      border-color: defaults.$color-highlight-on-dark;

      svg {
        fill: defaults.$color-highlight-on-dark;
      }
    }
  }

  &.bs-btn-green {
    @include basstyle_const.bs-btn-green-light;

    &.bas-btn-active,
    &:active,
    &.active {
      @include basstyle_const.bs-btn-green;
    }
  }

  &.bs-btn-red {
    @include basstyle_const.bs-btn-red-light;

    &.bas-btn-active,
    &:active,
    &.active {
      @include basstyle_const.bs-btn-red;
    }
  }

  &.bs-btn-highlight {
    border-color: defaults.$color-highlight-on-dark;

    svg {
      fill: defaults.$color-highlight-on-dark;
    }

    &.bas-btn-active,
    &:active,
    &.active {
      background-color: defaults.$color-highlight-on-dark;

      svg {
        fill: defaults.$color-light-control-buttons;
      }
    }
  }

  &.bs-btn-highlight-dark {
    border-color: defaults.$color-highlight-on-light;

    svg {
      fill: defaults.$color-highlight-on-light;
    }

    &.bas-btn-active,
    &:active,
    &.active {
      background-color: defaults.$color-highlight-on-light;

      svg {
        fill: defaults.$color-light-control-buttons;
      }
    }
  }

  &.bs-btn-svg-big {

    svg {
      width: 70%;
      height: 70%;
    }
  }

  &.bs-btn-svg-60 {

    svg {
      width: 60%;
      height: 60%;
    }
  }
}

.bs-btn-circle-big {
  width: defaults.$bs-btn-circle-big-size;
  height: defaults.$bs-btn-circle-big-size;
}

.bs-btn-svg-big {

  svg {
    width: 70%;
    height: 70%;
  }
}

.bs-btn-svg-60 {

  svg {
    width: 60%;
    height: 60%;
  }
}

.bs-btn-svg-70 {

  svg {
    width: 70%;
    height: 70%;
  }
}

.bs-btn-svg-wrap {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.bs-radio-btn {
  position: relative;
  width: defaults.$bs-btn-radio-btn-size;
  height: defaults.$bs-btn-radio-btn-size;
  box-sizing: border-box;
  border-radius: 100%;
  border: 1px solid defaults.$color-highlight-on-light;

  &.bs-radio-btn-selected {

    &::after {
      @include util.absolute-center;

      content: '';
      width: defaults.$bs-btn-radio-btn-inner-size;
      height: defaults.$bs-btn-radio-btn-inner-size;
      border-radius: 100%;
      background-color: defaults.$color-highlight-on-light;
    }
  }
}

.bs-radio-btn-white {
  border-color: defaults.$color-foreground;
  opacity: 0.7;

  &.bs-radio-btn-selected {

    &::after {
      background-color: defaults.$color-foreground;
    }
  }
}

.bs-radio-btn-small {
  width: 22px;
  height: 22px;
  border-width: 2px;

  &.bs-radio-btn-selected::after {
    width: 12px;
    height: 12px;
  }
}

.bs-btn-animate {
  @include basstyle_const.bs-btn-animate;
}

.bs-btn-grey {
  @include basstyle_const.bs-btn-grey;
}

.bs-btn-active-highlight-on-dark {
  @include basstyle_const.bs-btn-active-highlight-on-dark;
}

.bs-btn-active-full-highlight-on-light {
  @include basstyle_const.bs-btn-active-full-highlight-on-light;
}

.bs-btn-active-highlight-fill-transparent {
  @include basstyle_const.bs-btn-active-highlight-transparent-fill;
}

.bs-btn-active-highlight-on-light {
  @include basstyle_const.bs-btn-active-highlight-on-light;
}

.bs-link-button {
  @include defaults.font-roboto-regular;

  display: inline-block;
  color: defaults.$color-highlight-on-light;
  padding: 10px;
  transition: color defaults.$standard-animation-time;

  &:active {
    transition: color 0s;
    color: defaults.$color-highlight-on-light-darker;
  }
}

// Segmented control

// bssc = bas-segmented-control

.bs-segmented-ctrl {
  @include defaults.font-roboto-regular;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
}

.bssc-el {
  position: relative;
  flex: 1 1 auto;
  text-align: center;
  font-size: .8em;
  border-right-width: 1px;
  border-right-style: solid;
  height: 100%;

  &::before {
    content: 'l';
    display: block;
    opacity: 0;
    z-index: -1;
    padding-top: .5em;
    padding-bottom: .5em;
  }

  &:last-child {
    border-right: none;
  }
}

.bssc-el-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Label

.bs-lbl-base {
  @include defaults.font-roboto-regular;

  padding: .42em 1em .48em;
  border: 1px solid;
  border-radius: .5em;
  text-align: center;
}

.bs-lbl--grey {
  @include basstyle_const.bs-lbl--grey-on-dark;
}

// Line title

.bs-title-line {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &::before,
  &::after {
    position: relative;
    flex: 1 1 auto;
    height: 1px;
    width: 100%;
  }
}

.bs-title-line-txt {
  @include util.ellipsis-overflow;

  flex: 0 0 auto;
  max-width: 100%;
}

.bs-title-line-inline {
  display: inline-flex;
}

.bs-title-line-font-light {
  @include defaults.font-roboto-light;
}

.bs-title-line-color-dark {
  color: defaults.$color-grey;

  &::before,
  &::after {
    background-color: defaults.$color-grey;
  }
}

.bs-title-line-color-light {
  color: defaults.$color-text-light-transparent;

  &::before,
  &::after {
    background-color: defaults.$color-foreground-selected;
  }
}

.bs-title-line-size-large {
  font-size: 2em;
  margin-top: .4em;
  margin-bottom: .4em;
}

.bs-title-line-size-normal {
  font-size: 1.2em;
  margin-top: .4em;
  margin-bottom: .4em;
}

.bs-title-line-empty {

  &::after {
    content: '';
  }
}

.bs-title-line-left {
  text-align: left;

  &::after {
    content: '';
    margin-left: basstyle_const.$bas-title-line-spacing;
  }
}

.bs-title-line-right {
  text-align: right;

  &::before {
    content: '';
    margin-right: basstyle_const.$bas-title-line-spacing;
  }
}

.bs-title-line-center {
  text-align: center;

  &::before,
  &::after {
    content: '';
  }

  &::before {
    margin-right: basstyle_const.$bas-title-line-spacing;
  }

  &::after {
    margin-left: basstyle_const.$bas-title-line-spacing;
  }
}

.bas-context--light {

  .bs-title-line-txt {
    color: defaults.$color-black-dark-bg-trans;
  }
}

.bas-spinner-small {
  display: block;
  position: relative;
  width: 30px;
  height: 20px;

  .bas-spinner-blade {
    background-color: defaults.$color-foreground-dark;
  }
}

.bas-spinner-small-highlight {
  display: block;
  position: relative;
  width: 40px;
  height: 20px;

  .bas-spinner-blade {
    background-color: defaults.$color-highlight-on-dark;
  }
}

// Other

// General

.bas-button {
  @include defaults.font-roboto-regular;

  position: relative;
  display: inline-block;
  padding: basstyle_const.$bas-button-padding;
  vertical-align: middle;
  border-width: basstyle_const.$bas-button-border-size;
  border-style: solid;
  border-color: defaults.$color-highlight-selected;
  border-radius: basstyle_const.$bas-button-radius;
  font-size: basstyle_const.$bas-button-font-size;
  color: defaults.$color-foreground;
  text-transform: uppercase;
  text-align: center;
  background-color: defaults.$color-highlight-bg;
  overflow: hidden;
}

// Select button

.bs-btn-select {
  @include defaults.font-roboto-light;
  @include util.ellipsis-overflow;

  position: relative;
  cursor: pointer;
  padding: .3em .5em;
  line-height: initial;
  border-width: basstyle_const.$bas-button-border-size;
  border-style: solid;
  border-color: defaults.$color-highlight-on-light;
  border-radius: basstyle_const.$bas-button-radius;
  color: defaults.$color-highlight-on-light;
  background-color: transparent;

  &.bs-btn-select-transition {
    transition: color defaults.$active-animation-time,
      background-color defaults.$active-animation-time,
      border-color defaults.$active-animation-time;
  }

  &.bas-btn-active,
  &:active {
    background-color: defaults.$color-highlight-on-light;
    color: defaults.$color-foreground;
  }

  &.bs-btn-select-disabled {
    border-color: defaults.$color-grey;
    color: defaults.$color-text-light;
    background-color: transparent;
  }
}

.bs-btn-centered {
  text-align: center;
}

// Join button

.bas-button-join {
  height: basstyle_const.$bas-button-toggle-handle-height;
  padding: basstyle_const.$bas-button-join-padding;
  line-height: basstyle_const.$bas-button-toggle-handle-height;
  transition: color defaults.$standard-animation-time;

  svg {
    transition: fill defaults.$standard-animation-time;
  }

  &.bas-btn-active,
  &:active {
    transition: none;
    color: defaults.$color-highlight-on-dark;

    svg {
      fill: defaults.$color-highlight-on-dark;
      transition: none;
    }
  }

  @media (max-width: #{defaults.$media-phone-width}) {
    height: basstyle_const.$bas-button-toggle-handle-height;
    padding: basstyle_const.$bas-button-join-padding;
    font-size: 1.8em;
    line-height: basstyle_const.$bas-button-toggle-handle-height;
  }
}

// Toggle button

.bas-toggle-dark {
  .bas-toggle {
    > .toggle-background {
      background-color: defaults.$color-highlight-on-light;
    }
  }
}

.bas-toggle {
  @include util.button-cursor;

  position: relative;
  display: inline-block;
  width: basstyle_const.$bas-button-toggle-width;
  margin: basstyle_const.$bas-toggle-margin;
  padding: basstyle_const.$bas-button-padding;
  vertical-align: middle;
  border-width: basstyle_const.$bas-button-border-size;
  border-style: solid;
  border-color: defaults.$color-highlight-selected;
  border-radius: basstyle_const.$bas-button-radius;
  background-color: defaults.$color-highlight-bg;
  overflow: hidden;
  transform: translateZ(0);

  > .toggle-background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: basstyle_const.$bas-button-radius;
    background-color: defaults.$color-highlight-on-dark;
    opacity: 0;
    transition: opacity basstyle_const.$bas-button-toggle-transition-time;
  }

  > .toggle-handle {
    display: block;
    height: basstyle_const.$bas-button-toggle-handle-height;
    width: basstyle_const.$bas-button-toggle-handle-width;
    border-radius: 100%;
    background-color: defaults.$color-foreground;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    transition: transform basstyle_const.$bas-button-toggle-transition-time;
    transform: translateX(0);
  }

  &.active {
    > .toggle-background {
      opacity: 1;
    }

    > .toggle-handle {
      transform: translateX(basstyle_const.$bas-button-toggle-white-space);
    }
  }

  &.disabled {
    @include basstyle_const.bas-toggle-disabled;
  }
}

// btbs = bas-toggle-button-state

.btbs-default {
  display: block;
}

.btbs-active {
  display: none;
}

// Quad position indicator

.bs-quad-position {
  position: relative;
}

.bsqp-item {
  position: absolute;
  display: block;
  width: 40%;
  height: 40%;
  background-color: defaults.$color-text-light;
}

.bsqp-tl {
  top: 0;
  left: 0;
}

.bsqp-tr {
  top: 0;
  right: 0;
}

.bsqp-bl {
  bottom: 0;
  left: 0;
}

.bsqp-br {
  bottom: 0;
  right: 0;
}

.bsqp--is-tl {

  .bsqp-tl {
    background-color: defaults.$color-light-control-buttons-f;
  }
}

.bsqp--is-tr {

  .bsqp-tr {
    background-color: defaults.$color-light-control-buttons-f;
  }
}

.bsqp--is-bl {

  .bsqp-bl {
    background-color: defaults.$color-light-control-buttons-f;
  }
}

.bsqp--is-br {

  .bsqp-br {
    background-color: defaults.$color-light-control-buttons-f;
  }
}

// Text input

.bs-input {
  padding-top: .6em;
  padding-bottom: .6em;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 999999px;
  box-sizing: border-box;
}

.bs-input-container {
  position: relative;
  border-radius: 999999px;
}

.bs-input-element {
  width: 100%;
  height: 2em;
  padding-top: .6em;
  padding-bottom: .6em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1em;
  border: none;
  box-sizing: border-box;
}

.bs-input-element--right-icon-spacing {
  padding-right: 1em + basstyle_const.$bas-input-icon-size;
}

.bs-input-icon {
  position: absolute;
  top: .37em;
  right: basstyle_const.$bas-input-icon-side-spacing;
  width: basstyle_const.$bas-input-icon-size;
  height: basstyle_const.$bas-input-icon-size;

  svg {
    @include util.absolute-center;

    width: 100%;
  }
}

.bs-input-on-dark {
  background-color: defaults.$color-foreground;

  .bs-input-element {
    background-color: transparent;
  }

  .bs-input-icon {

    svg {
      fill: defaults.$color-grey;
    }
  }
}

// Scrubber

.bas-scrubber {
  @include util.button-cursor;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.bas-scrubber-wrapper,
.bas-scrubber-background,
.bas-scrubber-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: scale3d(1, 1, 1);
  transform-origin: 0 100%;
}

.bas-scrubber-wrapper {
  transition: transform defaults.$standard-animation-time;
}

.bas-scrubber-background {
  background-color: rgba(defaults.$color-background, 0.4);
}

.bas-scrubber-progress {
  background-color: rgba(defaults.$color-highlight-on-dark, 0.8);
}

.bas-scrubber {

  .bas-scrubber-wrapper {
    transform: scale3d(1, .25, 1);
  }

  &.bas-scrubber-enlarge {

    .bas-scrubber-wrapper {
      transform: scale3d(1, 1, 1);
    }
  }
}

// Sideways scroll indication

$arrow-size: 16px;

.bas-scroll-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: $arrow-size;

  svg {
    fill: defaults.$color-background-light-very-transparent;
  }
}

.bas-scroll-indicator-arrow {
  display: inline-block;
  width: $arrow-size;
  height: $arrow-size;
}

.bs-btn-full-highlight-on-dark {
  @include basstyle_const.bs-btn-full-highlight-on-dark;
}

.bs-btn-full-highlight-transparent {
  @include basstyle_const.bs-btn-active-highlight-transparent-fill;
}

.bs-btn-full-highlight-on-light {
  @include basstyle_const.bs-btn-full-highlight-on-light;
}

.bs-btn-on-dark {
  @include basstyle_const.bs-btn-on-dark;
}

.bs-btn-on-light {
  @include basstyle_const.bs-btn-on-light;
}

.bs-btn-on-light-disabled {
  @include basstyle_const.bs-btn-on-light-disabled;
}

.bs-btn-highlight-on-dark {
  @include basstyle_const.bs-btn-highlight-on-dark;
}

.bs-btn-highlight-on-light {
  @include basstyle_const.bs-btn-highlight-on-light;
}

.bs-btn-instant {
  @include basstyle_const.bs-btn-instant;
}

.bs-btn-fast {
  @include basstyle_const.bs-btn-fast;
}

.bs-btn-foreground {
  @include basstyle_const.bs-btn-foreground;
}

// 3 parts fading

.bs-pulse-fade {
  @include basstyle_const.bs-pulse-fade;
}

.bs-pulse-fade-1 {
  animation-delay: -.7s;
}

.bs-pulse-fade-2 {
  animation-delay: -.4s;
}

.bs-pulse-fade-3 {
  animation-delay: -.1s;
}

.bas-video-element {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  position: absolute;
}

// Pagination

$bas-pagination-scroll-height: 50px;

.bas-pagination-scroll {
  width: 100%;
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height defaults.$standard-animation-time;

  .bas-pagination-scroll-indicator,
  .bas-pagination-scroll-spinner {
    @include util.absolute-center;

    transition: opacity defaults.$standard-animation-time;
    display: inline-block;
  }

  .bas-pagination-scroll-indicator {
    position: relative;
    width: 30px;
    height: 30px;
    transition-delay: defaults.$standard-animation-time;
    cursor: pointer;
    opacity: 0;

    svg {
      @include util.absolute-center;

      fill: defaults.$color-light-control-buttons;
      width: 100%;
    }

    &:active {
      svg {
        fill: defaults.$color-highlight-on-dark;
      }
    }
  }

  .bas-pagination-scroll-spinner {
    width: 20px;
    height: 20px;
    opacity: 0;
    pointer-events: none;

    .bas-spinner-blade {
      background-color: defaults.$color-text-light;
    }
  }

  &.bas-pagination-scroll-has-more,
  &.bas-pagination-scroll-loading {
    height: $bas-pagination-scroll-height;
    transition: none;
  }

  &.bas-pagination-scroll-has-more {

    .bas-pagination-scroll-indicator {
      opacity: 1;
    }
  }

  &.bas-pagination-scroll-loading {

    .bas-pagination-scroll-indicator {
      opacity: 0;
      transition-delay: 0s;
    }

    .bas-pagination-scroll-spinner {
      opacity: 1;
      transition-delay: defaults.$standard-animation-time;
    }
  }

  &.bas-pagination-scroll-dark {

    .bas-spinner-blade {
      background-color: defaults.$color-text-dark;
    }

    svg {
      fill: defaults.$color-text-dark;
    }
  }
}

// Text with (right) icon

.bas-text-icon {
  @include defaults.font-roboto-regular;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}

.bti-text {
  flex: 1 1 auto;
  display: inline-block;
  color: defaults.$color-text-lighter;
}

.bti-icon {
  flex: 0 0 auto;
  display: inline-block;
  position: relative;
  margin-left: .3em;
  width: 16px;
  height: 16px;

  svg {
    @include util.absolute-center;

    width: 100%;
    height: 100%;
    fill: defaults.$color-light-control-buttons;
  }
}

// Swiper

.bas-swiper-nav-icon {
  // Experimentally determined
  --swiper-navigation-size: 60px;
  --swiper-navigation-color: #{defaults.$color-light-control-buttons};
  --swiper-theme-color: #{defaults.$color-light-control-buttons};

  svg {
    position: static;
    width: defaults.$bs-swiper-nav-icon-size;
    height: defaults.$bs-swiper-nav-icon-size;
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$standard-animation-time;
  }

  // Disable default indicator
  &::after,
  &::before {
    content: none;
  }

  &:active {

    svg {
      fill: defaults.$color-highlight-on-dark;
      transition: none;
    }
  }
}

.bas-swiper-nav--on-dark {

  svg {
    fill: defaults.$color-light-control-buttons;
  }

  &:active {

    svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

.outline-btn {
  @include defaults.font-roboto-regular();

  color: defaults.$color-foreground;
  height: basstyle_const.$outline-button-width;
  align-items: center;
  display: flex;
  text-align: center;
  border: basstyle_const.$outline-button-border;
  border-radius: #{basstyle_const.$outline-button-width * .5};
  box-sizing: border-box;
}

.outline-btn-wrapper {
  cursor: pointer;
  // Static touch surface expansion
  margin: -#{basstyle_const.$outline-button-spacing};
  padding: basstyle_const.$outline-button-spacing;
  display: inline-block;

  &:active > .outline-btn {
    color: defaults.$color-highlight-on-dark;
  }
}

.bas-width-collapse-animate {
  transition: width defaults.$standard-animation-time;
}

.bas-height-collapse-animate {
  transition: height defaults.$standard-animation-time;
}
