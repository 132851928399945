@use 'defaults';

.security-alarms-view {
  max-width: defaults.$media-width-large;

  .bs-tile-header {
    height: 48px;
  }

  .security-alarm-active-status {
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  .security-alarm-active-icon {
    display: flex;
    justify-content: center;
    width: 100%;

    svg {
      fill: white;
    }
  }

  .security-alarm-tiles {
    display: flex;
    margin-top: 1em;

    .security-alarm-tile {
      flex: 1;
      padding: 1em 0;
      margin-right: 1em;
      cursor: pointer;
      color: white;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.3);

      &:last-child {
        margin: 0;
      }

      .security-alarm-tile-icon {
        margin-bottom: 1em;
        width: 48px;
        height: 48px;

        svg {
          fill: white;
        }
      }

      .security-alarm-tile-title {
        margin-bottom: 1em;
      }
    }
  }

  .security-alarm-armed-bg {
    background-color: rgba(198, 40, 40, .3);
  }
}

.modal-card-settings-body {
  padding-bottom: 0;
}

.bs-tile-on-dark .security-status-error {
  color: #eb1e38;
}

.bs-tile-body {
  transition-duration: defaults.$standard-animation-time;

  .security-room-extra-info {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.bas-progress {
  display: none;
}

.im-show-cancel-button {
  .modal-card-body-footer {
    text-align: center;

    .modal-submit-button {
      border: none;
    }
  }
}

.modal-card-keypad-button:last-child {
  margin: 0;
}

.security-rooms-status-view {
  .bst-title {
    margin-bottom: 2px;
  }

  .collapsible-wrapper {
    overflow: hidden;
  }

  .security-rooms-drop-down-icon {
    width: 20px;
    transition-duration: defaults.$standard-animation-time;

    svg {
      fill: defaults.$color-text-grey;
    }
  }
}
