@use 'defaults';
@use 'util';

$max-width-item-title-lena: 68px;
$max-width-item-title-ellie: 75px;

.bmsd-item-indicator {

  .bmsdi-name,
  .callhistory-item-timestamp-arrow {
    fill: darkgrey;
  }

  &.active {
    .callhistory-item-timestamp-arrow {
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

.callhistory-container {
  overflow: scroll;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  display: block;

  .bmsd-scroll-container {
    overflow-x: unset;
    overflow-y: unset;
  }

  .ch-line {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    padding: 1em 1.5em;
    background-color: defaults.$color-background;
    border-top: 1px solid defaults.$color-foreground-selected;
    z-index: 5;
  }

  .ch-title {
    @include defaults.font-roboto-regular;
    
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    color: defaults.$color-foreground;
    font-size: 1.2em;
    min-width: 0;
  }

  .bmsd-item {
    padding: unset;
    margin: 0.6em 1em 1em;
  }
  
  .callhistory-no-calls {
    color: white;
    text-align: center;
    width: 100%;
  }

  .collapsible-wrapper {
    overflow: hidden;
  }

  .collapsible-wrapper,
  .callhistory-collapsible-items {
    &.add-collapsed-padding {
      position: relative;
      z-index: 1;
      height: 0;
    }
  }

  .callhistory-collapsible-items {
    padding-bottom: .5em;

    &.hide-callhistory-collapsible-items {
      padding: 0;
    }
  }

  .callhistory-container-content {
    .callhistory-collapse-icon {
      width: 20px;
      transition-duration: .4s;
    
      @media (min-width: #{defaults.$media-phone-width}) {
        width: 24px;
      }
    }

    .callhistory-collapse-icon.bmsdh-icon {
      margin-left: -.5em;
      margin-right: .5em;
    }
  }

  .callhistory-item-container {
    .callhistory-item {
      display: flex;

      .callhistory-item-timestamp-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .callhistory-item-timestamp {
          text-transform: capitalize;
          font-size: .8em;
          padding-right: .2em;
          margin: 0;
          flex: 1;
        }

        .callhistory-item-timestamp-arrow {
          width: 15px;
          height: 13px;
        }
      }

      .callhistory-item-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 2;
        padding-top: 1em;
        height: 20px;

        .callhistory-item-content-description {
          padding-left: .6em;

          .callhistory-item-header {

            .callhistory-item-title {
              max-width: $max-width-item-title-ellie;
              color: white;
              font-size: 1em;

              @media (min-width: defaults.$screen-size-1100) {
                max-width: $max-width-item-title-lena;
              }

              &.call-declined {
                color: #cc2323;
              }
            }
          }
        }
      }

      .callhistory-item-image {
        position: relative;
        height: 100px;
        max-width: 100px;
        flex: 1;

        .callhistory-item-placeholder {
          background-color: #353535;
        }
      }
    }

    .callhistory-item-divider {
      border-bottom: 1px solid rgba(255, 255, 255, .6);
      margin: 1em 0;
    }
  }

  .callhistory-item-container:last-child {
    .callhistory-item-divider {
      border: none;
    }
  }
}

// Detail page
.callhistory-detail-images {
  .callhistory-detail-no-images {
    font-family: 'Roboto', sans-serif;
    color: #bebebe;
    z-index: 5;
  }

  .callhistory-detail-content-container {
    max-width: 415px;
    height: 100%;

    &:not(.bas-device-lena &) {
      @media (min-width: defaults.$media-query-pc-landscape) {
        max-width: 700px;
      }
    }
  }

  .callhistory-detail-content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .callhistory-detail-text-overlay {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    font-family: 'Roboto', sans-serif;
    color: #f1f1f1;
    background: linear-gradient(
        to top, rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.45) 30%,
        rgba(0, 0, 0, 0.35) 50%,
        transparent 100%
      );
    z-index: 3;

    .text-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .column-left {
      padding-left: 1em;
    }
    
    .column-right {
      align-items: flex-end;
      padding-right: 1em;
    }
  }

  .no-preview-available .callhistory-detail-text-overlay {
    max-width: 415px;
    width: 100%;

    &:not(.bas-device-lena &) {
      @media (min-width: defaults.$media-query-pc-landscape) {
        max-width: 700px;
      }
    }
  }

  .callhistory-detail-text-metadata {
    font-size: .8em;
    line-height: 1.5em;
  }
}
