@use 'defaults';
@use 'bas_notch_const';
@use 'util';
@use 'tiles_const';
@use 'grid';

$spacing: 15px;
$spacing-wide: 30px;
$margin-actual: 25px;
$margin-corrected: 20px;
$title-height: 4em;
$title-height-small: auto;
$tile-spacing-mobile: 10px;
$tile-spacing: 40px;
$scrollbar-height: 1em;
$third-spacing-correction: 1.3em;
$padding-top: 15px;

$bas-comfort-grid-mask-size: 5px;
$bas-comfort-grid-mask: linear-gradient(
  to right,
  transparent #{$margin-corrected - $bas-comfort-grid-mask-size},
  black #{$margin-corrected},
  black calc(100% - #{$margin-corrected}),
  transparent calc(100% - #{$margin-corrected - $bas-comfort-grid-mask-size})
);
$bas-comfort-grid-mask-vertical: linear-gradient(
  to bottom,
  transparent #{$margin-corrected - $bas-comfort-grid-mask-size},
  black #{$margin-corrected},
  black calc(100% - #{$margin-corrected}),
  transparent calc(100% - #{$margin-corrected - $bas-comfort-grid-mask-size})
);

$max-aspect-ratio-portrait-sticky-bottom: '9/14';
$max-aspect-ratio-landscape-sticky-bottom: '100/85';
$low-tablet-portrait-height: 760px;
$bottom-margin: calc(#{$margin-actual} + #{bas_notch_const.$notch-safe-bottom});

// Compatibility layout
.bas-room-content {
  width: calc(100% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
  max-width: defaults.$media-query-tablet-landscape;
  margin: auto;
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.bas-rds-grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  @media (min-width: defaults.$media-query-tablet-portrait) {
    background-color: defaults.$color-black-bg-trans;
  }
}

.bas-rds-grid-element {
  display: inline-block;
  flex: 0 0 calc(50% - #{tiles_const.$bas-small-tile-outer-margin});
  padding-bottom: calc(50% - #{tiles_const.$bas-small-tile-outer-margin});

  @media (min-width: defaults.$media-query-tablet-portrait) {
    flex: 0 0 calc(33.3333% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    padding-bottom: calc(33.333% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
  }

  @media (min-width: defaults.$media-query-tablet-landscape) {
    flex: 0 0 calc(25% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    padding-bottom: calc(25% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
  }
}

.bas-rdc-container {
  flex-direction: row;

  @media (min-width: defaults.$media-query-tablet-portrait) {
    background-color: defaults.$color-black-bg-trans;
  }
}

.bas-rd-grid-element {
  width: 100%;
  display: inline-block;
}

.bas-rd-grid-element-music {
  margin-right: 20px;
  width: 100%;
  margin-bottom: 20px;
  padding-top: 24px;

  @media (min-width: defaults.$media-query-tablet-portrait) {
    width: 100%;
  }

  @media (min-width: defaults.$media-query-tablet-landscape) {
    width: calc(50% - 20px);
  }
}

.bas-rd-grid-element-thermostat {
  width: 100%;
  margin-top: 20px;

  .bas-tile-temperature {
    padding-bottom: 50%;
  }

  @media (min-width: defaults.$media-query-tablet-portrait) {
    width: 100%;
  }

  @media (min-width: defaults.$media-query-tablet-landscape) {
    width: calc(50% - 20px);
    float: right;
    margin-top: 65px;
  }
}

.bas-rdc-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.bas-small-comfort-tile {
  width: 100%;
  padding-bottom: 100%;
}

.bas-rdc-comfort-tile-wrapper {
  display: inline-block;
  width: 100%;
  flex: 0 0 calc(50% - #{tiles_const.$bas-small-tile-outer-margin});
  margin-right: #{tiles_const.$bas-small-tile-outer-margin};

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: defaults.$media-query-tablet-portrait) {
    flex: 0 0 calc(33.3333% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    margin-right: #{tiles_const.$bas-small-tile-outer-margin-tablet};
  }

  @media (min-width: defaults.$media-query-tablet-landscape) {
    flex: 0 0 calc(25% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
  }
}

// Titles
.bas-rd-grid-element-title {
  padding-top: 1em;
}

.bas-rd-grid-element-music-title {
  display: none;
}

.bas-settings-arrows {
  display: none;
}

.brs-add-favourite {
  .bas-tile-message-scenes {
    display: block;
  }

  &.brs-has-on-off {
    .bas-tile-message-scenes {
      top: 25%;

      @media (max-width: defaults.$media-query-tablet-portrait) {
        top: 33.33%;
      }
    }
  }
}

.bas-thermostats {
  .bas-rd-pagination {
    bottom: 0;
  }
}

// Grid layout if supported
@supports (display: grid) {

  // Reset compatibility layout
  .bas-room-content {
    width: initial;
    max-width: initial;
    margin: initial;
    position: initial;
    height: initial;
    overflow-y: initial;
    overflow-x: initial;
  }

  .bas-rds-grid-container {
    display: initial;
    flex-direction: initial;
    flex-wrap: initial;
    position: initial;

    @media (min-width: defaults.$media-query-tablet-portrait) {
      background-color: initial;
    }
  }

  .bas-rds-grid-element {
    display: initial;
    flex: initial;
    padding-bottom: initial;

    @media (min-width: defaults.$media-query-tablet-portrait) {
      flex: initial;
      padding-bottom: initial;
    }

    @media (min-width: defaults.$media-query-tablet-landscape) {
      flex: initial;
      padding-bottom: initial;
    }
  }

  .bas-rdc-container {
    flex-direction: initial;

    @media (min-width: defaults.$media-query-tablet-portrait) {
      background-color: initial;
    }
  }

  .bas-rd-grid-element {
    width: initial;
    display: flex;
  }

  .bas-rd-grid-element-music {
    margin-right: initial;
    width: initial;
    margin-bottom: initial;
    padding-top: initial;

    @media (min-width: defaults.$media-query-tablet-portrait) {
      width: initial;
    }

    @media (min-width: defaults.$media-query-tablet-landscape) {
      width: initial;
    }
  }

  .bas-rd-grid-element-thermostat {
    width: initial;
    margin-top: initial;

    .bas-tile-temperature {
      padding-bottom: initial;
    }

    @media (min-width: defaults.$media-query-tablet-portrait) {
      width: initial;
    }

    @media (min-width: defaults.$media-query-tablet-landscape) {
      width: initial;
      float: initial;
      margin-top: initial;
    }
  }

  .bas-rdc-container {
    display: initial;
    flex-wrap: initial;
    flex-direction: initial;
  }

  .bas-small-comfort-tile {
    width: initial;
    padding-bottom: initial;
  }

  .bas-rdc-comfort-tile-wrapper {
    display: initial;
    width: initial;
    flex: initial;
    margin-right: initial;

    &:last-child {
      margin-right: initial;
    }

    @media (min-width: defaults.$media-query-tablet-portrait) {
      flex: initial;
      margin-right: initial;
    }

    @media (min-width: defaults.$media-query-tablet-landscape) {
      flex: initial;
    }
  }

  // Titles
  .bas-rd-grid-element-title {
    padding-top: initial;
  }

  .bas-rd-grid-element-music-title {
    display: initial;
  }

  .bas-settings-arrows {
    display: initial;
  }

  // The way the grid system is used here:
  //  All elements that should be placed within the grid, should define 4
  //  properties:
  //    grid-column-start: col-...-start;
  //    grid-column-end: col-...-end;
  //    grid-row-start: row-...-start;
  //    grid-row-end: row-...-end;
  //  This can be easily done with the mixin 'grid-id(id), in _grid.scss.
  //  The idea is that the positioning properties of the elements themselves should
  //  never change. We just change the template of the grid to create a whole
  //  new arrangement, and this can be done with the properties
  //  'grid-template-columns' and 'grid-template-rows'.
  //
  //  To make sure that the elements within the grid take up the right amount of
  //  space within its 'box' within the grid, we can use the mixin
  //  'aspect-ratio(width, height)' aspect-ratio-percent(heightPercentage) to
  //  give them a specific aspect ratio.
  //
  // When the screen is within a specific aspect-ratio, we set the height in a
  //  manner so that the bottom elements stick to the bottom of the screen. When
  //  the screen is no longer within these proportions, it means that there is
  //  too much whitespace in between the items. Height/alignment is then changed
  //  so the items are compact again.

  .bas-room-content {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    // Mobile layout: utilize all space, dont take '$notch-safe-bottom' into
    //  account. It will be handled in an inner element.
    box-sizing: border-box;
    height: 100%;
    padding-top: $padding-top;

    // Tablet layout or higher:
    //  - notch bottom padding is handled on separate element
    @media (min-width: defaults.$media-query-tablet-portrait) {

      .bas-rd-grid-bottom-spacing {
        flex: 0 0 bas_notch_const.$notch-safe-bottom;
      }
    }

    // Smaller tablets in landscape layout: hide comfort title and tweak padding
    @media (max-height: $low-tablet-portrait-height) and (min-width: defaults.$media-query-tablet-landscape) and (max-width: #{defaults.$media-query-pc-landscape - 1}) {
      padding-top: 0;

      .bas-rd-grid-element-comfort-title {
        display: none;
      }

      .bas-rd-grid-bottom-spacing {
        // Allow bottom spacing to shrink until grid bottom margin and this
        //  element combined is still at least as high as $notch-safe-bottom
        flex-shrink: 1;
        min-height: calc(#{bas_notch_const.$notch-safe-bottom} - #{$margin-actual});
      }
    }
  }

  .bas-rd-grid-container {

    // Main grid template for mobile
    @include grid.reset-height();

    display: grid;
    flex: 1 1 auto;
    width: 100%;
    grid-template-columns: //
      [col-comfort-start] $spacing //
      [col-title-scenes-start col-scenes-start col-title-comfort-start col-thermostat-start] 1fr //
      [col-title-scenes-end col-scenes-end col-thermostat-end col-title-comfort-end] $spacing //
      [col-comfort-end];
    grid-template-rows: //
      [row-title-scenes-start] $title-height-small //
      [row-title-scenes-end row-scenes-start] min-content //
      [row-scenes-end] minmax(3px, 1fr) //
      [row-title-comfort-start] $title-height-small //
      [row-title-comfort-end row-thermostat-start] min-content //
      [row-thermostat-end] $spacing //
      [row-comfort-start] min-content //
      [row-comfort-end];

    // Main grid template for tablet in portrait
    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      @include grid.reset-height();

      grid-template-columns: $margin-actual //
        [col-title-scenes-start col-scenes-start col-title-comfort-start col-comfort-start] 1fr //
        [col-title-scenes-end col-scenes-end] $spacing //
        [col-title-music-start col-music-start col-thermostat-start] 1fr //
        [col-title-music-end col-music-end col-thermostat-end col-title-comfort-end col-comfort-end] $margin-actual;
      grid-template-rows: //
        [row-title-scenes-start row-title-music-start] $title-height //
        [row-title-scenes-end row-title-music-end row-scenes-start row-music-start] auto //
        [row-music-end] $spacing //
        [row-thermostat-start] auto //
        [row-scenes-end row-thermostat-end] 1fr //
        [row-title-comfort-start] $title-height //
        [row-title-comfort-end row-comfort-start] auto //
        [row-comfort-end] $margin-actual;

      @media (pointer: coarse) and (min-aspect-ratio: $max-aspect-ratio-portrait-sticky-bottom) {
        flex: 1 1 auto;
      }
    }

    // Main grid template for tablet in landscape
    @media (min-width: #{defaults.$media-query-tablet-landscape}) {
      @include grid.reset-height();

      grid-template-columns: $margin-actual //
        [col-title-scenes-start col-scenes-start col-title-comfort-start col-comfort-start] 2fr //
        [col-title-scenes-end col-scenes-end col-comfort-end] $spacing-wide //
        [col-title-music-start col-music-start col-thermostat-start] 1fr //
        [col-title-music-end col-music-end col-thermostat-end col-title-comfort-end] $margin-actual;
      grid-template-rows: //
        [row-title-scenes-start row-title-music-start] $title-height //
        [row-title-scenes-end row-title-music-end row-scenes-start row-music-start] auto //
        [row-scenes-end row-music-end] 1fr [row-title-comfort-start] $title-height //
        [row-title-comfort-end row-comfort-start row-thermostat-start] auto //
        [row-thermostat-end] auto //
        [row-comfort-end] $margin-actual;

      @media (pointer: coarse) and (min-aspect-ratio: $max-aspect-ratio-landscape-sticky-bottom) {
        flex: 1 1 auto;
      }

      @media (max-height:#{$low-tablet-portrait-height}) {
        grid-template-rows: //
          [row-title-scenes-start row-title-music-start] $title-height //
          [row-title-scenes-end row-title-music-end row-scenes-start row-music-start] auto //
          [row-scenes-end row-music-end] minmax(#{$spacing-wide}, 1fr) //
          [row-title-comfort-start row-title-comfort-end row-comfort-start row-thermostat-start] auto //
          [row-thermostat-end] auto //
          [row-comfort-end] $margin-actual;
      }
    }

    // Main grid template for pc in landscape
    @media (min-width: #{defaults.$media-query-pc-landscape}) {
      @include grid.reset-height();

      max-width: 1100px;
      margin: 0 auto;
      grid-template-rows: //
        [row-title-scenes-start row-title-music-start] $title-height //
        [row-title-scenes-end row-title-music-end row-scenes-start row-music-start] auto //
        [row-scenes-end row-music-end row-title-comfort-start] $title-height //
        [row-title-comfort-end row-comfort-start row-thermostat-start] auto //
        [row-thermostat-end] auto //
        [row-comfort-end] $margin-actual;

      @media (pointer: coarse) and (min-height: #{defaults.$media-width-large}) {
        max-width: 1200px;
      }
    }
  }

  .bas-rd-grid-element {
    height: 100%;
    width: 100%;
  }

  .bas-rd-grid-inner {
    @include util.absolute-match-parent;
  }

  // Scenes
  .bas-rd-grid-element-scenes-title {
    @include grid.grid-id('title-scenes');
  }

  .bas-rd-grid-element-scenes {
    @include grid.grid-id('scenes');

    // Mobile: 3x2
    @include grid.aspect-ratio(3,2);

    // Tablet portrait: 2x3
    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      @include grid.aspect-ratio(2,3);
      @include grid.before-background(defaults.$color-black-bg-trans);
    }

    // Tablet portrait: 2x1
    @media (min-width: #{defaults.$media-query-tablet-landscape}) {
      @include grid.aspect-ratio(2,1);
    }
  }

  // Music
  .bas-rd-grid-element-music-title {
    @include grid.grid-id('title-music');

    display: none;

    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      display: flex;
    }
  }

  .bas-rd-grid-element-music {
    @include grid.grid-id('music');

    // Always 1x1
    @include grid.aspect-ratio(1,1);

    display: none;

    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      display: initial;
    }
  }

  // Thermostat
  .bas-rd-grid-element-thermostat {
    @include grid.grid-id('thermostat');

    // Aspect ratio
    // Mobile: 3x1 +-
    @include grid.aspect-ratio-percent(37%);

    .bas-tile-temperature {
      width: calc(100% - #{ 2 * tiles_const.$bas-tile-resetter-margin});
      margin: 0 tiles_const.$bas-tile-resetter-margin;
      height: 100%;
    }

    // Tablet portrait: 2x1 with spacing correction
    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      @include grid.aspect-ratio-percent(calc(50% - #{$spacing}));

      .bas-tile-temperature {
        width: 100%;
        margin: 0;
      }
    }

    // Tablet landscape and up: 2x1
    @media (min-width: #{defaults.$media-query-tablet-landscape}) {
      @include grid.aspect-ratio(2,1);
    }
  }

  // Thermostat and comfort should be equal height, so if comfort grows for scrollbar,
  //  thermostat should grow too in height (Only landscape view)
  // brgc = bas-room-dashboard-grid-comfort
  // brgc-3-plus -> scroll on mobile
  // brgc-4-plus -> scroll on all screen sizes (except pc)

  // Comfort
  .bas-rd-grid-element-comfort-title {
    @include grid.grid-id('title-comfort');

    .bas-title-wrapper {
      // TODO: When using 'max-width' we should always add ' - 1' to the values to
      //  get consistent breakpoints when compared to 'min-width' media queries.
      //  Since this is often forgotten and also goes against a mobile-first
      //  approach, we should try to only use 'min-width' media queries instead.
      @media (max-width: #{defaults.$media-query-tablet-portrait - 1}) {
        padding-bottom: tiles_const.$bas-tile-resetter-margin;
      }
    }
  }

  .bas-rd-grid-element-comfort {
    @include grid.grid-id('comfort');
    @include grid.before-background(defaults.$color-black-bg-trans);

    // Mobile: +- 3x1 with correction for IOS home bar
    @include grid.aspect-ratio-percent(calc(33.3333% + #{$third-spacing-correction + $scrollbar-height}));
    @include grid.aspect-ratio-percent(calc(33.3333% + #{$third-spacing-correction + $scrollbar-height}
    + #{bas_notch_const.$notch-safe-bottom}));
    // In mobile layout this element sticks to the bottom of the screen without
    //  any spacing. This is why we need to take '$notch-safe-bottom' into
    //  account for determining the correct size.

    // Tablet: 4x1
    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      @include grid.aspect-ratio(4,1);
    }

    .bas-scroll-indicator {
      bottom: 10px;
      // In mobile layout the comfort tiles stick to the bottom of the screen
      //  without any spacing. This is why we need to take '$notch-safe-bottom'
      //  into account when determining the position of the scroll-indicator.
      bottom: calc(10px + #{bas_notch_const.$notch-safe-bottom});
      display: none;

      svg {
        fill: defaults.$color-background-light-more-transparent;
      }

      @media (min-width: defaults.$media-query-tablet-portrait) {
        // In tablet layout the comfort tiles no longer stick to the bottom of
        //  the screen, safe bottom insets are handled on a different element.
        bottom: 10px;
      }
    }
  }

  .brgc-3-plus .bas-rd-grid-element-comfort {
    // TODO: When using 'max-width' we should always add ' - 1' to the values to
    //  get consistent breakpoints when compared to 'min-width' media queries.
    //  Since this is often forgotten and also goes against a mobile-first
    //  approach, we should try to only use 'min-width' media queries instead.
    @media (pointer: coarse) and (max-width: #{defaults.$media-query-tablet-portrait - 1}) {
      .bas-scroll-indicator {
        display: initial;
      }
    }
  }

  .brgc-4-plus {
    .bas-rd-grid-element-comfort {
      // Tablet portrait and landscape: 4x1 with scrollbar height (only if there are 4+ items)
      @media (min-width: #{defaults.$media-query-tablet-portrait}) {
        @include grid.aspect-ratio-percent(calc(25% + #{$scrollbar-height}));

        @media (pointer: coarse) {
          .bas-scroll-indicator {
            display: initial;
          }
        }
      }
    }

    .bas-rd-grid-element-thermostat {
      @media (pointer: coarse) and (min-width: #{defaults.$media-query-tablet-landscape}) {
        @include grid.aspect-ratio-percent(calc(50% + #{$scrollbar-height}));
      }
    }

    // Pc landscape: 4x2 (only if there are 4+ items)
    @media
      (pointer: fine) and (min-width: #{defaults.$media-query-tablet-portrait}),
      (min-width: #{defaults.$media-query-tablet-landscape}) and (max-aspect-ratio: $max-aspect-ratio-landscape-sticky-bottom),
      (min-width: #{defaults.$media-query-pc-landscape}) and (min-height: defaults.$media-width-large) {

      .bas-rd-grid-element-comfort {
        @include grid.aspect-ratio(4,2);

        .bas-rdc-container {
          height: 100%;
          flex-wrap: wrap;
          overflow: auto;
          mask: $bas-comfort-grid-mask-vertical;
        
          &::-webkit-scrollbar {
            width: 8px;
          }
        }

        .bas-scroll-indicator {
          display: none;
        }
      }
    }

    // Match thermostat height with comfort container height
    .bas-rd-grid-element-thermostat {
      @media (pointer: coarse) and (min-width: #{defaults.$media-query-tablet-landscape}) and (max-aspect-ratio: #{$max-aspect-ratio-landscape-sticky-bottom - 1}) {
        @include grid.aspect-ratio-percent(calc(50% + #{$scrollbar-height}));
      }
    }
  }

  // For some reason, i350's pointer is seen as fine, causing desktop comfort
  //  tiles layout to be used. This undoes this.
  // TODO: Remove this when this is fixed
  .bas-device-lena {
    .brgc-4-plus {
      .bas-rd-grid-element-comfort {
        @include grid.aspect-ratio-percent(calc(25% + #{$scrollbar-height}));

        .bas-rdc-container {
          flex-wrap: nowrap;
        }

        .bas-scroll-indicator {
          display: initial;
        }
      }

      .bas-rd-grid-element-thermostat {
        @include grid.aspect-ratio-percent(calc(50% + #{$scrollbar-height}));
      }
    }
  }

  .bas-device-ellie {
    .brgc-3-plus {
      .bas-rd-grid-element-comfort {

        .bas-scroll-indicator {
          display: initial;
        }
      }
    }
  }

  // Scenes sub-grid
  .bas-rds-grid-container {
    display: grid;

    // Scenes grid template for mobile
    grid-template-columns: //
      [col-placeholder-start col-tile-on col-tile-off] 1fr //
      [col-placeholder-side-start col-tile-scene-1 col-tile-scene-3] 1fr //
      [col-tile-scene-2 col-tile-scene-4] 1fr //
      [col-placeholder-end];
    grid-template-rows: //
      [row-placeholder-start row-tile-on row-tile-scene-1 row-tile-scene-2] 1fr //
      [row-tile-off row-tile-scene-3 row-tile-scene-4] 1fr //
      [row-placeholder-end row-placeholder-side-end];

    // Scenes grid template for tablet in portrait
    @media (min-width: #{defaults.$media-query-tablet-portrait}) {
      height: initial;
      grid-template-columns: //
        [col-placeholder-start col-placeholder-side-start col-tile-scene-1 col-tile-scene-3 col-tile-on] 1fr //
        [col-tile-scene-2 col-tile-scene-4 col-tile-off] 1fr //
        [col-placeholder-end];
      grid-template-rows: //
        [row-placeholder-start row-tile-scene-1 row-tile-scene-2] 1fr //
        [row-tile-scene-3 row-tile-scene-4] 1fr //
        [row-placeholder-side-end row-tile-on row-tile-off] 1fr //
        [row-placeholder-end];
    }

    // Scenes grid template for tablet in landscape (and bigger)
    @media (min-width: #{defaults.$media-query-tablet-landscape}) {
      height: initial;
      grid-template-columns: //
        [col-placeholder-start col-tile-on col-tile-off] 1fr //
        [col-placeholder-side-start] 1fr //
        [col-tile-scene-1 col-tile-scene-3] 1fr //
        [col-tile-scene-2 col-tile-scene-4] 1fr //
        [col-placeholder-end];
      grid-template-rows: //
        [row-placeholder-start row-tile-on row-tile-scene-1 row-tile-scene-2] 1fr //
        [row-tile-off row-tile-scene-3 row-tile-scene-4] 1fr //
        [row-placeholder-end row-placeholder-side-end];
    }
  }

  // Scenes sub-grid elements placement
  .bas-rds-grid-element {
    width: calc(100% - #{tiles_const.$bas-small-tile-outer-margin});
    padding-bottom: calc(100% - #{tiles_const.$bas-small-tile-outer-margin});

    @media (min-width: defaults.$media-query-tablet-portrait) {
      width: calc(100% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
      padding-bottom: calc(100% - #{tiles_const.$bas-small-tile-outer-margin-tablet});
    }

    &:nth-child(1) {
      grid-column-start: col-tile-scene-1;
      grid-row-start: row-tile-scene-1;
    }

    &:nth-child(2) {
      grid-column-start: col-tile-scene-2;
      grid-row-start: row-tile-scene-2;
    }

    &:nth-child(3) {
      grid-column-start: col-tile-scene-3;
      grid-row-start: row-tile-scene-3;
    }

    &:nth-child(4) {
      grid-column-start: col-tile-scene-4;
      grid-row-start: row-tile-scene-4;
    }

    &.bas-rds-grid-element-on {
      grid-column-start: col-tile-on;
      grid-row-start: row-tile-on;
    }

    &.bas-rds-grid-element-off {
      grid-column-start: col-tile-off;
      grid-row-start: row-tile-off;
    }
  }

  // Comfort tiles
  .bas-rdc-container {
    display: flex;
    scroll-snap-type: x mandatory;
    mask: $bas-comfort-grid-mask;
    -webkit-overflow-scrolling: touch;
    overflow: auto;

    .bas-rdc-comfort-tile-wrapper {
      flex: 0 0 calc(33% - #{$tile-spacing-mobile * 0.5});
      flex-basis: calc(33% - #{$tile-spacing-mobile * 0.5 + $margin-corrected * 0.66666});
      padding-left: #{$tile-spacing-mobile * 0.5};
      padding-right: #{$tile-spacing-mobile * 0.5};
      scroll-snap-align: center;

      .bas-small-comfort-tile {
        width: 100%;
        margin: $margin-corrected 0 9px;
        padding-bottom: 100%;
      }

      // TODO: When using 'max-width' we should always add ' - 1' to the values to
      //  get consistent breakpoints when compared to 'min-width' media queries.
      //  Since this is often forgotten and also goes against a mobile-first
      //  approach, we should try to only use 'min-width' media queries instead.
      @media (max-width: #{defaults.$media-query-tablet-portrait - 1}) {
        &:first-child {
          padding-left: $margin-corrected;
        }

        &:last-child {
          padding-right: $margin-corrected;
        }
      }
    }

    @media (min-width: #{defaults.$media-query-tablet-portrait}) {

      .bas-rdc-comfort-tile-wrapper {
        flex-basis: 25%;
        box-sizing: border-box;
        padding-left: #{$tile-spacing * 0.5};
        padding-right: #{$tile-spacing * 0.5};
        scroll-snap-align: start;
      }
    }
  }

  // Placeholder message
  .bas-tile-message-scenes {
    // Full size
    @include grid.grid-id('placeholder');
  }

  .brs-add-favourite.brs-has-on-off {
    .bas-tile-message-scenes {
      top: 50%;
      grid-column-start: col-placeholder-side-start;
      grid-row-end: row-placeholder-side-end;
    }
  }

  // Hide unavailable items in main grid
  .bas-room-content {

    // Separate selectors (Only 1 element is unavailable)

    &:not(.bas-room--scenes--has) {
      .bas-rd-grid-element-scenes,
      .bas-rd-grid-element-scenes-title {
        display: none;
      }

      // Alternative arrangement of items when only scenes is not available
      .bas-rd-grid-container {

        // Alternative main grid template only for tablet in portrait
        //  -> music takes it place and thermostat goes up to take music's place
        @media (min-width: #{defaults.$media-query-tablet-portrait}) and
          // TODO: When using 'max-width' we should always add ' - 1' to the values to
          //  get consistent breakpoints when compared to 'min-width' media queries.
          //  Since this is often forgotten and also goes against a mobile-first
          //  approach, we should try to only use 'min-width' media queries instead.
          (max-width: #{defaults.$media-query-tablet-landscape - 1px}) {
          grid-template-columns: $margin-actual //
            [col-title-comfort-start col-comfort-start col-title-music-start col-music-start] 1fr //
            [col-title-music-end col-music-end] $spacing //
            [col-thermostat-start] 1fr //
            [col-thermostat-end col-title-comfort-end col-comfort-end] $margin-actual;
          grid-template-rows: //
            [row-title-music-start] $title-height //
            [row-title-music-end row-music-start row-thermostat-start] auto //
            [row-thermostat-end] auto //
            [row-music-end] 1fr //
            [row-title-comfort-start] $title-height //
            [row-title-comfort-end row-comfort-start] auto //
            [row-comfort-end] $margin-actual;
        }

        // Alternative main grid template only for tablet in landscape and bigger
        //  -> music takes it place
        @media (min-width: #{defaults.$media-query-tablet-landscape}) {
          grid-template-columns: $margin-actual //
            [col-title-comfort-start col-comfort-start col-title-music-start col-music-start] 1fr //
            [col-title-music-end col-music-end] 1fr //
            [col-comfort-end] $spacing-wide //
            [col-thermostat-start] 1fr //
            [col-thermostat-end col-title-comfort-end] $margin-actual;
          // template for rows stays default here
        }
      }
    }

    &:not(.bas-room--music--has) {
      .bas-rd-grid-element-music,
      .bas-rd-grid-element-music-title {
        display: none;
      }

      // Alternative arrangement of items when only music is not available
      .bas-rd-grid-container {
        // Alternative main grid template only for tablet in portrait
        // -> thermostat moves up
        @media (min-width: #{defaults.$media-query-tablet-portrait}) and
          // TODO: When using 'max-width' we should always add ' - 1' to the values to
          //  get consistent breakpoints when compared to 'min-width' media queries.
          //  Since this is often forgotten and also goes against a mobile-first
          //  approach, we should try to only use 'min-width' media queries instead.
          (max-width: #{defaults.$media-query-tablet-landscape - 1px}) {
          grid-template-rows: //
            [row-title-scenes-start] $title-height //
            [row-title-scenes-end row-scenes-start row-thermostat-start] auto //
            [row-thermostat-end] auto //
            [row-scenes-end] 1fr //
            [row-title-comfort-start] $title-height //
            [row-title-comfort-end row-comfort-start] auto //
            [row-comfort-end] $margin-actual;
          // template for columns stays default here
        }
      }
    }

    &:not(.bas-room--thermostat--has) {
      .bas-rd-grid-element-thermostat {
        display: none;
      }

      // Alternative placement of items when only thermostat is not available
      .bas-rd-grid-container {
        // Alternative main grid template only for mobile
        //  -> spacing row underneath comfort goes away
        // TODO: When using 'max-width' we should always add ' - 1' to the values to
        //  get consistent breakpoints when compared to 'min-width' media queries.
        //  Since this is often forgotten and also goes against a mobile-first
        //  approach, we should try to only use 'min-width' media queries instead.
        @media (max-width: #{defaults.$media-query-tablet-portrait - 1}) {
          grid-template-rows: //
            [row-title-scenes-start] $title-height-small //
            [row-title-scenes-end row-scenes-start] min-content //
            [row-scenes-end] minmax(5px, 1fr) //
            [row-title-comfort-start] $title-height-small //
            [row-title-comfort-end] min-content //
            [row-comfort-start] min-content //
            [row-comfort-end];
          // template for columns stays default here
        }

        // Alternative main grid template only for tablet in landscape and bigger
        // -> comfort title row is only above comfort now, no longer above empty thermostat space
        @media (min-width: #{defaults.$media-query-tablet-landscape}) {
          grid-template-columns: $margin-actual //
            [col-title-scenes-start col-scenes-start col-title-comfort-start col-comfort-start] 2fr //
            [col-title-scenes-end col-scenes-end col-comfort-end col-title-comfort-end] $spacing-wide //
            [col-title-music-start col-music-start col-thermostat-start] 1fr //
            [col-title-music-end col-music-end col-thermostat-end] $margin-actual;
          // template for rows stays default here
        }
      }
    }

    // Combination selectors
    //    Here can come selectors for when multiple items are not available, to
    //    give these situations a specific element arrangement that is not covered
    //    in the general layout, e.g.
    //          &:not(.bas-room--thermostat--has):not(.bas-room--music--has)

    &:not(.bas-room--thermostat--has):not(.bas-room--scenes--has) {
      // Alternative placement of items when both thermostat and scenes are not
      // available
      .bas-rd-grid-container {

        // Alternative main grid template only for tablet in portrait
        // -> music goes left, comfort title shorter to only cover comfort
        @media (min-width: #{defaults.$media-query-tablet-portrait}) and
          // TODO: When using 'max-width' we should always add ' - 1' to the values to
          //  get consistent breakpoints when compared to 'min-width' media queries.
          //  Since this is often forgotten and also goes against a mobile-first
          //  approach, we should try to only use 'min-width' media queries instead.
          (max-width: #{defaults.$media-query-tablet-landscape - 1}) {
          grid-template-columns: $margin-actual //
            [col-title-comfort-start col-comfort-start col-title-music-start col-music-start] 1fr //
            [col-title-music-end col-music-end] $spacing //
            [] 1fr //
            [col-title-comfort-end col-comfort-end] $margin-actual;
          grid-template-rows: //
            [row-title-music-start] $title-height //
            [row-title-music-end row-music-start] auto //
            [row-music-end] 1fr //
            [row-title-comfort-start] $title-height //
            [row-title-comfort-end row-comfort-start] auto //
            [row-comfort-end] $margin-actual;
        }

        // Alternative main grid template only for tablet in landscape and bigger
        // -> same arrangement but more free space on right side
        @media (min-width: #{defaults.$media-query-tablet-landscape}) {
          grid-template-columns: $margin-actual //
            [col-title-comfort-start col-comfort-start col-title-music-start col-music-start] 1fr //
            [col-title-music-end col-music-end] $spacing //
            [] 1fr //
            [col-title-comfort-end col-comfort-end] $spacing-wide //
            [] 1fr //
            [] $margin-actual;
        }
      }
    }

    &:not(.bas-room--music--has):not(.bas-room--scenes--has) {
      // Alternative placement of items when both music and scenes are not
      // available
      .bas-rd-grid-container {

        // Alternative main grid template only for tablet in portrait
        // -> thermostat goes left, no row for top titles
        @media (min-width: #{defaults.$media-query-tablet-portrait}) and
          // TODO: When using 'max-width' we should always add ' - 1' to the values to
          //  get consistent breakpoints when compared to 'min-width' media queries.
          //  Since this is often forgotten and also goes against a mobile-first
          //  approach, we should try to only use 'min-width' media queries instead.
          (max-width: #{defaults.$media-query-tablet-landscape - 1}) {
          grid-template-columns: $margin-actual //
            [col-title-comfort-start col-comfort-start col-thermostat-start] 1fr //
            [col-thermostat-end] $spacing //
            [] 1fr //
            [col-title-comfort-end col-comfort-end] $margin-actual;
          grid-template-rows: //
            [row-thermostat-start] auto //
            [row-thermostat-end] 1fr //
            [row-title-comfort-start] $title-height //
            [row-title-comfort-end row-comfort-start] auto //
            [row-comfort-end] $margin-actual;
        }
      }
    }

    &:not(.bas-room--music--has):not(.bas-room--scenes--has):not(.bas-room--thermostat--has) {
      // Alternative placement of items when both music, scenes and comfort
      // are not available
      .bas-rd-grid-container {

        // Alternative main grid template only for tablet in portrait
        @media (min-width: #{defaults.$media-query-tablet-portrait}) {
          grid-template-rows: //
            1fr
            [row-title-comfort-start] $title-height //
            [row-title-comfort-end row-comfort-start row-thermostat-start] auto //
            [row-comfort-end row-thermostat-end] $margin-actual;
        }
      }
    }
  }

  .bas-device-lena {

    .bas-room-content {

      // Remove top padding
      @media (min-width: defaults.$media-query-tablet-portrait) {
        padding-top: 0;
      }
    }

    // Increase maximum width for better space utilization
    .bas-rd-grid-container {
      max-width: 1200px;
    }
  }
}
