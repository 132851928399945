@use 'sass:math';
@use 'defaults';
@use 'util';

// bpy = bas-player

$player-font-size: 16px;
$player-font-size-phone: 12px;

$bpy-queue-animation-time: .5s;

$bpy-spacing-outer: 16px;
$bpy-spacing-outer-p: 10px;
$bpy-spacing-outer-p2: 5%;

$bpy-queue-width: 30%;
$bpy-player-width: 60%;

$player-playing-element-side-margin-phone-portrait: .8em;
$player-scrubber-active-scale-y: 5;

.bpy-container {
  @include util.absolute-match-parent;
}

.bpy-page {

  .bpy-clear-queue,
  .bpy-save-playlist,
  .bpy-next,
  .bpy-replay-15,
  .bpy-forward-15,
  .bpy-repeat,
  .bpy-repeat-normal,
  .bpy-repeat-track,
  .bpy-shuffle,
  .bpy-mute-on,
  .player-scrubber,
  .player-playing-next-song-divider,
  .player-playing-main-controls,
  .bpy-previous {
    display: none;
  }

  .bpy-next-song,
  .bpy-pause-icon,
  .bas-buffering {
    opacity: 0;
  }

  &.bpy-has-queue {
    .bpy-queue {
      display: block;
    }
  }

  &.bpy-save-playlist-show.bpy-not-empty-queue {
    .bpy-save-playlist {
      display: block;
    }
  }

  &.bpy-queue-menu-open {
    .bpy-save-playlist {
      svg {
        fill: defaults.$color-highlight-on-dark;
        transition: fill 0s;
      }
    }
  }

  &.bpy-not-empty-queue {
    .bpy-clear-queue {
      display: block;
    }
  }

  .bpy-content {
    @include util.bas-fake-hide();
  }

  &.bpy-can-previous {
    .bpy-previous {
      display: block;
    }
  }

  &.bpy-can-next {
    .bpy-next {
      display: block;
    }
  }

  &.bas-paused {
    .bpy-pause-icon {
      opacity: 0;
    }

    .bpy-play-icon {
      opacity: 1;
    }
  }

  &.bas-playing {
    .bpy-pause-icon {
      opacity: 1;
    }

    .bpy-play-icon {
      opacity: 0;
    }
  }

  &.bpy-can-repeat {
    .bpy-repeat {
      display: inline-block;
    }

    .bpy-repeat-normal,
    .bpy-repeat-track {
      display: block;
    }

    // Show correct icon
    .bpy-repeat-normal {
      opacity: 1;
    }

    .bpy-repeat-track {
      opacity: 0;
    }

    &.bpy-is-repeat-track {
      .bpy-repeat-normal {
        opacity: 0;
      }

      .bpy-repeat-track {
        opacity: 1;
      }
    }

    // Highlight color
    &.bpy-is-repeat-context,
    &.bpy-is-repeat-track {
      .bpy-repeat {
        .player-playing-controls-icon-wrapper {
          svg {
            fill: defaults.$color-highlight-on-dark;
            transition: fill 0s;
          }
        }
      }
    }
  }

  &.bpy-can-replay-15 {
    .bpy-replay-15 {
      display: block;
    }
  }

  &.bpy-can-forward-15 {
    .bpy-forward-15 {
      display: block;
    }
  }

  &.bpy-is-random {
    .bpy-shuffle {
      .player-playing-controls-icon-wrapper {
        svg {
          fill: defaults.$color-highlight-on-dark;
          transition: fill 0s;
        }
      }
    }
  }

  &.bpy-can-shuffle {
    .bpy-shuffle {
      display: inline-block;
    }
  }

  &.bpy-has-next-song {
    .bpy-next-song {
      opacity: 1;
    }
  }

  &.bpy-positive-duration {
    .player-scrubber {
      display: block;
    }
  }

  &.bpy-message-hide {
    .bpy-content {
      @include util.bas-fake-show();
    }

    .bpy-message {
      @include util.bas-fake-hide();
    }
  }

  &.bpy-has-next-song-title-small.bpy-has-next-song-title-big {
    .player-playing-next-song-divider {
      display: block;
    }
  }

  &.bas-room--music--is-muted {
    .bpy-mute-on {
      display: block;
    }

    .bpy-mute-off {
      display: none;
    }
  }

  &.bpy-is-spotify {
    .player-playing-main-controls {
      display: block;
    }

    .player-playing-cover-side-flow-control {
      display: none;
    }
  }

  &.bpy-animate {
    .player-playing {
      transition: transform $bpy-queue-animation-time ease-in-out;
    }

    .bpy-queue {
      transition: transform $bpy-queue-animation-time ease-in-out;
    }
  }

  &.bpy-has-queue.bpy-show-queue {
    @media (min-aspect-ratio: #{defaults.$min-aspect-ratio-device}) {
      .player-playing {
        transform: translate3d(100%, 0, 0);

        @media (min-width: #{defaults.$media-phone-width}) {
          transform: translate3d($bpy-queue-width, 0, 0);

          @media (orientation: portrait) {
            transform: translate3d(100%, 0, 0) translate3d(2 * $bpy-spacing-outer, 0, 0);
          }
        }
      }

      .bpy-queue {
        transform: translate3d(100%, 0, 0) translate3d(2 * $bpy-spacing-outer, 0, 0);
      }
    }
  }

  &.bpy-is-buffering {

    .bas-buffering {
      transition: opacity defaults.$standard-animation-time;
      opacity: 1;
    }
  }
}

.bpy-page-settings {

  .player-playing-controls-context-icon-wrapper {
    display: none;
  }

  &.bpy-logo-deezer,
  &.bpy-logo-tidal,
  &.bpy-logo-spotify,
  &.bpy-logo-aap {
    .player-playing-controls-context-icon-wrapper {
      display: inline-block;
    }
  }

  .only-phone {
    @media (min-width: #{defaults.$media-phone-width}) {
      display: none !important;
    }
  }

  .only-tablet {
    // TODO: When using 'max-width' we should always add ' - 1' to the values to
    //  get consistent breakpoints when compared to 'min-width' media queries.
    //  Since this is often forgotten and also goes against a mobile-first
    //  approach, we should try to only use 'min-width' media queries instead.
    @media (max-width: #{defaults.$media-phone-width}) {
      display: none !important;
    }
  }
}

.bas-buffering {
  @include util.absolute-match-parent();

  pointer-events: none;
  background-color: defaults.$color-black-dark-bg-trans-dark;
  transition-duration: 0s;

  .bas-buffering-spinner {
    @include util.absolute-center();

    width: 15%;
  }
}

// Queue

.bpy-queue {
  position: absolute;
  display: none;
  top: $bpy-spacing-outer-p;
  bottom: $bpy-spacing-outer-p;
  left: $bpy-spacing-outer;
  right: $bpy-spacing-outer;
  overflow: hidden;
  box-sizing: border-box;
  background-color: defaults.$color-background-dark-transparent-light;

  @media (min-aspect-ratio: #{defaults.$min-aspect-ratio-device}) {
    left: calc(#{$bpy-spacing-outer} - 100%);
    right: calc(#{$bpy-spacing-outer} + 100%);
    transform: translate3d(0, 0, 0);

    @media (min-width: #{defaults.$media-phone-width}) {
      top: $bpy-spacing-outer;
      bottom: $bpy-spacing-outer;
      left: -$bpy-queue-width;
      right: auto;
      width: $bpy-queue-width;

      @media (orientation: portrait) {
        left: calc(#{$bpy-spacing-outer} - 100%);
        right: calc(#{$bpy-spacing-outer} + 100%);
        width: auto;
      }
    }
  }
}

// Player

.player-playing {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  overflow: visible;
  text-align: center;
  font-size: $player-font-size;
  box-sizing: border-box;

  @media (min-aspect-ratio: #{defaults.$min-aspect-ratio-device}) {
    bottom: $bpy-spacing-outer-p2;

    @media (min-aspect-ratio: #{defaults.$aspect-ratio-device-55}) {
      bottom: $bpy-spacing-outer-p;
    }

    @media (min-width: #{defaults.$media-phone-width}) {
      top: $bpy-spacing-outer;
      bottom: $bpy-spacing-outer;
      left: (100% - $bpy-player-width) * 0.5;
      right: (100% - $bpy-player-width) * 0.5;
      transform: translate3d(0, 0, 0);

      @media (orientation: portrait) {
        left: $bpy-spacing-outer;
        right: $bpy-spacing-outer;
      }
    }
  }
}

// Player - Now Playing

.player-playing-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  overflow: visible;
  text-align: center;
}

// Cover

$player-playing-previous-next-size: 26px;

.player-playing-cover {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

// Cover - Sides
////////////////

$player-playing-side-width: 56px;
$player-playing-side-margin-value: 4%;
$player-playing-side-margin: 0 $player-playing-side-margin-value;

$player-playing-provider-context-width: 24px;
$player-playing-provider-logo-size: 32px;
$player-playing-provider-logo-barp-size: 26px;

.player-playing-cover-side {
  position: relative;
  display: block;
  flex: 0 0 auto;
  align-self: stretch;
  min-width: $player-playing-side-width;
  margin: $player-playing-side-margin;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    display: none;
  }
}

.player-playing-cover-side-flow-control {
  @include util.absolute-center();
  @include util.button-cursor();

  fill: defaults.$color-light-control-buttons;
  width: $player-playing-previous-next-size;
  height: $player-playing-previous-next-size;
  padding: 5px;

  &:active {
    fill: defaults.$color-light-control-buttons-active;
  }

  svg {
    @include util.absolute-center();

    width: $player-playing-previous-next-size;
  }
}

.player-playing-cover-provider-bottom {
  position: absolute;
  display: block;
  width: 100%;
  height: $player-playing-provider-logo-size;
  bottom: 0;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.player-playing-cover-context-logo {
  @include util.absolute-center();

  bottom: 0;
  width: $player-playing-provider-context-width;
  transform: translate(-50%);

  > svg {
    fill: defaults.$color-light-control-buttons;
  }

  &:active {
    > svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }
}

.bpy-logo-deezer,
.bpy-logo-tidal,
.bpy-logo-spotify,
.bpy-logo-aap {
  .player-playing-cover-logo {
    @include util.absolute-center();

    width: 100%;
    height: 100%;
    fill: defaults.$color-light-control-buttons;

    svg {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.bpy-logo-tidal {
  .player-playing-cover-logo {
    fill: white;
  }
}

.bpy-logo-spotify {
  .player-playing-cover-logo {
    @include util.button-cursor();

    svg {
      fill: defaults.$color-spotify-green;
    }
  }
}

.bpy-logo-aap {
  .player-playing-cover-logo {
    width: $player-playing-provider-logo-barp-size;
  }
}

.player-playing-cover-provider-logo {
  right: auto;
}

.player-playing-cover-provider-context,
.player-playing-cover-provider-group {
  @include util.button-cursor();

  display: none;
  left: 50%;
}

.player-playing-cover-provider-group {
  margin-bottom: 7px;
}

.bas-room--music--has-settings,
.bpy-has-context {
  .player-playing-cover-provider-context {
    display: block;
  }
}

.bas-room--music--can-group {
  .player-playing-cover-provider-group {
    display: block;
  }

  &.bas-room--music--has-settings,
  &.bpy-has-context {

    .player-playing-cover-provider-group {
      display: none;
    }
  }
}

// Cover - Cover container
//////////////////////////

.player-playing-cover-container {
  position: relative;
  flex: 1 1 auto;
  align-self: stretch;
  text-align: center;

  @if defaults.$debug {
    border: 2px solid red;
  }
}

// Sized with UiHelper

.player-playing-cover-container-sized {
  @include util.absolute-center();

  display: block;
  width: 100%;
  padding-bottom: 100%;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    top: 0;
    transform: translate(-50%, 0);
  }
}

.player-playing-cover-bas-image {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  fill: defaults.$color-highlight-muted;
}

// Cover - Cover container - Scrubber
/////////////////////////////////////

$player-scrubber-background-color: rgba(defaults.$color-background, .4);
$player-scrubber-progress-color: rgba(defaults.$color-highlight-on-dark, .8);
$player-scrubber-active-height: 20px;
$player-scrubber-height: 4px;

.player-scrubber {
  @include util.button-cursor();

  position: absolute;
  bottom: 0;	// Fix for slight offset on iPad (-1px)
  left: 0;
  width: 100%;
  height: $player-scrubber-active-height;
  overflow: hidden;
  z-index: 1;

  @if defaults.$debug {
    box-sizing: border-box;
    border: 2px solid dodgerblue;
  }
}

.player-scrubber-background,
.player-scrubber-progress {
  position: absolute;
  height: $player-scrubber-height;
  bottom: 0;
  left: 0;
  transition: transform defaults.$standard-animation-time;
  transform: scale3d(1, 1, 1);
}

.player-scrubber-background {
  width: 100%;
  background-color: rgba(defaults.$color-background, 0.4);
}

.player-scrubber-progress {
  background-color: rgba(defaults.$color-highlight-on-dark, 0.8);
}

// Cover - cover container - Swipe area
///////////////////////////////////////

$player-cover-swipe-area-width: 100%;
$player-cover-swipe-area-height: 90%;
$player-cover-swipe-area-max-height: calc(100% - #{$player-scrubber-active-height});

.player-playing-cover-swipe-area {
  @include util.button-cursor();

  position: absolute;
  top: 0;
  left: 0;
  width: $player-cover-swipe-area-width;
  height: $player-cover-swipe-area-height;
  max-height: $player-cover-swipe-area-max-height;
}

// Cover - Cover container - Play pause
///////////////////////////////////////

@keyframes playPauseFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

$player-cover-play-pause-size: 60px;
$player-cover-play-pause-animation: playPauseFadeOut 1.5s;

.bas-play-pause {
  @include util.absolute-center();

  width: $player-cover-play-pause-size;
  visibility: hidden;
  opacity: 0;
  animation-play-state: paused;
  animation-iteration-count: 0;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
  }
}

.bas-play-pause-show {

  &.bas-paused {
    .bas-pause {
      visibility: visible;
      animation-play-state: running;
      animation-iteration-count: 1;
      animation: $player-cover-play-pause-animation;
    }
  }

  &.bas-playing {
    .bas-play {
      visibility: visible;
      animation-play-state: running;
      animation-iteration-count: 1;
      animation: $player-cover-play-pause-animation;
    }
  }
}

// Titles + controls container
//////////////////////////////

$player-playing-title-controls-fixed-height-lg: 140px;
$player-playing-title-controls-fixed-height: 125px;

.player-playing-title-controls-fixed-height {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: $player-playing-title-controls-fixed-height-lg;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    min-height: $player-playing-title-controls-fixed-height;
  }
}

// Current Song
///////////////

$player-song-info-margin: .8em 0;
$player-song-info-margin-phone-portrait: .8em $player-playing-element-side-margin-phone-portrait;
$player-current-song-title-big-font: 1.4em;
$player-current-song-title-small-font: 1em;
$player-current-song-divider-margin: 0 .5em;

.player-playing-current-song {
  position: relative;
  display: block;
  margin: $player-song-info-margin;
  overflow: hidden;
  white-space: nowrap;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width})
    and (orientation: portrait) {
    margin: $player-song-info-margin-phone-portrait;
  }
}

// Next Song
////////////

$player-next-song-font: .7em;
$player-next-song-side-margin-value: math.div($player-playing-element-side-margin-phone-portrait, $player-next-song-font) * 1em;
$player-next-song-margin-phone-portrait: auto $player-next-song-side-margin-value;
$player-next-song-font-phone: .9em;
$player-next-song-txt-margin: 0 .6em 0 0;
$player-next-song-divider-margin: 0 .5em;

.player-playing-next-song {
  position: relative;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: baseline;
  font-size: $player-next-song-font;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    display: none;
  }
}

.player-playing-next-song-filler {
  flex: 2 2 auto;
}

.player-playing-next-song-txt {
  @include defaults.font-roboto-light;

  flex: 0 0 auto;
  margin: $player-next-song-txt-margin;
  text-align: center;
  color: defaults.$color-light-control-buttons;
}

.player-playing-next-song-wrapper {
  position: relative;
  overflow: hidden;
}

.player-playing-next-song-container {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  overflow: hidden;
}

.player-playing-next-song-title-big {
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  min-width: 0;
  text-align: right;
  color: defaults.$color-foreground;
  white-space: nowrap;
}

.player-playing-next-song-divider {
  flex: 0 0 auto;
  width: 1px;
  margin: $player-current-song-divider-margin;
  background: defaults.$color-highlight-on-dark;
  white-space: nowrap;
}

.player-playing-next-song-divider-placeholder {
  @include defaults.font-roboto-regular;

  opacity: 0;
}

.player-playing-next-song-title-small {
  @include defaults.font-roboto-light;

  flex: 1 1 auto;
  min-width: 0;
  text-align: left;
  color: defaults.$color-foreground;
  white-space: nowrap;
}

// Main controls
////////////////

$bpy-mc-spacing-top: 16px;
$bpy-mc-spacing-bottom: 8px;
$bpy-mc-spacing-side: 20px;
$bpy-mcc-spacing-side: 18px;

$bpy-control-big: 46px;

.player-playing-main-controls {
  position: relative;
  flex: 0 0 auto;
  align-self: stretch;
  display: block;
  margin-top: $bpy-mc-spacing-top;
  margin-bottom: $bpy-mc-spacing-bottom;
  //margin: $bpy-mc-spacing-top $bpy-mc-spacing-side $bpy-mc-spacing-bottom;
  text-align: center;
  white-space: nowrap;
}

.bpy-main-control-container {
  position: relative;
  display: inline-block;
  width: 20%;
  min-width: 50px;
  height: 32px;
  //margin-left: $bpy-mcc-spacing-side;
  //margin-right: $bpy-mcc-spacing-side;
  vertical-align: middle;
}

.bpy-mcc-big {
  height: $bpy-control-big;

  .bpy-main-control {
    width: $bpy-control-big;
  }

  .bpy-mc-icon-wrapper {
    width: 32px;
    height: 32px;
  }
}

.bpy-main-control {
  position: absolute;
  height: 100%;
  width: 32px;
  left: 0;
  right: 0;
  margin: auto;

  > svg {
    @include util.absolute-center;

    width: 24px;
    height: 24px;
  }
}

.bpy-main-control-circle-btn {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-color: defaults.$color-light-control-buttons;
  transition: border-color defaults.$standard-animation-time;

  &:active {
    transition: border-color 0s;
    border-color: defaults.$color-highlight-on-dark;
  }
}

.bpy-mc-icon {

  svg {
    fill: defaults.$color-light-control-buttons;
    transition: fill defaults.$standard-animation-time;
  }

  &:active {

    svg {
      fill: defaults.$color-highlight-on-dark;
      transition: fill 0s;
    }
  }
}

.bpy-play-pause-icon {
  opacity: 1;
  transition: opacity defaults.$standard-animation-time;
}

.bpy-mc-icon-wrapper {
  @include util.absolute-center;

  width: 24px;
  height: 24px;
}

// Fix visual center
.bpy-play-icon {
  > svg {
    margin-left: 2px;
  }
}

// Volume
/////////

$player-volume-margin-top: .4em;
$player-volume-margin: 0
  $player-playing-element-side-margin-phone-portrait
  0
  $player-playing-element-side-margin-phone-portrait;
$player-volume-mute-size: 22px;
$player-volume-mute-size-phone: 18px;
$player-volume-slider-margin-side: .75em;
$player-volume-slider-margin: 0 $player-volume-slider-margin-side;
$player-volume-number-width: 1.8em;
$player-volume-width: calc(
  #{100% - 4 * $player-playing-side-margin-value} -
  #{2 * $player-playing-side-width} +
  #{2 * $player-volume-number-width + 2 * $player-volume-slider-margin-side}
);
$player-volume-number-font-phone: 1.2em;

.player-playing-volume {
  position: relative;
  flex: 0 0 auto;
  align-self: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: $player-volume-width;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    max-width: 100%;
  }

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width})
    and (orientation: portrait) {
    max-width: calc(100% - #{2 * $player-playing-element-side-margin-phone-portrait});
  }

  .grsw {
    padding: 0.55em 0;
  }
}

.player-playing-volume-mute {
  @include util.button-cursor();

  position: relative;
  flex: 0 0 auto;
  width: $player-volume-number-width;
  height: $player-volume-number-width;

  &:active {
    .player-playing-volume-mute-icon {
      fill: defaults.$color-light-control-buttons-active;
    }
  }
}

.player-playing-volume-mute-icon {
  @include util.absolute-center();

  width: 100%;
  height: 100%;
  fill: defaults.$color-light-control-buttons;

  svg {
    @include util.absolute-center();

    width: $player-volume-mute-size;

    // TODO: When using 'max-width' we should always add ' - 1' to the values to
    //  get consistent breakpoints when compared to 'min-width' media queries.
    //  Since this is often forgotten and also goes against a mobile-first
    //  approach, we should try to only use 'min-width' media queries instead.
    @media (max-width: #{defaults.$media-phone-width}) {
      width: $player-volume-mute-size-phone;
    }
  }
}

.player-playing-volume-slider {
  @include util.button-cursor;

  flex: 1 1 auto;
  margin: $player-volume-slider-margin;
}

.player-playing-volume-number {
  @include defaults.font-roboto-light;

  flex: 0 0 auto;
  width: $player-volume-number-width;
  text-align: right;
  color: defaults.$color-highlight-on-dark;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    font-size: $player-volume-number-font-phone;
  }
}

// Player - Controls
////////////////////

$spotify-logo-minimum-width: 70px;
$player-controls-icon-size: 32px;
$player-controls-icon-size-phone: 24px;
$player-controls-margin-top: .5em;
$player-controls-margin-top-phone: .4em;
$player-controls-margin: $player-controls-margin-top
  0
  0
  0;
$player-controls-margin-phone-portrait: $player-controls-margin-top-phone
  $player-playing-element-side-margin-phone-portrait
  0
  $player-playing-element-side-margin-phone-portrait;
$player-controls-control-margin-value: 2em;
$player-controls-control-margin-value-phone: 0.4em;
$player-controls-control-margin: 0 $player-controls-control-margin-value;
$player-controls-control-margin-phone: 0 $player-controls-control-margin-value-phone;
$player-controls-control-context-min-width: 50px;

// When to stop trying to center the repeat, shuffle buttons
$player-controls-min-collapse-with: 300px;

@mixin player-controls-only-context-icon () {
  .bpy-logo-aap {
    .player-playing-controls-repeat {
      display: none;
    }

    .player-playing-controls-shuffle {
      display: none;
    }

    .player-playing-controls-context {
      display: none;
    }
  }
}

.player-playing-controls {
  @include player-controls-only-context-icon();

  position: relative;
  flex: 0 0 auto;
  align-self: stretch;
  display: block;
  margin: $player-controls-margin;
  height: $player-controls-icon-size;

  .player-playing-controls-context-icon {
    display: none;
  }

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin: $player-controls-margin-phone-portrait;
  }
}

.bas-room--music--has-settings,
.bpy-has-context {
  .player-playing-controls {
    .player-playing-controls-context-icon {
      display: block;
    }
  }
}

.player-playing-controls-repeat,
.player-playing-controls-shuffle,
.player-playing-controls-group {
  position: relative;
  display: none;
  width: $player-controls-icon-size;
  height: $player-controls-icon-size;
  margin: $player-controls-control-margin;
  vertical-align: middle;

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    flex: 1 1 auto;
    width: $player-controls-icon-size-phone;
    height: $player-controls-icon-size-phone;
    margin: $player-controls-control-margin-phone;
  }
}

.player-playing-controls-icon-wrapper {
  @include util.absolute-center();
  @include util.button-cursor();

  width: $player-controls-icon-size;
  height: $player-controls-icon-size;

  > svg {
    @include util.absolute-center();

    width: 100%;
    fill: defaults.$color-light-control-buttons;
    transition: fill .6s ease-out;
  }

  &.active {
    > svg {
      fill: defaults.$color-highlight-on-dark;
      transition: fill 0s;
    }
  }

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    width: $player-controls-icon-size-phone;
    height: $player-controls-icon-size-phone;
  }
}

@mixin player-playing-controls-context () {
  position: relative;
  flex: 1 1 auto;
  display: inline-block;
  height: $player-controls-icon-size;
  min-width: $player-controls-control-context-min-width;
  vertical-align: middle;

  &.player-playing-controls-context-provider {
    min-width: $spotify-logo-minimum-width;
  }
}

.player-playing-controls-context,
.player-playing-controls-context-icon-wrapper,
.player-playing-controls-context-provider {
  @include player-playing-controls-context();
}

// Order is important
.player-playing-controls-context-icon-wrapper,
.player-playing-controls-context {
  width: $player-controls-icon-size-phone;
}

.player-playing-controls-context-provider {
  width: $spotify-logo-minimum-width;
}

.player-playing-controls-context {
  display: none;

  &.player-playing-controls-context-provider {

    // TODO: When using 'max-width' we should always add ' - 1' to the values to
    //  get consistent breakpoints when compared to 'min-width' media queries.
    //  Since this is often forgotten and also goes against a mobile-first
    //  approach, we should try to only use 'min-width' media queries instead.
    @media (max-width: #{$player-controls-min-collapse-with}) {
      width: $player-controls-icon-size-phone;
      min-width: $player-controls-icon-size-phone;
    }
  }
}

.bas-room--music--has-settings,
.bpy-has-context {

  .player-playing-controls-context {
    display: inline-block;
  }
}

.player-playing-controls-context-icon {
  @include util.absolute-center();

  width: $player-controls-icon-size-phone;
  height: 100%;

  > svg {
    @include util.absolute-center();

    fill: defaults.$color-light-control-buttons;
  }
}

.player-playing-controls-context-logo {
  @include util.absolute-center();

  width: 100%;
  height: 100%;

  &.phone-logo {
    display: none;

    // TODO: When using 'max-width' we should always add ' - 1' to the values to
    //  get consistent breakpoints when compared to 'min-width' media queries.
    //  Since this is often forgotten and also goes against a mobile-first
    //  approach, we should try to only use 'min-width' media queries instead.
    @media (max-width: #{defaults.$media-phone-width}) {
      display: block;
    }

  }
}

.bpy-logo-deezer {
  .player-playing-controls-context-logo {
    width: $player-controls-icon-size-phone;

    svg {
      @include util.absolute-center();

      width: $player-controls-icon-size-phone;
      fill: defaults.$color-spotify-green;
    }
  }
}

.bpy-logo-spotify {
  .player-playing-controls-context-logo {
    width: $player-controls-icon-size-phone;

    svg {
      @include util.absolute-center();

      width: $player-controls-icon-size-phone;
      fill: defaults.$color-spotify-green;
    }
  }
}

.bpy-logo-tidal {
  .player-playing-controls-context-logo {
    width: $player-controls-icon-size-phone;

    svg {
      @include util.absolute-center();

      width: $player-controls-icon-size-phone;
      fill: white;
    }
  }
}

.bpy-logo-aap {
  .player-playing-controls-context-logo {
    width: $player-controls-icon-size-phone;

    svg {
      fill: defaults.$color-light-control-buttons;
    }
  }
}

.player-playing-controls-group-icon {
  svg {
    width: 80%;
    padding-bottom: 2px;
  }
}

.bas-room--music--can-group {

  // Show bottom grouping button when in phone view, or when a context
  //  button is already shown next to the cover art

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width - 1}) {

    .player-playing-controls-group {
      display: inline-block;
    }
  }

  &.bas-room--music--has-settings,
  &.bpy-has-context {
    .player-playing-controls-group {
      display: inline-block;
    }
  }
}

// Player - Ellie specific
//////////////////////////

$player-playing-title-controls-fixed-height-ellie: 100px;

.bas-device-ellie {

  .player-playing-volume .grsw {
    padding: 0.32em 0;
  }

  .player-playing-main-controls {
    margin-top: 0;
    margin-bottom: 0;
  }

  .player-playing-title-controls-fixed-height {
    min-height: $player-playing-title-controls-fixed-height-ellie;
  }
}

// Player - Message
///////////////////

$player-message-width: $bpy-player-width;
$player-message-width-phone: 90%;
$player-playing-message-width: 90%;
$player-message-simple-title-font: 1.2em;

@mixin player-message-only-action () {
  &.only-action {
    > * {
      display: none;
    }
  }
}

@mixin player-message () {
  @include util.absolute-center();
  @include player-message-only-action();

  text-align: center;
}

.player-message {
  @include player-message();

  width: $player-message-width;
  transition: transform $bpy-queue-animation-time ease-in-out;
  transform: translate3d(-50%, -50%, 0);

  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{defaults.$media-phone-width}) {
    width: $player-message-width-phone;
  }
}

.player-playing-message {
  @include player-message();

  width: $player-playing-message-width;
  color: defaults.$color-foreground;
}

.player-message-simple-title {
  @include defaults.font-roboto-regular;

  position: static;
  display: block;
  font-size: $player-message-simple-title-font;
  color: defaults.$color-foreground;
}

$delayed-message-animation-duration: .6s;
$delayed-message-timing: cubic-bezier(1, 0, 0.8, 0.2);

.delayed-player-message {
  opacity: 1;

  // ngIf
  &.ng-enter-prepare {
    opacity: 0;
    transition:
      opacity
      $delayed-message-timing
      $delayed-message-animation-duration;
  }

  &.ng-enter {
    transition:
      opacity
      $delayed-message-timing
      $delayed-message-animation-duration;
  }

  &.ng-leave {
    transition: none;
  }

  &.ng-enter,
  &.ng-leave {
    opacity: 0;
  }

  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity: 1;
  }
}

// Widget - Overlay controls
////////////////////////////

$widget-player-control-small-size: 28px;
$widget-player-overlay-color: rgba(0, 0, 0, 0.7);

.btmp-overlay {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  z-index: 0;
}

.widget-player-stream {
  @include defaults.font-roboto-regular();

  width: calc(100% - #{2 * defaults.$main-header-height-phone});
  height: defaults.$main-header-height-phone;
  margin: 0 defaults.$main-header-height-phone;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  color: defaults.$color-foreground;
  text-shadow: 0 0 9px black;
  font-size: 1.3em;
  transition: opacity defaults.$standard-animation-time;
}

.widget-player-controls-show {
  .widget-player-stream {
    opacity: 0;
  }
}

.widget-player-show-stream {
  .widget-player-stream {
    display: flex;
  }
}

.widget-player-swipe-control {
  height: calc(100% - 40px);
}

.widget-player-overlay-control {
  flex: 0 1 100%;
  z-index: 0;
  pointer-events: none;
}

.widget-player-overlay-volume {
  flex: 0 0 40px;
  z-index: 0;
  background-color: $widget-player-overlay-color;
}

.widget-player-overlay-title {
  flex: 0 0 50px;
  z-index: 0;
  background-color: $widget-player-overlay-color;
  pointer-events: none;

  .widget-player-overlay-title-text {
    margin: auto;
    text-align: center;
    color: defaults.$color-foreground;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.widget-player-controls {
  width: 100%;
  transition: opacity defaults.$standard-animation-time-long;
  opacity: 0;
  pointer-events: none;

  .widget-player-control {
    pointer-events: none;
    position: absolute;
  }
}

.widget-player-overlay-title {
  transition: opacity defaults.$standard-animation-time-long;
  opacity: 0;
}

.widget-player-hide-bottom {
  .btmp-bottom {
    transition: opacity defaults.$standard-animation-time-long;
    opacity: 0;
    pointer-events: none;
  }
}

.widget-player-use-overlay-controls {
  &.widget-player-controls-show {
    .widget-player-overlay-control {
      opacity: 1;

      .widget-player-control {
        pointer-events: initial;
      }
    }

    .widget-player-overlay-title {
      opacity: 1;
    }

    .btmp-bottom {
      background-color: $widget-player-overlay-color;
    }

    &.widget-player-hide-bottom {
      .btmp-bottom {
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
}

.bas-music-widget-advanced {
  @include util.absolute-match-parent();

  display: none;
  overflow: auto;
  scroll-snap-type: y mandatory;
}

.widget-player-use-advanced-flipside {
  .bas-tile-favourites {
    display: none;
  }

  .bas-music-widget-advanced {
    display: block;
  }
}

.widget-player-control-small {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: $widget-player-control-small-size;
  width: $widget-player-control-small-size;

  svg {
    @include util.absolute-center();

    fill: defaults.$color-foreground;
    width: 100%;
    transition: fill defaults.$standard-animation-time;
  }

  &:active svg {
    transition: none;
    fill: defaults.$color-highlight-on-dark;
  }
}

$widget-player-control-size: 55px;

.widget-player-control-play-pause {
  @include util.absolute-center();

  width: $widget-player-control-size;
  height: $widget-player-control-size;

  .widget-player-control-pause,
  .widget-player-control-play {
    width: 100%;
    height: 100%;

    svg {
      @include util.absolute-center();

      width: 100%;
      height: 100%;
      fill: defaults.$color-foreground;
      transition: fill defaults.$standard-animation-time;
      display: block;
      opacity: 0.85;
    }
  }

  &:active {
    svg {
      fill: defaults.$color-highlight-on-dark;
      transition: none;
    }
  }
}

.widget-player-control-shuffle {
  top: 50%;
  transform: translateY(-50%);
  left: 12%;
  padding: 1em;

  .player-playing-controls-icon-wrapper {
    height: 32px;
    width: 32px;
  }
}

.widget-player-control-repeat {
  top: 50%;
  transform: translateY(-50%);
  right: 12%;
  padding: 1em;

  .player-playing-controls-icon-wrapper {
    height: 32px;
    width: 32px;
  }
}

.widget-player-control-play,
.widget-player-control-repeat,
.widget-player-control-shuffle,
.widget-player-control-next,
.widget-player-control-previous,
.widget-player-control-play-pause {
  display: none;
}

.widget-player-is-paused {
  .widget-player-control-play {
    display: block;
  }

  .widget-player-control-pause {
    display: none;
  }
}

.widget-player-controls-background {
  width: 100%;
  height: 100%;
  background-color: $widget-player-overlay-color;
}

.widget-player-control.widget-player-control-music-off {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 8px;
  border-color: defaults.$color-foreground;
  transition: border;
  display: none;

  &:active {
    border-color: defaults.$color-highlight-on-dark;
    color: defaults.$color-highlight-on-dark;
    transition: none;
  }
}

.widget-player-control-previous {
  left: 5px;
}

.widget-player-control-next {
  right: 5px;
}

.widget-player-swipe-control-alt {
  display: block;
}

.widget-player-swipe-control,
.widget-player-overlay-control,
.widget-player-overlay-title {
  display: none;
}

.widget-player-use-swipe-controls {
  .widget-player-swipe-control {
    display: block;
  }

  .widget-player-swipe-control-alt {
    display: none;
  }
}

.widget-player-use-overlay-controls {
  .widget-player-overlay-control {
    display: block;
  }

  &.widget-player-show-forward-backward-overlay-controls {
    .widget-player-control-next {
      display: block;
    }

    .widget-player-control-previous {
      display: block;
    }
  }

  &.widget-player-show-play-pause-overlay-control {
    &.bas-can-play-pause {
      .widget-player-control-play-pause {
        display: block;
      }
    }
  }

  &.widget-player-show-shuffle-overlay-control {
    &.bpy-can-shuffle {
      .widget-player-control-shuffle {
        display: block;
      }
    }

    &.bpy-is-random {
      .widget-player-control-shuffle {
        svg {
          fill: defaults.$color-highlight-on-dark;
          transition: fill 0s;
        }
      }
    }
  }

  &.widget-player-show-repeat-overlay-control {
    &.bpy-can-repeat {
      .widget-player-control-repeat {
        display: block;
      }

      // Show correct icon
      .bpy-repeat-normal {
        opacity: 1;
      }

      .bpy-repeat-track {
        opacity: 0;
      }

      &.bpy-is-repeat-track {
        .bpy-repeat-normal {
          opacity: 0;
        }

        .bpy-repeat-track {
          opacity: 1;
        }
      }

      // Highlight color
      &.bpy-is-repeat-context,
      &.bpy-is-repeat-track {
        .widget-player-control-repeat {
          svg {
            fill: defaults.$color-highlight-on-dark;
            transition: fill 0s;
          }
        }
      }
    }
  }

  &.widget-player-show-turn-off-music-overlay-control {
    .widget-player-control-music-off {
      display: block;
    }
  }

  &.widget-player-show-title-overlay {
    .widget-player-overlay-title {
      display: block;
    }
  }
}

.btm-toggle-streams-favourites {

  .btm-streams {
    svg {
      width: 60%;
      height: 60%;
    }
  }

  .btm-favourites {
    display: none;
  }
}

.bas-music-widget-advanced-streams {
  display: none;
}

.btm-streams--show {
  .btm-toggle-streams-favourites {
    .btm-streams {
      display: none;
    }

    .btm-favourites {
      display: block;
    }
  }

  .bas-music-widget-advanced-streams {
    display: block;
  }

  .bas-music-widget-advanced-favourites {
    display: none;
  }
}
