@use 'defaults';
@use 'basstyle_const';

$bst-row-button-side-spacing: 8px;

@mixin bstrb-left {
  margin-right: $bst-row-button-side-spacing;
}

@mixin bstrb-center {
  margin-left: $bst-row-button-side-spacing;
  margin-right: $bst-row-button-side-spacing;
}

@mixin bstrb-right {
  margin-left: $bst-row-button-side-spacing;
}

.bs-tile-container {
  margin-bottom: defaults.$bs-card-between-spacing-phone;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-bottom: defaults.$bs-card-between-spacing;
  }
}

.bs-tile-container-full-width {
  position: relative;
  width: 100%;
  padding-left: defaults.$bs-side-spacing-phone;
  padding-right: defaults.$bs-side-spacing-phone;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding-left: defaults.$bs-side-spacing;
    padding-right: defaults.$bs-side-spacing;
  }
}

.bs-tile {
  @include defaults.font-roboto-regular;

  .bs-btn-base,
  .bs-lbl-base {
    font-size: .85em;
  }

  @media (min-width: #{defaults.$media-phone-width}) {

    .bs-btn-base,
    .bs-lbl-base {
      font-size: 1em;
    }
  }
}

.bs-tile-on-dark {
  color: defaults.$color-foreground;

  .bs-tile-header {
    background-color: defaults.$color-black-dark-bg-trans-dark;
  }

  .bs-tile-body {
    background-color: defaults.$color-black-dark-bg-trans;
  }

  .bst-subtitle {
    color: defaults.$color-text-lighter;
  }

  .bst-icon {

    svg {
      fill: defaults.$color-light-control-buttons;
    }
  }

  .bst-icon-active {

    &:active {

      svg {
        fill: defaults.$color-highlight-on-dark;
      }
    }
  }
}

.bs-tile-full-width {
  width: 100%;
}

.bs-tile-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: defaults.$bs-card-inner-spacing-phone;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding: defaults.$bs-card-inner-spacing;
  }
}

.bs-tile-body {
  padding: defaults.$bs-card-inner-spacing-phone;
  box-sizing: border-box;

  @media (min-width: #{defaults.$media-phone-width}) {
    padding: defaults.$bs-card-inner-spacing;
  }
}

.bst-hor-margin-left {
  margin-left: defaults.$bs-side-spacing-phone;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-left: defaults.$bs-side-spacing;
  }
}

.bst-hor-margin-right {
  margin-right: defaults.$bs-side-spacing-phone;

  @media (min-width: #{defaults.$media-phone-width}) {
    margin-right: defaults.$bs-side-spacing;
  }
}

.bst-icon {
  position: relative;
  width: 24px;
  height: 100%;
}

.bst-label {
  @include defaults.font-roboto-regular;

  min-width: 0;
  font-size: .85em;
  color: defaults.$color-grey;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1em;
  }
}

.bst-title-element {
  min-width: 0;
}

.bst-title {
  @include defaults.font-roboto-regular;

  font-size: 1em;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1.2em;
  }
}

.bst-subtitle {
  @include defaults.font-roboto-light;

  font-size: .9em;

  @media (min-width: #{defaults.$media-phone-width}) {
    font-size: 1em;
  }
}

.bstb-line {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  .bst-button-container {
    flex: 0 0 auto;
  }
}

.bst-button-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 30em;
  max-width: 100%;
}

.bst-row-button {
  flex: 0 1 auto;
  width: 100%;
}

.bstrb-left {
  @include bstrb-left;
}

.bstrb-center {
  @include bstrb-center;
}

.bstrb-right {
  @include bstrb-right;
}

.bs-tile-container {

  &.slip-reordering {

    .bs-tile-on-dark {

      .bst-title {
        color: defaults.$color-highlight-on-dark;
      }

      .bst-icon-select {
        @include basstyle_const.bs-btn-highlight-on-dark;
      }
    }
  }
}

.bas-open-close-devices {

  .bst-icon-select,
  .bas-open-close-edit-btn {
    display: none;
  }

  &.bas-state-editing {

    .bst-icon-select {
      display: block;
    }
  }

  &.bas-open-close-devices-can-edit-order {

    .bas-open-close-edit-btn {
      display: flex;
    }
  }
}

.bas-open-close {

  .bs-tile-body {
    display: none;
  }

  .bas-open-close-trigger,
  .bas-open-close-close,
  .bas-open-close-stop,
  .bas-open-close-open,
  .bas-open-close-state {
    display: none;
  }

  .bas-open-close-state-opened,
  .bas-open-close-state-closed {
    opacity: 0;
  }

  &.bas-open-close--has-open,
  &.bas-open-close--has-close {
    .bs-tile-body {
      display: block;
    }
  }

  &.bas-open-close--has-trigger {

    .bas-open-close-trigger {
      display: block;
    }
  }

  &.bas-open-close--has-open {

    .bas-open-close-open {
      display: block;

      &:active {
        @include basstyle_const.bs-btn-active-highlight-transparent-fill;
      }
    }
  }

  &.bas-open-close--has-close {

    .bas-open-close-close {
      display: block;

      &:active {
        @include basstyle_const.bs-btn-active-highlight-transparent-fill;
      }
    }
  }

  &.bas-open-close--has-stop {

    .bas-open-close-stop {
      display: block;

      &:active {
        @include basstyle_const.bs-btn-active-highlight-transparent-fill;
      }
    }
  }

  &.bas-open-close--has-state {

    .bas-open-close-state {
      display: block;
    }
  }

  &.bas-open-close--is-opened {

    .bas-open-close-open {
      @include basstyle_const.bs-btn-highlight-on-dark;

      &:active {
        @include basstyle_const.bs-btn-active-highlight-transparent-fill;
      }
    }

    .bas-open-close-state-opened {
      opacity: 1;
    }
  }

  &.bas-open-close--is-closed {

    .bas-open-close-close {
      @include basstyle_const.bs-btn-highlight-on-dark;

      &:active {
        @include basstyle_const.bs-btn-active-highlight-transparent-fill;
      }
    }

    .bas-open-close-state-closed {
      opacity: 1;
    }
  }

  &.bas-open-close--has-subtitle {

    .bst-subtitle {
      margin-top: .4em;
    }
  }

  // Margin for state icon next to trigger button

  &.bas-open-close--has-trigger.bas-open-close--has-state {

    .bas-open-close-state {
      margin-right: defaults.$bs-side-spacing-phone;

      @media (min-width: #{defaults.$media-phone-width}) {
        margin-right: defaults.$bs-side-spacing;
      }
    }
  }
}
