.bas-connect-stats-connection {

  .bas-connect-stats-webrtc {
    display: none;
  }

  &.bas-connect-type--demo {
    // Empty
  }

  &.bas-connect-type--local {
    // Empty
  }

  &.bas-connect-type--webrtc {

    .bas-connect-stats-webrtc {
      display: block;
    }
  }
}

.bas-connect-stats-overlay {
  position: absolute;
  top: 16px;
  left: 16px;
  pointer-events: none;
  z-index: 10;
  font-family: 'Courier New', sans-serif, monospace;
  color: white;
  padding: 8px;
  background-color: rgba(10, 10, 10, 0.75);
  font-size: 12px;
}

.bas-connect-stats-list-item {
  margin-left: 8px;
}

.bas-connect-stats-highlight {
  color: #1db954;
}
