@use 'defaults';
@use 'util';

$bas-image-bg-transition: background-size defaults.$standard-animation-time linear;

.bas-image {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-shadow: none;
  filter: none;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: $bas-image-bg-transition;

  &::after {
    @include util.absolute-match-parent();

    background-color: transparent;
  }

  svg {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: 50%;
    box-shadow: none;
    transform: translate(-50%, -50%);
    filter: none;
  }

  &.bas-image-background-cover {
    background-size: cover;
  }

  &.bas-image-background-contain {
    background-size: contain;
  }

  &.bas-image-color-foreground {

    svg {
      fill: defaults.$color-foreground;
    }
  }

  &.bas-image-color-highlight {

    svg {
      fill: defaults.$color-highlight-on-dark;
    }
  }

  &.bas-image-color-muted {

    svg {
      fill: defaults.$color-highlight-muted;
    }
  }

  &.bas-image-color-light-control {

    svg {
      fill: defaults.$color-light-control-buttons;
    }
  }

  &.bas-image-box-shadow {
    box-shadow: 0 0 40px #000;
  }

  &.bas-image-drop-shadow {
    filter: drop-shadow(0 0 10px #000);
  }

  &.bas-image-brightness-0 {

    &::after {
      content: '';
      background-color: rgb(0, 0, 0);
    }
  }

  &.bas-image-brightness-30 {

    &::after {
      content: '';
      background-color: rgba(0, 0, 0, .7);
    }
  }

  &.bas-image-brightness-50 {
    filter: brightness(50%);
  }

  &.bas-image-brightness-75 {

    &::after {
      content: '';
      background-color: rgba(0, 0, 0, .25);
    }
  }

  &.bas-image-blur-brightness-30 {
    filter: blur(20px);

    &::after {
      content: '';
      background-color: rgba(0, 0, 0, .7);
    }
  }

  &.bas-image-opacity-70 {
    opacity: .7;
  }

  &.bas-image-size-50 {
    background-size: 50%;

    svg {
      width: 50%;
    }
  }

  &.bas-image-size-60 {
    background-size: 60%;

    svg {
      width: 60%;
    }
  }

  &.bas-image-size-70 {
    background-size: 70%;

    svg {
      width: 70%;
    }
  }

  &.bas-image-size-80 {
    background-size: 80%;

    svg {
      width: 80%;
    }
  }

  &.bas-image-size-100 {

    svg {
      width: 100%;
    }
  }

  &.bas-image-top-40 {

    svg {
      top: 40%;
    }
  }

  &.bas-image-height-90 {

    svg {
      height: 90%;
    }
  }
}

.bas-image-video {
  @include util.absolute-center();

  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.bas-image-img {
  position: absolute;
  display: block;
  width: 50%;
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &.bii-tl {
    top: 0;
    left: 0;
  }

  &.bii-tr {
    top: 0;
    right: 0;
  }

  &.bii-bl {
    bottom: 0;
    left: 0;
  }

  &.bii-br {
    bottom: 0;
    right: 0;
  }
}

.bii-fade-in {
  transition: opacity defaults.$standard-animation-time linear;
}

.bii-init {
  opacity: 0;
}

.bas-image-transition {

  &.bas-image-trans-no-box-shadow {

    .bas-image {
      box-shadow: none;
    }
  }

  &.bas-image-transition--fade {

    .bas-image {
      &.ng-enter,
      &.ng-leave {
        transition: opacity .5s linear, $bas-image-bg-transition;
      }

      &.ng-enter-prepare {
        opacity: 0;
      }

      &.ng-enter {
        opacity: 0;
      }

      &.ng-enter-active {
        opacity: 1;
      }

      &.ng-leave-prepare {
        opacity: 1;
      }

      &.ng-leave {
        opacity: 1;
      }

      &.ng-leave-active {
        opacity: 0;
      }
    }

    &.bas-image-transition--fade-3 {

      .bas-image {
        &.ng-enter,
        &.ng-leave {
          transition: opacity .3s linear, $bas-image-bg-transition;
        }
      }
    }
  }
}
