@use 'sass:math';
@use 'defaults';
@use 'basstyle_const';
@use 'util';

$grid-button-spacing: 6px;
$navigation-size: 174px;
$navigation-arrow-size-multiplier: .28;
$navigation-arrow-size: $navigation-size * $navigation-arrow-size-multiplier;

$bvr-secondary-spacing: 16px;
$bvr-secondary-spacing-small: 8px;
$bvr-max-width: defaults.$media-query-tablet-landscape;
$bvr-main-max-width: 400px;
$bvr-secondary-width: 300px;
$bvr-info-margin: 15px;
$bvr-arrow-mid-spacing: 31px;
$bvr-section-spacing: 8px;
$media-width-always-show-grids: defaults.$media-width-medium;
$bvr-round-button-size: 60px;

$bvr-side-spacing: 16px;
$bvr-side-spacing-medium: 24px;
$bvr-side-spacing-large: 32px;
$bvr-side-spacing-xlarge: 56px;

$increase-touch-surface: 10px;
$increase-touch-surface-medium: 20px;
$increase-touch-surface-large: 32px;

$screen-width-touch-surface-medium: 380px;
$screen-width-touch-surface-large: 450px;

// Button grid

.bas-video-button-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  grid-template-rows: repeat(4, minmax(40px, 70px));
  grid-gap: $grid-button-spacing;
  width: 100%;
  flex: 0 1 auto;
  min-height: 0;
  padding: $grid-button-spacing;
  box-sizing: border-box;
  background-color: defaults.$color-background-dark-transparent-light;

  .bas-video-button {
    @include defaults.font-roboto-regular();

    color: defaults.$color-foreground;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: defaults.$color-background-light-practically-less-transparent;
    cursor: pointer;
    position: relative;

    &:not(.bas-video-button-disabled):active {
      background-color: defaults.$color-background-light-practically-transparent;
    }

    .bas-video-button-text {
      padding: 3px;

      &:empty {
        display: none;
      }
    }

    .bas-video-button-color {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      position: absolute;
    }

    .bas-video-button-icon {
      @include util.absolute-match-parent();

      position: relative;

      &:empty {
        display: none;
      }

      svg {
        @include util.absolute-center();

        height: 60%;
        fill: defaults.$color-foreground;
      }
    }
  }

  .bas-grid-bottom-center {
    grid-row-start: 4;
    grid-column-start: 2;
  }
}

.bas-video-button-grid--spacer {
  flex: 0 1000 60px;
}

// General layout

.bas-video-remote {
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-width: $bvr-max-width;
  max-height: $bvr-max-width;
  margin: auto;
  overflow: hidden;

  .bas-video-remote-main {
    height: 100%;
    flex-grow: 1;

    .bas-video-remote-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      justify-content: space-evenly;
      height: 100%;
      margin: 0 $bvr-side-spacing;

      @media (min-width: defaults.$media-width-xxxsmall) {
        margin: 0 #{$bvr-side-spacing-medium};
      }

      @media (min-width: defaults.$media-width-xxsmall) {
        margin: 0 #{$bvr-side-spacing-large};
      }

      @media (min-width: defaults.$media-width-xsmall) {
        margin: 0 #{$bvr-side-spacing-xlarge};
      }

      @media (min-width: defaults.$media-phone-width) {
        margin: 0 auto;
        padding-right: $bvr-side-spacing;
        max-width: $bvr-main-max-width;
      }

      @media (min-width: $bvr-max-width) {
        padding-right: 0;
      }
    }
  }

  .bas-video-remote-secondary {
    flex: 0;
    height: 100%;
    box-sizing: border-box;
    padding: $bvr-secondary-spacing;
    display: none;
    flex-direction: column;
    justify-content: center;

    @media (max-height: defaults.$media-width-medium) {
      @media (max-width: defaults.$media-phone-width) {
        padding: $bvr-secondary-spacing-small;
      }

      .bas-video-button-grid--spacer {
        background-color: defaults.$color-background-dark-transparent-light;
        flex-grow: 1000;
      }
    }
  }

  &.bpy-has-custom-grid,
  &.bpy-has-number-grid {
    .bas-video-remote-secondary {
      display: flex;
    }
  }

  &.bpy-has-custom-grid.bpy-has-number-grid {
    .bas-video-button-grid--spacer {
      display: initial;
    }

    @media (max-height: defaults.$media-width-medium) {

      .bas-video-button-grid--numbers {
        padding-top: 0;
      }
    }
  }

  &.bpy-has-custom-grid {
    .bas-video-button-grid--custom {
      display: grid;
    }
  }

  &.bpy-has-number-grid {
    .bas-video-button-grid--numbers {
      display: grid;
    }
  }

  // Main subsections

  .bas-video-remote-main--top {
    display: flex;
    justify-content: center;
    margin-top: $bvr-section-spacing;
    flex-direction: row;
    align-items: center;
  }

  .bas-video-remote-main--info {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    margin: 0 $bvr-info-margin 0 0;
  }

  .bas-video-remote-main--power {
    display: flex;
    justify-content: center;

    .bvr-power {
      width: $bvr-round-button-size;
      height: $bvr-round-button-size;
      cursor: pointer;
      // Static touch surface expansion
      margin: -#{$increase-touch-surface-medium};
      padding: $increase-touch-surface-medium;
      // Make it align better with channel buttons
      margin-right: -#{$increase-touch-surface-medium + 8px};

      .bvr-power-inner {
        position: relative;
        width: 100%;
        height: 100%;
        border: basstyle_const.$outline-button-border;
        border-radius: 100px;
        box-sizing: border-box;

        svg {
          @include util.absolute-center();

          width: 40%;
          transition: fill defaults.$standard-animation-time;
          fill: defaults.$color-foreground;
        }
      }

      &:active {

        svg {
          fill: defaults.$color-highlight-on-dark;
          transition: none;
        }
      }
    }
  }

  .bas-video-remote-main--navigation {
    display: flex;
    justify-content: space-between;
    margin: $bvr-section-spacing 0;

    .bvr-dumb-volume,
    .bvr-channel {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bvr-dumb-volume {
      margin-right: 14px;
    }

    .bvr-channel {
      margin-left: 14px;
    }
  }

  .bas-video-remote-main--volume {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: $bvr-section-spacing 0;

    .bvrm-slider {
      flex: 0 1 400px;
      padding: 0 5px;
    }
  }

  .bas-video-remote-main--buttons {
    display: flex;
    justify-content: space-between;
    justify-content: space-evenly;
    flex-direction: row;
    margin: $bvr-section-spacing 0;
    min-height: basstyle_const.$outline-button-width;
  }

  .bas-video-remote-main--media-controls {
    margin: $bvr-section-spacing 0;
  }

  // Swing out button grids
  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{$media-width-always-show-grids - 1}) {
    .bas-video-remote-secondary,
    .bas-video-remote-main {
      transition:
        transform defaults.$standard-animation-time-long ease-in-out,
        width defaults.$standard-animation-time-long ease-in-out;
    }

    .bas-video-remote-secondary {
      display: flex;
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      bottom: 0;
    }

    .bas-video-remote-main {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    &.bpy-has-custom-grid.bvr-show-grids,
    &.bpy-has-number-grid.bvr-show-grids {

      .bas-video-remote-secondary {
        transform: translate3d(100%, 0, 0);
      }

      .bas-video-remote-main {
        transform: translate3d(100%, 0, 0);
      }
    }

    @media (min-width: #{defaults.$media-phone-width}) {
      .bas-video-remote-secondary {
        left: -$bvr-secondary-width;
        width: $bvr-secondary-width;
      }

      .bas-video-remote-main {
        left: 0;
        width: 100%;
      }

      &.bpy-has-custom-grid.bvr-show-grids,
      &.bpy-has-number-grid.bvr-show-grids {

        .bas-video-remote-secondary {
          transform: translate3d(100%, 0, 0);
        }

        .bas-video-remote-main {
          transform: translate3d($bvr-secondary-width, 0, 0);
          width: calc(100% - #{$bvr-secondary-width});
        }
      }
    }
  }

  // Default hide

  .bvr-back,
  .bvr-menu,
  .bvr-enter,
  .bvr-navigation,
  .bas-video-remote-main--controls,
  .bas-video-button-grid--numbers,
  .bas-video-button-grid--spacer,
  .bas-video-button-grid--custom {
    display: none;
  }

  .bvrm-rewind,
  .bvrm-fast-forward,
  .bvrm-skip-next,
  .bvrm-skip-previous,
  .bvrm-play-pause,
  .bvr-dumb-volume,
  .bvr-channel,
  .bas-video-remote-main--volume,
  .bvr-arrow {
    @include util.bas-fake-hide-opacity();
  }

  .bvr-dumb-volume,
  .bvr-channel {

    .bvr-plus-minus--plus,
    .bvr-plus-minus--minus {
      @include util.bas-fake-hide-opacity();

      background-color: transparent;
    }
  }

  .bvr-navigation {
    border: none;
  }

  // Navigation

  &.bpy-can-up,
  &.bpy-can-down,
  &.bpy-can-left,
  &.bpy-can-right {

    .bvr-navigation {
      border: basstyle_const.$outline-button-border;
      display: block;
    }
  }

  &.bpy-can-enter {

    .bvr-navigation {
      display: block;
    }

    .bvr-enter {
      display: flex;
    }
  }

  &.bpy-can-up {
    .bvr-arrow-up {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bpy-can-down {
    .bvr-arrow-down {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bpy-can-left {
    .bvr-arrow-left {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bpy-can-right {
    .bvr-arrow-right {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bpy-can-back {
    .bvr-back {
      display: block;
    }
  }

  &.bpy-can-menu {
    .bvr-menu {
      display: block;
    }
  }

  // Media controls

  &.bpy-can-fast-forward {

    .bvrm-fast-forward {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bpy-can-rewind {

    .bvrm-rewind {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bpy-can-next {

    .bvrm-skip-next {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bpy-can-previous {

    .bvrm-skip-previous {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bas-can-play-pause {

    .bvrm-play-pause {
      @include util.bas-fake-show-opacity();
    }
  }

  // Volume

  &.bas-room--video--can-adjust-volume {

    .bas-video-remote-main--volume {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bas-room--video--can-volume-up,
  &.bas-room--video--can-volume-down {
    .bvr-dumb-volume {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bas-room--video--can-volume-up {

    .bvr-dumb-volume {

      .bvr-plus-minus--plus {
        @include util.bas-fake-show-opacity();
      }
    }
  }

  &.bas-room--video--can-volume-down {

    .bvr-dumb-volume {

      .bvr-plus-minus--minus {
        @include util.bas-fake-show-opacity();
      }
    }
  }

  &.bas-room--video--is-muted {
    .bpy-mute-on {
      display: block;
    }

    .bpy-mute-off {
      display: none;
    }
  }

  // Channel

  &.bpy-can-channel-up,
  &.bpy-can-channel-down {

    .bvr-channel {
      @include util.bas-fake-show-opacity();
    }
  }

  &.bpy-can-channel-up {

    .bvr-channel {

      .bvr-plus-minus--plus {
        @include util.bas-fake-show-opacity();
      }
    }
  }

  &.bpy-can-channel-down {

    .bvr-channel {

      .bvr-plus-minus--minus {
        @include util.bas-fake-show-opacity();
      }
    }
  }
}

// Navigation

.bvr-navigation {
  position: relative;
  min-width: $navigation-size;
  height: $navigation-size;
  border-radius: 50%;
  box-sizing: border-box;
  border: basstyle_const.$outline-button-border;
}

.bvr-navigation-inner {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.bvr-arrows {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  transform: rotate(45deg);
}

.bvr-arrow {
  position: relative;
  flex: 0 0 50%;
  cursor: pointer;
  display: inline-block;

  svg {
    position: absolute;
    transform: rotate(-45deg);
    width: 30px;
    fill: defaults.$color-foreground;
    transition: fill defaults.$standard-animation-time;
  }

  &:active {

    svg {
      fill: defaults.$color-highlight-on-dark;
      transition: none;
    }
  }

  // Arrows are sized up and rotated 45 deg to increase UX for touch surface
  &.bvr-arrow-up {
    svg {
      right: $bvr-arrow-mid-spacing;
      bottom: $bvr-arrow-mid-spacing;
    }
  }

  &.bvr-arrow-left {
    svg {
      top: $bvr-arrow-mid-spacing;
      right: $bvr-arrow-mid-spacing;
    }
  }

  &.bvr-arrow-right {
    svg {
      left: $bvr-arrow-mid-spacing;
      bottom: $bvr-arrow-mid-spacing;
    }
  }

  &.bvr-arrow-down {
    svg {
      left: $bvr-arrow-mid-spacing;
      top: $bvr-arrow-mid-spacing;
    }
  }
}

.bvr-enter {
  @include util.absolute-center();
  @include defaults.font-roboto-regular();

  color: defaults.$color-foreground;
  width: #{$navigation-size - 2 * basstyle_const.$outline-button-width};
  height: #{$navigation-size - 2 * basstyle_const.$outline-button-width};
  border-radius: 50%;
  border: basstyle_const.$outline-button-border;
  justify-content: center;
  align-items: center;
  transition: color defaults.$standard-animation-time;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;

  &:active {
    color: defaults.$color-highlight-on-dark;
    transition: none;
  }
}

// Plus-minus buttons

.bvr-plus-minus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-radius: #{basstyle_const.$outline-button-width * .5 + 4px};
  border: basstyle_const.$outline-button-border;
  position: relative;
  width: basstyle_const.$outline-button-width;
  height: $navigation-size;
  box-sizing: border-box;

  .bvr-plus-minus-text {
    @include defaults.font-roboto-light();

    width: 100%;
    flex-grow: 1;
    display: flex;

    .bvr-plus-minus-text-inner {
      color: defaults.$color-foreground;
      padding: 5px;
      box-sizing: border-box;
      text-align: center;
      width: 100%;
      transition: color defaults.$standard-animation-time;
      font-size: 2rem;
    }

    &:active .bvr-plus-minus-text-inner {
      color: defaults.$color-highlight-on-dark;
      transition: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .bvr-plus-minus--minus {
    align-items: flex-end;

    .bvr-plus-minus-text-inner {
      font-size: 2.5rem;
    }
  }

  .bvr-plus-minus--icon {
    @include util.absolute-match-parent();
    @include defaults.font-roboto-regular();

    pointer-events: none;
    position: absolute;
    font-size: 2rem;
    color: defaults.$color-foreground-dark;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      @include util.absolute-center();

      fill: defaults.$color-foreground-dark;
      width: 60%;
    }
  }
}

// Media controls

$bvrm-media-control-size: 45px;
$bvrm-media-control-size-large: $bvr-round-button-size;

.bvr-media-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .bvrm-media-control {
    cursor: pointer;
    // Static touch surface expansion
    margin: -#{$increase-touch-surface};
    padding: $increase-touch-surface;

    .bvrm-media-control-inner {
      height: $bvrm-media-control-size;
      width: $bvrm-media-control-size;
      position: relative;
    }

    svg {
      @include util.absolute-center();

      height: 40%;
      fill: defaults.$color-foreground;
      transition: fill defaults.$standard-animation-time;
    }

    &:active {
      svg {
        fill: defaults.$color-highlight-on-dark;
        transition: none;
      }
    }
  }

  .bvrm-play-pause .bvrm-media-control-inner {
    border: basstyle_const.$outline-button-border;
    border-radius: 50%;
    width: $bvrm-media-control-size-large;
    height: $bvrm-media-control-size-large;
    box-sizing: border-box;

    svg {
      height: 60%;
    }
  }
}

// Source and metadata

.bvr-source-name {
  @include defaults.font-roboto-regular();
  @include util.ellipsis-overflow();

  color: defaults.$color-foreground;
  font-size: 2rem;
  width: 90%;
}

.bvr-source-subtitle {
  @include defaults.font-roboto-regular();
  @include util.ellipsis-overflow();

  color: defaults.$color-foreground-dark;
  font-size: 1.1rem;
  padding: 10px 0 5px;
  width: 90%;
}

// Expand touch surface

@mixin expand-touch($expandSize) {
  .bvr-expand-touch-dynamic,
  .bvr-expand-touch-dynamic--top {
    padding-top: #{$expandSize};
    margin-top: -#{$expandSize};
  }

  .bvr-expand-touch-dynamic,
  .bvr-expand-touch-dynamic--right {
    padding-right: #{$expandSize};
    margin-right: -#{$expandSize};
  }

  .bvr-expand-touch-dynamic,
  .bvr-expand-touch-dynamic--bottom {
    padding-bottom: #{$expandSize};
    margin-bottom: -#{$expandSize};
  }

  .bvr-expand-touch-dynamic,
  .bvr-expand-touch-dynamic--left {
    padding-left: #{$expandSize};
    margin-left: -#{$expandSize};
  }
}

.bas-video-remote {

  // Default: small expansion
  @include expand-touch($increase-touch-surface);

  // Expand touch surface more as more space is available
  @media (min-width: $screen-width-touch-surface-medium) {
    @include expand-touch($increase-touch-surface-medium);
  }

  @media (min-width: $screen-width-touch-surface-large) {
    @include expand-touch($increase-touch-surface-large);
  }

  // Collapsable button grid, main controls can be narrow again
  // TODO: When using 'max-width' we should always add ' - 1' to the values to
  //  get consistent breakpoints when compared to 'min-width' media queries.
  //  Since this is often forgotten and also goes against a mobile-first
  //  approach, we should try to only use 'min-width' media queries instead.
  @media (max-width: #{$media-width-always-show-grids - 1}) and
    (min-width: #{defaults.$media-phone-width}) {

    &.bpy-has-custom-grid.bvr-show-grids,
    &.bpy-has-number-grid.bvr-show-grids {

      .bvr-expand-touch-dynamic {
        // Expand touch surface more as more space is available
        @include expand-touch($increase-touch-surface-medium);
      }
    }
  }
}
