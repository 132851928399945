@use 'defaults';
@use 'util';

$library-font-size: 16px;

$library-header-background-color: defaults.$color-library-header;
$library-header-fill: defaults.$color-library-control;

// Emblem

$bas-emblem-padding: .3em .6em;
$bas-emblem-radius: .8em;
$bas-emblem-font-size: .8em;

.bas-emblem {
  @include defaults.font-roboto-regular;

  display: inline;
  padding: $bas-emblem-padding;
  border-radius: $bas-emblem-radius;
  background-color: defaults.$color-highlight-on-light;
  font-size: $bas-emblem-font-size;
  vertical-align: baseline;
  text-align: center;
  color: defaults.$color-foreground;
}

// Multi cover art

$bas-multi-cover-art-icon-placeholder-size: 60%;
$bas-multi-cover-art-transition-duration: .2s;

.bas-multi-cover-art {
  overflow: hidden;

  .bas-icon-placeholder {
    position: absolute;
    display: block;
    width: $bas-multi-cover-art-icon-placeholder-size;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      @include util.absolute-center();

      width: 100%;
    }

    &.spotify {

      svg {
        fill: defaults.$color-spotify-green;
      }
    }
  }

  .image {
    position: absolute;
    display: inline-block;

    &.placeholder {
      position: relative;
      display: block;
      width: 100%;

      &.only-size {
        opacity: 0;
      }
    }

    &.single {
      height: 100%;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.multiple {
      width: 50%;

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }
  }
}

// Library list item info

$library-item-info-subtitle-font: .8em;
$library-item-info-subtitle-color: #8c8c8c;

.bas-library-item-info {
  .title-element,
  .subtitle-element {
    display: inline;
  }

  .addition {
    color: $library-item-info-subtitle-color;
  }

  .subtitle {
    color: $library-item-info-subtitle-color;
    font-size: $library-item-info-subtitle-font;
  }
}

// Library list item control

$library-item-control-icon-padding: 25%;
$library-item-control-icon-color-grey: #ababab;
$library-item-control-icon-color-grey-selected: #929292;
$library-item-control-icon-color-selected: rgba(#fff, 0.6);
$library-item-control-icon-background-color: rgba(#fff, 0);

$library-item-control-icon-transition-time: .2s;

$library-item-control-icon-medium-padding: 20%;
$library-item-control-icon-big-padding: 10%;

.bas-library-item-control {
  @include util.button-cursor();

  fill: $library-item-control-icon-color-grey;
  transition: fill $library-item-control-icon-transition-time;

  &::before {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    padding: 0 0 100%;
  }

  svg {
    @include util.absolute-center();

    width: 100%;
    padding: $library-item-control-icon-padding;
    border: 1px solid $library-item-control-icon-color-grey;
    border-radius: 100%;
    background-color: $library-item-control-icon-background-color;
    box-sizing: border-box;
    transition: border $library-item-control-icon-transition-time, background-color $library-item-control-icon-transition-time;
  }

  &.medium {
    svg {
      padding: $library-item-control-icon-medium-padding;
    }
  }

  &.big {
    svg {
      padding: $library-item-control-icon-big-padding;
    }
  }

  &.colored {
    fill: defaults.$color-highlight-on-light;

    svg {
      border: 1px solid defaults.$color-highlight-on-light;
    }
  }

  &.grey {
    fill: $library-item-control-icon-color-grey;

    svg {
      border: 1px solid $library-item-control-icon-color-grey;
    }

    &:active {
      fill: $library-item-control-icon-color-grey-selected;

      svg {
        border: 1px solid $library-item-control-icon-color-grey-selected;
      }
    }

    &.active {
      svg {
        background-color: $library-item-control-icon-color-grey;
      }
    }
  }

  &:active {
    fill: defaults.$color-highlight-on-light;

    svg {
      border: 1px solid defaults.$color-highlight-on-light;
    }
  }

  &.active {
    fill: $library-item-control-icon-color-selected;

    svg {
      background-color: defaults.$color-highlight-on-light;
    }
  }
}

// Library flex

.library-header {
  flex: 0 0 auto;
}

.library-navigation-bar {
  flex: 0 0 auto;
}

.library-content-wrapper {
  flex: 1 1 auto;
}

.library-content {
  flex: 1 1 auto;
}

// Library

$library-header-element-padding: .85em; // to match legacy queue header height

$library-header-border-wrapper-padding: 0 $library-header-element-padding 0 0;

$library-header-search-field-padding-side: .5em;
$library-header-search-field-padding: .3em $library-header-search-field-padding-side $library-header-search-field-padding-side .5em;

$library-header-search-icon-width: 20px;
$library-header-search-icon-height: $library-header-search-icon-width;
$library-header-icon-width: 1.35em;
$library-header-icon-height: $library-header-icon-width;

.library-header {
  @include defaults.font-roboto-light;

  position: relative;
  display: block;
  width: 100%;
  font-size: $library-font-size;
  background-color: $library-header-background-color;
}

.library-header-element {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: $library-header-element-padding;
}

.library-header-selection-word {
  @media (max-width: #{defaults.$media-phone-width}) {
    display: none;
  }
}

.library-back {
  @include util.button-cursor();
  @include defaults.font-roboto-regular;

  display: none;
  color: defaults.$color-highlight-on-light;
  padding: $library-header-element-padding 0 $library-header-element-padding $library-header-element-padding;

  &.active {
    display: inline-block;
  }

  .border-wrapper {
    display: inline-block;
    padding: $library-header-border-wrapper-padding;
    border-right: 1px solid $library-header-fill;
  }
}

.library-title {
  @include defaults.font-roboto-regular;
}

.library-section-title {
  @include defaults.font-roboto-regular;
}

.library-section-type {
  @include defaults.font-roboto-light;
}

// Library spinner

.library-spinner {
  float: right;
  white-space: nowrap;
}

// Library header actions

$library-header-actions-width: $library-header-icon-width + 2 * $library-header-element-padding;

.library-actions {
  float: right;
  width: $library-header-actions-width;
  padding: 0;
  white-space: nowrap;
  z-index: 1;

  &.active {
    width: auto;
  }
}

// Library header search icon

.search-icon {
  @include util.button-cursor();

  width: $library-header-icon-width;
  height: $library-header-icon-height;
  fill: $library-header-fill;

  svg {
    @include util.absolute-center();

    display: block;
    width: $library-header-search-icon-width;
  }
}

// Library Header selection

$library-header-selection-emblem-font-size: .8em;
$library-header-selection-emblem-margin: 0 0 0 .2em;
$library-header-selection-padding: 0;

.library-selection {
  @include defaults.font-roboto-light;

  position: static;
  font-size: $library-font-size;
  color: defaults.$color-foreground;
  background-color: defaults.$color-highlight-on-light;
  white-space: nowrap;

  .selection-emblem {
    display: inline;
    margin: $library-header-selection-emblem-margin;
    vertical-align: middle;
    color: defaults.$color-foreground;
    background-color: #000;
    font-size: $library-header-selection-emblem-font-size;
  }
}

// Library Header search field

.library-search {
  position: relative;
  display: none;
  padding: $library-header-search-field-padding;
  clear: both;

  .search-input {
    width: 100%;
    vertical-align: middle;
  }

  &.active {
    display: block;
  }
}

// Library Header Navigation bar

$library-header-navigation-border-top-color: #d1d1d1;
$library-header-navigation-background-color: #f7f7f7;

$library-header-navigation-font-size: .85em;
$library-header-navigation-margin: 0 .2em;
$library-header-navigation-border-radius: 10em;

.library-navigation-bar-background {
  background-color: $library-header-navigation-background-color;
  border-top: 1px solid $library-header-navigation-border-top-color;
}

// Library Header Sections

$library-header-section-transition: .2s;
$library-header-section-color-selected: rgba(defaults.$color-highlight-on-light, .55);

.library-section {
  @include util.button-cursor();
  @include defaults.font-roboto-regular;

  display: inline-block;
  margin: $library-header-navigation-margin;
  vertical-align: middle;
  font-size: $library-header-navigation-font-size;
  color: defaults.$color-highlight-on-light;
  background-color: transparent;
  border-radius: $library-header-navigation-border-radius;

  &.selected {
    color: defaults.$color-foreground;
    background-color: $library-header-section-color-selected;
  }

  &.active {
    color: defaults.$color-foreground;
    background-color: defaults.$color-highlight-on-light;
  }

  &.bas-library-tab-active {
    color: defaults.$color-foreground;
    background-color: defaults.$color-highlight-on-light;

    &.bas-library-tab-selected {
      color: defaults.$color-foreground;
      background-color: $library-header-section-color-selected;
    }
  }
}

// Library Anchors

$library-header-anchor-margin: 0;
$library-header-anchor-padding-side: .2em;
$library-header-anchor-padding: .2em $library-header-anchor-padding-side .3em $library-header-anchor-padding-side;

$library-header-anchor-min-width: .8em;

.library-anchors {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;
}

.library-anchor {
  @include util.button-cursor();
  @include defaults.font-roboto-regular;

  flex: 1 1 auto;
  display: inline-block;
  min-width: $library-header-anchor-min-width;
  margin: $library-header-anchor-margin;
  padding: $library-header-anchor-padding;
  vertical-align: middle;
  text-align: center;
  font-size: $library-header-navigation-font-size;
  color: defaults.$color-background;
  background-color: transparent;
  border-radius: $library-header-navigation-border-radius;
  transition: background-color .2s, color .2s;

  &:active {
    color: defaults.$color-foreground;
    background-color: defaults.$color-highlight-on-light;
  }
}

// Library content

$library-content-border: 1px solid defaults.$color-library-border;
$library-content-fade: .2s;

.library-content-wrapper {
  position: relative;
  overflow-y: auto;
  border-top: $library-content-border;
  font-size: $library-font-size;
  opacity: 0;
}

.library-content {
  position: relative;
  overflow-y: auto;
  border-top: $library-content-border;
  font-size: $library-font-size;
}

.bas-library-content-show {
  opacity: 1;
  transition: opacity $library-content-fade ease-in-out;
}

.library-view {
  position: relative;
  width: 100%;
  height: 100%;
}

// Library Main

#new-main-library.library-main {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-size: $library-font-size;
  transform: translate3d(0, 0, 0);
}

// Library type header

$library-category-header-margin: .5em;
$library-category-header-size: 1.5em;

$library-category-header-color: #ababab;
$library-category-header-line-color: #c5c5c5;

$library-category-header-line-height: 1px;
$library-category-header-line-offset: .5em;

.library-category-header {
  @include defaults.font-roboto-light;

  position: relative;
  display: block;
  margin: $library-category-header-margin;
  overflow: hidden;
  text-align: center;
  font-size: $library-category-header-size;
  color: $library-category-header-color;

  &::before,
  &::after {
    content: '';
    position: relative;
    display: inline-block;
    height: $library-category-header-line-height;
    width: 50%;
    vertical-align: middle;
    background-color: $library-category-header-line-color;
  }

  &::after {
    left: $library-category-header-line-offset;
    margin-right: -50%;
  }

  &::before {
    right: $library-category-header-line-offset;
    margin-left: -50%;
  }
}

// Library list

$library-list-transition-duration: .25s;

.main-list {
  position: relative;
  list-style-type: none;
}

// Library list item

$library-list-item-font-size: 1em;
$library-list-item-width: 95%;
$library-list-item-margin: 0 auto;
$library-list-item-padding: .7em 0;
$library-list-item-padding-small: .3em 0;
$library-list-item-padding-small-first: .7em 0 .3em 0;
$library-list-item-color: #1f1f1f;
$library-list-item-separator: 1px solid rgba(#fff, 0.6);

$library-list-item-element-spacing: 1em;

$library-list-item-flex-element-margin: 0 $library-list-item-element-spacing;

$library-list-item-art-width: 8%;
$library-list-item-art-width-small: 4.5%;
$library-list-item-art-min-width: 2.8em;

$library-list-item-control-flex-basis: 36px;

.main-list-item {
  @include util.button-cursor();
  @include defaults.font-roboto-light;

  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  width: $library-list-item-width;
  margin: $library-list-item-margin;
  padding: $library-list-item-padding;
  font-size: $library-list-item-font-size;
  color: $library-list-item-color;
  border-bottom: $library-list-item-separator;
  box-sizing: border-box;

  .flex-element {
    position: relative;
    align-self: stretch;
    margin: $library-list-item-flex-element-margin;

    &.item-art {
      flex: 1 1 auto;
      min-width: $library-list-item-art-min-width;
      width: $library-list-item-art-width;
      margin: 0;
    }

    &.item-info {
      flex: 20 1 0;
      align-self: center;
      margin: 0 0 0 $library-list-item-element-spacing;
      min-width: 0;
    }

    &.item-icon {
      flex: 0 0 $library-list-item-control-flex-basis;
      margin: 0 0 0 $library-list-item-element-spacing;
    }
  }

  &.small-list-item {
    padding: $library-list-item-padding-small;

    .item-art {
      width: $library-list-item-art-width-small;
    }

    &:first-child {
      padding: $library-list-item-padding-small-first;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

// Library infinite-scroll

.bas-library-infinite-scroll {
  height: 40px;
}

.bas-library-infinite-scroll-spinner {
  height: 100%;
  top: 10px;
}

// Detail view

#playlist-detail-new.library-main {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  font-size: $library-font-size;
  transform: translate3d(0, 0, 0);
}

// Library Detail header

$library-header-detail-width: 95%;

$library-header-detail-font: 1em;
$library-header-detail-margin: 1em auto;

$library-header-detail-art-min-width: 4em;
$library-header-detail-art-width: 10%;

$library-header-detail-controls-padding: 0 0 46px 0;

.header-detail {
  position: relative;
  display: block;
  width: $library-header-detail-width;
  margin: $library-header-detail-margin;
  padding: 0;
  font-size: $library-header-detail-font;

  .header-content {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    align-content: flex-start;

    .header-art {
      position: relative;
      flex: 1 1 auto;
      min-width: $library-header-detail-art-min-width;
      width: $library-header-detail-art-width;
    }

    .header-info {
      position: relative;
      flex: 5 1 0;
      min-width: 0;
    }
  }

  .header-controls {
    position: absolute;
    right: 0;
    bottom: 0;

    @media (orientation: portrait), (max-width: 860px) {
      position: relative;
    }
  }
}

// Library Detail header info

$library-header-detail-into-font-size: 1.2em;
$library-header-detail-info-margin: 0 1em;

$library-header-detail-title-font-size: 1em;

$library-header-detail-title-transition: 1s;

$library-header-detail-subtitle-font-size: .8em;
$library-header-detail-subtitle-color: #c5c5c5;

$library-header-detail-tag-margin: 0 .3em 0 0;

$library-header-detail-emblem-margin: 0 0 0 .4em;
$library-header-detail-emblem-font-size: .8em;

.header-info {
  margin: $library-header-detail-info-margin;
  font-size: $library-header-detail-into-font-size;
  min-height: 3em;

  .title,
  .subtitle {
    display: block;
    text-align: left;
  }

  .title {
    @include defaults.font-roboto-light;

    position: relative;
    height: 1.8em;
    font-size: $library-header-detail-title-font-size;
    max-width: 100%;
  }

  .subtitle {
    @include defaults.font-roboto-regular;

    font-size: $library-header-detail-subtitle-font-size;
    color: $library-header-detail-subtitle-color;
  }

  .detail-tag {
    margin: $library-header-detail-tag-margin;
  }

  .title-element {
    display: inline-block;
    vertical-align: middle;
  }

  .bas-emblem {
    margin: $library-header-detail-emblem-margin;
    font-size: $library-header-detail-emblem-font-size;
    min-width: 0;
  }
}

// Library Detail header tag

$library-detail-tag-padding: .2em .4em .3em .4em;
$library-detail-tag-radius: .6em;
$library-detail-tag-color: #bfbfbf;
$library-detail-tag-font: .8em;

.detail-tag {
  padding: $library-detail-tag-padding;
  border-radius: $library-detail-tag-radius;
  border: 1px solid $library-detail-tag-color;
  font-size: $library-detail-tag-font;
  color: $library-detail-tag-color;
}

// Library Detail header controls

$library-header-detail-control-basis: 36px;
$library-header-detail-control-margin-top: .4em;
$library-header-detail-control-margin: $library-header-detail-control-margin-top 0 0 1em;
$library-header-detail-control-first-margin: $library-header-detail-control-margin-top 0 0 0;

.header-controls {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: stretch;
  max-width: 100%;

  .control {
    position: relative;
    flex: 0 0 $library-header-detail-control-basis;
    margin: $library-header-detail-control-margin;
    width: $library-header-detail-control-basis;
    height: $library-header-detail-control-basis;

    &:first-child {
      margin: $library-header-detail-control-first-margin;
    }
  }
}

// Library input

$library-input-padding: .25em .75em;
$library-input-radius: .8em;
$library-input-font-size: 1em;
$library-title-input-font-size: .68em;

.library-search-input {
  padding: $library-input-padding;
  border-radius: $library-input-radius;
  font-size: $library-input-font-size;
}

.library-title-input {
  padding: $library-input-padding;
  border-radius: $library-input-radius;
  font-size: $library-title-input-font-size;
  max-width: 100%;
}

// Library Detail placeholder

$library-detail-placeholder-font-size: 1.4em;

.detail-placeholder {
  @include util.absolute-center();
  @include defaults.font-roboto-light;

  display: block;
  font-size: $library-detail-placeholder-font-size;
}
