.bas-visible {
  visibility: visible;
  pointer-events: auto;
}

.bas-invisible {
  visibility: hidden;
  pointer-events: none;

  * {
    pointer-events: none;
  }
}

.bas-show {
  opacity: 1;
  pointer-events: auto;
}

.bas-hide {
  opacity: 0;
  pointer-events: none;

  * {
    pointer-events: none;
  }
}

.bas-value-slider {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
